<template>
	<div>
		<ValidationObserver ref="form">
			<form name="form" lba-size-class="{'contentWide': 700}">
				<lba-section
					:parentComponentId="currentComponentId"
					v-bind:title="$t('settings.user.user')"
					slotType="form"
					class="size-6"
					expanded
				>
					<s class="size-3">
						<small>{{ $t('settings.user.firstname') }}</small>
						<input
							type="text"
							name="firstname"
							v-model="user.firstname"
							:disabled="readOnly"
							@input="$emit('input'); addAvoided('firstname', user.firstname, $t('settings.user.firstname'));"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__firstName__inputText`"
						>
					</s>
					<s class="size-3">
						<small>{{ $t('settings.user.lastname') }}</small>
						<input
							type="text"
							name="lastname"
							v-model="user.lastname"
							:disabled="readOnly"
							@input="$emit('input'); addAvoided('lastname', user.lastname, $t('settings.user.lastname'));"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__lastName__inputText`"
						>
					</s>
					<ValidationProvider
						:name="$t('settings.user.login')"
						v-slot="{ invalid, errors }"
						:rules="{ required: true, regex: '^[-@a-zA-Z0-9_.]+$', is_not: 'system' }"
					>
						<s class="size-3">
							<small>{{ $t('settings.user.login') }} <span class="required-sign"></span></small>
							<input
								type="text"
								name="user_name"
								:data-cy="`${currentComponentId}__userName__inputText`"
								v-model="user.user_name"
								:disabled="user.id"
								@change="$emit('change'); user.user_name = $_.trim(user.user_name)"
								@input="$emit('input'); addAvoided('login', user.user_name, $t('settings.user.login'));"
								:class="{ 'lba-invalid': invalid && isDirty }"
							>
							<template v-if="isDirty">
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`${currentComponentId}__userName__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</template>
						</s>
					</ValidationProvider>
					<s class="size-3">
						<small>{{ $t('settings.user.company') }}</small>
						<input
							type="text"
							name="company"
							v-model="user.company"
							:disabled="readOnly"
							@input="$emit('input')"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__company__inputText`"
						>
					</s>
					<ValidationProvider
						:name="$t('settings.user.email')"
						v-slot="{ invalid, errors }"
						:rules="{
							email: true,
							required: user.send_wizard ? user.send_wizard : false,
						}"
					>
						<s class="size-3">
							<small>{{ $t('settings.user.email') }}<span v-if="user.send_wizard" class="required-sign"></span></small>
							<input
								:data-cy="`${currentComponentId}__email__inputText`"
								type="text"
								name="email"
								v-model="user.email"
								:disabled="readOnly"
								@change="$emit('change'); user.email = $_.trim(user.email)"
								@input="$emit('input'); addAvoided('email', user.email, $t('settings.user.email'));"
								:class="{ 'lba-invalid': invalid && isDirty }"
							>
							<template v-if="isDirty">
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`${currentComponentId}__email__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</template>
						</s>
					</ValidationProvider>
					<s class="size-3" v-if="$user.allowedPerUserChanges.allow_language_per_user">
						<small>{{ $t('settings.user.language') }}</small>
						<select
							name="lang"
							v-model="user.lang"
							:disabled="readOnly"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__language__select`"
						>
							<option value="cs" :data-cy="`${currentComponentId}__language__select__optionCs`">česky</option>
							<option value="en" :data-cy="`${currentComponentId}__language__select__optionEn`">english</option>
						</select>
					</s>
					<s class="size-3" v-if="$user.allowedPerUserChanges.allow_timezone_per_user">
						<small>{{ $t('settings.user.timezone') }}</small>
						<select
							name="timezone"
							v-model="user.timezone"
							:disabled="readOnly"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__timezone__select`"
						>
							<option :value="null" :data-cy="`${currentComponentId}__timezone__select__option0`">
								{{ defaultTimezone ? $t('settings.defaultWithInfo', { info: defaultTimezone }) : $t('settings.default') }}
							</option>
							<option v-for="(tz, index) in this.timezoneList"
								v-bind:key="index"
								v-bind:value="tz"
								:data-cy="`${currentComponentId}__timezone__select__option${index+1}`"
							>
								{{ tz }}
							</option>
						</select>
					</s>
					<s class="size-6">
						<small>{{ $t('settings.user.description') }}</small>
						<textarea
							name="description"
							v-model="user.description"
							:disabled="readOnly"
							@input="$emit('input')"
							@change="$emit('change')"
							:data-cy="`${currentComponentId}__description__textarea`"
						></textarea>
					</s>
					<div v-if="!hideCredentials">
						<div class="fieldset fieldsetBottom">
							<s class="block">
								<label
									v-if="$root.servers[0].agents_enabled && isNew"
									class="checkbox"
									:data-cy="`${currentComponentId}__createAgent__checkboxLabel`"
								>
									<input
										:data-cy="`${currentComponentId}__createAgent__inputCheckbox`"
										type="checkbox"
										name="createAgent"
										v-model="user.createAgent"
										:disabled="readOnly"
										@change="$emit('change')"
									>
									<i class="icon-ok"></i>
									<span class="label">{{ $t('settings.user.createAgent') }}</span>
								</label>
								<button
									:data-cy="`${currentComponentId}__showAgentInNewTab`"
									v-if="user.agent_uid"
									class="buttonIcon m-2"
									type="button"
									@click="openAgent"
								>
									<i class="icon-new-tab"></i>{{ $t('settings.user.showAgent') }}
								</button>
							</s>
						</div>
					</div>
				</lba-section>
				<lba-section
					:parentComponentId="currentComponentId"
					v-bind:title="$t('settings.login')"
					slotType="form"
					class="size-6"
					expanded
				>
					<div v-if="!hideCredentials">
						<template v-if="isNew">
							<s class="size-3">
								<label
									class="checkbox"
									:data-cy="`${currentComponentId}__send_wizard__checkboxLabel`"
								>
									<input
										:data-cy="`${currentComponentId}__send_wizard__inputCheckbox`"
										type="checkbox"
										name="send_wizard"
										v-model="user.send_wizard"
										:disabled="readOnly"
										@change="$emit('change')"
									>
									<i class="icon-ok"></i>
									<span class="label">
										{{ $t('settings.user.sendWizard') }}
										<i class="icon-info" v-tooltip="$t(`settings.user.sendWizardInfo`)"></i>
									</span>
								</label>
							</s>
							<br>
						</template>
						<s class="size-3">
							<label class="checkbox" :data-cy="`${currentComponentId}__canLogin__checkboxLabel`">
								<input
									:data-cy="`${currentComponentId}__canLogin__inputCheckbox`"
									type="checkbox"
									name="can_login"
									v-model="user.can_login"
									:disabled="readOnly"
									@change="$emit('change')"
								>
								<i class="icon-ok"></i>
								<span class="label">{{ $t('settings.user.allowLogin') }}</span>
							</label>
						</s>
						<s v-if="pbxLoaded" class="size-3">
							<label class="checkbox" :data-cy="`${currentComponentId}__requireExten__checkboxLabel`">
								<input
									type="checkbox"
									name="requireExten"
									:data-cy="`${currentComponentId}__requireExten__inputCheckbox`"
									v-model="user.exten_login"
									:disabled="readOnly || !user.can_login"
									@change="$emit('change')"
								>
								<i class="icon-ok"></i>
								<span class="label">{{ $t('settings.requireExten') }}</span>
							</label>
						</s>
						<br>
						<s class="size-3">
							<label class="checkbox" :data-cy="`${currentComponentId}__forcePasswordChange__checkboxLabel`">
								<input
									type="checkbox"
									name="force_password_change"
									:data-cy="`${currentComponentId}__forcePasswordChange__inputCheckbox`"
									v-model="user.force_password_change"
									:disabled="readOnly || !user.can_login"
									@change="$emit('change')"
								>
								<i class="icon-ok"></i>
								<span class="label">{{ $t('settings.forcePasswordChange') }}</span>
							</label>
						</s>
						<br>
						<s class="size-3">
							<small>
								{{ $t('settings.user.tfaRequirement') }}
							</small>
							<select
								name="tfa_required"
								v-model="user.tfa_required"
								:disabled="readOnly || !user_tfa_required_write || !user.can_login"
								@change="$emit('change')"
								:data-cy="`${currentComponentId}__tfa_required__select`"
							>
								<option
									:value="null"
									:data-cy="`${currentComponentId}__tfa_required__select__option0`"
								>
									{{ $t(`settings.default`) + getRequiredString() }}
								</option>
								<option
									:value="false"
									:data-cy="`${currentComponentId}__tfa_required__select__option1`"
								>
									{{ $t('tfaMethods.tfaRequiredNo') }}
								</option>
								<option
									:value="'forced'"
									:data-cy="`${currentComponentId}__tfa_required__select__option2`"
								>
									{{ $t('tfaMethods.tfaRequiredYesAfterLogin') }}
								</option>
								<option
									:value="'noLogin'"
									:data-cy="`${currentComponentId}__tfa_required__select__option2`"
								>
									{{ $t('tfaMethods.tfaRequiredYes') }}
								</option>
							</select>
						</s>
						<s v-if="tfaMethodsList.length && !hideAuthMethod">
							<small>{{ $t('settings.user.tfa') }}</small>
							<div
								v-if="!user.tfa || !user.tfa.length"
							>
								{{ $t(`notSet`) }}
							</div>
							<div
								v-else
							>
								{{ getTfa() }}
							</div>
						</s>
						<!-- active/expired token info -->
						<s
							v-if="!user.can_login && user.active_token_created"
							class="size-3 value-listing mt-2"
						>
							<small>{{ $t('settings.user.activeToken') }}</small>
							{{ $t('settings.user.created') }} - {{ $d(new Date(user.active_token_created), 'medium') }}
							<i class="icon-info"
								v-tooltip="`${$t('settings.user.expire')} - ${$d(new Date(user.active_token_expiration), 'medium')} ` +
									`(${moment(new Date(user.active_token_expiration)).locale($i18n.locale).fromNow()})`"
							></i>
						</s>
						<s
							v-else-if="!user.can_login && !user.active_token_created && user.last_invalid_token_expiration"
							class="size-3 value-listing mt-2"
						>
							<small>{{ $t('settings.user.activeToken') }}</small>
							<i class="icon-alert"></i>
							{{ $t('settings.user.expired') }} - {{ $d(new Date(user.last_invalid_token_expiration), 'medium') }}
							{{ ` (${moment(new Date(user.last_invalid_token_expiration)).locale($i18n.locale).fromNow()})` }}
							<i class="icon-info"
								v-tooltip="`${$t('settings.user.created')} - ${$d(new Date(user.last_invalid_token_created), 'medium')} ` +
									`(${moment(new Date(user.last_invalid_token_created)).locale($i18n.locale).fromNow()})`"
							></i>
						</s>
						<br>
						<s class="size-3" v-if="!hideAuthMethod">
							<small>{{ $t('settings.user.authMethod') }}</small>
							<select
								name="auth_method"
								v-model="user.auth_method"
								:disabled="readOnly || !user.can_login"
								@change="$emit('change')"
								:data-cy="`${currentComponentId}__authMethod__select`"
							>
								<option
									v-bind:key="`authMethod-0`"
									v-bind:value="null"
									:data-cy="`${currentComponentId}__authMethod__select__option0`"
								>
									{{ $t(`authMethods.lbadmin`) }}
								</option>
								<option v-for="(auth, index) in authMethodsList"
									v-bind:key="`authMethod-${index+1}`"
									v-bind:value="auth.auth_method"
									:data-cy="`${currentComponentId}__authMethod__select__option${index+1}`"
								>
									{{ $t(`authMethods.${auth.auth_method}`) }}
								</option>
							</select>
						</s>
						<br>
						<s class="size-3">
							<small>{{ $t('settings.user.validUntil') }}</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="validUntil"
								v-model="user.valid_until"
								mode="date"
								:initEmpty="false"
								:format="'D.M.YYYY'"
								:readOnly="readOnly || !user.can_login"
								@input="$emit('input')"
							></lba-datepicker>
						</s>
						<br>
						<ValidationProvider
							v-if="!user.send_wizard && showPasswd"
							:name="$t('settings.user.password')"
							v-slot="{ invalid, errors }"
							:rules="{
								required: (showPasswd && !user.password_t && user.can_login),
								regex: passwordRegex,
								notContains: (passwordRules && passwordRules.easy_to_guess) ? avoidedItems : []
							}"
						>
							<s class="size-3">
								<small>
									{{ $t('settings.user.password') }}
									<span v-if="showPasswd && !user.password_t && user.can_login" class="required-sign"></span>
								</small>
								<lba-input-password
									:parentComponentId="currentComponentId"
									componentId="password"
									v-model="user.password"
									:hasPassword="user.password_t"
									:isPasswordReadable="false"
									:required="true"
									:invalid="invalid && isDirty"
									:writeable="!readOnly && user.can_login"
									:readable="!readOnly && user.can_login"
									@input="$emit('input'); testingPassword = user.password;"
									@change="$emit('change')"
									:class="{ 'lba-invalid': invalid && isDirty }"
									:rules="passwordRules"
									autocomplete="new-password"
								></lba-input-password>
								<template v-if="isDirty">
									<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
									<span
										v-for="(err, index) in passwordErrors"
										:key="`pwe-${index}`"
										:data-cy="`${currentComponentId}__password__error${errors.length + index}`"
										class="lba-messages"
									>{{ err }}</span>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`${currentComponentId}__password__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</template>
							</s>
						</ValidationProvider>
						<s class="size-3" v-if="!user.send_wizard && showPasswd">
							<small>{{ $t('settings.user.passExpiration') }}</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="passwordExpiration"
								v-model="user.password_expiration"
								mode="date"
								:initEmpty="false"
								:format="'D.M.YYYY'"
								:readOnly="readOnly || !user.can_login"
								@input="$emit('input')"
							></lba-datepicker>
						</s>
						<br>
						<s class="size-3 value-listing" v-if="user.id">
							<small>{{ $t('settings.user.lastLogin') }}</small>
							<span v-if="user.last_login">{{ $d(new Date(user.last_login), 'long') }}</span>
							<span v-else>{{ $t('settings.user.notLoggedYet') }}</span>
						</s>
					</div>
				</lba-section>
				<div v-if="!hideExtra">
					<lba-section
						v-if="!$_.isEmpty(extraSettings)"
						:parentComponentId="currentComponentId"
						componentId="extraSettings"
						v-bind:title="$t('settings.user.extra')"
						slotType="form" expanded
					>
						<template #default="props">
							<template v-for="(setting, index) in extraSettings">
								<custom-value
									v-if="!setting.permsDisabled"
									:key="`${index}-${extraSettingsKey}`"
									:componentId="`extraSetting${index}`"
									:attribute="{
										options: {
											...setting.options,
											parentComponentId: props.parentComponentId,
											name: setting.name,
										},
										key: setting.name,
										data: user,
										readOnly
									}"
									@input="$emit('input')"
									@change="$emit('change')"
								></custom-value>
							</template>
						</template>
					</lba-section>
				</div>
			</form>
		</ValidationObserver>
		<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
		<!-- <lba-dialog name="settings-update-auth-qrcode" v-on:close="resetOTPDialog" :parentComponentId="currentComponentId">
			<div v-if="otpDialog.step === 0" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.passwordChange') }}</h4>
				<div class="col2">
					<p>{{ $t('settings.user.otp.resetOtpInfo') }}</p>
					<div class="popupButtons">
						<button type="button" class="buttonBig" :disabled="otpDialog.loading"
							@click="requestQRCode" :data-cy="`${currentComponentId}__QRCode__request`"
						>
							<i class="icon16 icon-ok"></i>&nbsp;
							<span>{{ $t('settings.user.otp.understand') }}</span>
						</button>
					</div>
				</div>
			</div>
			<div v-if="otpDialog.step === 1" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.otp.enterPassword') }}</h4>
				<div class="form">
					<s>
						<small class="alignLeft">{{ $t('password') }}</small>
						<input type="password" v-model="otpDialog.password" :data-cy="`${currentComponentId}__QRCode__inputPassword`">
					</s>
				</div>
				<div class="popupButtons">
					<button type="button" class="buttonBig" v-bind:disabled="otpDialog.loading" v-on:click="requestQRCode"
						:data-cy="`${currentComponentId}__QRCode__request`"
					>
						<i class="icon16 icon-ok"></i>&nbsp;
						<span>{{ $t('settings.user.otp.loadQRCode') }}</span>
					</button>
				</div>
			</div>
			<div v-if="otpDialog.step === 2" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.otp.newQRCode') }}</h4>
				<div class="content">
					<p>{{ $t('scanQRCodeInfo') }}</p>
					<svg class="qrcode" v-bind:viewBox="`0 0 ${otpDialog.qr.size} ${otpDialog.qr.size}`">
						<path v-bind:d="otpDialog.qr.path" />
					</svg>
				</div>
			</div>
		</lba-dialog> -->
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import PasswordMixin from '../mixins/Password';
import OptionsModel from '../models/Options';

export default {
	name: 'LbaUserForm',
	mixins: [ComponentIdentifier, PasswordMixin],
	props: {
		user: {
			type: Object,
			default: null,
		},
		model: Object,
		groups: Array,
		extraSettings: Array,
		expanded: Boolean,
		hideAuthMethod: Boolean,
		hideExtra: Boolean,
		hideCredentials: Boolean,
		isDirty: Boolean,
		invalidPass: {
			type: Boolean,
			default: false,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
		user_tfa_required_write: {
			type: Boolean,
			default: false,
		},
		extraSettingsKey: [String, Number],
	},
	data() {
		return {
			authMethodsList: [],
			tfaMethodsList: [],
			// otpDialog: {
			// 	loading: false,
			// 	step: 1,
			// 	password: '',
			// },
			userEditingHimself: false,
			timezoneList: [

			],
			loaded: false,
			validationDebouncer: null,
			validating: false,
			optionsModel: null,
			avoidedItems: [],
			avoidedItemsWhole: {},
			tfa_required_translations: {
				false: this.$t('tfaMethods.tfaRequiredNo'),
				forced: this.$t('tfaMethods.tfaRequiredYesAfterLogin'),
				noLogin: this.$t('tfaMethods.tfaRequiredYes'),
			},
			defaultTimezone: null,
		};
	},
	computed: {
		showPasswd() {
			return (['null', 'gAuth', 'agent'].includes(this.user.auth_method || 'null'));
		},
		pbxLoaded() {
			return (
				this.$user &&
				this.$user.loaded &&
				this.$user.loadedModules &&
				this.$user.loadedModules.includes('pbx')
			);
		},
	},
	watch: {
		user: {
			immediate: true,
			deep: true,
			handler(newValue) {
				this.validationDebouncer?.emit();
				this.user.force_password_change = this.user.force_password_change === 'true' || this.user.force_password_change === true;
			},
		},
	},
	async created() {
		this.model.queryAction('timezoneList').then((response) => {
			this.timezoneList = response;
		});

		this.optionsModel = new OptionsModel(this.$http);
		const resp = await this.optionsModel.getAuth();
		this.authMethodsList = $_.filter(resp.data.auth_methods, 'enabled');
		$_.forEach(resp.data.tfa_methods, (m) => {
			if (m.enabled) {
				this.tfaMethodsList.push({
					value: m.tfa_method,
					label: this.$t(`tfaMethods.methods.${m.tfa_method}`),
				});
			}
		});

		this.defaultTimezone = this.$root?.servers[0]?.timezone || '';

		this.validationDebouncer = new this.$Debouncer(
			this,
			this.validate,
			null,
			200
		);

		this.user.force_password_change = this.user.force_password_change === 'true' || this.user.force_password_change === true;
		this.addAvoided('firstname', this.user.firstname, this.$t('settings.user.firstname'));
		this.addAvoided('lastname', this.user.lastname, this.$t('settings.user.lastname'));
		this.addAvoided('login', this.user.login, this.$t('settings.user.login'));
		this.addAvoided('email', this.user.email, this.$t('settings.user.email'));
	},
	methods: {
		resetOTPDialog() {
			this.otpDialog = {
				loading: false,
				step: 1,
				password: '',
			};
		},
		async requestQRCode() {
			// show new QR code only if current user is editing himself
			if (!this.userEditingHimself) {
				this.resetOTPDialog();
				this.$emit('dialog-close', 'settings-update-auth-qrcode');
				return;
			}

			this.otpDialog.loading = true;
			const response = await this.model.otpQrCode({ password: this.otpDialog.password });
			this.otpDialog.step = 2;
			this.otpDialog.password = '';
			this.otpDialog.imageDataUrl = response.imageDataUrl;
		},
		openAgent() {
			this.$routerWrap.push({
				name: 'pbx-agent',
				params: { id: this.user.agent_uid, openNewTab: true },
			});
		},
		async validate() {
			if (
				this.validating ||
				!this.$refs ||
				!this.$refs.form
			) {
				return;
			}
			this.validating = true;
			this.user.valid = await this.$refs.form.validate();
			this.validating = false;
		},
		addAvoided(key, value, label) {
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			$_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
		getRequiredString() {
			return ` (${this.tfa_required_translations[this.$root.servers[0].tfa_required_global]})`;
		},
		getTfa() {
			const tfa = [];
			$_.forEach(this.user.tfa, (el) => {
				tfa.push(this.$t(`tfaMethods.methods.${el}`));
			});
			return tfa.join(', ');
		},
	},
};
</script>
