<template>
	<div :id="id" :class="{
			dateTime: $_.isEmpty(mode) || mode === 'datetime' || mode === 'future-datetime',
			calendarDateTime: $_.isEmpty(mode) || mode === 'datetime' || mode === 'future-datetime',
			calendarDate: mode === 'date',
			calendarTime: mode === 'time',
			input: true,
		}"
		ref="component"
	>
		<div :class="{'inputDate-invalid': invalid}"
		>
			<vue-date-picker
				v-model="date"
				:data-cy="`${currentComponentId}__vue-pikaday`"
				:key="calendarUid"
				:attributes='attributes'
				:min-date="minDate"
				:max-date="maxDate"
				:popover="popover"
				:locale="locale"
				:timezone="internalMode === 'date' ? null : $user.timezone"
				:input-debounce="inputDebounce"
				@input="$emit('input', date); emitChangeOnDirty()"
				@update="$emit('update', date)"
				@input.once="onTouch"
				:mode="internalMode"
				:is24hr="$user.lang === 'cs'"
				:masks="{
					L: 'DD.MM.YYYY',
					M: 'H:mm',
					input: [internalPlaceholder, 'L','DD.MM.YYYY H:mm'],
					inputDateTime24hr: [internalPlaceholder, 'L', 'DD.MM.YYYY'],
					inputTime24hr: [internalPlaceholder, 'M', 'H:mm'],
					inputDateTime: [internalPlaceholder, 'L', 'DD.MM.YYYY'],
					data: [internalPlaceholder, 'L', 'DD.MM.YYYY H:mm'],
				}"
			>
				<template v-slot="{ inputValue, inputEvents }">
					<span
						class="inputDate"
						:class="{
							'inputDate-invalid': invalid
						}"
						v-on="!readOnly ? inputEvents : null"
					>
						<input
							ref="dateInput"
							:data-cy="`${currentComponentId}__date__inputText`"
							:value="inputValue"
							:class="{
								'ng-invalid': required && value == null && internalDirty,
								'date-invalid': invalid
							}"
							:disabled="readOnly"
							:placeholder="internalPlaceholder"
						/>
						<template v-if="!readOnly">
							<button v-if="allowDelete" class="calendarEmpty" type="button"
								@click.stop="clear" tabindex="-1" v-tooltip="$t('deleteDT')"
								:data-cy="`${currentComponentId}__clear`"
							>
								<i class="icon-cancel"></i>
							</button>
							<i @click.stop="$refs.dateInput && $refs.dateInput.click();" :class="{
								'icon-calendar': ['date', 'dateTime'].includes(internalMode),
								'icon-clock': internalMode === 'time',
							}"></i>
						</template>
					</span>
				</template>

				<template v-slot:footer>
					<div class="p-2 buttonContainer">
						<button
							:data-cy="`${currentComponentId}__now`"
							type="button"
							class="buttonInverse buttonSmall"
							@click.stop="setTimeToNow(); $emit('input', date); $emit('change', date)"
						>
							{{ $t('now') }}
						</button>
						<button
							:data-cy="`${currentComponentId}__close`"
							type="button"
							class="button buttonSmall orangeButton ml-3"
							@click.stop="$refs.dateInput.click(); $emit('input', date); $emit('change', date)"
						>
							{{ $t('ok') }}
						</button>
					</div>
				</template>
			</vue-date-picker>
			<span v-if="invalid" class="lba-messages">{{ invalid }}</span>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: [String, Date],
			required: false,
			default: null,
		},
		mode: {
			type: String,
			required: false,
			default: null,
		},
		format: {
			type: String,
			required: false,
			default: 'DD.MM.YYYY',
		},
		min: {
			type: [Date, String],
			required: false,
		},
		max: {
			type: [Date, String],
			required: false,
		},
		readOnly: {
			type: Boolean,
			required: false,
			default: false,
		},
		validator: {
			type: Object,
			required: false,
			default: null,
		},
		invalid: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
		dirty: {
			type: Boolean,
			default: false,
		},
		initEmpty: Boolean,
		allowDelete: {
			type: Boolean,
			default: true,
		},
		yearRange: {
			type: [Number, Array],
			default: 10,
			validator: (value) => {
				return (
					(
						value.constructor === Number &&
						value >= 1
					) || (
						value.constructor === Array &&
						value.length === 2 &&
						value[0].constructor === Number && (
							value[1].constructor === Number ||
							value[1] === 'NOW'
						)
					)
				);
			},
		},
	},
	computed: {
		internalMode() {
			if ($_.isEmpty(this.mode) || this.mode === 'datetime' || this.mode === 'future-datetime') {
				return 'dateTime';
			} else if (this.mode === 'time') {
				return 'time';
			} else {
				return 'date';
			}
		},
		internalPlaceholder() {
			if ($_.isEmpty(this.mode) || this.mode === 'datetime' || this.mode === 'future-datetime') {
				return `${this.format} H:mm`;
			} else if (this.mode === 'time') {
				return 'H:mm';
			} else {
				return this.format;
			}
		},
		minDate() {
			let min = this.min;
			if (min && this.min.constructor === String) {
				min = new Date(this.min);
			}
			return min;
		},
		maxDate() {
			let max = this.max;
			if (max && this.max.constructor === String) {
				max = new Date(this.max);
			}
			return max;
		},
	},
	data() {
		return {
			quiet: false,
			visible: false,
			focused: false,
			date: null,
			internalDirty: false,
			initialized: false,
			calendarUid: this.$generateUID(),
			attributes: [
				{
					key: 'today',
					highlight: 'gray',
					dates: new Date(),
					popover: {
						label: this.$t('today'),
					},
				},
			],
			emitChange: false,
			timeId: 'time_id',
			popover: {
				visibility: 'click',
				positionFixed: false,
			},
			id: this.$generateUID(),
			locale: null,
			inputDebounce: 1000,
		};
	},
	created() {
		this.locale = window.moment().locale();
		if ($_.isEmpty(this.mode) || this.mode === 'datetime' || this.mode === 'future-datetime') {
			this.showTime = true;
			this.showDate = true;
		} else if (this.mode === 'time') {
			this.showTime = true;
		} else {
			this.showDate = true;
		}

		if (this.value) {
			if (typeof this.value === 'string') {
				this.date = new Date(this.value);
			} else {
				this.date = new Date(this.value.getTime());
			}
		} else if (this.initEmpty) {
			this.date = new Date();
		}

		// must emit input in created, since first emit is ignored when initEmpty is true
		if (this.initEmpty) {
			this.$emit('input', this.date);
		}

		this.preparePikadayOptions();
	},
	mounted() {
		// set positionFixed only in popups
		const el = document.getElementById(this.id);
		if (el) {
			this.popover.positionFixed = Boolean(el.closest('.popup-dialog, .popup, .popup-modal, .popupBody'));
		}
	},
	watch: {
		value() {
			if (!this.value) {
				return this.clear();
			}

			if (typeof this.value === 'string') {
				this.date = new Date(this.value);
				this.time = new Date(this.value);
			} else {
				this.date = new Date(this.value.getTime());
				this.time = new Date(this.value.getTime());
			}
		},
		date() {
			if (!this.date) {
				this.clear();
			}
		},
		min() {
			this.preparePikadayOptions();
		},
		max() {
			this.preparePikadayOptions();
		},
		format() {
			this.preparePikadayOptions();
		},
		yearRange() {
			this.preparePikadayOptions();
		},
	},
	methods: {
		emitChangeOnDirty() {
			if (this.dirty || this.internalDirty) {
				this.$emit('change', this.date);
			}
		},
		preparePikadayOptions() {
			const yearRange = $_.cloneDeep(this.yearRange) || 10;

			if (yearRange.constructor === Array && yearRange[1] === 'NOW') {
				yearRange[1] = (new Date()).getFullYear();
			}

			let min = this.min;
			if (min && this.min.constructor === String) {
				min = new Date(this.min);
			}

			let max = this.max;
			if (max && this.max.constructor === String) {
				max = new Date(this.max);
			}
		},
		onTouch() {
			this.internalDirty = true;

			if (this.validator) {
				this.validator.$touch();
			}
		},
		clear() {
			this.date = null;
			this.$emit('input', null);
			this.$emit('change', null);
		},
		async setTimeToNow() {
			this.inputDebounce = 0;
			await this.$nextTick();
			let value;
			if ($_.isEmpty(this.mode) || this.mode === 'datetime' || this.mode === 'future-datetime') {
				value = this.$d(new Date(), 'medium');
			} else if (this.mode === 'time') {
				value = this.$d(new Date(), 'timeShort');
			} else {
				value = this.$d(new Date(), 'short');
			}

			this.$refs.dateInput.value = value;

			const e = new Event('input', {
				bubbles: true,
				cancelable: true,
			});
			this.$refs.dateInput.dispatchEvent(e);
			this.calendarUid = this.$generateUID();

			this.inputDebounce = 1000;
		},
	},
};
</script>
;
