import ECharts from 'vue-echarts';
import {
	use
} from 'echarts/core';
import {
	CanvasRenderer
} from 'echarts/renderers';
import {
	BarChart,
	LineChart,
	PieChart,
	GaugeChart,
	HeatmapChart
} from 'echarts/charts';
import {
	GridComponent,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	DatasetComponent,
	CalendarComponent,
	VisualMapComponent
} from 'echarts/components';
import '../themes/echarts-dark';

export default {
	install(Vue) {
		use([
			CanvasRenderer,
			BarChart,
			LineChart,
			PieChart,
			GaugeChart,
			HeatmapChart,
			GridComponent,
			TooltipComponent,
			LegendComponent,
			TitleComponent,
			DatasetComponent,
			CalendarComponent,
			VisualMapComponent,
		]);
		Vue.component('v-chart', ECharts);
	},
};
