import Activity from '../views/Activity.vue';
import Changelog from '../views/Changelog';
import Documentation from '../views/Documentation';
import DocumentationPart from '../views/DocumentationPart';
import FirstLogin from '../views/FirstLogin.vue';
import Group from '../views/Group.vue';
import Groups from '../views/Groups.vue';
import Home from '../views/Home.vue';
import ImportExportConfiguration from '../views/ImportExportConfiguration.vue';
import ImportExportConfigurations from '../views/ImportExportConfigurations.vue';
import ImportExportSetting from '../views/ImportExportSetting.vue';
import ImportExportSettings from '../views/ImportExportSettings.vue';
import ImportRecord from '../views/ImportRecord.vue';
import ImportRecords from '../views/ImportRecords.vue';
import LoggedIn from '../views/LoggedIn.vue';
import Options from '../views/Options.vue';
import Profile from '../views/Profile.vue';
import Profiles from '../views/Profiles.vue';
import Search from '../views/Search.vue';
import User from '../views/User.vue';
import Users from '../views/Users.vue';
import UserSettings from '../views/UserSettings.vue';

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			name: () => 'Home',
			breadcrumb: () => [{ name: 'home', text: 'Home' }],
		},
	},
	{
		path: '/user-settings',
		name: 'user-settings',
		component: UserSettings,
		meta: {
			icon: 'icon-settings',
			name: (vm) => vm.$t('settings.user.settings'),
			breadcrumb: () => [{ name: 'user-settings', text: 'settings.user.settings' }],
		},
	},
	{
		path: '/changelog',
		name: 'changelog',
		component: Changelog,
		meta: {
			icon: 'icon-history',
			name: (vm) => vm.$t('releaseNotes'),
			breadcrumb: () => [{ name: 'changelog', text: 'releaseNotes' }],
		},
	},
	{
		path: '/import-records',
		name: 'import-records',
		component: ImportRecords,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importRecords'),
			breadcrumb: () => [{ name: 'import-records', text: 'importRecords' }],
		},
	},
	{
		path: '/import-record/:id',
		name: 'import-record',
		component: ImportRecord,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importRecord'),
			breadcrumb: () => [
				{ name: 'import-records', text: 'importRecords' },
				{ name: 'import-record', text: 'importRecord' },
			],
		},
	},
	{
		path: '/import-export/configurations',
		name: 'import-export-configurations',
		component: ImportExportConfigurations,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importExportConfigurations'),
			breadcrumb: () => [{ name: 'import-export-configurations', text: 'importExportConfigurations' }],
		},
	},
	{
		path: '/import-export/configuration/:id',
		name: 'import-export-configuration',
		component: ImportExportConfiguration,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importExportConfiguration'),
			breadcrumb: () => [
				{ name: 'import-export-configurations', text: 'importExportConfigurations' },
				{ name: 'import-export-configuration', text: 'importExportConfiguration' },
			],
		},
	},
	{
		path: '/import/settings',
		name: 'import-settings',
		component: ImportExportSettings,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importSettings'),
			breadcrumb: () => [{ name: 'import-settings', text: 'importSettings' }],
		},
	},
	{
		path: '/import/setting/:id',
		name: 'import-setting',
		component: ImportExportSetting,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importSetting'),
			breadcrumb: () => [
				{ name: 'import-settings', text: 'importSettings' },
				{ name: 'import-setting', text: 'importSetting' },
			],
		},
	},
	{
		path: '/export/settings',
		name: 'export-settings',
		component: ImportExportSettings,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('exportSettings'),
			breadcrumb: () => [{ name: 'export-settings', text: 'exportSettings' }],
		},
	},
	{
		path: '/export/setting/:id',
		name: 'export-setting',
		component: ImportExportSetting,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('exportSetting'),
			breadcrumb: () => [
				{ name: 'export-settings', text: 'exportSettings' },
				{ name: 'export-setting', text: 'exportSetting' },
			],
		},
	},
	{
		path: '/search',
		name: 'lbadmin-search',
		component: Search,
		meta: {
			icon: 'icon-search',
			name: (vm) => vm.$route.meta.setName || vm.$t('search'),
			breadcrumb: () => [
				{ name: 'lbadmin-search', text: ['search'] },
			],
		},
	},
	{
		path: '/users',
		name: 'settings-users',
		component: Users,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.users'),
			breadcrumb: () => [
				{ name: 'settings-users', text: ['settings.menu.users', 'settings.menu.users'] },
			],
		},
	},
	{
		path: '/users/:id',
		name: 'settings-user',
		component: User,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.newUser'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-users', text: ['settings.menu.users', 'settings.menu.users'] },
				{ name: 'settings-user', text: ['settings.user.user'] },
			],
		},
	},
	{
		path: '/groups',
		name: 'settings-groups',
		component: Groups,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$t('settings.menu.groups'),
			breadcrumb: () => [
				{ name: 'settings-groups', text: ['settings.menu.users', 'settings.menu.groups'] },
			],
		},
	},
	{
		path: '/groups/:id',
		name: 'settings-group',
		component: Group,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.group'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-groups', text: ['settings.menu.users', 'settings.menu.groups'] },
				{ name: 'settings-group', text: 'settings.group' },
			],
		},
	},
	{
		path: '/profiles',
		name: 'settings-profiles',
		component: Profiles,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$t('settings.menu.profiles'),
			breadcrumb: () => [
				{ name: 'settings-profiles', text: ['settings.menu.users', 'settings.menu.profiles'] },
			],
		},
	},
	{
		path: '/profiles/:id',
		name: 'settings-profile',
		component: Profile,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.profile'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-profiles', text: ['settings.menu.users', 'settings.menu.profiles'] },
				{ name: 'settings-profile', text: 'settings.profile' },
			],
		},
	},
	{
		path: '/activity',
		name: 'settings-activity',
		component: Activity,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.activity'),
			breadcrumb: () => [
				{ name: 'settings-activity', text: ['settings.menu.users', 'settings.menu.activity'] },
			],
		},
	},
	{
		path: '/logged-in',
		name: 'settings-logged-in',
		component: LoggedIn,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.loggedIn'),
			breadcrumb: () => [
				{ name: 'settings-logged-in', text: ['settings.menu.users', 'settings.menu.loggedIn'] },
			],
		},
	},
	{
		path: '/options',
		name: 'options',
		component: Options,
		meta: {
			icon: 'icon-settings',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.settings'),
			breadcrumb: () => [
				{ name: 'options', text: ['settings.settings'] },
			],
		},
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: Documentation,
		meta: {
			icon: 'icon-mailboxes-whitelist',
			name: (vm) => vm.$t('documentation'),
			breadcrumb: () => [{ name: 'documentation', text: 'documentation' }],
		},
	},
	{
		path: '/documentation/:module/:fileName',
		name: 'documentation-part',
		component: DocumentationPart,
		meta: {
			icon: 'icon-mailboxes-whitelist',
			name: (vm) => vm.$route.meta.setName || vm.$t('documentation'),
			breadcrumb: () => [
				{ name: 'documentation', text: 'documentation' },
				{ name: 'documentation-part', text: 'documentation' },
			],
		},
	},
	{
		path: '/login-first',
		name: 'firstLogin',
		component: FirstLogin,
		meta: {
			name: () => 'User setup',
			breadcrumb: () => [{ name: 'firstLogin', text: 'User setup' }],
		},
	},
];
