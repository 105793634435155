export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/favourite-pages', { params });
	}

	add(params) {
		return this.$http.post('lbadmin/favourite-page-add', { params });
	}

	remove(params) {
		return this.$http.post('lbadmin/favourite-page-remove', { params });
	}

	update(params) {
		return this.$http.post('lbadmin/favourite-pages-update', { params });
	}

	updatePages(params) {
		return this.$http.post('lbadmin/favourite-pages-update-pages', { params });
	}
}
