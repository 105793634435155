<template>
	<s
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired || isRequiredGroup" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<div class="lba-input-file">
			<input
				class="inputfile"
				type="file"
				name="file-1[]"
				:id="innerComponentID"
				@change="onChange"
			/>
			<label :for="innerComponentID" :class="inputClassList" v-tooltip="label" :data-cy="`${currentComponentId}__fileLabel`">
					<i class="icon-upload"></i>
					<span>
						{{ fileLabel || label }}
					</span>
			</label>
		</div>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputFile',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
		inputClassList: [String, Array, Object],
		disabled: Boolean,
		fileLabel: String,
	},
	computed: {
		innerComponentID() {
			return $generateUID();
		},
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && !this.data[this.key]) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
		onChange(event) {
			const { files } = event.target;

			if (files && files.length > 0) {
				if (this.multiple) {
					this.fileList = this.fileList.concat(Array.from(files));
				} else {
					this.fileList = Array.from(files);
				}
			} else if (!files && !this.fileList.length) {
				this.fileList = [];
			}
			this.data[this.key] = this.fileList[0];

			this.$root.$emit('file-input', this.fileList, event);
		},
	},
};
</script>
