import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css'; // import syntax highlighting styles
import 'vue-prism-editor/dist/prismeditor.min.css';

export default {
	install(Vue) {
		window.highlight = highlight;
		window.languages = languages;
		Vue.component('PrismEditor', PrismEditor);
	},
};
