<template>
	<div v-if="loaded">
		<div class="log-reader-header">
			<span>
				<s>
					<small>{{ $t('logReader.files')}}</small>
					<select name="file" v-model="files.selected.name">
						<option v-for="file in files.available.data" :key="file">{{ file }}</option>
					</select>
				</s>
				<s>
					<small>{{ $t('logReader.filter')}}</small>
					<input
						type="text"
						v-model.trim="files.selected.filter"
						@keyup.enter="files.selected.currentFilter = files.selected.filter; files.selected.page = 1; loadFile()"
					/>
				</s>
				<s>
					<small>&nbsp;</small>
					<button
						class="buttonOrange"
						v-if="files.selected.filter !== ''"
						@click="files.selected.filter = ''; files.selected.currentFilter = ''; files.selected.page = 1; loadFile()"
					>
						{{ $t('cancelFilter') }}
					</button>
				</s>
			</span>
			<button
				@click="files.selected.currentFilter = files.selected.filter; files.selected.page = 1; loadFile()"
				:disabled="files.selected.name === ''"
			>
				{{ $t('logReader.show')}}
			</button>
		</div>
		<nav class="grid-paginaton" ref="pagination">
			<ul v-if="files.selected.data">
				<li
					class="p-item prev"
					:class="{ 'disabled': files.selected.page === 1 }" @click="files.selected.page -= 1"
				>
					<i class="icon-collapse-all"></i>
				</li>
				<li
					class="p-item next"
					:class="{ 'disabled': files.selected.page === pages.paginationCeil }"
					@click="files.selected.page += 1"
				>
					<i class="icon-collapse-all"></i>
				</li>
				<li
					v-for="(page, index) in pages.paginationRange"
					:key="`page-${index}`"
					:class="{
						'dots': page === '...',
						'p-item': page !== '...',
						'active': files.selected.page === page,
					}"
					@click="files.selected.page = page"
				>{{ page }}</li>
			</ul>
			<div class="p-info">
				<span v-if="files.selected.count > 0">
					{{ $t('grid.pagination_records', {
						from: pages.pageSize * (files.selected.page - 1) + 1,
						to: Math.min(pages.pageSize * (files.selected.page), files.selected.count),
						total: files.selected.count
					}) }}
				</span>
				<span v-else>
					{{ $t('grid.pagination_no_records') }}
				</span>
			</div>
			<div class="p-switch">
				<span style="margin-right: 10px;">{{ $t('grid.show_on_page') }}</span>
				<span
					class="count"
					:class="{ 'active': pages.pageSize === item }"
					v-for="item in [50, 100, 250, 500]"
					:key="`gridPerPage${item}`"
					@click="pages.pageSize = item; files.selected.page = 1; loadFile();"
				>
					{{ item }}
				</span>
				<span>{{ $t('grid.records') }}</span>
			</div>
		</nav>
		<div v-if="files.selected.data" style="overflow-x: auto;">
			<pre v-html="files.selected.data"></pre>
		</div>
		<nav class="grid-paginaton" ref="pagination" v-if="files.selected.data">
			<ul>
				<li
					class="p-item prev"
					:class="{ 'disabled': files.selected.page === 1 }" @click="files.selected.page -= 1"
				>
					<i class="icon-collapse-all"></i>
				</li>
				<li
					class="p-item next"
					:class="{ 'disabled': files.selected.page === pages.paginationCeil }"
					@click="files.selected.page += 1"
				>
					<i class="icon-collapse-all"></i>
				</li>
				<li
					v-for="(page, index) in pages.paginationRange"
					:key="`page-${index}`"
					:class="{
						'dots': page === '...',
						'p-item': page !== '...',
						'active': files.selected.page === page,
					}"
					@click="files.selected.page = page"
				>{{ page }}</li>
			</ul>
			<div class="p-info">
				<span v-if="files.selected.count > 0">
					{{ $t('grid.pagination_records', {
						from: pages.pageSize * (files.selected.page - 1) + 1,
						to: Math.min(pages.pageSize * (files.selected.page),
						files.selected.count), total: files.selected.count
					}) }}
				</span>
				<span v-else>
					{{ $t('grid.pagination_no_records') }}
				</span>
			</div>
			<div class="p-switch">
				<span style="margin-right: 10px;">{{ $t('grid.show_on_page') }}</span>
				<span
					class="count"
					:class="{ 'active': pages.pageSize === item }"
					v-for="item in [50, 100, 250, 500]"
					:key="`gridPerPage${item}`"
					@click="pages.pageSize = item; files.selected.page = 1; loadFile();"
				>
					{{ item }}
				</span>
				<span>{{ $t('grid.records') }}</span>
			</div>
		</nav>
	</div>
</template>

<style scoped>
div.log-reader-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 1rem;
}

div.log-reader-header s {
	padding: 0;
}

div.nav {
	display: flex;
	flex-direction: row;
	justify-content: left;
	gap: .3rem;
}

pre {
	line-height: 1.35rem;
}
</style>

<script>
import ComponentIdentifier from '../../mixins/ComponentIdentifier';
import LogModel from './models/Log';

export default {
	mixins: [ComponentIdentifier],
	props: {
		moduleName: {
			type: [String],
			required: true,
		},
	},
	computed: {
		paginationCeil() {
			return Math.ceil(this.files.selected.count / this.pages.pageSize);
		},
	},
	watch: {
		'files.selected.page'(newPage) {
			this.files.selected.page = Math.max(1, Math.min(newPage, this.paginationCeil));
			this.loadFile();
		},
	},
	data() {
		return {
			loaded: false,
			logModel: null,
			pages: {
				pageSize: 50,
				paginationCeil: 1,
				paginationRange: [1],
			},
			files: {
				available: [],
				selected: {
					name: '',
					filter: '',
					currentFilter: '',
					data: null,
					count: 0,
					page: 1,
				},
			},
		};
	},
	async mounted() {
		// the log model retrieves log files from the specified module
		// the module must have server-side routes implemented (see firewall mod.)
		this.logModel = new LogModel(this);
		this.files.available = await this.logModel.files(this.$props.moduleName);

		this.loaded = true;
	},
	methods: {
		async loadFile() {
			if (!this.files.selected.name) return;

			const queryParams = {
				pageSize: this.pages.pageSize,
				...(this.files.selected.currentFilter && { filter: this.files.selected.currentFilter }),
			};

			try {
				const response = await this.logModel.file(
					this.$props.moduleName,
					this.files.selected.name,
					this.files.selected.page,
					queryParams
				);
				this.files.selected.data = response.data;
				this.files.selected.count = response.headers['x-lbadmin-count'];

				this.pages.paginationCeil = Math.ceil(this.files.selected.count / this.pages.pageSize);

				this.pages.paginationRange = [];
				if (this.pages.paginationCeil < 7) {
					this.pages.paginationRange = Array.from({ length: this.pages.paginationCeil }, (_, i) => i + 1);
				} else {
					this.pages.paginationRange = [1];
					if (this.files.selected.page - 2 > 2) {
						this.pages.paginationRange.push('...');
					}
					for (let i = (this.files.selected.page - 2); i <= (this.files.selected.page + 2); i++) {
						if (i > 1 && i < this.pages.paginationCeil) {
							this.pages.paginationRange.push(i);
						}
					}
					if (this.files.selected.page + 2 < this.pages.paginationCeil - 1) {
						this.pages.paginationRange.push('...');
					}
					if (this.pages.paginationCeil > 1) {
						this.pages.paginationRange.push(this.pages.paginationCeil);
					}
				}
			} catch (error) {
				console.error('Error loading file:', error);
			}
		},
	},
};
</script>
