// requires ValidationMethods

function getLocale(locale, allowObject = false, joinWith = ' ') {
	const i18nLocale = window.getI18nLocale();
	if (!i18nLocale) { window.setI18nLocale(window.getBrowserLanguage()); }
	if ($_.isEmpty(locale)) return '';
	if (locale.constructor === String) return locale;
	if (locale.constructor === Array) return locale.map((value) => getLocale(value)).join(joinWith);
	if (locale[i18nLocale]) return locale[i18nLocale];
	if (locale.translate && window.validatePathToLocale(locale.translate, allowObject)) {
		return window.$t(locale.translate, locale.attributes);
	}
	if (locale.translate) {
		console.warn(`[$getLocale] locale translate is invalid:`, locale, 'allowObject:', allowObject);
	}
	if (locale.translated) return locale.translated;
	console.warn(`[$getLocale] locale: ${i18nLocale} not found:`, locale);
	return '';
}
function containsLocale(a, b) {
	return !!a.split('')
		.filter((v, i) => a.slice(i, b.length).localeCompare(b, window.getI18nLocale(), { sensitivity: 'base' }) === 0).length;
}
function localeIncludes(string, searchString, { position = 0, locales, ...options } = {}) {
	if (string === undefined ||
		string === null ||
		searchString === undefined ||
		searchString === null
	) {
		console.error('localeIncludes requires at least 2 parameters');
		return false;
	}

	const stringLength = string.length;
	const searchStringLength = searchString.length;
	const lengthDiff = stringLength - searchStringLength;

	for (let i = position; i <= lengthDiff; i++) {
		if (string.substring(i, i + searchStringLength).localeCompare(searchString, locales, options) === 0) {
			return true;
		}
	}

	return false;
}

export default {
	install(Vue) {
		Vue.prototype.$getLocale = getLocale;
		Vue.prototype.$containsLocale = containsLocale;
		Vue.prototype.$localeIncludes = localeIncludes;
	},
};
