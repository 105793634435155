var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lba-section',{key:_vm.objKey,attrs:{"parentComponentId":_vm.currentComponentId,"title":_vm.$t('permissions'),"slotType":'form',"expanded":""},scopedSlots:_vm._u([(!_vm.readOnly && _vm.loaded)?{key:"controls",fn:function(){return [_c('span',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('settings.addGroup')),expression:"$t('settings.addGroup')"}],staticClass:"icon-plus-circle",attrs:{"data-cy":(_vm.currentComponentId + "__addGroup")},on:{"click":function($event){$event.stopPropagation();return _vm.addGroup()}}})])]},proxy:true}:null],null,true)},[(_vm.objectPermissions.length > 0)?_vm._l((_vm.objectPermissions),function(perm,index){return _c('div',{key:index,staticClass:"formRow"},[_c('s',{staticClass:"size-2"},[_c('small',[_vm._v(_vm._s(_vm.$t('settings.groupOrUser')))]),_c('ValidationProvider',{ref:"provider",refInFor:true,attrs:{"name":_vm.$t('settings.groupOrUser'),"rules":{ required: true },"data-cy":(_vm.currentComponentId + "__slot")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('lba-select',{class:{
							'lba-invalid': _vm.dirty && invalid,
						},attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__group__select"),"name":("group-" + (perm.role_uid)),"opts":(_vm.disableAll ? [] : [{ value: null, label: ("-- " + (_vm.$t('all')) + " --") }]).concat( _vm.groupRoles.map(function (group) { return ({
								value: group.role_uid,
								label: (((_vm.$t('settings.group') || '').toLowerCase()) + " - " + (group.role_name)),
							}); }),
							_vm.users.map(function (user) { return ({
								value: user.role_uid,
								label: (((_vm.$t('settings.user.user') || '').toLowerCase()) + " - " + (_vm.$user.getName(user))),
							}); }) ),"multiple":false},on:{"change":_vm.change},model:{value:(perm.role_uid),callback:function ($$v) {_vm.$set(perm, "role_uid", $$v)},expression:"perm.role_uid"}}),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages"},[_vm._v(" "+_vm._s(err)+" ")])}):_vm._e()]}}],null,true)})],1),_c('s',{staticClass:"size-2"},[_c('small',[_vm._v(_vm._s(_vm.$t('settings.profile')))]),_c('ValidationProvider',{ref:"provider",refInFor:true,attrs:{"name":_vm.$t('settings.profile'),"rules":{ required: true },"data-cy":(_vm.currentComponentId + "__slot")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var invalid = ref.invalid;
						var errors = ref.errors;
return [_c('lba-select',{class:{
							'lba-invalid': _vm.dirty && invalid,
						},attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__profile__select"),"name":("profile-" + (perm.profile_uid)),"opts":(_vm.disableAll ? [] : [{ value: null, label: ("-- " + (_vm.$t('all')) + " --") }]).concat( _vm.profiles.map(function (profile) { return ({
								value: profile.profile_uid,
								label: profile.profile_name,
							}); }) ),"multiple":false},on:{"change":_vm.change},model:{value:(perm.profile_uid),callback:function ($$v) {_vm.$set(perm, "profile_uid", $$v)},expression:"perm.profile_uid"}}),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages"},[_vm._v(" "+_vm._s(err)+" ")])}):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-controls"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('remove')),expression:"$t('remove')"}],staticClass:"icon-delete",attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__remove")},on:{"click":function($event){return _vm.removePermission(index)}}})])])}):[_vm._v(" "+_vm._s(_vm.$t('noEntries'))+" ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }