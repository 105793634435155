export default {
	data() {
		return {
			recordDeleted: false,
			recordDeletedEmited: false,
			gridNames: null,
		};
	},
	activated() {
		if (this.recordDeleted && !this.recordDeletedEmited) {
			this.$emit('view-record-deleted');
			this.recordDeletedEmited = true;
		}
	},
	methods: {
		onRecordDeleted(uid) {
			if ($_.isEmpty(this.$vnode.key)) {
				console.error(
					`[lbadmin.mixins.OnRecordDeleted] missing vnode key parameter,`,
					`component must be directly under <router-view> and contain key parameter, current key: ${this.$vnode.key}`,
					`uid: ${uid}`
				);
				return;
			}
			if ($_.isEmpty(uid)) {
				console.error('[lbadmin.mixins.OnRecordDeleted] missing uid:', uid);
				return;
			}
			if ($_.isEmpty(this.gridNames)) {
				console.error('[lbadmin.mixins.OnRecordDeleted] gridNames are not set:', this.gridNames);
				return;
			}
			return (info) => this.checkRecordDeleted(info, uid);
		},
		checkRecordDeleted(info, uid) {
			if (
				!this.recordDeleted &&
				this.gridNames.includes(info.gridName) && (
					(!$_.isEmpty(info.uid) && info.uid === uid) ||
					(!$_.isEmpty(info.uids) && info.uids.includes(uid))
				)
			) {
				this.recordDeleted = true;

				if (!this._inactive) {
					this.$emit('view-record-deleted');
					this.recordDeletedEmited = true;
				}
			}
		},
	},
};
