export default {
	data() {
		return {
			loginInfoShow: false,
			loginInfoText: null,
			loginInfoType: null,
		};
	},
	methods: {
		setLoginText() {
			const serverSettings = $_.first(this.$root.servers);

			if (serverSettings && serverSettings.login_info_show) {
				this.loginInfoShow = serverSettings.login_info_show;
			}

			if (serverSettings && serverSettings.login_info_text) {
				this.loginInfoText = serverSettings.login_info_text;
			}

			if (serverSettings && serverSettings.login_info_type) {
				this.loginInfoType = serverSettings.login_info_type;
			}
		},
	},
};
