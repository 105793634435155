import _ from 'lodash';

export default {
	install(Vue) {
		window._ = _;
		window.$_ = _;
		Vue.prototype.$_ = _;
		Object.defineProperties(Vue.prototype, { _: { get() { return _; } } });
		Object.defineProperties(Vue.prototype, { $_: { get() { return _; } } });
	},
};
