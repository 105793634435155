<template>
	<div
		:class="{
			menuModule: !item.isGroup,
			menuItemLineAfter: item.lineAfter,
			singleItem: $_.isEmpty(item.items),
			menuAdmin: item.name === 'administration' && !expanded,
			'menu-bottom': item.name === 'administration' && expanded,
			menuAdminExpaned: item.name === 'administration' && expanded
		}"
		:id="`${item.module || item.group}_${item.name}`"
		v-tooltip="smallMenuTooltip"
	>
		<span
			v-if="item.isGroup"
			class="menuItem menuItemGroup"
			v-lba-expander.expanded.closeOnClick="item.openOnLoad"
			:data-cy="`${currentComponentId}__expandItems`"
		>
			<i class="icon-menu-group"></i>
			{{ item.translatedName }}
		</span>
		<span
			v-else-if="!$_.isEmpty(item.items)"
			:key="expanderKey"
			ref="menuItem"
			class="menuItem"
			:class="{ menuItemModule: item.rootItem, menuItemLineAfter: depth > 0 || nextGroup !== item.group }"
			:style="getStyle()"
			:data-cy="`${currentComponentId}__expandItems`"
			@click="onExpandToggle"
			v-lba-expander.expanded="{
				/* log: true,
				disabled: !item.rootItem && smallMenu, */
				expanded: item.name === 'administration' ? expanded : item.openOnLoad,
				/* onHover: onHoverExpand,
				adjustHeight: onHoverExpand,
				setStyle: setExpandedItemStyle,
				dontSetExpandedOnHover: onHoverExpand && item.name === 'administration', */
			}"
		>
			<i :class="item.icon" :style="getIconStyle()"></i>
			{{ item.translatedName }}
		</span>
		<template v-if="$_.isEmpty(item.items)">
			<router-link
				v-if="item.action"
				:data-cy="`${currentComponentId}__item`"
				@click.native.prevent="onClick()"
				:to="link"
				class="menuItem"
				:class="{ menuItemModule: item.rootItem, active, menuItemLineAfter: nextGroup !== item.group }"
				:style="getStyle()"
			>
				<i :class="item.icon"></i>
				{{ item.translatedName }}
				<span v-if="status != null" v-html="status"></span>
				<span class="new-tab" @click.prevent="onClick(true)" :data-cy="`${currentComponentId}__itemOpenNewTab`">
					<i class="icon-new-tab"></i>
				</span>
			</router-link>
			<a
				v-else
				:data-cy="`${currentComponentId}__item`"
				@click.prevent="onClick(null, true)"
				class="menuItem"
				:class="{ menuItemModule: item.rootItem, active, menuItemLineAfter: nextGroup !== item.group }"
				:style="getStyle()"
			>
				<i :class="item.icon"></i>
				{{ item.translatedName }}
				<span v-if="status != null" v-html="status"></span>
				<span class="new-tab" @click.prevent="onClick(true, true)" :data-cy="`${currentComponentId}__itemOpenNewTab`">
					<i class="icon-new-tab"></i>
				</span>
			</a>
		</template>
		<div v-else class="expand" :style="sectionStyle">
			<div
				v-for="(childItem, index) in item.items"
				:key="childItem.name"
				:class="{ menuModule: !childItem.isGroup && childItem.rootItem, menuSection: !$_.isEmpty(childItem.items) }"
			>
				<lba-menu-tree
					:parentComponentId="currentComponentId"
					:componentId="childItem.name"
					:item="childItem"
					:depth="depth + 1"
					:nextGroup="getNextGroup(index + 1)"
					:smallMenu="smallMenu"
					:tempBigMenu="tempBigMenu"
					@toggle-temp-big-menu="$emit('toggle-temp-big-menu', $event)"
					@toggle-overlay="toggleOverlay"
				></lba-menu-tree>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

/* eslint-disable no-useless-escape */
export default {
	mixins: [ComponentIdentifier],
	props: {
		item: Object,
		depth: {
			type: Number,
			default: 0,
		},
		nextGroup: String,
		classList: String,
		smallMenu: Boolean,
		tempBigMenu: Boolean,
	},
	data() {
		return {
			status: null,
			ctrlLeft: false,
			ctrlRight: false,
			paddingLeft: 0,
			// top level item: icon 16px, padding-left: 40px (offset) 40 - 16 => 24 / 2 => 12 icon offset
			offsetLeft: 30,
			iconOffsetLeft: 7,
			expanderKey: Math.random(),
			expanded: this.item.openOnLoad,
			expandedItemStyle: null,
		};
	},
	computed: {
		link() {
			return `/${this.item.action}`;
		},
		active() {
			let isActive = false;

			if (this.item.action || this.item.actionName) {
				isActive = this.isMenuDescendant(this.item.action, this.item.actionName);
			}

			if (!isActive && this.item.name && this.$route.meta && this.$route.meta.menuItemName) {
				isActive = this.item.name === this.$route.meta.menuItemName;
			}

			return isActive;
		},
		ctrlPressed() {
			return this.ctrlLeft || this.ctrlRight;
		},
		/* onHoverExpand() {
			return this.item.rootItem === true && this.smallMenu;
		}, */
		sectionStyle() {
			/* let style = this.expandedItemStyle || '';
			if (this.onHoverExpand) {
				style += 'position: absolute;';
			} */
			if (this.depth === 0) return '';

			const base = parseInt('40', 16);
			const color = (base + this.depth * 12);
			return `background-color: rgba(${color}, ${color}, ${color}, ${0.5});`;
		},
		smallMenuTooltip() {
			if (this.smallMenu && !this.tempBigMenu) {
				return {
					placement: 'right',
					classes: 'smallMenuTooltip',
					content: this.item.translatedName,
					boundariesElement: document.body,
				};
			}
			return null;
		},
	},
	watch: {
		/* smallMenu() {
			// force hide on small menu
			if (this.smallMenu && !$_.isEmpty(this.item.items) && this.expanded) {
				this.$refs.menuItem.click();
			}
			// this.expandedItemStyle = null;
			// this.expanderKey = Math.random();
		}, */
		tempBigMenu() {
			if (this.smallMenu && !this.tempBigMenu && !$_.isEmpty(this.item.items) && this.expanded) {
				this.$refs.menuItem.click();
			}
		},
	},
	created() {
		if (this.depth !== 0) {
			this.paddingLeft = this.offsetLeft * this.depth;
		}
		if (this.item.openOnLoad && this.item.name === 'administration') {
			this.$emit('expanded');
		} else {
			this.$emit('collapsed');
		}
		this.$root.$listen('add-menu-item-status', this.addStatus, this);
		this.$root.$listen('remove-menu-item-status', this.removeStatus, this);

		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);
	},
	methods: {
		/* setExpandedItemStyle(value) {
			this.expandedItemStyle = value;
		}, */
		getNextGroup(index) {
			if (index < this.item.items.length) {
				return this.item.items[index].group;
			}

			return null;
		},
		getStyle() {
			if (this.depth < 2) {
				return '';
			}

			return `padding-left: ${this.paddingLeft}px;`;
		},
		getIconStyle() {
			if (this.depth < 2) {
				if (this.depth === 1) {
					return 'display: initial;';
				}

				return '';
			}

			return `display: initial; left: ${(this.depth - 1) * this.offsetLeft + this.iconOffsetLeft}px;`;
		},
		isMenuDescendant(action, actionName) {
			if (action) {
				const escaped = action.replace(/[\.\*\+\?\^\$\{\}\(\)\|\[\]\\\/]/g, '\\$&');
				const re = new RegExp(`^\\/${escaped}($|\\?|\\/)`);
				return re.test(this.$route.path);
			}
			return actionName === this.$route.name;
		},
		onKeyDown(event) {
			if (event.code === 'ControlLeft') {
				this.ctrlLeft = true;
			}

			if (event.code === 'ControlRight') {
				this.ctrlRight = true;
			}
		},
		onKeyUp(event) {
			if (event.code === 'ControlLeft') {
				this.ctrlLeft = false;
			}

			if (event.code === 'ControlRight') {
				this.ctrlRight = false;
			}
		},
		onClick(newTab = false, useActionName = false) {
			if (this.ctrlPressed || newTab) {
				if (useActionName) {
					this.$routerWrap.push({ name: this.item.actionName, params: { openNewTab: true } });
				} else {
					this.$root.$emit('open-new-tab', this.link);
				}
			} else if (this.smallMenu && this.tempBigMenu) {
				this.$emit('toggle-temp-big-menu', false);
			}

			if (useActionName) {
				this.$routerWrap.push({ name: this.item.actionName });
			}

			this.toggleOverlay();
		},
		toggleOverlay() {
			this.$emit('toggle-overlay', this.item.name);
		},
		addStatus(info) {
			if (info.moduleName === this.item.module && info.name === this.item.name) {
				this.status = info.html;
			}
		},
		removeStatus(info) {
			if (info.moduleName === this.item.module && info.name === this.item.name) {
				this.status = null;
			}
		},
		onExpandToggle() {
			// classList contains current state, it changes after click
			if (this.$refs.menuItem.classList.contains('expanded')) {
				this.expanded = false;
				this.$emit('collapsed');
			} else {
				this.expanded = true;
				this.$emit('expanded');
				this.$emit('toggle-temp-big-menu', true);
			}
		},
	},
};
</script>
