<template>
	<div id="content" class="text" v-if="!loading">
		<lba-grid
			ref="grid"
			multiSelectAll
			hideableColumns
			gridName="lbadmin.def_import_export"
			:collection="collection"
			:rowId="['def_import_export_uid']"
			:title="$t('importExportConfigurations')"
			:filterAttributes="filterAttributes"
			:dynamicHeight="false"
			sendVisibleColumnNames
		>
			<template v-slot:toolbar="props">
				<button
					v-if="$permissions.checkPermission('lbadmin.import-export-configurations.write')"
					@click="openDetail('+')"
					:data-cy="`${props.parentComponentId}__new`"
				>
					{{ $t('new') }}
				</button>
			</template>
			<template v-slot:selected="props">
				<button class="buttonInverse" @click="openRemoveSelected" :data-cy="`${props.parentComponentId}__remove`">
					{{ $t('remove') }}
				</button>
			</template>
			<template v-slot:columns="props">
				<lba-grid-column
					id="title"
					:label="$t('name')"
					searchable
					width="280"
					:data-cy="`${props.parentComponentId}__title`"
				>
					<a @click="openDetail(props.row.def_import_export_uid)" :data-cy="`${props.parentComponentId}__title__openDetail`">
						{{ $getLocale(props.row.title) }}
					</a>
				</lba-grid-column>
				<lba-grid-column
					id="name"
					:label="$t('forTable')"
					searchable
					width="280"
					:data-cy="`${props.parentComponentId}__name`"
				>
					{{ props.row.name }}
				</lba-grid-column>
				<lba-grid-column
					id="created"
					:label="$t('created')"
					width="150"
					:data-cy="`${props.parentComponentId}__created`"
				>
					<lba-datetime :date="props.row.created" dateFormat="fullDigitsMedium" isInGrid />
				</lba-grid-column>
				<lba-grid-column
					id="created_by"
					:label="$t('createdByUser')"
					width="150"
					:data-cy="`${props.parentComponentId}__createdBy`"
				>
					{{ getName(props.row) }}
				</lba-grid-column>
				<lba-grid-column
					id="update_enabled"
					:label="$t('updateEnabled')"
					width="150"
					:data-cy="`${props.parentComponentId}__updateEnabled`"
				>
					<i class="icon-ok green" v-if="props.row.update_enabled" />
				</lba-grid-column>
				<lba-grid-column
					id="update_default"
					:label="$t('updateDefault')"
					width="150"
					:data-cy="`${props.parentComponentId}__updateDefault`"
				>
					<i class="icon-ok green" v-if="props.row.update_default" />
				</lba-grid-column>
				<lba-grid-column
					id="delete_enabled"
					:label="$t('deleteEnabled')"
					width="120"
					:data-cy="`${props.parentComponentId}__deleteEnabled`"
				>
					<i class="icon-ok green" v-if="props.row.delete_enabled" />
				</lba-grid-column>
				<lba-grid-column
					id="delete_default"
					:label="$t('deleteDefault')"
					width="120"
					:data-cy="`${props.parentComponentId}__deleteDefault`"
				>
					<i class="icon-ok green" v-if="props.row.delete_default" />
				</lba-grid-column>
				<lba-grid-column
					id="import_finish_pg_callback"
					label="PG Import Callback"
					width="150"
					:data-cy="`${props.parentComponentId}__bulkImportPgCallback`"
				>
					{{ props.row.import_finish_pg_callback }}
				</lba-grid-column>
				<lba-grid-column
					id="bulk_delete_pg_callback"
					label="PG Delete Callback"
					width="150"
					:data-cy="`${props.parentComponentId}__bulkDeletePgCallback`"
				>
					{{ props.row.bulk_delete_pg_callback }}
				</lba-grid-column>
				<lba-grid-column
					:id="'actions'"
					:label="$t('actions')"
					width="60"
					class="actions"
					style="justify-content: right"
					:data-cy="`${props.parentComponentId}__actions`"
				>
					<a
						src="#"
						style="flex: 1"
						:data-cy="`${props.parentComponentId}__actions__openDetail`"
						@click.stop="openDetail(props.row.def_import_export_uid)"
					>
						<i class="icon-new-tab"
							v-tooltip="$t('showInCard')">
						</i>
					</a>
					<a
						src="#"
						:data-cy="`${props.parentComponentId}__actions__openDialogRemoveEntry`"
						@click.stop="openRemoveDialog(props.row)"
					>
						<i class="icon-delete"
							v-tooltip="$t('remove')"
						>
						</i>
					</a>
				</lba-grid-column>
			</template>
		</lba-grid>
		<lba-dialog-modal
			parentComponentId="importExportConfiguration"
			componentId="removeEntry"
			name="removeEntry"
			:title="$t('removeImportExportConfiguration')"
			@close="removeRow = null"
		>
			<template #default="props">
				<div class="popup-footer">
					<button class="buttonRed" type="button" @click="confirmRemove" :data-cy="`${props.parentComponentId}__remove`">
						{{ $t('remove') }}
					</button>
					<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<lba-dialog-modal
			parentComponentId="importExportConfiguration"
			componentId="removeSelectedEntries"
			name="removeSelectedEntries"
			:title="messageSelectedRows()"
			@close="removeRow = null"
		>
			<template #default="props">
				<div class="popup-footer">
					<button class="buttonRed" type="button" @click="confirmRemoveSelected" :data-cy="`${props.parentComponentId}__remove`">
						{{ $t('remove') }}
					</button>
					<button class="buttonInverse" type="button" v-lba-dialog-close="'removeSelectedEntries'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
	</div>
</template>

<script>
import ImportExportConfigurationsModel from '../models/ImportExportConfigurations';

export default {
	data() {
		return {
			loading: true,
			importExportConfigurationsModel: null,
			collection: null,
			filterAttributes: {},
			removeRow: null,
			removeSelected: null,
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.importExportConfigurationsModel = new ImportExportConfigurationsModel(this.$http);
		this.collection = new this.$Collection(this.importExportConfigurationsModel);
		this.collection.attach(this, 'collection');
		this.loading = false;
	},
	methods: {
		getTableName(name) {
			if (!name) return '';
			const splittedByDot = name.split('.');
			const splittedByUnderscore = splittedByDot[1].split('_');
			return `${splittedByDot[0].toUpperCase()} - ${splittedByUnderscore.join(' ')}`;
		},
		getName(row) {
			if (row.firstname && row.lastname) {
				return `${row.firstname} ${row.lastname}`;
			}
			if (row.firstname) {
				return row.firstname;
			}
			if (row.lastname) {
				return row.lastname;
			}
			return row.user_name;
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'import-export-configuration',
				params: { id, openNewTab: true },
			});
		},
		openRemoveDialog(row) {
			this.removeRow = row;
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		confirmRemove() {
			if (this.removeRow) {
				this.importExportConfigurationsModel.remove(this.removeRow.def_import_export_uid);
			}
			this.removeRow = null;
			this.$root.$emit('dialog-close', 'removeEntry');
		},
		openRemoveSelected() {
			this.removeSelected = this.$refs.grid.getChecked();
			this.$root.$emit('dialog-open', { name: 'removeSelectedEntries' });
		},
		confirmRemoveSelected() {
			if (this.removeSelected) {
				this.importExportConfigurationsModel.removeSelected(this.removeSelected)
					.then((response) => {
						if (response && response.data === 'ok') {
							this.$notify.success(this.$t('form.removed'));
							this.$root.$emit('dialog-close', 'removeSelectedEntries');
							this.$root.$emit('grid.reload', this.$refs.grid.id);
						}
					})
					.catch(() => {
						this.$notify.warn(this.$t('entriesCantRemove'));
					});
			}
		},
		messageSelectedRows() {
			const selection = this.removeSelected;
			const action = this.$t('remove').toLowerCase();

			if (!selection) {
				return '';
			}
			if (selection.checkedAll) {
				if (selection.unchecked && Object.keys(selection.unchecked).length > 0) {
					return this.$t(
						'confirmRemoveDialog.messageCheckedAllUnchecked', {
							action,
							uncheckedCount: Object.keys(selection.unchecked).length,
						});
				} else {
					return this.$t('confirmRemoveDialog.messageCheckedAll', { action });
				}
			} else if (selection.filterCheckedAll) {
				return this.$t('confirmRemoveDialog.messageFilteredAll', { action });

			} else if (selection.checked && Object.keys(selection.checked).length > 0) {
				return this.$t('confirmRemoveDialog.messageChecked', {
					action,
					checkedCount: Object.keys(selection.checked).length,
				});
			}
			return '';
		},
	},
};
</script>
