<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ valueLabel }}
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired && !options.noRequiredSign" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<fake-provider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			:customMessages="options.customMessages"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-select
				:parentComponentId="currentComponentId"
				ref="select"
				v-model="data[key]"
				v-bind="bindOptions(extended_options)"
				:opts="selectOptions"
				:multiple="options.multiple"
				:renderHtmlLabels="options.renderHtmlLabels"
				:required="isRequired"
				:dontUseSearch="options.dontUseSearch"
				class="showInvalidTooltip"
				:class="{
					'lba-invalid': (showRequirement || (dirty && invalid)),
					'showWarning': (showRecommendation)
				}"
				:rootTag="options.rootTag || 'span'"
				:combobox="options.combobox || false"
				@input="onEvent('input')"
				@change="onEvent('change')"
				@search-change="onSearchChange"
				:readOnly="readOnly"
				:linkAction="options.linkAction || null"
			></lba-select>
			<template v-if="dirty && (invalid || customMessages)">
				<span
					v-for="(err, index) in errors"
					:key="index"
					:class="['lba-messages', options.invalidClass || '']"
					:data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputLbaSelect',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			extended_options: {},
		};
	},
	computed: {
		valueLabel() {
			const state = $_.find(this.options.options, (s) => s.value === this.data[this.key]);
			return (state && this.$getLocale(state.label)) || this.data[this.key];
		},
		showRequirement() {
			if (this.dirty && this.rules.required && !this.data[this.key]) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		if (this.data[this.key] === undefined) {
			this.$set(this.data, this.key, null);
		}
		if (this.options && this.options.boundaryElementSelector) {
			this.extended_options.boundaryElementSelector = this.options.boundaryElementSelector;
		}
	},
	methods: {
		async onSearchChange(event) {
			if (this.options.onSearchChange) {
				const result = this.options.onSearchChange(event);

				if (typeof result === 'object' && result.constructor === Promise) {
					await result;
				}
				this.selectOptions = this.options.options;
			}
		},
	},
};
</script>
