<template>
	<div>
		<div
			v-if="!multiple"
			class="lba-input-file add-attachment-area"
			ref="addAttachmentArea"
			@drop="onDrop"
			@dragover.prevent.stop
			@dragenter.prevent.stop="onDragEnter"
			@dragleave.prevent.stop="onDragLeave"
		>
			<input
				:data-cy="`${currentComponentId}__inputFile`"
				ref="inputFileSingle"
				@change="onChange"
				type="file"
				name="file-1[]"
				:id="id"
				class="inputfile"
				:multiple="multiple"
				:disabled="disabled"
			>
			<label :for="id" :class="inputClassList" v-tooltip="label" :data-cy="`${currentComponentId}__fileLabel`">
				<i class="icon-upload"></i>
				<span>
					{{ fileLabel || label }}
				</span>
			</label>
		</div>
		<div v-else>
			<lba-dnd-list2 :list="fileList" staticListOrder :id="listID"
				class="file-list" :style="fileListStyle" notDraggable
				:parentComponentId="`${currentComponentId}`"
			>
				<template v-slot:list>
					<div
						v-for="(file, index) in fileList"
						:key="index"
						class="file-list-item"
						:data-cy="`${currentComponentId}__fileListItem__${index}`"
						:class="{ silver: file.removed }"
						v-tooltip="file.name.length > 30 ? file.name : ''"
					>
						<div>
							<span class="file-list-item-icon">
								<i :class="getFileIcon(file)"></i>
							</span>
						</div>
						<div class="file-list-item-name">
							{{ getFileName(file) }}
						</div>
						&nbsp;
						<div class="file-list-item-size">
							({{ getFileSize(file.size) }})
						</div>
						<div class="file-list-item-icon float-right">
							<span
								v-if="multipleReadOnly"
								class="pointer"
								:data-cy="`${currentComponentId}__toggleFileAddRemove`"
								@mouseover="toggleBorder($event, true)"
								@mouseleave="toggleBorder($event, false)"
								@click="toggleFileRemoved(file)"
							>
								<i v-if="file.removed" class="icon-plus"></i>
								<i v-else class="icon-cancel"></i>
							</span>
							<span
								v-else
								class="pointer"
								:data-cy="`${currentComponentId}__fileRemove__${index}`"
								@mouseover="toggleBorder($event, true)"
								@mouseleave="toggleBorder($event, false)"
								@click="toggleFileRemoved(file)"
							>
								<i class="icon-cancel"></i>
							</span>
						</div>
					</div>
				</template>
				<template v-slot:after>
					<div class="lba-input-file" v-if="!multipleReadOnly">
						<input
							:data-cy="`${currentComponentId}__inputFile`"
							ref="inputFileMultiple"
							@change="onChange"
							type="file"
							name="file-1[]"
							:id="id"
							class="inputfile"
							:multiple="multiple"
							:disabled="disabled"
						>
						<label :for="id" v-if="!hideAddButton" :class="inputClassList" :data-cy="`${currentComponentId}__fileLabel`">
							<i class="icon-plus"></i>
							<span>
								{{ label }}
							</span>
						</label>
					</div>
				</template>
			</lba-dnd-list2>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

const applicationFileTypes = [{ type: 'zip', icon: 'archiv' }, { type: 'pdf', icon: 'pdf' }];

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: Array,
		id: {
			type: String,
			required: true,
		},
		multiple: Boolean,
		hideAddButton: {
			type: Boolean,
			default: false,
		},
		listDirection: {
			validator: (value) => {
				return ['row', 'column'].indexOf(value) !== -1;
			},
			default: 'column',
		},
		dynamicWidth: {
			type: Boolean,
			default: false,
		},
		multipleReadOnly: {
			type: Boolean,
			default: false,
		},
		inputClassList: [String, Array, Object],
		disabled: Boolean,
		fileLabel: String,
	},
	data() {
		return {
			label: null,
			listID: null,
			fileListStyle: null,
			canEmit: true,
		};
	},
	computed: {
		fileList: {
			get() {
				return this.value;
			},
			set(value) {
				if (this.canEmit) {
					this.$emit('input', value);
				} else {
					this.canEmit = true;
				}
			},
		},
	},
	created() {
		if (!this.fileList) {
			this.canEmit = false;
			this.fileList = [];
		}

		this.fileListStyle = `flex-direction: ${this.listDirection}`;
		this.listID = `${this.id}-list`;
		this.setDefaultLabel();
		this.$root.$listen('input-file.select-files', this.selectFiles, this);
		this.$root.$listen('input-file.add-files', this.addFiles, this);
	},
	methods: {
		toggleFileRemoved(file) {
			file.removed = !file.removed;
			const newList = this.fileList.filter((element) => !element.removed);
			this.$emit('input', newList);
		},
		getFileIcon(file) {
			const icon = 'icon-file';
			let mimeType = mime.getType(file.name);

			if (!mimeType) {
				mimeType = file.type || 'unknown';
			}

			const splittedType = mimeType.split('/');
			let fileType = splittedType[0];

			if (fileType === 'application') {
				const appFileType = applicationFileTypes.find((appType) => appType.type === splittedType[1]);

				if (appFileType) {
					fileType = appFileType.icon;
				}
			}

			const classes = {};
			classes[`${icon}-${fileType}`] = true;
			classes.silver = file.removed;
			return classes;
		},
		selectFiles(id) {
			if (id === this.id) {
				if (this.multiple && this.$refs.inputFileMultiple) {
					this.$refs.inputFileMultiple.click();

				} else if (!this.multiple && this.$refs.inputFileSingle) {
					this.$refs.inputFileSingle.click();

				}
			}
		},
		addFiles(event) {
			if (event.id === this.id) {
				this.change(event?.files);
			}
		},
		getFileName(file) {
			let fileNameSnippet = file.name;

			if (file.name.length > 30) {
				fileNameSnippet = file.name.substring(0, 30) + '...';
			}
			return fileNameSnippet;
		},
		getParent(targetNode, root) {
			let target = targetNode;

			while (target && target !== document.body) {
				if (target.parentNode === root) {
					return target;
				}

				target = target.parentNode;
			}

			return null;
		},
		toggleBorder(event, state) {
			const list = document.getElementById(this.listID);
			const parent = this.getParent(event.target, list);

			if (state) {
				parent.setAttribute('style', 'border-color: #f00;');
			} else {
				parent.setAttribute('style', '');
			}
		},
		getFileSize(size) {
			if (size < 1024) {
				return `${size} B`;
			}
			if (size < 1024 * 1024) {
				return `${parseInt(size / 1024, 10)} kB`;
			}
			return `${parseInt(size / (1024 * 1024), 10)} MB`;
		},
		setDefaultLabel() {
			if (this.multiple) {
				this.label = '';
			} else {
				this.label = this.$t('chooseFileButton');
			}
		},
		onChange(event) {
			const { files } = event.target;

			if (files && files.length > 0) {
				if (this.multiple) {
					this.fileList = this.fileList.concat(Array.from(files));
				} else {
					this.fileList = Array.from(files);
				}

				if (this.multiple) {
					this.setDefaultLabel();
				} else {
					if (files.length > 1) {
						if (files.length >= 2 && files.length <= 4) {
							this.label = this.$t('chosen2To4Files', { count: files.length });
						} else {
							this.label = this.$t('chosenFiles', { count: files.length });
						}
					} else {
						this.label = files[0].name;
					}
				}
			} else if (!files && !this.fileList.length) {
				this.fileList = [];
				this.setDefaultLabel();
			}
			event.target.value = null;
		},
		onDrop(event) {
			this.change(event?.dataTransfer?.files);

			if (this.$refs.addAttachmentArea) {
				this.$refs.addAttachmentArea.classList.remove('drag-enter');
			}

			event.preventDefault();
		},
		onDragEnter(event) {
			const addAttachmentArea = (
				event.target.classList.contains('add-attachment-area')
					? event.target
					: event.target.closest('.add-attachment-area')
			);

			if (addAttachmentArea) {
				addAttachmentArea.classList.add('drag-enter');
			}
		},
		onDragLeave(event) {
			const addAttachmentArea = (
				event.target.classList.contains('add-attachment-area')
					? event.target
					: event.target.closest('.add-attachment-area')
			);
			const addAttachmentAreaRect = addAttachmentArea.getBoundingClientRect();

			if (addAttachmentArea && (
				event.pageX <= addAttachmentAreaRect.left || event.pageX >= addAttachmentAreaRect.right ||
				event.pageY <= addAttachmentAreaRect.top || event.pageY >= addAttachmentAreaRect.bottom
			)) {
				addAttachmentArea.classList.remove('drag-enter');
			}
		},
		change(files) {
			if (!files) {
				return;
			}

			if (this.multiple && this.$refs.inputFileMultiple) {
				this.$refs.inputFileMultiple.files = files;
				this.$refs.inputFileMultiple.dispatchEvent(new Event('change'));

			} else if (!this.multiple && this.$refs.inputFileSingle) {
				this.$refs.inputFileSingle.files = files;
				this.$refs.inputFileSingle.dispatchEvent(new Event('change'));

			}
		},
	},
};
</script>
