export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.post('lbadmin/activity', { params });
	}

	logout(session_id) {
		return this.$http.delete(`lbadmin/activity/${session_id}`);
	}
}
