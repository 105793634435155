// JSON not yet supported for clipboard in browsers (https://caniuse.com/mdn-api_clipboard_write)
export default {
	data() {
		return {
			supportedMethods: {
				read: false,
				readText: false,
				write: false,
				writeText: false,
				execCommand: false,
				clipboardItem: false,
			},
		};
	},
	created() {
		if (navigator && navigator.clipboard) {
			if (typeof navigator.clipboard?.read === 'function') {
				this.supportedMethods.read = true;
			}
			if (typeof navigator.clipboard?.readText === 'function') {
				this.supportedMethods.readText = true;
			}
			if (typeof navigator.clipboard?.write === 'function') {
				this.supportedMethods.write = true;
			}
			if (typeof navigator.clipboard?.writeText === 'function') {
				this.supportedMethods.writeText = true;
			}
		}

		if (typeof document.execCommand === 'function') {
			this.supportedMethods.execCommand = true;
		}

		if ('ClipboardItem' in window && typeof ClipboardItem === 'function') {
			this.supportedMethods.clipboardItem = true;
		}

	},
	methods: {
		isClipboardAvailable() {
			return (navigator && navigator.clipboard);
		},
		async clipboardCopy(data, mimeType = 'text/plain') {
			try {
				if (this.supportedMethods.clipboardItem && this.supportedMethods.write) {
					const { ClipboardItem } = window;
					const blob = new Blob([data], { type: mimeType });
					const clipboardItem = new ClipboardItem({ [mimeType]: blob });

					await navigator.clipboard.write([clipboardItem]);

				} else if (mimeType === 'text/plain' && this.supportedMethods.writeText) {
					await navigator.clipboard.writeText(data);

				} else if (mimeType === 'text/plain' && this.supportedMethods.execCommand) {
					const textArea = document.createElement('textarea');
					textArea.value = data;
					document.body.appendChild(textArea);
					textArea.focus();
					textArea.select();
					document.execCommand('copy');
					textArea.remove();

				} else {
					this.$notify.warn(this.$t('clipboardNotSupported'));
					console.error(`[Clipboard](copy) ${mimeType} is not supported by this browser`);
					return false;

				}

				this.$notify.success(this.$t('clipboardCopied'));
				return true;

			} catch (err) {
				this.$notify.warn(this.$t('clipboardCopyFailed'));
				console.error('[Clipboard](copy) error while copying into clipboard', err);
				return false;

			}
		},
		async clipboardPaste(mimeType = 'text/plain') {
			try {
				if (this.supportedMethods.read) {
					const clipboardItems = await navigator.clipboard.read();

					if (clipboardItems.length === 0) {
						console.error('[Clipboard](paste) clipboard is empty');
						this.$notify.warn(this.$t('clipboardEmpty'));
						return false;
					}

					const item = $_.find(clipboardItems, (clipboardItem) => clipboardItem.types.includes(mimeType));
					if (item) {
						const blob = await item.getType(mimeType);
						const str = await blob.text();

						this.$notify.success(this.$t('clipboardPasted'));
						return str;

					} else {
						this.$notify.warn(this.$t('clipboardEmpty'));
						console.error(`[Clipboard](paste) clipboard does not contain any ${mimeType} data`);

					}

				} else if (mimeType === 'text/plain' && this.supportedMethods.readText) {
					const str = await navigator.clipboard.readText();

					this.$notify.success(this.$t('clipboardPasted'));
					return str;

				} else {
					this.$notify.warn(this.$t('clipboardReadNotSupported'));
					console.error('[Clipboard](paste) reading from clipboard is not supported by this browser');

				}

			} catch (err) {
				this.$notify.warn(this.$t('clipboardPasteFailed'));
				console.error('[Clipboard](paste) error while reading from clipboard', err);

			}
		},
	},
};
