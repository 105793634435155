import Ajv from '../plugins/Ajv';
import Axios from '../plugins/Axios';
import CKEditor from '../plugins/CKEditor';
import Lodash from '../plugins/Lodash';
import Mime from '../plugins/Mime';
import Moment from '../plugins/Moment';
import RouteParams from '../plugins/RouteParams';
import RecommendedProvider from '../plugins/RecommendedProvider';
import Collision from '../plugins/Collision';
import DownloadFile from '../plugins/DownloadFile';
import UploadFile from '../plugins/UploadFile';
import FetchCached from '../plugins/FetchCached';
import CheckDestroyed from '../plugins/CheckDestroyed';
import Sleep from '../plugins/Sleep';
import PageSettings from '../plugins/PageSettings';
import Echarts from '../plugins/Echarts';
import PrismEditor from '../plugins/PrismEditor';
import Vuelidate from '../plugins/Vuelidate';
import TimeBeforeAfter from '../plugins/TimeBeforeAfter';
import Locale from '../plugins/Locale';
import RouteActions from '../plugins/RouteActions';

export default function registerPlugins(Vue) {
	Vue.use(Ajv);
	Vue.use(Axios);
	Vue.use(CKEditor);
	Vue.use(Lodash);
	Vue.use(Mime);
	Vue.use(Moment);
	Vue.use(RouteParams);
	Vue.use(Collision);
	Vue.use(Sleep);
	Vue.use(FetchCached);
	Vue.use(CheckDestroyed);
	Vue.use(DownloadFile);
	Vue.use(UploadFile);
	Vue.use(RecommendedProvider);
	Vue.use(PageSettings);
	Vue.use(Echarts);
	Vue.use(PrismEditor);
	Vue.use(Vuelidate);
	Vue.use(TimeBeforeAfter);
	Vue.use(Locale);
	Vue.use(RouteActions);
};
