function checkDestroyed(vm, throwError = true, ignoreKey = false) {
	if (vm == null) {
		throw new Error('Missing vm param');
	}
	if (vm._isDestroyed) {
		if (throwError) throw new Error('[plugin.checkDestroyed] component is already destroyed');
		return true;
	}

	if (ignoreKey) {
		return false;
	}

	if ($_.isEmpty($_.get(vm, '$vnode.key'))) {
		// in case it is in try catch and someone is wondering why it does not work
		return console.error(
			'[plugin.checkDestroyed] missing key parameter,',
			'component must be directly under <router-view> and contain key parameter'
		);
	}
	if ($_.isEmpty($_.get(vm, '$route.matched'))) {
		// in case it is in try catch and someone is wondering why it does not work
		return console.error('[plugin.checkDestroyed] missing matched routes, current key:', vm.$vnode.key);
	}

	const resolvedRoutes = vm.$route.matched.map((match) => {
		const { resolved } = vm.$router.resolve({ name: match.name, params: vm.$route.params });
		return resolved;
	});

	if (!resolvedRoutes.some((route) => route.path === vm.$vnode.key)) {
		if (throwError) throw new Error('[plugin.checkDestroyed] component key does not match any path:', vm.$vnode.key);
		return true;
	}
	return false;
}

export default {
	install(Vue) {
		Vue.prototype.$checkDestroyed = checkDestroyed;
	},
};
