function getAvailableRouteActions() {
	return $_.filter(this.$availableRouteActions, (action) => (
		action.permissions == null ||
		$_.every(action.permissions, (perm) => this.$permissions.checkPermission(perm))
	));
};

function getAvailableNewEntries() {
	return $_.filter(this.$availableNewEntries, (action) => (
		action.permissions == null ||
		$_.every(action.permissions, (perm) => this.$permissions.checkPermission(perm))
	));
};

function prepareRouteAction(action) {
	if (action && action.route) {
		let route = action.route;
		if (action.params) {
			$_.forEach(action.params, (value, key) => {
				if (value.match(/\{randomNumber\}/)) {
					value = value.replace('{randomNumber}', this.$prepareId());
				}
				route = route.replace(`:${key}`, value);
			});
		}
		return {
			route,
			label: action.label,
		};
	}
	return null;
};

export default {
	install(Vue) {
		Vue.prototype.$getAvailableRouteActions = getAvailableRouteActions;
		Vue.prototype.$getAvailableNewEntries = getAvailableNewEntries;
		Vue.prototype.$prepareRouteAction = prepareRouteAction;
	},
};
