/*
 * __back__
 *
 * Pomocné funkce pro přenačtení práv
 * Vyžaduje mixiny Component a Page
 *
 */

const loggerName = `[lbadmin.mixins.Permission]`;

export default {
	data() {
		return {
			permissions: [],
		};
	},
	activated() {
		this.permissionsReload();
	},
	methods: {
		// mixin: Permission
		listenPermissionOnChange() {
			this.$root.$listen('permissions-reload', this.permissionsReload, this);
		},
		// mixin: Permission
		permissionsReload() {
			console.debug(`${loggerName}(permissionsReload)`, this.$options.name);
			let anyChange = false;

			if ($_.isEmpty(this.permissions)) {
				console.warn(`${loggerName}(permissionsReload) permissions are empty`);
				return;
			}

			$_.forEach(this.permissions, (permission) => {
				if ($_.isEmpty(permission.variable)) {
					console.error(`${loggerName}(permissionsReload) ${this.$options.name} permission is missing variable:`, permission);
					return;
				}
				if (!(permission.variable in this)) {
					console.error(`${loggerName}(permissionsReload) ${this.$options.name} permission variable is not in data:`, permission);
					return;
				}
				if ($_.isEmpty(permission.name) && $_.isEmpty(permission.names)) {
					console.error(
						`${loggerName}(permissionsReload) ${this.$options.name} permission is missing name or names:`,
						permission
					);
					return;
				}

				const oldState = this[permission.variable];
				let newState = null;

				if (!$_.isEmpty(permission.names)) {
					console.debug(`${loggerName}(permissionsReload)`, this.$options.name, permission.variable, permission.names);
					newState = !!this.$permissions.checkPermissions(permission.names, this.teamRoleUid);
				} else {
					console.debug(`${loggerName}(permissionsReload)`, this.$options.name, permission.variable, permission.names);
					newState = !!this.$permissions.checkPermission(permission.name, this.teamRoleUid);
				}

				if (permission.negation) {
					newState = !newState;
				}

				if (newState !== oldState) {
					anyChange = true;
					this[permission.variable] = newState;
				}
				console.debug(`${loggerName}(permissionsReload)`, this.$options.name, permission.variable, this[permission.variable]);
			});

			if (anyChange) {
				if (!$_.isEmpty(this.gridId)) {
					this.$root.$emit('grid.reload', this.gridId);
				}
				this.forceRerender('global');
			}

			if (this.onPermissionReload != null && this.onPermissionReload.constructor === Function) {
				this.onPermissionReload(anyChange);
			}
		},
	},
};
