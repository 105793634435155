function getRowId(ids, row) {
	if (row == null) return undefined;
	const valuesId = [];
	ids.forEach((id) => {
		valuesId.push(row[id]);
	});

	return valuesId.join('|');
}

function getRowIdValues(ids, rowId) {
	const rowIdValues = {};
	const values = rowId.split('|');

	ids.forEach((columnName, index) => {
		rowIdValues[columnName] = values[index];
	});

	return rowIdValues;
}

const exceptKeys = ['_offset', '_limit', '_extra_columns', 'filter'];

class Collection {
	constructor(model, options = {}) {
		// console.debug(model, options);
		this.model = model;
		this.errors = null;
		this.modelMethod = options.method || 'query';
		this.modelExportMethod = options.exportMethod || `${this.modelMethod}Export`;
		this.params = { ...options.params };
		this.search = { ...options.search };
		this.createdOptions = { ...options };
		this.hiddenSearch = { ...options.hiddenSearch };
		this.filter = { ...options.filter };
		this.originalQueryOptions = this.getQueryOptions(0, 50, options);
		this.destroyWatcher = null;
		this.vm = options.vm || null;

		this.lastFetchParams = {};
		this.lastOptions = $_.cloneDeep(this.getQueryOptions(0, 50, options));
		// console.log('last opts:', this.lastOptions);

		this.cache = [];
		this.offset = 0;
		this.limit = 0;
		this.isLoading = false;

		this.length = 0;
		this.loadedRows = 0;

		this.get = this.get.bind(this);
		this.getAll = this.getAll.bind(this);
		this.attach = this.attach.bind(this); // TODO - deprecated?

		this.getAllLoaded = this.getAllLoaded.bind(this);
		this.reloadContent = this.reloadContent.bind(this);
		this.optionsChanged = this.optionsChanged.bind(this);
		this.getQueryOptions = this.getQueryOptions.bind(this);
		this.updateRow = this.updateRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);

		this.lastRequestTime = null;
	}

	getRowInfo(options) {
		const rowId = options.rowId || getRowId(options.ids, options.row);
		let data = null;
		let dataIndex = null;
		console.debug('[Collection](getRowInfo) rowId:', rowId, JSON.parse(JSON.stringify(this.cache)));
		this.cache.find((cachedRow, index) => {
			if (getRowId(options.ids, cachedRow) === rowId) {
				console.debug('[Cache](getRowInfo) cachedRowId:', getRowId(options.ids, cachedRow), cachedRow);
				data = cachedRow;
				dataIndex = index;
				return true;
			}
			return false;
		});

		if (dataIndex >= 0 && data) {
			return { data: this.cache, dataIndex, row: data };
		}
	}

	async updateRow(info) {
		const rowInfo = this.getRowInfo(info);
		console.debug('[Collection](updateRow) rowInfo:', rowInfo);

		if (rowInfo) {
			if (!info.reload) {
				rowInfo.data[rowInfo.dataIndex] = { ...rowInfo.data[rowInfo.dataIndex], ...info.row };
				return true;
			} else {
				const baseParams = { ...this.lastOptions, _offset: 0, _limit: 1, _scroll: false, _ts: new Date().getTime() };
				let params = { ...baseParams };

				if (info.rowId) {
					params = { ...baseParams, ...getRowIdValues(info.ids, info.rowId) };
				} else {
					info.ids.forEach((id) => {
						params[id] = info.row[id];
					});
				}

				const dataRequest = () => this.model[this.modelMethod](params);
				const { data } = await $fetchCached(this.vm, dataRequest);

				if (data) {
					if (!$_.isEmpty(data[0])) {
						rowInfo.data[rowInfo.dataIndex] = data[0];
						info.row = data[0];
						console.debug('[Collection](updateRow) data:', data[0]);
						return true;
					}

					console.debug('[Collection](updateRow) delete');
					this.deleteRow(info);
					return { deleted: true };
				}
				return false;
			}
		}
		return false;
	}

	deleteRow(info) {
		const rowId = info.rowId || getRowId(info.ids, info.row);
		let data = null;
		let dataIndex = null;
		this.cache.find((cachedRow, index) => {
			if (getRowId(info.ids, cachedRow) === rowId) {
				dataIndex = index;
				data = cachedRow;
				console.debug('[Collection](deleteRow) dataIndex:', dataIndex);
				return true;
			}
			return false;
		});

		if (dataIndex >= 0 && data) {
			this.length--;
			this.loadedRows--;
			this.cache.splice(dataIndex, 1);
			// TODO - load next row?
			return true;
		}
		return false;
	}

	async get(index) {
		if (this.cache.length && index >= this.cache[0].totalIndex && index <= this.cache[this.cache.length - 1].totalIndex) {
			return this.cache[index - this.offset];
		} else {
			const result = await this.model[this.modelMethod](this.getQueryOptions(index, 1));
			return result.data;
		}
	}

	getSiblings(options) {
		if ($_.isEmpty(options.ids)) options.ids = ['id'];
		if ($_.isEmpty(options.rowId) && $_.isEmpty(options.row)) throw new Error('[Collection] getSiblings: "rowId" or "row" is required');
		const rowId = options.rowId || getRowId(options.ids, options.row);
		const siblings = { previous: null, next: null };

		$_.find(this.cache, (el, index) => {
			if (getRowId(options.ids, el) === rowId) {
				if (index > 0) {
					siblings.previous = this.cache[index - 1];
				}
				if (index < this.cache.length - 1) {
					siblings.next = this.cache[index + 1];
				}
			}
		});
		// TODO - pokud dojdu na konec stranky, nemam next/previous. udelat prenacteni na dalsi/predchozi stranku?
		return siblings;
	}

	getAllLoaded(offset, limit, options = {}) {
		return this.getAll(offset, limit, options);
	}

	async getAll(offset, limit, options = {}) {
		if (limit > this.cacheLimit && !this.noCacheLimit) {
			throw new Error(`request limit too big, maximum: ${this.cacheLimit} requested: ${limit}`);
		}

		console.debug(`[Collection] (getAll) - offset: ${offset}, limit: ${limit}, options:`, options);

		this.offset = offset;
		this.limit = limit;

		const queryOptions = options.queryOptions || this.getQueryOptions(offset, limit, options);

		if (options.changed || this.optionsChanged(queryOptions)) {
			this.clear();
		}

		this.lastOptions = $_.cloneDeep(queryOptions);

		const queryParams = this.getQueryOptions(offset, limit, options);

		if (options.saveParams) {
			this.lastFetchParams = options.params || this.params;
		}

		// console.debug(this.modelMethod, queryParams);
		this.isLoading = true;
		const requestTime = new Date();
		this.lastRequestTime = requestTime;
		const dataRequest = () => this.model[this.modelMethod](queryParams);
		const result = await $fetchCached(this.vm, dataRequest);
		this.isLoading = false;

		// if current request is not last one, do not proceed
		if (this.lastRequestTime !== requestTime) return;

		this.errors = null;
		if (result && result.response && result.response.status) {
			if (result.response.status === 404) {
				this.errors = this.vm.$t('viewNotFound');

			} else if (result.response.status === 401 || result.response.status === 403) {
				this.errors = this.vm.$t('viewPermissionDenied');

			} else if (result.response.status === 500) {
				this.errors = this.vm.$t('viewServerError');

			}
		}

		if (result && result.data) {
			const count = parseInt(result.headers['x-lbadmin-count'], 10);

			if (!Number.isNaN(count)) {
				this.length = count;
			}
			this.loadedRows = result.data.length;

			this.cache = result.data;

			$_.forEach(this.cache, (el, index) => {
				el.totalIndex = index + offset;
			});
			// console.debug('[Collection] (getAll) - data - ', this.cache);

			return this.cache;
		}
	}

	getQueryOptions(offset, limit, options = {}) {
		// lbadmin has somewhere inside it a function which sets _limit to 100 if it is null
		if (options.queryOptions) {
			return { ...options.queryOptions, _offset: offset, _limit: limit || 0 };
		}
		let _locale = 'en';
		if ($_.get(this.vm, '$user.lang')) {
			_locale = this.vm.$user.lang;
		}
		return {
			_locale,
			...this.params,
			...options.params,
			...this.search,
			...this.hiddenSearch,
			filter: this.filter,
			_offset: offset,
			_limit: limit || 0,
		};
	}

	clear() {
		console.debug('[Collection](clear) cache');
		this.cache = [];
		// this.requests.forEach((request) => { request.canceled = true; });
	}

	optionsChanged(options) {
		// console.log('new opts:', JSON.parse(JSON.stringify(options)));
		// console.log('old opts:', JSON.parse(JSON.stringify(this.lastOptions)));

		if (!this.lastOptions) {
			return false;
		}

		const oldOptionKeys = Object.keys(this.lastOptions);
		const newOptionKeys = Object.keys(options);

		return (
			this.compareOptions(oldOptionKeys, options, this.lastOptions) >= 0 ||
			this.compareOptions(newOptionKeys, options, this.lastOptions) >= 0 ||
			JSON.stringify(this.lastOptions.filter) !== JSON.stringify(options.filter)
		);
	}

	compareOptions(keys, options1, options2) {
		return keys.findIndex(
			(key) => (
				!exceptKeys.includes(key) && (
					options1[key] !== options2[key] &&
					(options1[key] || options2[key])
				)
			)
		);
	}

	getRequestedLength() {
		return this.limit;
	}

	reloadContent() {
		this.clear();
		this.getAll(this.offset, this.limit, { params: this.lastFetchParams });
	}

	attach(vm, collectionName = 'collection') {
		// TODO - deprecated?
		// this.vm = vm;
		// this.destroyWatcher = this.vm.$watch(`${collectionName}.search.search`, (newVal, oldVal) => {
		// 	if (newVal !== oldVal) {
		// 		this.getAll(0, 1000);
		// 	}
		// });
		// this.vm.$once('beforeDestroy', this.destroyWatcher);

		// this.vm.$root.$listen('content.reload', this.reloadContent, this.vm, true);
	}

	toggleOrderBy(columnId) {
		if (this.params._order === columnId) {
			if (this.params._order_dir === 'asc') {
				this.params._order_dir = 'desc';
			} else {
				this.params._order_dir = 'asc';
			}
		} else {
			this.params._order = columnId;
			this.params._order_dir = 'asc';
		}
	}

	setOrderBy(columnId, direction) {
		this.params._order = columnId;
		this.params._order_dir = direction;
	}
}

module.exports = Collection;
