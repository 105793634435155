<template>
	<div v-if="!loading" id="content">
		<div class="page-header">
			<h1>
				{{ $t("settings.user.user") }}
				<span class="gray">{{ meta.name }}</span>
			</h1>
			<div class="page-controls" >
				<button
					v-if="!isNew && !readOnly"
					name="openWizardDialog"
					class="buttonTransparent"
					data-cy="user__sendWizard"
					@click.stop="sendWizard"
					:disabled="dirty"
				>
					{{ $t('tfaMethods.wizard.sendWizard') }}
				</button>
				<button
					v-if="!isNew && !readOnly"
					name="openRemoveDialog"
					class="buttonTransparent"
					data-cy="user__remove"
					@click.stop="deleteUser"
				>
					{{ $t('remove') }}
				</button>

				<lba-button-save
					ref="saveButton"
					:id="saveButtonId"
					:inputDirty="inputDirtyForSave"
					name="save"
					data-cy="user__save__button"
					@click.stop="submit"
				/>
				<span v-if="!isNew" @click="tabReload" data-cy="user__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<masonry
				:cols="{default: 2, 1000: 1}"
				:gutter="{default: '20px', 1000: '15px'}"
			>
				<lba-user-form
					parentComponentId="user"
					componentId="settings"
					:user="user"
					:model="userModel"
					:groups="groups"
					:extraSettings="extraSettings"
					:extraSettingsKey="extraSettingsKey"
					:invalidPass="invalidPass"
					:readOnly="readOnly"
					:isDirty="dirty"
					:isNew="isNew"
					:user_tfa_required_write="user_tfa_required_write"
					expanded
					ref="form"
					@input="setInputDirty(true);"
					@change="setDirty(true);"

				></lba-user-form>
				<ValidationObserver ref="form2">
					<form name="form2" lba-size-class="{'contentWide': 700}">
						<lba-section
							:title="servercareLoaded ? $t('settings.profile') : $t('settings.profileTeams')"
							:slotType="'form'"
							expanded
							parentComponentId="user"
							componentId="profileTeams"
						>
							<template #default="props">
								<template v-if="!servercareLoaded">
									<s class="size-4">
										<small>
											{{ $t('settings.profiles') }}
											<span class="required-sign"></span>
										</small>
										<ValidationProvider
											:name="servercareLoaded ? $t('settings.profile') :
												$t('settings.profiles')"
											v-slot="{ invalid, errors }"
											:rules="{ required: true }"
										>
											<lba-select
												style="width: -webkit-fill-available;"
												v-model="user.profiles"
												:opts="labeledProfiles"
												:multiple="true"
												:renderHtmlLabels="true"
												:rootTag="'div'"
												noNotSetOption
												disableUnknown
												:combobox="true"
												@change="setDirty(true);"
												:class="{ 'lba-invalid': invalid && dirty }"
												:readOnly="readOnly"
											></lba-select>
											<template v-if="dirty">
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`${props.parentComponentId}__profileName__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</template>
										</ValidationProvider>
									</s>
									<br>
									<s class="wide mt-3" v-if="!$_.isEmpty(user.roles) || !$_.isEmpty(filterGroups)">
										<small>
											{{ $t('settings.groups') }}
										</small>
										<table
											class="content-table wide"
										>
											<tr>
												<th style="width: 200px;">{{ $t('settings.group') }}</th>
												<th>{{ $t('settings.profile') }}</th>
												<th style="width: 20px;text-align: center;padding: 0;"></th>
											</tr>
											<tr
												v-for="(team, teamIndex) in user.roles" :key="teamIndex"
											>
												<td>
													<a
														:key="`team-${teamIndex}`"
														@click="openTeam(team.role_uid)"
														:data-cy="`user_team_${teamIndex}__openMember`"
													>
														{{ team.role_name }}
													</a>
												</td>
												<td
													style="display: flex; align-items: center;flex-direction: column; min-height: 1.5em;"
												>
													<lba-select
														style="width: 100%;"
														v-model="team.profiles"
														:opts="labeledProfiles"
														:multiple="true"
														:renderHtmlLabels="true"
														:rootTag="'div'"
														noNotSetOption
														disableUnknown
														:combobox="true"
														@change="setDirty(true);"
														:readOnly="readOnly"
														:topPlaceholder="$t('settings.inherited', {
															profiles: getProfilesName(savedProfiles)
														})"
													></lba-select>
												</td>
												<td style="width: 20px;text-align: center;padding: 5px;">
													<a
														v-if="!readOnly"
														v-on:click="removeGroup(teamIndex)"
														:data-cy="`${props.parentComponentId}__role${teamIndex}__remove`"
														:title="$t('remove')"
													>
														<i class="icon-delete"></i>
													</a>
												</td>
											</tr>
										</table>
									</s>
									<br>
									<s v-if="!$_.isEmpty(filterGroups) && !readOnly" class="mt-2">
										<select
											:data-cy="`${props.parentComponentId}__newGroup__select`"
											name="newGroup"
											@change="addGroup"
											v-model="addedGroup"
										>
											<option
												:value="null"
												:data-cy="`${props.parentComponentId}__newGroup__select__optionNull`"
											>
												{{ $t('settings.addGroup') }}
											</option>
											<option
												v-for="(group, index) in filterGroups"
												:value="group"
												:key="group.role_uid"
												:data-cy="`${props.parentComponentId}__newGroup__select__option${index}`"
											>
												{{ group.role_name }}
											</option>
										</select>
									</s>
									<br>
									<s class="wide mt-3" v-if="user.inherited_roles && user.inherited_roles.length">
										<small>
											{{ $t('settings.groupsAll') }}
											<i class="icon-info" v-tooltip="$t('settings.groupsAllInfo')"></i>
										</small>
										<table
											class="content-table wide"
										>
											<tr>
												<th style="width: 200px;">{{ $t('settings.group') }}</th>
												<th>{{ $t('settings.profile') }}</th>
											</tr>
											<tr
												v-for="(team, teamIndex) in user.inherited_roles" :key="teamIndex"
											>
												<td>
													<a :key="`i-${teamIndex}`" @click="openTeam(team.role_uid)"
														:data-cy="`${props.parentComponentId}__groupsAll__openTeam`"
													>
														{{ team.role_name }}
													</a>
												</td>
												<td
													style="display: flex; align-items: start;flex-direction: column;"
												>
													{{ getProfilesName(team.profiles) }}
												</td>
											</tr>
										</table>
									</s>
								</template>
								<template v-if="servercareLoaded">
									<s class="size-4">
										<small>
											{{ $t('settings.profile') }}
											<span
												v-if="!$user.extraParams.customer_super && servercareLoaded"
												class="required-sign"
											></span>
											<i
												class="icon-info"
												v-tooltip="$t('settings.oneCustomerProfileInfo')"
											></i>
										</small>
										<ValidationProvider
											:name="servercareLoaded ?
												$t('settings.profile') : $t('settings.profiles')"
											v-slot="{ invalid, errors }"
											:rules="{ required: (!$user.extraParams.customer_super &&
												servercareLoaded) }"
										>
											<lba-select
												v-if="!$user.extraParams || !$user.extraParams.customer_super"
												style="width: -webkit-fill-available;"
												v-model="currentCustomer.profiles"
												:opts="labeledProfiles"
												:multiple="true"
												:renderHtmlLabels="true"
												:rootTag="'div'"
												noNotSetOption
												disableUnknown
												:combobox="true"
												@change="currentCustomerChange();"
												:class="{ 'lba-invalid': invalid && dirty }"
												:readOnly="readOnly"
												:limitSelected="1"
											></lba-select>
											<lba-select
												v-else
												style="width: -webkit-fill-available;"
												v-model="user.profiles"
												:opts="labeledProfiles"
												:multiple="true"
												:renderHtmlLabels="true"
												:rootTag="'div'"
												noNotSetOption
												disableUnknown
												:combobox="true"
												@change="setDirty(true);"
												:class="{ 'lba-invalid': invalid && dirty }"
												:readOnly="readOnly"
												:limitSelected="1"
											></lba-select>
											<template v-if="dirty">
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`${props.parentComponentId}__profileName__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</template>
										</ValidationProvider>
									</s>
									<br>
									<template v-if="$user.extraParams && $user.extraParams.customer_super">
										<s class="size-4 mt-3" v-if="!$_.isEmpty(user.roles) || !$_.isEmpty(filterGroups)">
											<small>
												{{ $t('settings.customers') }}
											</small>
											<table
												class="content-table wide"
											>
												<tr>
													<th style="width: 200px;">{{ $t('settings.customer') }}</th>
													<th>
														{{ $t('settings.profile') }}
														<i
															class="icon-info"
															v-tooltip="$t('settings.oneCustomerProfileInfo')"
														></i>
													</th>
													<th style="width: 20px;text-align: center;padding: 0;"></th>
												</tr>
												<tr
													v-for="(customer, customerIndex) in user.customers" :key="customerIndex"
												>
													<td>
														<a
															:key="`customer-${customerIndex}`"
															@click="opencustomer(customer.role_uid)"
															:data-cy="`user_customer_${customerIndex}__openMember`"
														>
															{{ customer.role_name }}
														</a>
													</td>
													<td
														style="display: flex; align-items: center;
															flex-direction: column; min-height: 1.5em;"
													>
														<lba-select
															style="width: 100%;"
															v-model="customer.profiles"
															:opts="labeledProfiles"
															:multiple="true"
															:renderHtmlLabels="true"
															:rootTag="'div'"
															noNotSetOption
															disableUnknown
															:combobox="true"
															@change="setDirty(true);"
															:readOnly="readOnly"
															:limitSelected="1"
															:topPlaceholder="savedProfiles.length ? $t('settings.inherited', {
																profiles: getProfilesName(savedProfiles)
															}) : ''"
														></lba-select>
													</td>
													<td style="width: 20px;text-align: center;padding: 5px;">
														<a
															v-if="!readOnly"
															v-on:click="removeCustomer(customerIndex)"
															:data-cy="`${props.parentComponentId}__role${customerIndex}__remove`"
															:title="$t('remove')"
														>
															<i class="icon-delete"></i>
														</a>
													</td>
												</tr>
											</table>
										</s>
										<br>
										<s v-if="!$_.isEmpty(filterCustomers) && !readOnly" class="mt-2">
											<select
												:data-cy="`${props.parentComponentId}__newCustomer__select`"
												name="newCustomer"
												@change="addCustomer"
												v-model="addedCustomer"
											>
												<option
													:value="null"
													:data-cy="`${props.parentComponentId}__newCustomer__select__optionNull`"
												>
													{{ $t('settings.addCustomer') }}
												</option>
												<option
													v-for="(customer, customerIndex) in filterCustomers"
													:value="customer"
													:key="customer.role_uid"
													:data-cy="`${props.parentComponentId}__newCustomer__select__option${customerIndex}`"
												>
													{{ customer.role_name }}
												</option>
											</select>
										</s>
									</template>
								</template>
							</template>
						</lba-section>
					</form>
				</ValidationObserver>
			</masonry>
		</div>

		<lba-dialog-modal
			parentComponentId="user"
			componentId="confirmDelete"
			name="user-confirm-delete"
			:title="$t('settings.user.deleteUser')"
		>
			<template #default="props">
				<div class="popup-body">
					<p v-html="$t('settings.user.deleteUserLong', { user: meta.name })"></p>
				</div>
				<div class="popup-footer">
					<button type="button" class="buttonBig buttonRed" v-on:click="deleteUserConfirm"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						<span>{{ $t('settings.delete') }}</span>
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<lba-dialog-modal
			parentComponentId="user"
			componentId="sendWizard"
			name="user-send-wizard"
			:title="$t('tfaMethods.wizard.wizard')"
		>
			<template #default="props">
				<div class="popup-body">
					<p v-html="$t('tfaMethods.wizard.sendWizardLong', { email: user.email })"></p>
				</div>
				<div class="popup-footer">
					<button type="button" class="buttonBig buttonRed" v-on:click="sendWizardConfirm"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						<span>{{ $t('tfaMethods.wizard.send') }}</span>
					</button>
				</div>
			</template>
		</lba-dialog-modal>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import SaveEntry from '../mixins/SaveEntry';
import UserModel from '../models/User';
import ProfileModel from '../models/Profile';
import GroupModel from '../models/Group';

export default {
	name: 'User',
	mixins: [
		Titles,
		Page,
		OnScroll,
		SaveEntry,
	],
	data() {
		return {
			user: {
				id: null,
				user_name: null,
				description: null,
				can_login: true,
				last_login: null,
				valid_until: null,
				password: null,
				password_t: null,
				password_expiration: null,
				auth_method: null,
				firstname: null,
				lastname: null,
				company: null,
				email: null,
				lang: this.$i18n.locale,
				timezone: null,
				groups: [],
				group_names: [],
				modules: [],
				roles: [],
				customers: [],
				is_anonymous_user: null,
				extension: null,
				allow_su: null,
				profile_uid: null,
				createAgent: false,
				tfa_required: null,
			},
			modules: [],
			groups: [],
			customers: [],
			currentCustomer: {},
			profiles: [],
			allProfiles: [],
			profileModel: null,
			groupModel: null,
			userLoaded: false,
			extraSettings: [],
			addedGroup: null,
			addedCustomer: null,
			loading: true,
			isNew: true,
			readOnly: false,
			meta: {
				name: this.$t('settings.user.user'),
				path: 'settings-user',
			},
			invalidPass: false,
			savedProfiles: [],
			user_tfa_required_write: false,
			extraSettingsKey: 0,
			saveButtonId: 'lbadmin.user',
		};
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},
	computed: {
		filterGroups() {
			// remove teams I am already in
			if (this.user.roles) {
				const myTeams = this.user.roles.map((el) => {
					return el.role_name;
				});
				return this.groups.filter((el) => {
					if (!myTeams.includes(el.role_name)) return el;
				});
			}
			return this.groups;
		},
		filterCustomers() {
			// remove customers I am already in
			if (this.user.customers) {
				const myCustomers = this.user.customers.map((el) => {
					return el.role_uid;
				});
				return this.customers.filter((el) => {
					if (!myCustomers.includes(el.role_uid)) return el;
				});
			}
			return this.customers;
		},
		labeledProfiles() {
			return $_.map(this.profiles, (el) => {
				return { label: el.profile_name, value: el.profile_uid, custom: el.custom || false };
			});
		},
		servercareLoaded() {
			return (
				this.$user &&
				this.$user.loaded &&
				this.$user.loadedModules &&
				this.$user.loadedModules.includes('servercare')
			);
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.isNew = (this.$route.params.id === '+');
		this.userModel = new UserModel(this.$http);
		this.profileModel = new ProfileModel(this.$http);
		this.groupModel = new GroupModel(this.$http);
		await this.fetch();
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.$root.$listen('user.settings-updated', () => {
			if (this.$user.role_uid === this.user.role_uid) {
				if (!this._inactive) {
					this.tabReload();
				} else {
					this.reloadOnActive = true;
				}
			}
		}, this, true);
		this.$root.$listen('app.extra-settings-changed', this.onExtraSettignsChanged, this, true);
		this.permissionsReload();
		this.loading = false;
	},
	methods: {
		onExtraSettignsChanged(forceRefresh = false) {
			if ($_.isEmpty(this.extraSettings)) {
				return;
			}

			let anyChange = false;
			$_.forEach(this.extraSettings, (setting) => {
				let newPermsDisabled = false;
				if (!$_.isEmpty(setting.perms)) {
					const perms = [];

					$_.forEach(setting.perms, (el) => {
						perms.push(this.$permissions.checkPermission(el));
					});

					if (setting.permsEval && setting.permsEval === 'AND') {
						newPermsDisabled = (perms.indexOf(false) >= 0);
					} else {
						newPermsDisabled = !$_.some(perms, Boolean);
					}
				}

				if (newPermsDisabled !== (!!setting.permsDisabled)) {
					anyChange = true;
					setting.permsDisabled = newPermsDisabled;
				}
			});
			if (anyChange || forceRefresh) {
				this.extraSettingsKey += 1;
			}
		},
		async fetch() {
			let response = null;
			if (this.$route.params.id != null && !this.isNew) {
				let params = '';
				if (this.$root.$user.extraParams.customer_uid && !this.$root.$user.extraParams.customer_super) {
					params = `?customer_uid=${this.$root.$user.extraParams.customer_uid}`;
				}

				response = await this.$axiosFetch(this, this.userModel.get(this.$route.params.id, params));

				this.$set(this, 'user', response.data);
				this.currentCustomer = $_.find(this.user.customers, { role_uid: this.$user.extraParams.customer_uid });
				this.userLoaded = true;
				if (this.user.full_name_reverse) {
					this.meta.name = this.user.full_name_reverse;
					this.meta.tooltipPrefix = this.$t('settings.user.user');
				} else if (this.user.id) {
					this.meta.name = this.user.id;
					this.meta.tooltipPrefix = this.$t('settings.user.user');
				}
				this.refreshTitle();
			} else {
				this.meta.name = this.$t('settings.newUser');
				this.meta.tooltipPrefix = this.$t('settings.user.user');
			}

			response = await this.$http.get('lbadmin/extra-settings');
			this.extraSettings = response.data;
			const extensionSetting = $_.find(this.extraSettings, (setting) => setting.name === 'extension');
			if (!$_.isEmpty(extensionSetting) && !$_.isEmpty(extensionSetting.options)) {
				const availableExtensions = await this.userModel.getAvailableExtens(this.user.role_uid);
				this.user.available_extensions = availableExtensions.data;
				extensionSetting.options.options = [{ value: null, label: `-- ${this.$t('notSet')} --` }];
				extensionSetting.options.options = $_.concat(
					extensionSetting.options.options,
					this.user.available_extensions.map(
						(extension) => ({ value: extension.exten, label: `${extension.exten} - ${extension.name}` })
					)
				);
			}
			this.onExtraSettignsChanged(true);

			// get all profiles
			let resp = await this.profileModel.query({ all: true });
			this.allProfiles = resp.data;
			// get profiles to set
			resp = await this.profileModel.getProfilesToSet();
			this.profiles = resp.data;

			// get all teams
			resp = await this.groupModel.query();
			this.groups = resp.data;

			if (this.servercareLoaded &&
				this.$user.extraParams &&
				this.$user.extraParams.customer_super
			) {
				// get all customers
				resp = await this.groupModel.queryCustomers();
				this.customers = resp.data;
			}

			$_.forEach(this.user.profiles, (el) => {
				const found = $_.find(this.profiles, (eel) => {
					return eel.profile_uid === el.profile_uid;
				});
				if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
			});

			$_.forEach(this.user.roles, (role) => {
				$_.forEach(role.profiles, (el) => {
					const found = $_.find(this.profiles, (eel) => {
						return eel.profile_uid === el.profile_uid;
					});
					if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
				});
			});

			$_.forEach(this.user.customers, (customer) => {
				$_.forEach(customer.profiles, (el) => {
					const found = $_.find(this.profiles, (eel) => {
						return eel.profile_uid === el.profile_uid;
					});
					if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
				});
			});

			this.profiles = $_.sortBy(this.profiles, ['profile_name']);

			this.user.profiles = $_.map(this.user.profiles, (el) => { return el.profile_uid; });
			this.savedProfiles = $_.cloneDeep(this.user.profiles);

			$_.forEach(this.user.roles, (role) => {
				role.profiles = $_.map(role.profiles, (el) => { return el.profile_uid; });
			});
			$_.forEach(this.user.customers, (customer) => {
				customer.profiles = $_.map(customer.profiles, (el) => { return el.profile_uid; });
			});
		},
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.user.write');
			this.user_tfa_required_write = this.$permissions.checkPermission('lbadmin.user_tfa_required.write');
		},
		async submit() {
			this.setDirty(true);
			const valid = await this.$refs.form2.validate();
			if (!valid || !this.user.valid || (!this.servercareLoaded && !this.user.profiles.length)) {
				this.inputDirtyForSave = false;
				this.$root.$emit('content.validationFailed', { id: this.saveButtonId });
				return;
			}

			// change dates to string
			let value = this.user.valid_until;
			if (value && typeof value === 'object') {
				this.user.valid_until = this.user.valid_until.toISOString();
			}
			value = this.user.password_expiration;
			if (value && typeof value === 'object') {
				this.user.password_expiration = this.user.password_expiration.toISOString();
			}
			const user = $_.cloneDeep(this.user);

			user.profiles = $_.map(user.profiles, (el) => {
				return { profile_uid: el };
			});

			$_.forEach(user.roles, (role) => {
				role.profiles = $_.map(role.profiles, (el) => {
					return { profile_uid: el };
				});
			});

			// set customer by current customer
			if (this.user.id == null &&
				this.servercareLoaded &&
				this.$root.$user.extraParams &&
				this.$root.$user.extraParams.customer_uid &&
				this.$root.$user.extraParams.customer_id !== 'server_care_configuration' &&
				!user.customers.length
			) {
				user.customers = [
					{
						profiles: this.currentCustomer.profiles,
						role_uid: this.$root.$user.extraParams.customer_uid,
					},
				];
			}

			$_.forEach(user.customers, (customer) => {
				customer.profiles = $_.map(customer.profiles, (el) => {
					return { profile_uid: el };
				});
			});

			this.setDirty(false);

			if (this.user.id == null) {
				this.userModel.createUser(user).then(async (response) => {
					this.$root.$emit('content.saved', { reload: false, id: this.saveButtonId });
					this.$root.$emit('grid.reload', 'lbadmin.users_user');
					await this.$nextTick();
					this.$routerWrap.push({ name: 'settings-user', params: { id: response.data.role_uid || '+' } });
				}).catch(() => {
					this.$root.$emit('content.saveFailed', { id: this.saveButtonId });
				});
			} else {
				this.userModel.saveUser(user).then(async (response) => {
					this.savedProfiles = $_.cloneDeep(this.user.profiles);
					this.$root.$emit('content.saved', { reload: false, id: this.saveButtonId });
					this.$root.$emit('grid.update-row', {
						gridName: 'lbadmin.users_user',
						rowId: user.role_uid,
						reload: true,
					});
					await this.$nextTick();
					if (this.$user.role_uid !== this.user.role_uid) {
						await this.fetch();
					}
					this.$root.$emit('favourite-pages.name-updated');
				}).catch(() => {
					this.$root.$emit('content.saveFailed', { id: this.saveButtonId });
				});
			}
		},
		deleteUser() {
			this.$root.$emit('dialog-open', { name: 'user-confirm-delete' });
		},
		deleteUserConfirm() {
			this.$root.$emit('tab-editted', false);
			this.userModel.remove(this.user.role_uid).then(() => {
				this.$root.$emit('dialog-close', 'user-confirm-delete');
				this.$root.$emit('content.removed', { noBack: true });
				this.$root.$emit('grid.row-deleted', { gridName: 'lbadmin.users_user', uid: this.user.role_uid });
				this.$root.$emit('close-current-tab');
			}).catch(() => {
				this.$root.$emit('content.removeFailed');
			});
		},
		sendWizard() {
			if (!this.user.email || this.user.email === '') {
				this.$notify.warn(this.$t('tfaMethods.wizard.noEmail'));
				return;
			}
			this.$root.$emit('dialog-open', { name: 'user-send-wizard' });
		},
		sendWizardConfirm() {
			this.userModel.sendWizard(this.user.role_uid).then(() => {
				this.$root.$emit('dialog-close', 'user-send-wizard');
				this.tabReload();
			});
		},
		addGroup() {
			this.setDirty(true);
			if (!this.user.roles) this.user.roles = [];
			this.user.roles.push({
				role_name: this.addedGroup.role_name,
				role_uid: this.addedGroup.role_uid,
				profiles: [],
			});
			this.addedGroup = null;
		},
		addCustomer() {
			this.setDirty(true);
			if (!this.user.customers) this.user.customers = [];
			this.user.customers.push({
				role_name: this.addedCustomer.role_name,
				role_uid: this.addedCustomer.role_uid,
				profiles: [],
			});
			this.addedCustomer = null;
		},
		currentCustomerChange() {
			this.setDirty(true);
			$_.forEach(this.user.customers, (c) => {
				if (c.role_uid === this.currentCustomer.role_uid) {
					c.profiles = this.currentCustomer.profiles;
				}
			});
		},
		removeGroup(index) {
			this.setDirty(true);
			this.user.roles.splice(index, 1);
		},
		removeCustomer(index) {
			this.setDirty(true);
			this.user.customers.splice(index, 1);
		},
		getMyProfileName() {
			let profileName = '';
			if (this.user.profile_uid) {
				this.profiles.forEach((profile) => {
					if (profile.profile_uid === this.user.profile_uid) {
						profileName = ` (${profile.profile_name})`;
					}
				});
			}
			return profileName;
		},
		openTeam(id) {
			this.$routerWrap.push({
				name: 'settings-group',
				params: {
					id,
					openNewTab: true,
				},
			});
		},
		getProfilesName(profiles) {
			const profilesNames = [];
			$_.forEach(profiles, (profile_uid) => {
				const result = $_.find(this.allProfiles, { profile_uid: profile_uid });
				if (result && result.profile_name) {
					profilesNames.push(result.profile_name);
				} else {
					profilesNames.push(profile_uid);
				}
			});
			return profilesNames.join(', ');
		},
	},
};
</script>
