import * as VeeValidate from 'vee-validate';
import * as validationRules from 'vee-validate/dist/rules';

export default {
	created() {
		window.validatePathToLocale = this.validatePathToLocale.bind(this);
		Object.keys(validationRules).forEach((rule) => {
			VeeValidate.extend(rule, {
				...validationRules[rule],
				message: (value, params) => {
					return this.$t(`veeValidate.${rule}`, params);
				},
			});
		});
		VeeValidate.extend('after', {
			validate: this.validateAfter,
			message: (fieldName, placeholders) => {
				let after = '';
				if (placeholders._after_) {
					after = this.$d(new Date(placeholders._after_), placeholders.dateType || 'long');
				} else if (placeholders.after) {
					after = this.$d(new Date(placeholders.after), placeholders.dateType || 'long');
				}
				return this.$t('validation.after', null, {
					attribute: fieldName,
					after,
				});
			},
			params: ['after', 'dateType'],
		});
		VeeValidate.extend('before', {
			validate: this.validateBefore,
			message: (fieldName, placeholders) => {
				let before = '';
				if (placeholders._before_) {
					before = this.$d(new Date(placeholders._before_), placeholders.dateType || 'long');
				} else if (placeholders.before) {
					before = this.$d(new Date(placeholders.before), placeholders.dateType || 'long');
				}
				return this.$t('validation.before', null, {
					attribute: fieldName,
					before,
				});
			},
			params: ['before', 'dateType'],
		});
		VeeValidate.extend('pathToLocale', {
			validate: this.validatePathToLocale,
			message: (fieldName) => {
				return this.$t('validation.pathToLocale', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('locale', {
			validate: this.validateLocale,
			message: (fieldName) => {
				return this.$t('validation.locale', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('localeRequired', {
			validate: this.validateLocaleRequired,
			message: (fieldName) => {
				return this.$t('validation.required', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('notOneOf', {
			validate: this.validateNotOneOf,
			message: (fieldName) => {
				return this.$t('validation.unique', null, { attribute: fieldName });
			},
			params: ['values', 'pathToKey'],
		});
		VeeValidate.extend('templateCardItem', {
			validate: this.validateTemplateCardItem,
			message: (fieldName) => {
				return this.$t('validation.templateCardItem', null, { attribute: fieldName });
			},
			params: ['values'],
		});
		VeeValidate.extend('unique', {
			validate: this.validateUnique,
			message: (fieldName, placeholders) => {
				return this.$t('validation.uniques', null, { attribute1: fieldName, attribute2: placeholders.fieldName2 });
			},
			params: ['values', 'pathToKey', 'fieldName2', 'filter'],
		});
		VeeValidate.extend('requiredGroup', {
			validate: this.validateRequiredGroup,
			message: (fieldName, placeholders) => {
				return this.$t('validation.requiredGroup', null, {
					attributes: [fieldName, ...placeholders.info.labels].join(', '),
				});
			},
			params: ['info'],
			computesRequired: true,
		});
		VeeValidate.extend('emailWithName', {
			validate: this.validateEmailWithName,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
		});
		VeeValidate.extend('emailsWithName', {
			validate: this.validateEmailsWithName,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['filter'],
		});
		VeeValidate.extend('requiredInterval', {
			validate: this.validateRequiredInterval,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['time'],
		});
		VeeValidate.extend('requiredMulticheckbox', {
			validate: this.validateRequiredMultiCheckbox,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['checkboxes'],
		});
		VeeValidate.extend('requiredCheckbox', {
			validate: this.validateRequiredCheckbox,
			message: (fieldName) => {
				return this.$t('validation.required', null, {
					attribute: fieldName,
				});
			},
			params: ['checkbox'],
		});
		VeeValidate.extend('notContains', {
			validate: this.validateNotContains,
			message: (fieldName) => {
				return this.$t('validation.containBanned');
			},
			params: ['values'],
		});
		VeeValidate.extend('phoneNumber', {
			validate: this.validatePhoneNumber,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
			params: ['phone'],
		});
		VeeValidate.extend('lineNumber', {
			validate: this.validateLineNumber,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
			params: ['line'],
		});
		VeeValidate.extend('phoneNumberOrLineNumber', {
			validate: this.validatePhoneNumberOrLineNumber,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
			params: ['phone', 'line'],
		});
		VeeValidate.extend('json', {
			validate: this.validateJson,
			message: (fieldName) => {
				return this.$t('validation.json', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('isRegexp', {
			validate: this.validateRegularExpression,
			message: (fieldName) => {
				return this.$t('validation.regexp', null, {
					attribute: fieldName,
				});
			},
		});
	},
	methods: {
		validateJson(value, allowObject = false) {
			let status = false;
			try {
				JSON.parse(value);
				status = true;
			} catch (e) {
				const jsonError = JSON.stringify(e.message);
				if (jsonError.indexOf('position') > -1) {
					// console.log(e.message);
					// highlight error position
					const positionStr = jsonError.lastIndexOf('position') + 8;
					const position = parseInt(jsonError.substring(positionStr));
					const rows = value.split('\n');
					const rowsToError = value.substring(0, position).split('\n');
					const rowPosition = rowsToError.length;
					const rowErrorPosition = rowsToError[rowPosition - 1].length;
					const errorRow = rows[rowPosition - 1];
					if (position >= 0) {
						status = this.$t('validation.jsonAt', { rowPosition, rowErrorPosition, errorRow, jsonError });
					}
				} else {
					status = e.message;
				}
			}
			return status;
		},
		validateAfter(value, { after }) {
			if (value != null && after != null) {
				return new Date(value) > new Date(after);
			}
			return true;
		},
		validateBefore(value, { before }) {
			if (value != null && before != null) {
				return new Date(value) < new Date(before);
			}
			return true;
		},
		validatePathToLocale(value, allowObject = false) {
			return this.$te(value) && (allowObject || this.$t(value).constructor === String);
		},
		validateLocale(value, allowObject = false) {
			if ($_.isEmpty(value)) return true;
			if (value.constructor !== Object) return false;
			if (Object.keys(value).length === 1 && !$_.isEmpty(value.translated)) return true;
			if (!$_.isEmpty(value.translate)) return this.validatePathToLocale(value.translate, allowObject);
			if (!$_.isEmpty(value.translated)) return true;
			return this.$i18n.availableLocales.find((locale) => $_.isEmpty(value[locale])) == null;
		},
		validateLocaleRequired(value, allowObject = false) {
			return !$_.isEmpty(value) && this.validateLocale(value, allowObject);
		},
		validateNotOneOf(value, { values = [], pathToKey = null }) {
			if (!$_.isEmpty(pathToKey)) {
				return !values.some((item) => $_.get(item, pathToKey) === value);
			}
			return !values.includes(value);
		},
		validateTemplateCardItem(value, { values = [] }) {
			if ($_.isEmpty(value)) return true;

			const regex = /\{(?<cardItem>[\w.\-_0-9]+)\}/gmi;
			let match = regex.exec(value);

			while (match != null && !$_.isEmpty(match.groups) && !$_.isEmpty(match.groups.cardItem)) {
				if ($_.isEmpty(values) || !values.includes(match.groups.cardItem)) return false;
				match = regex.exec(value);
			}
			return true;
		},
		validateUnique(value, { values = [], pathToKey = null, filter = null }) {
			if ($_.isEmpty(value)) return true;

			const set = new Set();
			let values1 = value;
			let values2 = values;

			if (filter) {
				values1 = values1.filter(filter);
				values2 = values2.filter(filter);
			}

			if (pathToKey != null) {
				values1.forEach((item) => set.add($_.get(item, pathToKey)));
				values2.forEach((item) => set.add($_.get(item, pathToKey)));
			} else {
				values1.forEach((item) => set.add(item));
				values2.forEach((item) => set.add(item));
			}
			return (set.size === values1.length + values2.length);
		},
		validateRequiredGroup(value, { info = {} }) {
			if (
				this.$hasValue(value) || (
					!$_.isEmpty(info) &&
					!$_.isEmpty(info.data) &&
					!$_.isEmpty(info.paths) &&
					$_.some(info.paths, (path) => this.$hasValue($_.get(info.data, path)))
				)
			) {
				return { valid: true, required: false };
			}
			return { valid: false, required: true };
		},
		validateEmailWithName(value) {
			value = $_.trim(value);
			if (!this.$hasValue(value)) return true;

			const splitted = value.split(' ');
			if (splitted.length > 1) {
				let email = splitted.pop();
				let name = splitted.join(' ');

				// email with name must be inside <>
				const emailMatches = email.match(/^<.*>$/gi);
				if ($_.isEmpty(emailMatches) || $_.isEmpty(emailMatches[0])) return false;

				// remove <> for vee-validation and validate
				email = email.slice(1, email.length - 1);
				if (!validationRules.email.validate(email)) return false;

				// email is now valid, validate name
				const nameMatches = name.match(/^".*"$/gi);
				// name is not wrapped in double quotes
				if ($_.isEmpty(nameMatches) || $_.isEmpty(nameMatches[0])) {
					// check if name contains only allowed characters
					return !$_.isEmpty(name.match(/^[a-ž0-9!#$%&'*+\-/=?^_`{|}~ ]+$/gi));
				}

				// name is wrapped in double quotes
				name = name.slice(1, name.length - 1);
				// check if double quotes are escaped
				return (name.match(/"/gi) || []).length === (name.match(/\\"/gi) || []).length;
			}
			return validationRules.email.validate(value);
		},
		validateEmailsWithName(value, { filter = null }) {
			if ($_.isEmpty(value)) return true;
			// validate emails
			if (value.constructor === String && value.includes(',')) {
				const emailsArr = value.split(',');
				return emailsArr.every((email) => {
					return this.validateEmailWithName(email);
				});
			}
			if (value.constructor === String) return this.validateEmailWithName(value);
			if (filter) {
				value = value.filter(filter);
			}
			return $_.every(value, (email) => {
				if ($_.isEmpty(email)) return true;
				if (email.constructor === Object) {
					return this.validateEmailWithName(email.value);
				}
				return this.validateEmailWithName(email);
			});
		},
		validateRequiredInterval(value, { time = null }) {
			if (time.hours !== '00' || time.minutes !== '00' || time.seconds !== '00') {
				return true;
			}
			return false;
		},
		validateRequiredMultiCheckbox(value, { checkboxes = [] }) {
			for (let i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked === true) {
					return true;
				}
			}
			return false;
		},
		validateRequiredCheckbox(value, { checkbox = false }) {
			return !!checkbox;
		},
		validateNotContains(value, { values = [] }) {
			value = value.toLowerCase();
			if (Array.isArray(values)) {
				for (let i = 0; i < values.length; i++) {
					const test = values[i].toLowerCase();
					if (value.includes(test)) {
						return false;
					}
				}
			} else if (value.includes(values)) {
				return false;
			}
			return true;
		},
		validatePhoneNumber(value, { phone = {} }) {
			if (!value) return false;
			const min = phone.min || 7;
			const max = phone.max || 15;
			const phoneVal = this.$phoneNumber.validate(value, min, max);
			return phoneVal || false;
		},
		validateLineNumber(value, { line = {} }) {
			if (!value) return false;
			const emergencyNumbers = ['112', '150', '155', '156', '158'];
			const min = line.min || 2;
			const max = line.max || 5;
			return (
				value.length >= min &&
				value.length <= max &&
				/^[1-9][0-9]*$/.test(value) &&
				!emergencyNumbers.includes(value)
			);
		},
		validatePhoneNumberOrLineNumber(value, { phone = {}, line = {} }) {
			return this.validateLineNumber(value, { line }) || this.validatePhoneNumber(value, { phone });
		},
		validateRegularExpression(value) {
			if (!value) return false;
			try {
				RegExp(String.raw`${value}`);
			} catch {
				return false;
			}
			return true;
		},
	},
};
