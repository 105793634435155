<script>
import { createPopper } from '@popperjs/core';

let lastHide = null;

export default {
	inserted(element, binding) {
		const options = binding.value;
		const mouseOverDelay = $_.get(options, 'mouseOverDelay', 1000);
		const mouseLeaveDelay = $_.get(options, 'mouseLeaveDelay', 1000);
		const popperElement = document.getElementById(options.id);
		const onShow = options.onShow;
		popperElement.classList.add('popper');
		let popper = null;
		let mouseOverElement = false;
		let mouseOverPopper = false;
		let shown = false;

		const checkMouseOver = (force = false) => {
			if ((!mouseOverElement && !mouseOverPopper) || force) {
				popperElement.removeAttribute('data-show');

				if (popper) {
					popper.destroy();
				}

				shown = false;
			}
		};

		const hide = (force = false) => {
			if (mouseLeaveDelay > 0 && !force) {
				setTimeout(checkMouseOver, mouseLeaveDelay);
			} else {
				checkMouseOver(force);
			}
		};

		const show = () => {
			if (mouseOverElement) {
				popper = createPopper(element, popperElement);
				popperElement.setAttribute('data-show', '');

				if (onShow) {
					onShow();
				}

				popper.update();
				shown = true;
			}
		};

		element.addEventListener('mouseover', () => {
			mouseOverElement = true;

			if (!shown) {
				if (lastHide) {
					lastHide(true);
				}

				lastHide = hide;

				if (mouseOverDelay > 0) {
					setTimeout(show, mouseOverDelay);
				} else {
					show();
				}
			}
		});
		element.addEventListener('mouseleave', (event) => {
			if (event.target === element) {
				mouseOverElement = false;
				hide();
			}
		});

		popperElement.addEventListener('mouseover', () => {
			mouseOverPopper = true;
		});
		popperElement.addEventListener('mouseleave', (event) => {
			if (event.target === popperElement) {
				mouseOverPopper = false;
				hide();
			}
		});
	},
};
</script>
