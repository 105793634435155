export default {
	props: {
		filterAttributes: {
			type: Object,
			default() { return {}; },
		},
		filterDialogName: {
			type: String,
			default: () => `filterDialog${Math.ceil(Math.random() * 100000)}`,
		},
	},
	computed: {
		filterListUser: function () {
			return $_.filter(this.filterList, (f) => f.user_name);
		},
		filterListGlobal: function () {
			return $_.filter(this.filterList, (f) => !f.user_name);
		},
		filterListUserWithoutCurrent: function () {
			if (this.filterUid === '_' || $_.isEmpty(this.filterUid)) return this.filterListUser;
			return $_.filter(this.filterList, (f) => f.user_name && f.grid_settings_uid !== this.filterUid);
		},
		filterListGlobalWithoutCurrent: function () {
			if (this.filterUid === '_' || $_.isEmpty(this.filterUid)) return this.filterListGlobal;
			return $_.filter(this.filterList, (f) => !f.user_name && f.grid_settings_uid !== this.filterUid);
		},
	},
	data() {
		return {
			filterCheckedAll: false,
			filterUrl: '/lbadmin/grid-settings',
			filterList: [],
			filterUid: '_',
			filterIsGlobal: false,
			filterIsDefault: false,
			filterShow: [],
			filterName: '',
			filterDirty: false,
			filterFetchPromise: null,
			filterChanged: false,
			filterConditions: [
				{
					value: 'less',
					label: 'filterConditions.less',
					types: ['number'],
				},
				{
					value: 'lessorequal',
					label: 'filterConditions.lessOrEqual',
					types: ['number'],
				},
				{
					value: 'equal',
					label: 'filterConditions.equal',
					types: ['number', 'text', 'date', 'select', 'json_regex'],
					default: ['number', 'select', 'date'], // for which type is this option as default
				},
				{
					value: 'notequal',
					label: 'filterConditions.notEqual',
					types: ['number', 'text', 'select', 'json_regex'],
				},
				{
					value: 'greaterorequal',
					label: 'filterConditions.greaterOrEqual',
					types: ['number'],
				},
				{
					value: 'greater',
					label: 'filterConditions.greater',
					types: ['number'],
				},
				{
					value: 'contains',
					label: 'filterConditions.contains',
					types: ['text', 'json_regex', 'lba_select', 'html', 'partial_text'],
					default: ['text', 'json_regex', 'lba_select', 'html', 'partial_text'], // for which type is this option as default
				},
				{
					value: 'notcontains',
					label: 'filterConditions.notContains',
					types: ['text', 'json_regex', 'lba_select', 'html', 'partial_text'],
				},
				{
					value: 'startswith',
					label: 'filterConditions.startsWith',
					types: ['text', 'json_regex'],
				},
				{
					value: 'endswith',
					label: 'filterConditions.endsWith',
					types: ['text', 'json_regex'],
				},
				{
					value: 'period',
					label: 'period',
					types: ['datetime', 'future-datetime'],
					default: ['datetime', 'future-datetime'], // for which type is this option as default
				},
				{
					value: 'range',
					label: 'filterConditions.range',
					types: ['datetime', 'future-datetime', 'date', 'time'],
					default: ['time'], // for which type is this option as default
				},
				{
					value: 'before',
					label: 'filterConditions.before',
					types: ['datetime', 'future-datetime', 'date', 'time'],
				},
				{
					value: 'after',
					label: 'filterConditions.after',
					types: ['datetime', 'future-datetime', 'date', 'time'],
				},
				{
					value: 'allselected',
					label: 'filterConditions.allSelected',
					types: ['multi-select', 'multi-select-no-combobox'],
				},
				{
					value: 'anyofselected',
					label: 'filterConditions.anyOfSelected',
					types: ['multi-select', 'multi-select-no-combobox'],
					default: ['multi-select', 'multi-select-no-combobox'], // for which type is this option as default
				},
				{
					value: 'noneofselected',
					label: 'filterConditions.noneOfSelected',
					types: ['multi-select', 'multi-select-no-combobox'],
				},
			],
			periodOptions: [
				{
					value: 'today',
					label: 'today',
				},
				{
					value: 'yesterday',
					label: 'yesterday',
				},
				{
					value: 'dayBeforeYesterday',
					label: 'dayBeforeYesterday',
				},
				{
					value: 'thisWeek',
					label: 'thisWeek',
				},
				{
					value: 'thisMonth',
					label: 'thisMonth',
				},
				{
					value: 'previousWeek',
					label: 'previousWeek',
				},
				{
					value: 'previousMonth',
					label: 'previousMonth',
				},
				{
					value: 'lastHour',
					label: 'lastHour',
				},
				{
					value: 'last6Hours',
					label: 'last6Hours',
				},
				{
					value: 'last12Hours',
					label: 'last12Hours',
				},
				{
					value: 'last24Hours',
					label: 'last24Hours',
				},
				{
					value: 'last2Days',
					label: 'last2Days',
				},
				{
					value: 'last7Days',
					label: 'last7Days',
				},
				{
					value: 'last30Days',
					label: 'last30Days',
				},
			],
			futurePeriodOptions: [
				{
					value: 'next6Hours',
					label: 'next6Hours',
				},
				{
					value: 'nextHour',
					label: 'nextHour',
				},
				{
					value: 'today',
					label: 'today',
				},
				{
					value: 'untilToday',
					label: 'untilToday',
				},
				{
					value: 'yesterday',
					label: 'yesterday',
				},
				{
					value: 'dayBeforeYesterday',
					label: 'dayBeforeYesterday',
				},
				{
					value: 'thisWeek',
					label: 'thisWeek',
				},
				{
					value: 'thisMonth',
					label: 'thisMonth',
				},
				{
					value: 'previousWeek',
					label: 'previousWeek',
				},
				{
					value: 'previousMonth',
					label: 'previousMonth',
				},
				{
					value: 'lastHour',
					label: 'lastHour',
				},
				{
					value: 'last6Hours',
					label: 'last6Hours',
				},
				{
					value: 'last12Hours',
					label: 'last12Hours',
				},
				{
					value: 'last24Hours',
					label: 'last24Hours',
				},
				{
					value: 'last2Days',
					label: 'last2Days',
				},
				{
					value: 'last7Days',
					label: 'last7Days',
				},
				{
					value: 'last30Days',
					label: 'last30Days',
				},
			],
			usedFilterUid: null,
			usedFilterName: null,
			usedFilterIsGlobal: false,
			filterColumns: [],
			useParamsFilter: false,
		};
	},
	async created() {
		this.prepareFilter(false);
		this.setFilterDefaultConditions();

		await this.internalResourcesPromise;
		if (!$_.isEmpty(this.internalResources) && !$_.isEmpty(this.internalResources.filters)) {
			this.filterList = this.internalResources.filters;
		}
		if (this.useParamsFilter) {
			this.useFilter(true);
		}
	},
	activated() {
		const paramsFilter = this.getParamsFilter();
		if (!$_.isEmpty(paramsFilter)) {
			this.cleanFilter();
			this.filterAttributes.columns.forEach((column) => {
				this.$set(column, 'required', !!column.required);
				this.prepareDefaultValues(column, true, paramsFilter);
			});
			this.useFilter(true);
		}
	},
	methods: {
		getMinDate(a, b) {
			if (!a) {
				return b;
			}
			if (!b) {
				return a;
			}
			if (a.constructor !== Date) {
				a = new Date(a);
			}
			if (b.constructor !== Date) {
				b = new Date(b);
			}
			if (a.getTime() < b.getTime()) {
				return a;
			}
			return b;
		},
		prepareEmptyFilter() {
			this.filterColumns = [];
			const filterColumns = this.getFilterColumns(this.filterAttributes.columns);
			for (let i = 0; i < filterColumns.length; i += 1) {
				if (filterColumns[i].required) {
					this.filterColumns.push(filterColumns[i]);
				}
			}
			if (this.filterColumns.length >= 5) {
				return;
			}
			for (let i = 0; i < filterColumns.length; i += 1) {
				if (this.filterColumns.length >= 5) {
					break;
				}
				if (filterColumns[i].required) {
					continue;
				}
				this.filterColumns.push(filterColumns[i]);
			}
		},
		sortFilterColumns(a, b) {
			if (
				$_.get(this.columnsProperties[a.name], 'pos', Number.MAX_SAFE_INTEGER) <
				$_.get(this.columnsProperties[b.name], 'pos', Number.MAX_SAFE_INTEGER)
			) return -1;
			if (
				$_.get(this.columnsProperties[a.name], 'pos', Number.MAX_SAFE_INTEGER) >
				$_.get(this.columnsProperties[b.name], 'pos', Number.MAX_SAFE_INTEGER)
			) return 1;
			return 0;
		},
		anyFilterSet() {
			return (
				Object.keys(this.collection.search).length > 0 ||
				Object.keys(this.collection.filter).length > 0 ||
				!this.searchValueEmpty()
			);
		},
		getDefaultFilterCondition(type) {
			return this.filterConditions.find((condition) => condition.default && condition.default.includes(type));
		},
		getParamsFilter() {
			let paramsFilter = null;
			if (
				!$_.isEmpty(this.$route.query) &&
				!$_.isEmpty(this.$route.query.gridFilter) &&
				!$_.isEmpty(this.gridName)
			) {
				let gridFilter = null;
				try {
					gridFilter = JSON.parse(this.$route.query.gridFilter);
				} catch (error) {
					console.error(error);
				}

				if (
					!$_.isEmpty(gridFilter) &&
					!$_.isEmpty(gridFilter.params) &&
					!$_.isEmpty(gridFilter.gridName) &&
					gridFilter.gridName === this.gridName
				) {
					const query = $_.cloneDeep(this.$route.query);
					delete query.gridFilter;
					this.$routerWrap.replace({ query });
					paramsFilter = gridFilter.params;
					this.useParamsFilter = true;
				}
			}
			return paramsFilter;
		},
		prepareFilter(sort = true) {
			const paramsFilter = this.getParamsFilter();
			if (!this.collection.filter) {
				this.collection.filter = {};
			}
			if (!this.filterName && $_.isEmpty(paramsFilter)) {
				this.filterName = this.filterAttributes.name;
			}
			if (!this.filterAttributes) {
				this.filterAttributes = {};
			}
			if (!this.filterAttributes.columns) {
				this.filterAttributes.columns = [];
			}
			if (!this.filterAttributes.module) {
				this.filterAttributes.module = null;
			}

			this.filterAttributes.columns.forEach((column) => {
				this.$set(column, 'required', !!column.required);
				this.prepareDefaultValues(column, true, paramsFilter);
			});
			if (sort) {
				this.filterAttributes.columns.sort(this.sortFilterColumns);
			}
		},
		parseDate(value) {
			try {
				return Date.parse(value);
			} catch (e) {
				console.error(e);
			}
			const now = new Date();
			now.setMilliseconds(0);
			now.setSeconds(0);
			return now;
		},
		prepareDefaultValues(column, clean = false, paramsFilter = null) {
			if (!column) {
				return;
			}

			if (column.type === 'datetime' || column.type === 'future-datetime' || column.type === 'date' || column.type === 'time') {
				if ($_.isEmpty(column.value)) {
					this.$set(column, 'value', {});
				}
				let conditionDefinition = null;
				if (column.condition) {
					conditionDefinition = this.filterConditions.find((item) => item.value === column.condition);

				} else {
					conditionDefinition = this.getDefaultFilterCondition(column.type);

				}

				if (!$_.isEmpty(paramsFilter) &&
					!$_.isEmpty(paramsFilter[column.name]) &&
					!$_.isEmpty(paramsFilter[column.name].condition)
				) {
					const paramsColumn = paramsFilter[column.name];
					this.$set(column, 'condition', paramsColumn.condition);
					if (paramsColumn.condition === 'range') {
						this.$set(column.value, 'from', this.parseDate(paramsColumn.from));
						this.$set(column.value, 'to', this.parseDate(paramsColumn.to));

					} else if (paramsColumn.condition === 'before') {
						this.$set(column.value, 'before', this.parseDate(paramsColumn.before));

					} else if (paramsColumn.condition === 'after') {
						this.$set(column.value, 'after', this.parseDate(paramsColumn.after));

					} else if (paramsColumn.condition === 'period') {
						this.$set(column.value, 'period', $_.cloneDeep(paramsColumn.period));

					} else if (paramsColumn.condition === 'equal') {
						this.$set(column.value, 'equal', this.parseDate(paramsColumn.equal));

					}

				// apply default value from column definition
				} else if (column.default && 'condition' in column.default) {
					if (column.default.condition === 'range' && column.value.from === undefined && column.value.to === undefined) {
						this.$set(column.value, 'from', this.parseDate(column.default.from));
						this.$set(column.value, 'to', this.parseDate(column.default.to));

					} else if (column.default.condition === 'before' && column.value.before === undefined) {
						this.$set(column.value, 'before', this.parseDate(column.default.before));

					} else if (column.default.condition === 'after' && column.value.after === undefined) {
						this.$set(column.value, 'after', this.parseDate(column.default.after));

					} else if (column.default.condition === 'period' && column.value.period === undefined) {
						this.$set(column.value, 'period', $_.cloneDeep(column.default.period));

					} else if (column.default.condition === 'equal' && column.value.equal === undefined) {
						this.$set(column.value, 'equal', this.parseDate(column.default.equal));

					}

				} else {

					if (clean && !column.required) return;

					const now = new Date();
					now.setMilliseconds(0);
					now.setSeconds(0);

					// when undefined, set default, otherwise set by user
					if (conditionDefinition.value === 'range') {
						if (column.value.from === undefined) {
							const from = new Date(new Date().getTime() - 24 * 3600 * 1000);
							from.setMilliseconds(0);
							from.setSeconds(0);
							this.$set(column.value, 'from', from);
						}

						if (column.value.to === undefined) {
							this.$set(column.value, 'to', now);
						}

					} else if (conditionDefinition.value === 'before' && column.value.before === undefined) {
						this.$set(column.value, 'before', now);

					} else if (conditionDefinition.value === 'after' && column.value.after === undefined) {
						this.$set(column.value, 'after', now);
						column.value.after.setHours(0);
						column.value.after.setMinutes(0);
						column.value.after.setSeconds(0);
						column.value.after.setMilliseconds(0);

					} else if (conditionDefinition.value === 'period' && column.value.period === undefined) {
						this.$set(column.value, 'period', 'lastHour');

					} else if (conditionDefinition.value === 'equal' && column.value.equal === undefined) {
						this.$set(column.value, 'equal', now);
						column.value.equal.setHours(0);
						column.value.equal.setMinutes(0);
						column.value.equal.setSeconds(0);
						column.value.equal.setMilliseconds(0);

					}
				}
			} else {
				if (!$_.isEmpty(paramsFilter) &&
					!$_.isEmpty(paramsFilter[column.name]) &&
					!$_.isEmpty(paramsFilter[column.name].condition)
				) {
					const paramsColumn = paramsFilter[column.name];
					this.$set(column, 'condition', paramsColumn.condition);
					this.$set(column, 'value', $_.clone(paramsColumn.value));

				} else if (column.default && 'value' in column.default) {
					this.$set(column, 'value', $_.clone(column.default.value));

				}
			}
		},
		getFilterConditions(type) {
			return this.filterConditions.filter((cond) => (cond.types.indexOf(type) !== -1));
		},
		getFilterColumns(columns, all = true, includeColumn = null) {
			return columns.filter((column) => (
				(
					(
						this.columnsProperties &&
						this.columnsProperties[column.name]
					) ||
					(
						!this.columnsProperties[column.name] &&
						this.filterAttributes.columns.find((filter) => filter.name === column.name)
					)
				) && (
					all ||
					column.name === includeColumn ||
					!this.filterColumns.find((filter) => filter.name === column.name)
				)
			));
		},
		getFilterColumnLabel(attr) {
			if (attr.label && attr.label[this.$i18n.locale]) {
				attr.label = attr.label[this.$i18n.locale];
			}
			const columnProperties = this.columnsProperties && this.columnsProperties[attr.name];
			const label = (attr.label) ? attr.label : columnProperties?.label;
			const hidden = (!columnProperties || columnProperties?.visible) ? '' : ` (${this.$t('hidden').toLowerCase()})`;
			return `${label}${hidden}`;
		},
		addFilterColumn() {
			this.filterColumns.push({ name: null });
			// console.debug(JSON.stringify(this.filterColumns.map((column) => column.name)));
		},
		addAllFilterColumns() {
			this.filterColumns = [];
			this.filterColumns = this.filterColumns.filter((column) => column.name != null);
			const filterColumns = this.getFilterColumns(this.filterAttributes.columns, true);
			filterColumns.forEach((filterColumn) => {
				this.filterColumns.push(filterColumn);
			});
			// console.debug(JSON.stringify(this.filterColumns.map((column) => column.name)));
		},
		removeFilterColumn(index) {
			if (this.filterColumns[index].required) {
				return;
			}
			if (!$_.isEmpty(this.filterColumns[index].name)) {
				this.cleanFilterEntry(this.filterColumns[index].name);
			}
			this.filterColumns.splice(index, 1);
			// console.debug(JSON.stringify(this.filterColumns.map((column) => column.name)));
		},
		showOnlyFilledFilters() {
			this.filterColumns = this.filterColumns.filter((column) => {
				if (column.required) {
					return true;
				}
				if (column.value === null || column.value === undefined) {
					return false;
				}
				if (column.value.constructor === String && column.value.length === 0) {
					return false;
				}
				if (column.value.constructor === Array && column.value.length === 0) {
					return false;
				}
				if (column.value.constructor === Object) {
					if ($_.isEmpty(column.value)) {
						return false;
					}
					const containsSubKeysData = $_.reduce(column.value, (acc, cur) => {
						if (!acc) {
							if (cur === null || cur === undefined) return false;
							return true;
						}
						return acc;
					}, false);
					return containsSubKeysData;
				}
				return true;
			});
		},
		changeUsedFilterColumn(columnName, index) {
			if (!$_.isEmpty(this.filterColumns[index].name)) {
				this.cleanFilterEntry(this.filterColumns[index].name);
			}
			this.filterColumns.splice(index, 1, this.filterAttributes.columns.find((item) => item.name === columnName));
			// console.debug(columnName, index, JSON.stringify(this.filterColumns.map((column) => column.name)));
		},
		async applySelectedFilter(filter, fetch = true) {
			this.filterChanged = false;
			this.cleanFilter();
			this.filterUid = filter.grid_settings_uid;
			this.setFilterOptions(true);
			await this.useFilter(fetch);
		},
		async useDefaultFilter(fetch = true) {
			const defaultFilter = this.filterList.find((item) => item.is_default && item.global);
			if (!$_.isEmpty(defaultFilter)) {
				await this.applySelectedFilter(defaultFilter);
				if (fetch) {
					this.reload(this.id);
				}
			} else {
				console.error(`[LbaGrid] does not have default and global filter`, this.gridName || this.gridId);
			}
		},
		getValueText(cur) {
			let valueText = cur.value;
			let returnAcc = false;

			if (cur.options) {
				valueText = cur.options.reduce((accOpt, opt) => {
					if (opt.value === cur.value) {
						return opt.label;
					}
					return accOpt;
				}, cur.value);
			}

			if (cur.type === 'datetime' || cur.type === 'future-datetime' || cur.type === 'date' || cur.type === 'time') {
				let format = 'long';
				if (cur.type === 'date') {
					format = 'short';
				} else if (cur.type === 'time') {
					format = 'timeShort';
				}

				if (cur.condition === 'period') {
					if ($_.isEmpty(cur.value.period)) {
						returnAcc = true;
					} else {
						valueText = this.$t(cur.value.period);
					}

				} else if (cur.condition === 'range') {
					if (cur.value.from == null || cur.value.to == null) {
						returnAcc = true;
					} else {
						const from = `${this.$t('from')}: ${this.$d(new Date(cur.value.from), format)}`;
						const to = `${this.$t('to')}: ${this.$d(new Date(cur.value.to), format)}`;
						valueText = `${from} ${to}`;
					}

				} else if (cur.condition === 'before') {
					if (cur.value.before == null) {
						returnAcc = true;
					} else {
						valueText = this.$d(new Date(cur.value.before), format);
					}

				} else if (cur.condition === 'after') {
					if (cur.value.after == null) {
						returnAcc = true;
					} else {
						valueText = this.$d(new Date(cur.value.after), format);
					}

				} else if (cur.condition === 'equal') {
					if (cur.value.equal == null) {
						returnAcc = true;
					} else {
						valueText = this.$d(new Date(cur.value.equal), format);
					}

				}
			} else if (cur.type === 'multi-select' || cur.type === 'multi-select-no-combobox') {
				if (cur.options) {
					valueText = [];
					cur.options.forEach((opt) => {
						if (cur.value.includes(opt.value)) {
							valueText.push(opt.label);
						}
					});
					valueText = valueText.join(', ');
				}
			}

			return { valueText, returnAcc };
		},
		async useFilter(fetch = true, cleanSearch = true) {
			this.filterDirty = true;
			if (this.$refs && this.$refs.filters) {
				const valid = await this.$refs.filters.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
			}
			this.filterDirty = false;
			this.$root.$emit('dialog-close', this.filterDialogName);
			if ($_.isEmpty(this.filterUid) || this.filterUid === '_') {
				this.usedFilterUid = null;
				this.usedFilterName = null;
				this.usedFilterIsGlobal = false;
			} else {
				const filter = this.filterList.find((item) => item.grid_settings_uid === this.filterUid);
				if (!$_.isEmpty(filter)) {
					this.usedFilterUid = this.filterUid;
					this.usedFilterName = filter.name;
					this.usedFilterIsGlobal = !filter.user_name;
				}
			}
			if (cleanSearch) {
				this.searchValue = null;
				this.collection.search = {};
			}
			this.filterShow = [];

			this.collection.filter = {};
			this.prepareGridOrder(this.filterAttributes.order_by, this.filterAttributes.order_direction);

			const pickValues = {
				range: ['from', 'to'],
				before: ['before'],
				after: ['after'],
				period: ['period'],
				equal: ['equal'],
			};

			if (
				this.filterAttributes &&
				this.filterAttributes.columns &&
				Array.isArray(this.filterAttributes.columns)
			) {
				const filter = this.filterAttributes.columns.reduce((acc, cur) => {
					if (
						cur.name &&
						cur.value != null &&
						((typeof cur.value !== 'object' && typeof cur.value !== 'string') || !$_.isEmpty(cur.value))
					) {
						const { valueText, returnAcc } = this.getValueText(cur);
						if (returnAcc) {
							return acc;
						}
						const conditionLabel = this.filterConditions
							.filter((cond) => cond.value === cur.condition)
							.map((cond) => cond.label)[0] || '';

						let value = cur.value;

						if (['datetime', 'future-datetime', 'date', 'time'].includes(cur.type) &&
							cur.condition && pickValues[cur.condition]
						) {
							value = $_.pick(value, pickValues[cur.condition]);
						}

						acc.collection[cur.name] = {
							value: value,
							condition: cur.condition,
							type: cur.type,
						};
						if (Array.isArray(cur.value)) {
							const labels = [];
							const values = [...cur.value];
							cur.options.map((option) => {
								if (values.includes(option.value)) {
									labels.push(option.label);
								}
							});
							acc.show.push({
								name: cur.name,
								label: cur.label,
								conditionLabel,
								value: cur.value,
								valueText: labels.join(', '),
							});
						} else {
							acc.show.push({
								name: cur.name,
								label: cur.label,
								conditionLabel,
								value: cur.value,
								valueText,
							});
						}
					}
					return acc;
				}, {
					collection: {},
					show: [],
				});
				this.collection.filter = filter.collection;
				this.filterShow = filter.show;
			}
			if (fetch) {
				this.searching();
			}
			// wait for filter to update, then calc height
			await this.$nextTick();
			this.resize();
			// this.setViewportHeight();
		},
		async filterRemove(name) {
			this.cleanFilterEntry(name, true);
			this.useFilter(true, false);
			await this.fetchFilterList();
			if (this.filterShow.length === 0) {
				this.prepareEmptyFilter();
			}
			this.usedFilterUid = null;
			this.filterChanged = true;
			this.filterUid = '_';
			this.filterName = '';
		},
		filterHaveColumns() {
			return this.filterAttributes &&
				this.filterAttributes.columns &&
				Array.isArray(this.filterAttributes.columns);
		},
		filterAvailable() {
			return this.filterAttributes &&
				this.filterAttributes.columns &&
				this.filterAttributes.columns.length > 0;
		},
		cleanFilter() {
			this.usedFilterUid = null;
			this.filterChanged = false;
			this.filterUid = '_';
			this.filterName = '';
			this.filterIsGlobal = false;
			this.filterIsDefault = false;
			this.filterAttributes.order_by = null;
			this.filterAttributes.order_direction = null;
			this.cleanFilterEntry();
			this.setFilterDefaultConditions(null);
			this.prepareGridOrder();

			this.filterAttributes.columns.forEach((column) => {
				this.prepareDefaultValues(column, true);
			});
			this.prepareEmptyFilter();
			this.updateFilterValueKey();
		},
		cleanFilterOrder() {
			this.$set(this.filterAttributes, 'order_by', null);
			this.$set(this.filterAttributes, 'order_direction', null);
		},
		cleanFilterEntry(name, reset = false) {
			this.usedFilterUid = null;
			if (this.filterHaveColumns()) {
				this.filterAttributes.columns.forEach((entry) => {
					if ((!name || entry.name === name) && !entry.required) {
						this.setFilterDefaultConditions(entry.name);

						const value = reset ? null : undefined;
						if (
							entry.type === 'datetime' ||
							entry.type === 'future-datetime' ||
							entry.type === 'date' ||
							entry.type === 'time'
						) {
							if ($_.isEmpty(entry.value)) this.$set(entry, 'value', {});
							if (entry.condition === 'period') {
								this.$set(entry.value, 'period', value);
							} else if (entry.condition === 'range') {
								this.$set(entry.value, 'from', value);
								this.$set(entry.value, 'to', value);
							} else if (entry.condition === 'before') {
								this.$set(entry.value, 'before', value);
							} else if (entry.condition === 'after') {
								this.$set(entry.value, 'after', value);
							} else if (entry.condition === 'equal') {
								this.$set(entry.value, 'equal', value);
							}
						} else {
							this.$set(entry, 'value', value);
						}
					}
				});
			}
			this.updateFilterValueKey();
			this.$forceUpdate();
		},
		getFilterDefaultCondition(type) {
			return this.filterConditions
				.filter((entry) => (entry.default && entry.default.indexOf(type) !== -1))
				.map((entry) => entry.value)[0];
		},
		checkFilterCondition(type, condition) {
			const check = this.filterConditions
				.find((entry) => (entry.types.indexOf(type) !== -1 && entry.value === condition));
			return (check) ? check.value : this.getFilterDefaultCondition(type);
		},
		setFilterDefaultConditions(name) {
			const self = this;
			if (this.filterHaveColumns()) {
				this.filterAttributes.columns.forEach((entry) => {
					if ((name && name === entry.name) || !entry.condition) {
						entry.condition = self.getFilterDefaultCondition(entry.type);
					}
				});
			}
		},
		setFilterConditions(name, condition) {
			const self = this;
			if (this.filterHaveColumns()) {
				this.filterAttributes.columns.forEach((entry) => {
					if (entry.name === name) {
						entry.condition = self.checkFilterCondition(entry.type, condition);
					}
				});
			}
		},
		async fetchFilterList() {
			this.filterList = [];
			const moduleName = this.filterAttributes.module || this.gridName;
			if (moduleName) {
				return this.gridModel.getFilters(moduleName)
					.then((result) => {
						this.filterList = result.data;
					});

			}
			return Promise.resolve();
		},
		setFilterOptions(prepareUsed = false) {
			const self = this;
			if (this.filterUid && this.filterHaveColumns()) {
				if (this.filterUid === '_') {
					this.cleanFilter();
				} else {
					const filterSettings = this.filterList
						.find((entry) => (entry.grid_settings_uid === this.filterUid));

					if (filterSettings) {
						this.filterAttributes.order_by = filterSettings.order_by;
						this.filterAttributes.order_direction = filterSettings.order_direction;

						if (filterSettings.name) {
							this.filterName = filterSettings.name;
						}
						if (prepareUsed) {
							this.usedFilterName = filterSettings.name || null;
							this.usedFilterIsGlobal = filterSettings.global;
						}

						this.$set(this, 'filterIsGlobal', filterSettings.global);
						this.$set(this, 'filterIsDefault', filterSettings.is_default);
						this.filterColumns = [];

						this.filterAttributes.columns.forEach((entry) => {
							this.prepareDefaultValues(entry, true);
							let settings = null;

							if (Array.isArray(filterSettings.settings)) {
								settings = filterSettings.settings.find((e) => (e.name === entry.name));
							}

							if (settings) {
								entry.condition = self.checkFilterCondition(entry.type, settings.condition);
								if (
									settings.value && (
										settings.value.constructor === Object ||
										settings.value.constructor === Array
									)
								) {
									entry.value = $_.cloneDeep(settings.value);
									if (!$_.isEmpty(entry.value)) {
										this.filterColumns.push(entry);
										// console.debug('settings1:', settings);
									}
								} else {
									entry.value = settings.value;
									if (entry.value != null) {
										this.filterColumns.push(entry);
										// console.debug('settings2:', settings);
									}
								}

							} else {
								entry.condition = self.checkFilterCondition(entry.type, null);
								entry.value = null;

							}

						});
					} else {
						this.cleanFilterEntry(null);
						this.setFilterDefaultConditions(null);
					}
				}
			}
		},
		createDuplicateFilter() {
			this.filterUid = '_';
			this.filterName = '';
		},
		async saveFilter() {
			if (this.filterHaveColumns()) {
				this.filterDirty = true;
				const valid = await this.$refs.filters.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
				this.filterDirty = false;
				const filterSettings = this.filterAttributes.columns.reduce((acc, cur) => {
					if (cur.value !== null) {
						acc.push({
							name: cur.name,
							type: cur.type,
							value: cur.value,
							condition: cur.condition,
						});
					}
					return acc;
				}, []);

				const moduleName = this.filterAttributes.module || this.gridName;
				if (filterSettings.length > 0 && moduleName) {
					let request = null;
					if (this.filterUid === '_') {
						request = this.gridModel.insertFilter(moduleName, {
							name: this.filterName,
							settings: filterSettings,
							order_by: this.filterAttributes.order_by,
							order_direction: this.filterAttributes.order_direction,
							filterIsGlobal: this.filterIsGlobal,
							filterIsDefault: this.filterIsDefault,
						});
					} else {
						request = this.gridModel.updateFilter(moduleName, this.filterUid, {
							name: this.filterName,
							settings: filterSettings,
							order_by: this.filterAttributes.order_by,
							order_direction: this.filterAttributes.order_direction,
							filterIsGlobal: this.filterIsGlobal,
							filterIsDefault: this.filterIsDefault,
						});
					}
					request.then((result) => {
						if (result && result.data) {
							this.filterUid = result.data.grid_settings_uid;
							this.filterList = result.data.filter_list;
						}
						this.usedFilterName = this.filterName;
						this.usedFilterIsGlobal = this.filterIsGlobal;
						this.useFilter(true);
					});
				}
			}
		},
		removeFilter() {
			const moduleName = this.filterAttributes.module || this.gridName;
			if (moduleName) {
				this.gridModel.removeFilter(moduleName, this.filterUid)
					.then((result) => {
						if (result && result.data && result.data === 'ok') {
							this.cleanFilter();
							this.fetchFilterList();
						}
					});
			}
		},
	},
};
