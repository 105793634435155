export default class {
	constructor($http) {
		this.$http = $http;
	}

	sendError(params) {
		return this.$http.post('lbadmin/client-error', { params });
	}

	sendErrors(params) {
		return this.$http.post('lbadmin/client-errors', { params });
	}
}
