<template>
	<div v-if="updatedDate && updatedBy" class="lastChange">
		<span class="no-truncate">
			{{ $t('lastChangeByUser') }}
			{{ updatedBy }}&nbsp;
			{{ $d(new Date(updatedDate), 'fullDigitsMedium') }}
		</span>
		<!--not used anymore, just creates space on right side-->
		<span class="no-truncate"><a><i class="icon-calltray-history"></i></a></span>
	</div>
</template>

<script>

/*
 * __back__
 *
 * Zobrazení informace o poslední změně
 *
 */

export default {
	name: 'LastChange',
	props: {
		updatedDate: {
			type: [String, Date],
		},
		updatedBy: {
			type: String,
		},
	},
};
</script>
