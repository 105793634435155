<template>
	<div v-if="!loading" id="content">
		<div class="page-header">
			<h1>{{ $t("settings.user.settings") }}</h1>
			<div class="page-controls" >
				<button
					name="save"
					class="button"
					data-cy="user-settings__save"
					@click.stop="submit"
					:disabled="!dirty"
				>
					{{ $t('save') }}
				</button>
				<span @click="tabReload" data-cy="user-settings__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<ValidationObserver ref="formQuickSetting">
				<form name="formQuickSetting">
					<masonry
						:cols="{default: 2, 1000: 1}"
						:gutter="{default: '20px', 1000: '15px'}"
					>
						<lba-section
							:parentComponentId="`user-settings-basic`"
							:key="settingsKey"
							:title="$t('settings.user.user')"
							class="form-fieldset col-12"
							expanded
						>
							<div style="margin-right: -20px;">
								<s>
									<small>{{ $t('settings.user.firstname') }}</small>
									<input type="text" v-model="$user.firstname"
										:data-cy="`user-settings__firstName__inputText`"
										@change="dirty = true"
										@input="dirty = true; addAvoided('firstname', $user.firstname, $t('settings.user.firstname'));"
									>
								</s>
								<s>
									<small>{{ $t('settings.user.lastname') }}</small>
									<input type="text" v-model="$user.lastname"
										:data-cy="`user-settings__lastName__inputText`"
										@change="dirty = true"
										@input="dirty = true; addAvoided('lastname', $user.lastname, $t('settings.user.lastname'));"
									>
								</s>
								<s>
									<small>{{ $t('settings.user.username') }}</small>
									<input type="text" v-model="$user.user_name" disabled
										:data-cy="`user-settings__userName__inputText`"
									>
								</s>
								<s class="settings-lang" v-if="$user.allowedPerUserChanges.allow_language_per_user">
									<small>{{ $t('settings.user.language') }}</small>
									<select
										name="lang"
										v-model="$user.lang"
										:data-cy="`user-settings__language__select`"
										@change="dirty = true"
									>
										<option value="cs" :data-cy="`user-settings__language__select__optionCs`">
											česky
										</option>
										<option value="en" :data-cy="`user-settings__language__select__optionEn`">
											english
										</option>
									</select>
								</s>
								<s v-if="$user.allowedPerUserChanges.allow_timezone_per_user">
									<small>{{ $t('settings.user.timezone') }}</small>
									<select name="timezone" v-model="$user.timezone"
										:data-cy="`user-settings__timezone__select`"
										@change="dirty = true"
									>
										<option v-for="(tz, index) in timezoneList" :key="tz"
											:data-cy="`user-settings__timezone__select__option${index}`"
										>{{ tz }}</option>
									</select>
								</s>
								<s v-if="$user.allowedPerUserChanges.allow_color_theme_per_user">
									<small>{{ $t('settings.user.theme') }}</small>
									<select name="theme" v-model="theme"
										:data-cy="`user-settings__theme__select`"
										@change="dirty = true"
									>
										<option
											v-for="(th, index) in themes"
											:key="`theme-${index}`"
											:value="th.value"
											:data-cy="`user-settings__theme__select__option${index}`"
										>
											{{ th.label }}
										</option>
									</select>
								</s>
								<s>
									<small>{{ $t('settings.user.email') }}</small>
									<ValidationProvider
										:name="$t('settings.user.email')"
										v-slot="{ invalid, errors }"
										:rules="{ email: true }"
									>
										<input
											:data-cy="`user-settings__email__inputText`"
											type="text"
											v-model="$user.email"
											@change="dirty = true"
											@input="dirty = true; addAvoided('email', $user.email, $t('settings.user.email'));"
											:class="{ 'lba-invalid': invalid }">
										<span
											v-for="(err, index) in errors"
											:key="index"
											:data-cy="`user-settings__email__error${index}`"
											class="lba-messages"
										>{{ err }}</span>
									</ValidationProvider>
								</s>
								<s v-if="tfa.length">
									<small>{{ $t('settings.user.tfa') }}</small>
									<div class="link-icon mt-2" @click="openTfaSettings()">
										<template v-if="!$user.tfa || !$user.tfa.length">
											{{ $t(`notSet`) }}
										</template>
										<template v-else>
											{{ getTfa() }}
										</template>
										<i class="icon-settings"></i>
									</div>
								</s>
							</div>
						</lba-section>
						<lba-section
							v-show="$user.extraSettings.length > 0"
							:parentComponentId="`user-settings-extra`"
							:title="$t('settings.user.extra')"
							class="form-fieldset col-12"
							expanded
						>
							<div style="margin-right: -20px;">
								<template v-for="(setting, index) in $user.extraSettings">
									<custom-value
										v-if="!setting.permsDisabled"
										:key="`${index}-${extraSettingsKey}`"
										:attribute="{
											options: {
												...setting.options,
												parentComponentId: 'user-settings-extra',
												name: setting.name,
											},
											key: setting.name,
											data: $user
										}"
										@change="dirty = true"
										@input="dirty = true"
									></custom-value>
								</template>
							</div>
						</lba-section>
						<lba-section
							v-if="$user.auth_method === null || $user.auth_method === 'agent'"
							v-show="$user.extraSettings.length > 0"
							:parentComponentId="`user-settings-password-change`"
							:title="$t('settings.user.passwordChange')"
							class="form-fieldset col-12"
							expanded
						>
							<div style="margin-right: -20px;">
								<s>
									<small>{{ $t('settings.user.currentPassword') }}</small>
									<lba-input-password
										v-model="$user.current_password"
										name="oldPass"
										:readable="false"
										writeable
										:parentComponentId="'user-settings'"
										componentId="oldPassword"
										:rules="passwordRules"
										autocomplete="new-password"
										@change="dirty = true"
										:showGenPasswd="false"
									></lba-input-password>
								</s>
								<ValidationProvider
									:name="$t('settings.user.newPassword')"
									v-slot="{ invalid, errors }"
									:rules="{
										regex: passwordRegex,
										notContains: (passwordRules && passwordRules.easy_to_guess) ? avoidedItems : []
									}"
								>
									<s>
										<small>{{ $t('settings.user.newPassword') }}</small>
										<lba-input-password
											v-model="$user.password"
											name="newPass"
											:readable="false"
											writeable
											:parentComponentId="'user-settings'"
											componentId="newPassword"
											:class="{ 'lba-invalid': invalid }"
											@input="dirty = true; testingPassword = $user.password;"
											:rules="passwordRules"
											autocomplete="new-password"
											@change="dirty = true"
										></lba-input-password>
										<template v-if="invalid">
											<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
											<span
												v-for="(err, index) in passwordErrors"
												:key="`pwe-${index}`"
												:data-cy="`user-settings__password__error${errors.length + index}`"
												class="lba-messages"
											>{{ err }}</span>
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`user-settings__password__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s>
								</ValidationProvider>
							</div>
						</lba-section>
					</masonry>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import ColorThemes from '../mixins/ColorThemes';
import Page from '../mixins/Page';
import PasswordMixin from '../mixins/Password';
import OnScroll from '../mixins/OnScroll';
import UserModel from '../models/User';

export default {
	name: 'User',
	mixins: [
		ColorThemes,
		Page,
		PasswordMixin,
		OnScroll,
	],
	data() {
		return {
			dirty: false,
			loading: true,
			tabRefresh: false,
			timezoneList: [],
			userModel: null,
			theme: 'theme-basic',
			themes: null,
			tfa: [],
			avoidedItems: [],
			avoidedItemsWhole: {},
			settingsKey: this.$generateUID(),
			extraSettingsKey: 0,
		};
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
		$route() {
			if (!this._inactive) this.tabRefresh = true;
		},
		theme(newValue) {
			$_.forEach(this.themes, (th) => {
				document.body.classList.remove(th.value);
			});
			document.body.classList.add(newValue);
			this.$root.$emit('theme-changed');
		},
	},
	activated() {
		if (this.tabRefresh) this.userReload();
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		await this.fetch();
		this.$root.$listen('user-loaded', this.userUpdated, this);
		this.$root.$listen('socket-opened', this.userUpdated, this);
		this.$root.$listen('user.settings-updated', this.userReload, this);
		// this.$root.$listen('app.extra-settings-changed', this.onExtraSettignsChanged, this, true);
		this.loading = false;
	},
	beforeDestroy() {
		this.resetTheme();
	},
	mounted() {
		this.userModel = new UserModel(this.$http);
	},
	methods: {
		async fetch() {
			this.$user.queryAction('timezoneList').then((data) => {
				this.timezoneList = data;
			}).catch((err) => {
				console.error('Timezone list load error:', err);
			});

			await this.$user.load(false);
			this.initColorThemes();
			this.userUpdated();
			let resp = await this.userModel.getAuth();
			this.tfa = $_.filter(resp.data.tfa_methods, 'enabled');
			resp = await this.userModel.getAvailableExtens(this.$user.role_uid);
			this.$user.password = null;
			this.$user.current_password = null;
			this.$user.available_extensions = resp.data;
			const extensionSetting = $_.find(this.$user.extraSettings, (setting) => setting.name === 'extension');
			if (!$_.isEmpty(extensionSetting) && !$_.isEmpty(extensionSetting.options)) {
				extensionSetting.options.options = [{ value: null, label: `-- ${this.$t('notSet')} --` }];
				extensionSetting.options.options = $_.concat(
					extensionSetting.options.options,
					this.$user.available_extensions.map(
						(extension) => ({ value: extension.exten, label: `${extension.exten} - ${extension.name}` })
					)
				);
			}
		},
		userReload() {
			if (!this.dirty) {
				this.tabReload(true);
				this.tabRefresh = false;
			}
		},
		async userUpdated() {
			this.resetTheme();
			this.$forceUpdate();
			await this.$nextTick();
			this.addAvoided('firstname', this.$user.firstname, this.$t('settings.user.firstname'));
			this.addAvoided('lastname', this.$user.lastname, this.$t('settings.user.lastname'));
			this.addAvoided('login', this.$user.id, this.$t('settings.user.login'));
			this.addAvoided('email', this.$user.email, this.$t('settings.user.email'));
		},
		openTfaSettings() {
			this.$root.$emit('dialog-open', { name: '2fa_change' });
		},
		logout() {
			this.theme = 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			// clear temp variables for force password change
			this.password = null;
			this.current_password = null;
			this.$root.$emit('dialog-close', 'force_password_change');
			// logout user
			this.$user.logout();
			const { href } = this.$router.resolve({ name: 'login' });
			if (!this.$root.isFirstLogin) {
				window.history.pushState({}, null, href);
				this.reload();
			} else {
				this.$routerWrap.push('/login');
			}
		},
		reload() {
			window.location.reload();
		},
		addAvoided(key, value, label) {
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			$_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
		async submit() {
			this.dirty = true;
			const valid = await this.$refs.formQuickSetting.validate();
			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}
			this.$user.color_theme = this.theme;
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);

			this.$user.updateUser().then(() => {
				this.$user.load(false).then(() => {
					if (this.$user.current_password && this.$user.password) {
						delete this.$user.current_password;
						delete this.$user.password;
						this.logout();
						this.$root.$emit('dialog-close', 'force_password_change');
						this.$notify.success(this.$t('passChanged'));
					} else {
						this.$root.$emit('content.saved', { reload: false });
					}
					this.$root.$emit('user.updated');
					this.$root.$emit('user.settings-updated');
					this.dirty = false;
				});
			// already handled in $http interceptors
			}).catch(() => {});
		},
		getTfa() {
			const tfa = [];
			$_.forEach(this.$user.tfa, (el) => {
				tfa.push(this.$t(`tfaMethods.methods.${el}`));
			});
			return tfa.join(', ');
		},
		resetTheme() {
			this.theme = this.$user.color_theme || 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			$_.forEach(this.themes, (th) => {
				document.body.classList.remove(th.value);
			});
			document.body.classList.add(this.theme);
		},
	},
};
</script>
