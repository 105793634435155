<script>
export default {
	inserted(el, bindings, vnode) {
		const disabled = !!bindings.modifiers.disabled || (bindings.value && bindings.value.disabled);
		if (disabled) {
			return;
		}

		let expandedHeight = 0;
		let elementOffsetBottom = 0;
		let elementOffsetTop = 0;
		let elementRect = null;
		let docHandled = false;
		let contentHeight = null;

		let expanded = !!bindings.modifiers.expanded && (bindings.value === true || (bindings.value && bindings.value.expanded));
		const closeOnClick = !!bindings.modifiers.closeOnClick || (bindings.value && bindings.value.closeOnClick);
		const closeOnItemClick = !!bindings.modifiers.closeOnItemClick || (bindings.value && bindings.value.closeOnItemClick);
		const onContextMenu = !!bindings.modifiers.onContextMenu || (bindings.value && bindings.value.onContextMenu);
		const onHover = !!bindings.modifiers.onHover || (bindings.value && bindings.value.onHover);
		const adjustHeight = !!bindings.modifiers.adjustHeight || (bindings.value && bindings.value.adjustHeight);
		const dontSetExpandedOnHover = !!bindings.modifiers.dontSetExpandedOnHover ||
			(bindings.value && bindings.value.dontSetExpandedOnHover);
		const setStyle = bindings.value && bindings.value.setStyle;
		const boundaryElementClass = (bindings.value && bindings.value.boundaryElementClass) || null;
		const minusOffsetTop = (bindings.value && bindings.value.minusOffsetTop) || null;
		const minusOffsetBottom = (bindings.value && bindings.value.minusOffsetBottom) || null;
		const forceExpandTop = !!bindings.modifiers.forceExpandTop || (bindings.value && bindings.value.forceExpandTop);
		const forceExpandBottom = !!bindings.modifiers.forceExpandBottom || (bindings.value && bindings.value.forceExpandBottom);

		if (forceExpandTop && forceExpandBottom) {
			throw new Error('[LbaExpander] forceExpandTop and forceExpandBottom can not be set at the same time');
		}

		const callback = (mutationsList, observer) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'attributes' &&
					mutation.attributeName === 'data-close-expander' &&
					mutation?.target?.dataset?.closeExpander
				) {
					if (el.classList.contains('expanded')) {
						expanded = false;
						el.classList.remove('expanded');
					}
					delete mutation.target.dataset.closeExpander;
				}
			}
		};
		const observer = new MutationObserver(callback);
		const config = { attributes: true, childList: false, subtree: false };
		observer.observe(el, config);
		el._observer = observer;

		const close = (e) => {
			if (el.classList.contains('expanded')) {
				expanded = false;
				el.classList.remove('expanded');
			}
			e.stopPropagation();
		};

		const columnExpanderHandler = (closeExpander = true) => {
			// check if this is grid columns expander
			const childElement = el.querySelector('.icon-columns');
			if (!childElement) return;
			const parentElement = el.closest('.page-content-part');
			if (!parentElement) return;

			if (!closeExpander) {
				parentElement.style.zIndex = 15;

			} else if (closeExpander) {
				parentElement.style.zIndex = 10;
			}
		};

		const prepareSibling = () => {
			columnExpanderHandler(false);
			if (el.nextSibling) {
				if (el.nextSibling.getBoundingClientRect) {
					const siblingRect = el.nextSibling.getBoundingClientRect();
					expandedHeight = Math.ceil(siblingRect.height);
					if (contentHeight == null) {
						contentHeight = expandedHeight;
					}
					elementRect = el.getBoundingClientRect();
					let boundaryElement = null;
					if (!$_.isEmpty(boundaryElementClass)) {
						boundaryElement = document.getElementsByClassName(boundaryElementClass)[0];
					}
					if (boundaryElement) {
						const boundaryRect = boundaryElement.getBoundingClientRect();
						elementOffsetBottom = (
							Math.floor(boundaryRect.y) +
							Math.floor(boundaryRect.height) -
							Math.ceil(elementRect.y) -
							Math.ceil(elementRect.height)
						);
						elementOffsetTop = Math.floor(elementRect.y) - Math.ceil(boundaryRect.y);
					} else {
						elementOffsetBottom = Math.floor(window.innerHeight) - Math.ceil(elementRect.y) - Math.ceil(elementRect.height);
						elementOffsetTop = (
							Math.floor(elementRect.y) -
							Math.ceil(document.getElementById('header').getBoundingClientRect().height)
						);
					}

					if (minusOffsetTop) {
						elementOffsetTop -= minusOffsetTop;
					}
					if (minusOffsetBottom) {
						elementOffsetBottom -= minusOffsetBottom;
					}
				}

				/* console.log(
					'content height:', contentHeight,
					'\nexpanded height:', expandedHeight,
					'\nbottom:', elementOffsetBottom,
					'\ntop:', elementOffsetTop
				); */

				let maxHeight = null;

				if (forceExpandTop) {
					maxHeight = contentHeight;
					el.nextSibling.classList.add('expander-top');

				} else if (forceExpandBottom) {
					maxHeight = contentHeight;
					el.nextSibling.classList.remove('expander-top');

				// check if contentHeight fits
				} else if (contentHeight < elementOffsetBottom) {
					maxHeight = contentHeight;
					el.nextSibling.classList.remove('expander-top');

				} else if (contentHeight < elementOffsetTop) {
					maxHeight = contentHeight;
					el.nextSibling.classList.add('expander-top');

				// check if expandedHeight fits
				} else if (expandedHeight < elementOffsetBottom) {
					maxHeight = elementOffsetBottom;
					el.nextSibling.classList.remove('expander-top');

				} else if (expandedHeight < elementOffsetTop) {
					maxHeight = elementOffsetTop;
					el.nextSibling.classList.add('expander-top');

				// neither whole content nor expanded height fits
				} else {
					// choose bigger space
					if (elementOffsetBottom < elementOffsetTop) {
						el.nextSibling.classList.add('expander-top');
						maxHeight = elementOffsetTop;
					} else {
						el.nextSibling.classList.remove('expander-top');
						maxHeight = elementOffsetBottom;
					}
				}

				if (adjustHeight) {
					let style = `height: ${maxHeight}px;`;
					if (maxHeight < contentHeight) {
						style += `overflow-y: scroll;`;
					}

					if (setStyle) {
						setStyle(style);
					} else {
						el.nextSibling.style = style;
					}
				}
			}
		};

		if (el.nextSibling) {
			if (bindings.value != null && bindings.value.constructor === Object) {
				expanded = !!bindings.modifiers.expanded && bindings.value.expanded;
			} else {
				expanded = !!bindings.modifiers.expanded && bindings.value;
			}

			if (Object.keys(el.nextSibling).length > 0) {
				if (el.nextSibling.attributes['data-close'] != null) {
					el.nextSibling.addEventListener('click', close);
				}

				/*
				el.nextSibling.children.forEach((children) => {
					if (children.attributes['data-close'] != null) {
						children.addEventListener('click', close);
					}
				});
				*/
			}
		}

		if (expanded) {
			el.classList.add('expander', 'expanded');
			prepareSibling();
		} else {
			el.classList.add('expander');
		}

		if (closeOnClick || closeOnItemClick) {
			document.body.addEventListener('click', (event) => {
				const inExpandedArea = (
					el.nextSibling &&
					el.nextSibling.classList &&
					el.nextSibling.classList.contains('expand') &&
					el.nextSibling.contains(event.target)
				);
				if (inExpandedArea) {
					const ignoreItemsClick = Array.from(el.nextSibling.querySelectorAll('[data-ignore-item-click]'));
					if (
						!closeOnItemClick ||
						event.target.hasAttribute('data-ignore-item-click') ||
						ignoreItemsClick.some((item) => item.contains(event.target))
					) {
						return;
					}
				}

				if (expanded) {
					expanded = false;
					columnExpanderHandler(true);
					el.classList.remove('expanded');
					docHandled = true;
				} else {
					docHandled = false;
				}
			}, true);
		}

		const events = [];
		events.push((onContextMenu) ? 'contextmenu' : 'click');
		if (onHover) {
			events.push('mouseover');
		}

		events.forEach((eventName) => {
			el.addEventListener(eventName, (e) => {
				if (eventName === 'mouseover' && dontSetExpandedOnHover) {
					prepareSibling();
				} else {
					if (onContextMenu) {
						e.preventDefault();
					}
					if (el.classList.contains('expanded')) {
						expanded = false;
						el.classList.remove('expanded');
					} else if (docHandled) {
						docHandled = false;
					} else {
						expanded = true;
						el.classList.add('expanded');
						prepareSibling();
					}
					e.stopPropagation();
				}
			}, eventName === 'mouseover');
		});
	},
	unbind(el) {
		if (el._observer) {
			el._observer.disconnect();
			el._observer = null;
		}
	},
};
</script>
