<template>
	<div id="content" v-if="loaded">
		<div class="page-header">
			<h1>
				{{ $t("settings.gr") }}
				<span class="gray">{{ meta.name }}</span>
			</h1>
			<div class="page-controls">
				<button
					v-if="!isNew && !readOnly"
					name="openRemoveDialog"
					class="buttonTransparent"
					@click.stop="deleteGroup"
					data-cy="group__openDialogRemove"
				>
					{{ $t('remove') }}
				</button>

				<lba-button-save
					ref="saveButton"
					:id="saveButtonId"
					:inputDirty="inputDirtyForSave"
					name="save"
					data-cy="group__save__button"
					@click.stop="submit"
				/>
				<span v-if="!isNew" @click="tabReload" data-cy="group__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<ValidationObserver ref="form">
				<form name="form" lba-size-class="{'contentWide': 700}">
					<lba-section
						parentComponentId="group"
						:title="$t('settings.groupSettings')"
						slotType="form"
						expanded
					>
						<ValidationProvider
							:name="$t('settings.groupName')"
							v-slot="{ invalid, errors }"
							:rules="{ required: true }"
							style="display: inline-block;"
						>
							<s>
								<small>
									{{ $t('settings.groupName') }}
									<span class="required-sign"></span>
								</small>
								<input
									data-cy="group__groupName__inputText"
									type="text"
									name="role_name"
									@input="setInputDirty(true);"
									@change="setDirty(true);"
									v-model="group.role_name"
									:class="{ 'lba-invalid': invalid && dirty }"
									:disabled="readOnly"
								/>
								<template v-if="dirty">
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`group__groupName__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</template>
							</s>
						</ValidationProvider>
						<!-- <s>
							<small>
								{{ $t('settings.groupProfile') }}
							</small>
							<select
								name="profile"
								@change="dirty = true"
								v-model="group.profile"
							>
								<option :value="null"></option>
								<option
									v-for="profile in profiles"
									:value="profile.profile_uid"
									:key="profile.profile_uid"
								>
									{{ profile.profile_name }}
								</option>
							</select>
						</s> -->
						<lba-dnd-two-lists
							parentComponentId="group"
							componentId="members"
							ref="dnd"
							name="settings-group-members-dialog"
							:dialogTitle="$t('settings.groupMembersDialog')"
							:list1.sync="tmpMembers"
							:list2.sync="tmpSelectedMembers"
							:listTitle1="$t('settings.availableRoles')"
							:listTitle2="$t('settings.appendedRoles')"
							:staticListItems1="false"
							:staticListItems2="false"
							:staticListOrder1="true"
							:staticListOrder2="true"
							:sort="sortMembers"
							@save="saveMembers"
							class="popupScrollable"
						>
							<template v-slot:list1="props">
								<div
									class="flex-row inner border"
									v-for="(role, index) in props.list"
									:key="index"
								>
									<label
										class="checkbox label"
										:data-cy="`group__list1__member${index}__checkboxLabel`"
										style="width: 100%;"
									>
										<input type="checkbox" lba-dnd-list-checkbox
											:data-cy="`group__list1__member${index}__inputCheckbox`"
										/>
										<i class="icon-ok"></i>
										<span class="label">
											<i
												style="
													padding: 5px;
													border-radius: 50%;
													background-color: #00b8ff;
													color: white;
												"
												:class="{
													'icon-user': role.role_type === 'USER',
													'icon-users': role.role_type === 'GROUP',
												}"
												:title="`${role.role_type === 'GROUP' ? $t('settings.group') : $t('settings.user.user') }`"
											></i>
											{{ role.role_name }}
										</span>
									</label>
								</div>
							</template>
							<template v-slot:list2="props">
								<div
									class="flex-row inner border"
									v-for="(role, index) in props.list"
									:key="index"
								>
									<label
										class="checkbox label"
										:data-cy="`group__list2__member${index}__checkboxLabel`"
										style="width: 100%;"
									>
										<input type="checkbox" lba-dnd-list-checkbox
											:data-cy="`group__list2__member${index}__inputCheckbox`"
										/>
										<i class="icon-ok"></i>
										<span class="label">
											<i
												style="
													padding: 5px;
													border-radius: 50%;
													background-color: #00b8ff;
													color: white;
												"
												:class="{
													'icon-user': role.role_type === 'USER',
													'icon-users': role.role_type === 'GROUP',
												}"
												:title="`${role.role_type === 'GROUP' ? $t('settings.group') : $t('settings.user.user') }`"
											></i>
											{{ role.role_name }}
										</span>
									</label>
								</div>
							</template>
						</lba-dnd-two-lists>
						<s>
							<small>
								{{ $t('settings.groupMembers') }}
							</small>
							<button
								data-cy="group__openDialogMembers"
								class="buttonIcon"
								type="button"
								@click="openMembersDialog"
								:disabled="readOnly"
							>
								<i class="icon-edit"></i>{{ $t('settings.groupMembersEdit') }}
							</button>
						</s>
						<br>
						<s
							class="two mt-5"
							v-if="group.members.length"
						>
							<small>
								{{ $t('settings.groupMembers') }}
							</small>
							<table
								class="content-table wide mt-1"
								:key="tableId"
							>
								<tr>
									<th style="width: 50%;">{{ $t('settings.member') }}</th>
									<th style="width: 50%;">{{ $t('settings.profile') }}</th>
								</tr>
								<tr
									v-for="(member, memberIndex) in group.members" :key="memberIndex"
								>
									<td>
										<a :key="`i-${memberIndex}`" @click="openDetail(member.role_type, member.role_uid)"
											:data-cy="`group__members__member${memberIndex}__openMember`"
										>
											<i
												:class="{
													'icon-user': member.role_type === 'USER',
													'icon-users': member.role_type === 'GROUP',
												}"
												:title="(member.role_type === 'GROUP' ? $t('settings.group') : $t('settings.user.user'))"
											></i>
											{{ member.role_name }}
										</a>
									</td>
									<td
										style="display: flex; align-items: center;flex-direction: column; min-height: 1.5em;"
									>
										<lba-select
											:key="`member-${memberIndex}-${tableId}`"
											style="width: 100%"
											v-model="member.profiles"
											:opts="labeledProfiles"
											:multiple="true"
											:renderHtmlLabels="true"
											:rootTag="'div'"
											noNotSetOption
											disableUnknown
											:combobox="true"
											@change="setDirty(true);"
											:readOnly="readOnly"
											:topPlaceholder="member.user_profiles && member.user_profiles.length ?
												$t('settings.inherited', { profiles: member.user_profiles.join(', ') }) :
												$t('settings.inheritedFromTeam', { team: member.role_name })
											"
										></lba-select>
									</td>
								</tr>
							</table>
						</s>
					</lba-section>
					<lba-section
						parentComponentId="group"
						:title="$t('settings.memberOf')"
						slotType="form"
						expanded
					>
						<s
							class="size-3"
							v-if="group.member_of && group.member_of.length"
						>
							<div>
								<template v-for="(memberOf, memberOfIndex) of group.member_of">
									<i
										:key="`iii-${memberOfIndex}`"
										class="icon-users"
										:title="$t('settings.group')"
										style="color: #999;"
									></i>
									<a :key="`i-${memberOfIndex}`" @click="openDetail('GROUP', memberOf.role_uid)"
										:data-cy="`group__member_of__member${memberOfIndex}__openMember`"
									>
										{{ memberOf.role_name }}
									</a>
									<span :key="`ii-${memberOfIndex}`" v-if="memberOfIndex <= group.member_of.length - 2">, </span>
								</template>
							</div>
						</s>
						<span v-else>{{ $t('notSet') }}</span>
					</lba-section>
					<lba-confirm-remove-dialog
						parentComponentId="group"
						componentId="confirmDelete"
						name="group-confirm-delete"
						:title="$t('settings.deleteGroup')"
						:message="`${group.role_name} ?`"
						:on-confirm="deleteGroupConfirm"
					></lba-confirm-remove-dialog>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import GroupModel from '../models/Group';
import ProfileModel from '../models/Profile';
import SaveEntry from '../mixins/SaveEntry';

export default {
	name: 'Group',
	mixins: [
		Titles,
		Page,
		OnScroll,
		SaveEntry,
	],
	data() {
		return {
			group: {
				members: [],
			},
			roles: [],
			profiles: [],
			loaded: false,
			dirty: false,
			groupModel: null,
			profileModel: null,
			tmpMembers: [],
			tmpSelectedMembers: [],
			isNew: true,
			readOnly: false,
			meta: {
				name: this.$t('settings.newGroup'),
				path: 'settings-group',
				tooltipPrefix: this.$t('settings.group'),
			},
			tableId: 'table-id',
			saveButtonId: 'lbadmin.group',
		};
	},
	computed: {
		labeledProfiles() {
			return $_.map(this.profiles, (el) => {
				return { label: el.profile_name, value: el.profile_uid, custom: el.custom || false };
			});
		},
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.isNew = (this.$route.params.id === '+');
		this.groupModel = new GroupModel(this.$http);
		this.profileModel = new ProfileModel(this.$http);

		// get all roles
		let resp = await this.groupModel.getRoles();
		resp.data.map((el) => {
			if (el.role_uid !== this.$route.params.id) {
				el.id = el.role_uid;
				el.profiles = [];
				this.roles.push(el);
			}
		});
		// get all profiles
		resp = await this.profileModel.getProfilesToSet();
		this.profiles = resp.data;

		await this.fetch();
		this.loaded = true;
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.team.write');
		},
		sortMembers(a, b) {
			if ((a.role_name || '').toLowerCase() < (b.role_name || '').toLowerCase()) return -1;
			if ((a.role_name || '').toLowerCase() > (b.role_name || '').toLowerCase()) return 1;
			if (a.role_uid.toLowerCase() < b.role_uid.toLowerCase()) return -1;
			if (a.role_uid.toLowerCase() > b.role_uid.toLowerCase()) return 1;
			return 0;
		},
		async fetch() {
			if (this.$route.params.id != null && !this.isNew) {
				const response = await this.groupModel.get(this.$route.params.id);
				this.group = response.data;
				$_.forEach(this.group.members, (member) => {
					member.id = member.role_uid;
				});
				this.group.members.sort(this.sortMembers);
				this.meta.name = this.group.role_name;
				this.refreshTitle();

				$_.forEach(this.group.members, (member) => {
					$_.forEach(member.profiles, (el) => {
						const found = $_.find(this.profiles, (eel) => {
							return eel.profile_uid === el.profile_uid;
						});
						if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
					});
				});

				$_.forEach(this.group.members, (member) => {
					member.profiles = $_.map(member.profiles, (el) => { return el.profile_uid; });
				});
			}

		},
		saveMembers() {
			this.setDirty(true);
			this.group.members = this.tmpSelectedMembers.map((member) => {
				const originalMember = this.group.members.find((item) => item.role_uid === member.role_uid);
				if (!$_.isEmpty(originalMember)) {
					member.profile_uid = originalMember.profile_uid;
				} else {
					member.profile_uid = null;
				}
				return member;
			});
			this.group.members.sort(this.sortMembers);
			this.$root.$emit('dialog-close', 'settings-group-members-dialog');
			this.tableId = this.$generateUID();
		},
		openMembersDialog() {
			const members = $_.cloneDeep(this.roles);
			this.tmpSelectedMembers = this.prepareValues($_.cloneDeep(this.group.members));
			this.tmpMembers = this.prepareValues($_.differenceWith(
				members,
				this.tmpSelectedMembers,
				(a, b) => (a.role_uid === b.role_uid)
			));
			this.$root.$emit('dialog-open', { name: 'settings-group-members-dialog' });
		},
		async submit() {
			this.setDirty(true);
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.inputDirtyForSave = false;
				this.$root.$emit('content.validationFailed', { id: this.saveButtonId });
				return;
			}

			const group = $_.cloneDeep(this.group);

			$_.forEach(group.members, (member) => {
				member.profiles = $_.map(member.profiles, (el) => {
					return { profile_uid: el };
				});
			});

			this.setDirty(false);

			this.groupModel
				.save(group)
				.then(async (response) => {
					this.$root.$emit('content.saved', { id: this.saveButtonId });
					await this.$nextTick();
					this.meta.name = this.group.role_name;
					this.refreshTitle();

					this.$root.$emit('favourite-pages.name-updated');

					if (this.$route.params.id !== response.data.resid) {
						this.$routerWrap.push({
							name: 'settings-group',
							params: { id: response.data.resid },
						});
					}
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed', { id: this.saveButtonId });
				});
		},
		deleteGroup() {
			this.$root.$emit('dialog-open', { name: 'group-confirm-delete' });
		},
		deleteGroupConfirm() {
			this.$root.$emit('tab-editted', false);
			this.groupModel
				.delete(this.group.role_uid)
				.then(() => {
					this.$root.$emit('close-current-tab');
					this.$root.$emit('content.removed');
				})
				.catch(() => {
					this.$root.$emit('content.removeFailed');
				});
		},
		prepareValues(array) {
			return array.map((el) => {
				el.label = el.role_name;
				return el;
			});
		},
		openDetail(type, id) {
			let name = 'settings-group';
			if (type === 'USER') name = 'settings-user';
			this.$routerWrap.push({
				name,
				params: {
					id,
					openNewTab: true,
				},
			});
		},
	},
};
</script>
