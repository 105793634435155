<script>
export default {
	inserted(el) {
		if (!el.dataset.autofocus) {
			el.dataset.autofocus = 'autofocus';
		}

		const tabindexElements = document.querySelectorAll('[tabindex="0"]');
		for (const tabindexElement of tabindexElements) {
			tabindexElement.removeAttribute('tabindex');
		}

		const autofocusElements = document.querySelectorAll('[data-autofocus]');

		// Focus only the first autofocus element
		if (autofocusElements.length > 0) {
			autofocusElements[0].setAttribute('tabindex', '0');

			// Focus if not focused yet
			if (document.activeElement !== autofocusElements[0]) {
				autofocusElements[0].focus();
			}
		}
	},
};
</script>
