<template>
	<content-options v-if="!loading">
		<template v-slot:content>
			<div class="page-header">
				<h1>{{ getOriginalFileName(importRecord.file_name) }}</h1>

				<div class="page-controls">
					<button
						v-if="(
							$permissions.checkPermission('lbadmin.import-records.write') &&
							importRecord.bulk_delete_pg_callback != null &&
							!importRecord.data_deleted
						)"
						class="buttonTransparent"
						data-cy="importRecord__openDialogRemoveEntry"
						@click.stop="openRemoveDialog"
					>
						{{ $t('removeImportedData') }}
					</button>
				</div>
			</div>

			<div
				class="page-content"
				@scroll="setScroll"
				tabindex="0"
				v-autofocus
			>
				<lba-section
					parentComponentId="importRecord"
					componentId="information"
					:title="$t('information')"
					expanded
					class="form-fieldset"
				>
					<template>
						<s class="value-listing">
							<small>{{ $t('fileName') }}</small>
							<span >{{ getOriginalFileName(importRecord.file_name) }}</span>
						</s>
						<s class="value-listing">
							<small>{{ $t('rowCount') }}</small>
							<span>{{ importRecord.row_count }}</span>
						</s>
						<s class="value-listing">
							<small>{{ $t('importedTo') }}</small>
							<span>{{ $getLocale(importRecord.title) }}</span>
						</s>
						<br>

						<s class="value-listing">
							<small>{{ $t('created') }}</small>
							<span>{{ $d(new Date(importRecord.created), 'long') }}</span>
						</s>
						<s class="value-listing">
							<small>{{ $t('createdByUser') }}</small>
							<span>{{ getName(importRecord) }}</span>
						</s>
						<s class="value-listing">
							<small>{{ $t('form.removed').replace('.', '') }}</small>
							<span v-if="importRecord.data_deleted">{{  $t('yes') }}</span>
							<span v-if="!importRecord.data_deleted">{{  $t('no') }}</span>
						</s>
						<br>
					</template>
				</lba-section>

				<lba-section
					parentComponentId="importRecord"
					componentId="settings"
					:title="$t('settings.settings')"
					expanded
					class="form-fieldset"
				>
					<template #default="props">
						<lba-content-tabs
							:parentComponentId="props.parentComponentId"
							:tabs="tabs"
							:defaultTab="defaultTab"
						>
							<div class="content-table-wrapper">
								<table class="content-table mt-1 fakeGrid" style="width: 100%;">
									<tr>
										<th width="170">{{ $t('internalName') }}</th>
										<th width="300">{{ `${$t('columnNameInFile')} / ${$t('columnOrderInFile')}` }}</th>
										<th></th>
									</tr>
									<tr v-for="(row, index) in setting" :key="index">
										<td width="170">{{ $getLocale(row.title) }}</td>
										<td width="300">
											<template v-if="importRecord.import_export_setting.contains_header">
												{{ row.header }}
											</template>
											<template v-else>
												{{ isNumber(row.header) ? `${$t('column')} ${row.header + 1}` : '' }}
											</template>
										</td>
										<td></td>
									</tr>
								</table>
							</div>

							<div class="content-table-wrapper">
								<table class="content-table mt-1 fakeGrid" style="width: 100%;">
									<tr>
										<th width="170">{{ $t('title') }}</th>
										<th width="170">{{ $t('internalName') }}</th>
										<th width="270">{{ $t('example') }}</th>
										<th width="70">{{ $t('customData') }}</th>
										<th></th>
									</tr>
									<tr v-for="(row, index) in column" :key="index">
										<td width="170">{{ row.title }}</td>
										<td width="170">{{ row.name }}</td>
										<td width="270">{{ row.example }}</td>
										<td width="70"><i class="icon-ok green" v-if="row.custom"></i></td>
										<td></td>
									</tr>
								</table>
							</div>

							<div class="content-table-wrapper">
								<table class="content-table mt-1 fakeGrid" style="width: 100%;">
									<tr>
										<th width="170">{{ $t('internalName') }}</th>
										<th width="70">{{ $t('mandatory') }}</th>
										<th width="170">{{ $t('dataType') }}</th>
										<th width="70">{{ $t('format') }}</th>
										<th width="170">{{ $t('pattern') }}</th>
										<th></th>
									</tr>
									<tr v-for="(row, index) in validationSchema" :key="index">
										<td width="170">{{ row.name }}</td>
										<td width="70">{{ row.required }}</td>
										<td width="170">{{ getValidationType(row.type) }}</td>
										<td width="70">{{ row.format }}</td>
										<td width="170">{{ row.pattern }}</td>
										<td></td>
									</tr>
								</table>
							</div>
						</lba-content-tabs>
					</template>
				</lba-section>
			</div>

			<lba-dialog-modal
				parentComponentId="importRecord"
				componentId="removeEntry"
				name="removeEntry"
				:title="$t('removeEntries')"
				@close="removeRow = null"
			>
				<template #default="props">
					<div class="popup-body">
						<p>{{ $t('removeImportedDataInfo') }}</p>
					</div>
					<div class="popup-footer">
						<button class="buttonRed" type="button" @click="removeData"
							:data-cy="`${props.parentComponentId}__remove`"
						>
							{{ $t('remove') }}
						</button>
						<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
							:data-cy="`${props.parentComponentId}__closeDialog`"
						>
							{{ $t('cancel') }}
						</button>
					</div>
				</template>
			</lba-dialog-modal>
		</template>
	</content-options>
</template>

<style>
.fakeGrid th,
.fakeGrid td {
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.fakeGrid th {
	height: 35px;
	line-height: 35px;
	white-space: nowrap;
	color: #666;
	font-size: .87em;
	font-weight: 400;
}
</style>

<script>
import Model from '../models/ImportRecords';
import OnScroll from '../mixins/OnScroll';

export default {
	mixins: [
		OnScroll,
	],
	data() {
		return {
			dirty: false,
			loading: true,
			model: null,
			importRecord: {},
			tabs: [
				{
					id: 0,
					label: this.$t('columnMapping'),
				},
				{
					id: 1,
					label: this.$t('columnsSetting'),
				},
				{
					id: 2,
					label: this.$t('validationSetting'),
				},
			],
			defaultTab: 0,
			setting: [],
			column: [],
			validationSchema: [],
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.model = new Model(this.$http);

		if (!$_.isEmpty(this.$route.params.id) && this.$route.params.id !== '+') {
			const result = await this.model.get(this.$route.params.id);
			this.importRecord = result.data;

			this.setting = this.prepareSetting();
			this.column = this.prepareColumns();
			this.validationSchema = this.prepareValidationSchema();
		}
		this.loading = false;
	},
	methods: {
		getOriginalFileName(fileName) {
			if (!fileName) return '';
			return fileName.split('-').filter((item, index) => index > 0).join('-');
		},
		getName(row) {
			if (row.firstname && row.lastname) {
				return `${row.firstname} ${row.lastname}`;
			}
			if (row.firstname) {
				return row.firstname;
			}
			if (row.lastname) {
				return row.lastname;
			}
			return row.user_name;
		},
		getValidationType(type) {
			if (!type) return;
			if (type.constructor === String) return type;
			return type.join(', ');
		},
		isNumber(value) {
			if (value != null && value !== '' && !isNaN(value)) {
				return true;
			}
			return false;
		},

		prepareSetting() {
			const result = $_.cloneDeep(this.importRecord.import_export_setting.header_map);
			const rowsToRemove = [];
			result.forEach((column, index) => {
				const configurationColumn = this.importRecord.columns.columns.find((c) => c.name === column.column);
				if (!configurationColumn) {
					rowsToRemove.unshift(index);
					return;
				}

				column.id = index;
				column.title = configurationColumn.title;

				if (configurationColumn.custom) {
					column.removable = true;
				}
			});
			rowsToRemove.forEach((index) => {
				result.splice(index, 1);
			});
			return result;
		},
		prepareColumns() {
			const result = $_.cloneDeep(this.importRecord.columns.columns);
			result.forEach((column, index) => {
				column.id = index;
			});
			return result;
		},
		prepareValidationSchema() {
			const result = [];
			const schema = this.importRecord.validation_schema;
			const propertyKeys = Object.keys(schema.properties);
			propertyKeys.forEach((propertyKey, index) => {
				const property = $_.cloneDeep(schema.properties[propertyKey]);
				property.id = index;
				property.name = propertyKey;
				property.required = schema.required && schema.required.includes(propertyKey);
				result.push(property);
			});
			return result;
		},

		openRemoveDialog() {
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		async removeData() {
			if (this.importRecord.import_record_uid == null) return;

			try {
				await this.model.removeData(this.importRecord.import_record_uid);
				this.$notify.success(this.$t('form.removed'));
				this.$root.$emit('dialog-close', 'removeEntry');
				this.importRecord.data_deleted = true;
			} catch (error) {
				this.$notify.warn(this.$t('form.removeFailed'));
			}
		},
	},
};
</script>
