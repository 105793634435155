<template>
	<div
		id="options"
		ref="options"
		:style="styleWidth"
		class="optionsPin collapsed"
		:class="{
			active: isActive(),
			pinned,
			smsgwOptions: true,
		}"
		data-cy="content-options__options"
		v-lba-right-panel-resizer="{
			panel_width: internalWidth,
			panel_pinned: pinned
		}"
	>
		<span id="splitter" class="userSelectNone" data-cy="content-options__options__splitter">
			<i id="splitterIcon" class="icon24 icon-splitter"></i>
		</span>
		<div class="options-panel-controls">
			<slot name="optionsControls"></slot>
			<controls
				parentComponentId="content-options__options"
				btnPin
				:btnCancel="isActive()"
				:btnPrev="(isActive() && !getAnyError() && buttons.btnPrev) || null"
				:btnNext="(isActive() && !getAnyError() && buttons.btnNext) || null"
				:btnShowInCard="isActive() && !getAnyError() && buttons.btnShowInCard"
				:btnFavourite="(isActive() &&!getAnyError()) || null"
			>
				<template v-slot:controlsBefore>
					<slot v-if="!getAnyError()" name="controlsTitle"></slot>
					<last-change :updatedDate="updatedDate" :updatedBy="updatedBy" />
				</template>
			</controls>
		</div>
		<div v-if="!getAnyError()" class="options-controls">
			<slot name="controls" parentComponentId="content-options__options__slotControls"></slot>
		</div>
		<div class="optionsContent">
			<slot v-if="!getAnyError()" name="content"></slot>
			<div v-else class="error-wrap" data-cy="content-options__options__anyError">
				<h1 v-if="getErrorType() === 'notFound'" data-cy="content-options__options__viewNotFound">
					{{ $t('viewNotFound') }}
				</h1>
				<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="content-options__options__viewPermissionDenied">
					{{ $t('viewPermissionDenied') }}
				</h1>
				<h1 v-else-if="getErrorType() === 'serverError'" data-cy="content-options__options__viewServerError">
					{{ $t('viewServerError') }}
				</h1>
				<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="content-options__options__viewRecordDeleted">
					{{ getError() || $t('viewRecordDeleted') }}
				</h1>
				<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="content-options__options__viewMissingParameter">
					{{ $t('viewMissingParameter', { params: getError() }) }}
				</h1>
				<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="content-options__options__viewWrongParameter">
					{{ $t('viewWrongParameter', { params: getError() }) }}
				</h1>

				<button
					v-if="showReloadButton()"
					type="button"
					@click="reloadPath"
				>
					{{ $t('reload') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import RouteKey from '../mixins/RouteKey';
import TabErrors from '../mixins/TabErrors';

export default {
	mixins: [RouteKey, TabErrors],
	name: 'OptionsPanel',
	props: {
		match: {
			type: Number,
			required: false,
			default: 1,
		},
		buttons: {
			type: Object,
			default: () => {
				return {};
			},
		},
		updatedDate: [String, Date],
		updatedBy: String,
		anyViewError: Boolean,
	},
	data() {
		return {
			pinned: false,
			width: null,
			settings: null,
			componentName: 'OptionsPanel',
		};
	},
	computed: {
		internalWidth: {
			get() {
				return this.width;
			},
			set(value) {
				this.width = value;
				this.$root.$emit('right-panel-width', this.width);
			},
		},
		styleWidth() {
			return `width: ${this.internalWidth}px`;
		},
	},
	activated() {
		this.setRightPanel();
	},
	created() {
		this.$root.$listen('content.pin', this.contentPin, this);
		this.$root.$listen('content.unpin', this.contentUnpin, this);
		this.$root.$listen('content.close-detail', this.closeDetail, this);
		this.$root.$listen('right-panel-resized', this.updateSettings, this);
	},
	mounted() {
		this.setRightPanel();
	},
	beforeDestroy() {
		// for cases where you open options panel which throws error then you close it and reopen it and it does not throw error anymore
		this.setError({ key: this.routeKey, error: null }, null);
	},
	methods: {
		isActive() {
			return this.$route.matched.length > this.match;
		},
		closeDetail() {
			if (!this.pinned) {
				this.$routerWrap.push(this.$route.matched[this.$route.matched.length - 2]);
			}
		},
		contentPin() {
			this.pinned = true;
			this.updateSettings();
		},
		contentUnpin() {
			this.pinned = false;
			this.updateSettings();
		},
		routeKeyChanged() {
			this.$root.$emit('content-options-route-key-change', this.routeKey);
		},
		setRightPanel() {
			this.pinned = this.$pageSettings.get(this.componentName, 'pinned', true, false, Boolean);
			const width = this.$pageSettings.get(this.componentName, 'width', true, null, Number);
			if (this.$refs && !this.$refs.options) {
				const minWidth = parseInt(window
					.getComputedStyle(this.$refs.options)
					.getPropertyValue('min-width')
				);
				this.internalWidth = Math.max(width, minWidth);
			} else {
				this.internalWidth = width;
			}
		},
		updateSettings(width) {
			if (width) {
				this.internalWidth = width;
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'width',
					data: this.internalWidth,
				});
				this.$emit('update:width', this.internalWidth);
			} else {
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'pinned',
					data: this.pinned,
				});
			}
		},
	},
};
</script>
