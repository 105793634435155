<template>
	<component
		v-if="noInput && $_.isEmpty(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $d(new Date(data[key]), 'long') }}
	</component>
	<component
		v-else-if="noInput && ['time_since', 'from_now'].includes(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $timeBeforeAfter(data[key]) }}
	</component>
	<span
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<fake-provider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-period-picker
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation),
					'fullscreenHidex': true,
				}"
				:sClass="options.sClass != null ? options.sClass : 'size-0'"
				:sPeriodStyle="options.sPeriodStyle != null ? options.sPeriodStyle : 'width: 100px'"
				:sFromStyle="options.sFromStyle != null ? options.sFromStyle : 'width: 160px'"
				:sToStyle="options.sToStyle != null ? options.sToStyle : 'width: 160px'"
				:key="`${currentComponentId}-dateRange`"
				:period.sync="data[key].period"
				:from.sync="data[key].from"
				:to.sync="data[key].to"
				@input="onEvent('input')"
				@change="onEvent('change')"
				:inline="options.inline != null ? options.inline : true"
				:style="options.style != null ? options.style : 'display: inline'"
				:parentComponentId="currentComponentId"
			/>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages"
					:data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages" :key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`">
					{{ msg }}
				</span>
			</template>
		</fake-provider>
	</span>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputPeriodPicker',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
	},
};
</script>
