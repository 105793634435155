<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<span v-for="(option, index) in options.options"
			:key="index"
			style="display: inline-block; margin-right: 10px;"
		>
			<span
				v-if="option.tooltip"
				v-tooltip="option.tooltip"
			>{{ option.label }}:&nbsp;</span>
			<template v-else>
				{{ option.label }}:&nbsp;
			</template>
			<i v-if="option.checked" class="icon-ok" :data-cy="`${currentComponentId}__${option.value}__checked`"></i>
			<i v-else class="icon-cancel" :data-cy="`${currentComponentId}__${option.value}__notChecked`"></i>
		</span>
	</component>
	<s
		v-else-if="options.options.length > 0"
		:class="[options.class]"
		:style="style"
		class="wide showInvalidTooltip"
		:data-cy="currentComponentId"
	>
		<small v-if="label">
			{{ label }}
			<span v-if="rules.required" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<div
			:data-cy="`${currentComponentId}__multicheckbox`"
			class="showInvalidTooltip"
			:class="{
						'lba-invalid': (showRequirement),
						'showWarning': (showRecommendation)
					}"
		>
			<span v-for="(option, okey) in options.options"
				:key="okey"
				style="display: inline-block; margin-right: 10px;"
			>
				<fake-provider :name="`${$getLocale(options.label)} - ${option.label || option.value}`" :rules="validationRules"
					:data-required-recommended="requiredRecommended"
					:data-multi-checkbox="$getLocale(options.label)"
				>
					<label :data-cy="`${currentComponentId}__${option.value}__label`" class="checkbox" v-if="!readOnly || option.checked">
						<input
							:data-cy="`${currentComponentId}__${option.value}__input`"
							:data-value="option.value"
							:data-label="option.label || ''"
							v-model="option.checked"
							type="checkbox"
							:name="attribute.name"
							:title="option.label || ''"
							:disabled="readOnly"
							@input="onEvent('input')"
							@change="onEvent('change')"
						>
						<i class="icon-ok"></i>
						<span
							v-if="option.tooltip"
							v-tooltip="$getLocale(option.tooltip)"
							class="label"
						>{{ $getLocale(option.label) }}</span>
						<span
							v-else
							class="label"
						>{{ $getLocale(option.label) }}</span>
					</label>
				</fake-provider>
			</span>
			<span v-if="rules.required && !options.label" class="required-sign"></span>
		</div>
		<template v-if="dirty">
			<span v-if="rules.required" class="lba-messages" :data-cy="`${currentComponentId}__error`">
				{{ $t('validation.schemaRequired', {attribute: options.label})}}
			</span>
			<span v-if="showRecommendation" class="lba-messages warning" :data-cy="`${currentComponentId}__warning`">
				{{ $t('validation.requiredRecommended', {attribute: options.label})}}
			</span>
		</template>
	</s>
	<span v-else-if="options.options.length === 0">
		{{ $t('crm.noEntries') }}
	</span>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputMultiCheckbox',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			initializing: true,
		};
	},
	watch: {
		'options.options': {
			// immediate: true,
			deep: true,
			handler(newValue) {
				if (this.initializing) {
					this.initializing = false;
					return;
				}
				const checked = $_.filter(newValue, (opt) => opt.checked).map((opt) => opt.value);
				if (this.data && this.key) {
					if (!$_.isEqual(this.data[this.key].sort(), checked.sort())) {
						this.data[this.key] = checked;
						this.$emit('change', this.data[this.key]);
					}
				}
			},
		},
	},
	computed: {
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && this.attribute.options.options && this.showRecommended) {
				return this.hasValue();
			}
			return false;
		},
		showRequirement() {
			if (this.dirty && this.rules.required && this.attribute.options.options) {
				return this.hasValue();
			}
			return false;
		},
		validationRules() {
			let rules = {};
			if (this.rules.required) {
				rules = { requiredMulticheckbox: true };
				rules.requiredMulticheckbox = { checkboxes: this.attribute.options.options };
			}
			return rules;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareMultiCheckbox();
	},
	methods: {
		prepareMultiCheckbox() {
			if ($_.isEmpty(this.data[this.key])) {
				this.data[this.key] = [];
			}
			if (this.options.options) {
				$_.forEach(this.options.options, (opt) => {
					this.$set(opt, 'checked', this.data[this.key].indexOf(opt.value) !== -1);
				});
			}
			if (this.readOnly && this.options.options) {
				// this.options.options = $_.filter(this.options.options, (option) => {
				// 	return this.data[this.key][option.value];
				// });
			}
		},
		hasValue() {
			for (let i = 0; i < this.attribute.options.options.length; i++) {
				if (this.attribute.options.options[i].checked === true) {
					return false;
				}
			}
			return true;
		},
	},
};
</script>
