<script>
export default {
	inserted(el, binding, vnode) {
		const elSplitter = document.getElementById('splitter');
		if (!elSplitter) {
			return;
		}
		const pinned = binding.value.panel_pinned;
		let width = binding.value.panel_width;
		let currentWidth = null;
		let dragStartX = null;
		let startWidth = null;
		let minWidth = parseInt(window.getComputedStyle(el).getPropertyValue('min-width'), 10);
		const maxWidth = window.innerWidth - 350;
		let isMin = true;
		const newWidth = Math.max(width, minWidth);

		if (width && pinned) {
			el.setAttribute('style', `width: ${newWidth}px;`);
			const fakeOptions = document.getElementById('fakeOptions');
			if (fakeOptions) {
				fakeOptions.setAttribute('style', `width: ${newWidth}px;`);
			}
		}

		elSplitter.addEventListener('mousedown', (e1) => {
			dragStartX = e1.pageX;
			startWidth = el.clientWidth;

			const mouseMoveHandler = (e2) => {
				currentWidth = Math.max((startWidth + (dragStartX - e2.pageX)), minWidth);
				el.setAttribute('style', `width: ${currentWidth}px;`);
				const fakeOptions = document.getElementById('fakeOptions');
				if (fakeOptions) {
					fakeOptions.setAttribute('style', `width: ${currentWidth}px;`);
				}
				vnode.context.$root.$emit('right-panel-resize', currentWidth);

				if (currentWidth <= minWidth) {
					if (!isMin) {
						isMin = true;
					}
				} else if (currentWidth >= maxWidth) {
					return;
				} else if (isMin) {
					el.classList.remove('collapsed');
					isMin = false;
				}

				minWidth = parseInt(window.getComputedStyle(el).getPropertyValue('min-width'), 10);
				e2.preventDefault();
				e2.stopPropagation();
			};

			const selectionHandler = (e3) => {
				e3.preventDefault();
				e3.stopPropagation();
				return false;
			};

			const mouseUpHandler = () => {
				document.removeEventListener('mousemove', mouseMoveHandler);
				document.removeEventListener('mouseup', mouseUpHandler);
				document.removeEventListener('selectstart', selectionHandler);
				width = currentWidth;
				vnode.context.$root.$emit('right-panel-resized', width);
			};

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
			document.addEventListener('selectstart', selectionHandler);
		});
	},
};
</script>
