function getChecker(callback, vm, onInactive) {
	return (...args) => {
		/* eslint-disable-next-line no-underscore-dangle */
		if (onInactive || (!onInactive && !vm._inactive)) {
			/* eslint-disable-next-line standard/no-callback-literal */
			callback(...args);
		}
	};
}

export default {
	methods: {
		// register listener
		$listen(event, callback, vm, onInactive = false) {
			if (event == null) {
				console.error('[lbadmin.mixins.RootListener]($listen) missing event param, params:', event, callback, vm);
				return;
			}
			if (callback == null) {
				console.error('[lbadmin.mixins.RootListener]($listen) missing callback param, params:', event, callback, vm);
				return;
			}
			if (vm == null) {
				console.error('[lbadmin.mixins.RootListener]($listen) missing vm param, params:', event, callback, vm);
				return;
			}
			/* DEVELOPMENT ONLY - BEGIN
			if (this.$checkDestroyed(vm, false, true)) {
				console.error(
					`[mixin.RootListener] trying to listen on root event (${event}) in already destroyed component: `, vm,
					'try using "this.$checkDestroyed(this);" on created'
				);
				return;
			}
			/* DEVELOPMENT ONLY - END */
			const checker = getChecker(callback, vm, onInactive);
			this.$on(event, checker);
			vm.$once('hook:beforeDestroy', () => {
				this.$off(event, checker);
			});
		},
	},
};
