
import DocumentationModel from '../models/Documentation';

export default {
	data() {
		return {
			documentationModel: null,
			isDocumentationAvailable: false,
		};
	},
	async created() {
		this.documentationModel = new DocumentationModel(this.$http);
		const { data } = await this.documentationModel.checkDocumentation();
		this.isDocumentationAvailable = data.available;
	},
};
