export default class {
	constructor($http) {
		this.$http = $http;

		this.actions = {
			update: { method: 'PUT' },
			suUsers: { method: 'GET', url: 'lbadmin/su/users', isArray: true },
			switchUser: { method: 'POST', url: 'lbadmin/su/switch', isArray: true },
			resetUser: { method: 'POST', url: 'lbadmin/su/reset', isArray: true },
			groups: { method: 'GET', url: 'lbadmin/groups', isArray: true },
			timezoneList: { method: 'GET', url: 'lbadmin/timezone-list', isArray: true },
		};
	}

	query(params) {
		return this.$http.post('lbadmin/query-users', { params });
	}

	get(role_uid, params = '') {
		return this.$http.get(`lbadmin/users/${role_uid}${params}`);
	}

	async queryAction(action) {
		const response = await this.$http(this.actions[action]);
		return response.data;
	}

	otpQrCode(params) {
		return this.$http.post('lbadmin/user/qr-auth', params);
	}

	getAuth() {
		return this.$http.get(`lbadmin/options/auth-methods`);
	}

	getGauthQr(renew = false) {
		return this.$http.post(`lbadmin/user/gauth-qr/`, { renew });
	}

	getTfaSettings() {
		return this.$http.get(`lbadmin/user-2fa`);
	}

	saveTfaSettings(params) {
		return this.$http.post(`lbadmin/user-2fa`, params);
	}

	testGauthToken(token) {
		return this.$http.post('lbadmin/users/test-gauth-qr', token);
	}

	remove(role_uid) {
		return this.$http.delete(`lbadmin/users/${role_uid}`);
	}

	createUser(user) {
		delete user.permissions;
		delete user.valid;
		delete user.available_extensions;

		user.valid_until = user.valid_until ? user.valid_until : 'infinity';
		user.password_expiration = user.password_expiration ? user.password_expiration : 'infinity';

		if (!user.auth_method) user.auth_method = null;

		return this.$http.post('lbadmin/users/', user);
	}

	saveUser(user) {
		delete user.permissions;
		delete user.valid;
		delete user.available_extensions;

		user.valid_until = user.valid_until ? user.valid_until : 'infinity';
		user.password_expiration = user.password_expiration ? user.password_expiration : 'infinity';

		if (!user.auth_method) user.auth_method = null;

		return this.$http.put(`lbadmin/users/${user.role_uid}`, user);
	}

	getAvailableExtens(role_uid) {
		if (!role_uid) {
			role_uid = '';
		}
		return this.$http.get(`lbadmin/available-extens/${role_uid}`);
	}

	getSuUsers() {
		return this.$http.get(`lbadmin/su/users`);
	}

	setSuUser(user) {
		return this.$http.post(`lbadmin/su/switch`, { user });
	}

	resetSu() {
		return this.$http.post(`lbadmin/su/reset`);
	}

	sendWizard(role_uid) {
		return this.$http.post(`lbadmin/send-wizard/${role_uid}`);
	}
}
