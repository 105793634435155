<template>
	<div class="lastChange" v-if="showHistoryBar" :style="offsetRight" :key="historyBarId">
		{{ $t('lastChangeByUser') }}
		<!-- <a href="#"> -->{{ userName }}<!-- </a> -->&nbsp;
		{{ getDate() }}&nbsp;
		<span v-if="showHistoryChangesButton">
			<a
				:data-cy="`${currentComponentId}__showHistory`"
				@click.prevent="$root.$emit('show-history')"
				v-tooltip.left="$t('changesHistory')"
			><i class="icon-time-history"></i></a>
		</span>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
/*
meta: {
	showHistoryBar: Boolean,
	showHistoryChangesButton: Boolean,
	historyBar: {
		userName: String,
		date: Date,
	}
}
*/

export default {
	mixins: [ComponentIdentifier],
	data() {
		return {
			vm: this,
			pinned: false,
			offsetRight: null,
			showHistoryBar: false,
			showHistoryChangesButton: false,
			userName: null,
			historyBarId: this.$generateUID(),
		};
	},
	watch: {
		$route() {
			this.update();
			this.checkOptions();
		},
	},
	created() {
		this.$root.$listen('history-bar-update', this.update, this);
	},
	mounted() {
		this.pinned = localStorage.getItem('options-pinned') === 'true';
		this.$root.$listen('content.pin', this.contentPin, this);
		this.$root.$listen('content.unpin', this.contentUnpin, this);
		this.$root.$listen('right-panel-resize', this.checkOptions, this);
	},
	methods: {
		getDate() {
			if (this.$route.meta.historyBar.date) {
				if (this.$route.meta.historyBar.date.constructor === Date) {
					return this.$d(this.$route.meta.historyBar.date, 'long');
				}
				return this.$d(new Date(this.$route.meta.historyBar.date), 'long');
			}
			return '';
		},
		update() {
			this.showHistoryBar = $_.get(this.$route.meta, 'showHistoryBar', false);
			this.showHistoryChangesButton = $_.get(this.$route.meta, 'showHistoryChangesButton', false);
			this.userName = $_.get(this.$route.meta, 'historyBar.userName', null);
			this.historyBarId = this.$generateUID();
		},
		contentPin() {
			this.checkOptions(true);
		},
		contentUnpin() {
			this.checkOptions(false);
		},
		checkOptions(state) {
			const options = document.getElementById('options');

			if (state === true) {
				this.offsetRight = `right: ${options.offsetWidth + 20}px`;
			} else if (state === false) {
				this.offsetRight = `right: ${20}px`;
			} else if (options) {
				if (options.classList.contains('pinned')) {
					this.offsetRight = `right: ${options.offsetWidth + 20}px`;
				} else {
					this.offsetRight = `right: ${20}px`;
				}
			}
		},
	},
};
</script>
