<template>
	<portal to="root">
		<div v-if="visible" v-show="isActive" class="popup overlay" :class="{ ...rootClass, animate }" @click="onNodeClick"
			:data-cy="currentComponentId"
		>
			<div class="popupBody" @click="onContentNodeClick" :data-cy="`${currentComponentId}__popupBody`">
				<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
			</div>
		</div>
	</portal>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import Dialog from '../mixins/Dialog';

export default {
	mixins: [ComponentIdentifier, Dialog],
	props: {
		name: {
			type: String,
			required: true,
		},
		modal: {
			type: Boolean,
			required: false,
			default: false,
		},
		closeOnEscape: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			visible: false,
			animate: false,
			active: false,
			node: null,
			contentNode: null,
		};
	},
	created() {
		this.$root.$listen('dialog-open', this.onOpen, this);
		this.$root.$listen('dialog-close', this.onClose, this);

		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.onKeyDown);
		}
	},
	beforeDestroy() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.onKeyDown);
		}
	},
	methods: {
		onKeyDown(event) {
			if (event.key === 'Escape' && this.visible && !this.modal) {
				this.close();
			}
		},
		onNodeClick() {
			if (!this.modal && !this.ignoreClick) {
				this.close();
			} else if (this.ignoreClick) {
				this.ignoreClick = false;
			}
		},
		onContentNodeClick() {
			this.ignoreClick = true;
		},
		onOpen(arg) {
			if (arg.name === this.name) {
				this.open();
			}
		},
		onClose(name) {
			if (name === this.name) {
				this.close();
			}
		},
		open() {
			this.animate = false;
			this.visible = true;
			setTimeout(() => {
				this.animate = true;
			}, 0);
		},
		close(event, quiet) {
			const closeTimeout = (quiet) ? 0 : 200;
			this.animate = false;
			setTimeout(() => {
				this.visible = false;
			}, closeTimeout);
		},
	},
};
</script>
