import ClientErrorModel from '../models/ClientError';

export default {
	data() {
		return {
			logClientErrors: false,
			clientErrorModel: null,
			clientErrors: [],
			clientErrorSendInterval: 30 * 1000,
			clientErrorSendIntervalId: null,
			clientErrorSendPromise: null,
			maxClientErrorsPerBatch: null,
			maxClientErrorsPerHour: null,
			firstClientError: true,
			lastPressedShortcuts: [],
			lastClickedElements: [],
			maxLastPressedShortcuts: 5,
			maxLastClickedElements: 5,
			clientErrorsLogPauseTo: null,
		};
	},
	created() {
		const storedClientErrors = localStorage.getItem('clientErrors');
		if (!$_.isEmpty(storedClientErrors)) {
			try {
				this.clientErrors = JSON.parse(storedClientErrors);
			} catch (error) {
				console.error(`[root.mixin.ClientError](initClientErrorListener) failed to parse stored client errors:`, error);
			}
		}

		window.addEventListener('error', this.onClientError);
		document.addEventListener('click', this.onClick);
	},
	beforeDestroy() {
		if (this.clientErrorSendIntervalId) {
			clearInterval(this.clientErrorSendIntervalId);
			this.clientErrorSendIntervalId = null;
		}
		window.removeEventListener('error', this.onClientError);
		document.removeEventListener('click', this.onClick);
	},
	methods: {
		formatSize(size) {
			if (size < 1024) {
				return `${size} B`;
			}
			if (size < 1024 * 1024) {
				return `${parseInt(size / 1024, 10)} kB`;
			}
			return `${parseInt(size / (1024 * 1024), 10)} MB`;
		},
		getDomPath(element) {
			let elementInfo = element.tagName.toLowerCase();
			if (!$_.isEmpty(element.classList)) {
				elementInfo += `(${$_.join(element.classList, '.')})`;
			}
			const path = [elementInfo];

			while (element !== document.body && element.parentElement) {
				const parent = element.parentElement;
				const childrens = Array.from(parent.children);
				const nodeIndex = childrens.indexOf(element);
				let hasEnoughInfo = false;
				let parentInfo = parent.tagName.toLowerCase();
				if (parent.getAttribute('data-cy')) {
					parentInfo += `$${parent.getAttribute('data-cy')}`;
					hasEnoughInfo = true;
				}
				if (parent.id) {
					parentInfo += `#${parent.id}`;
					hasEnoughInfo = true;
				}
				if (!$_.isEmpty(parent.classList)) {
					parentInfo += `(${$_.join(parent.classList, '.')})`;
				}
				path.splice(0, 0, `${parentInfo}:${nodeIndex}`);
				if (hasEnoughInfo) {
					break;
				}
				element = parent;
			}
			return path.join('->');
		},
		onClientError(error) {
			const events = $_.concat(this.lastPressedShortcuts, this.lastClickedElements);
			const sortedEvents = $_.sortBy(events, ['ts']);
			sortedEvents.forEach((event) => {
				event.ts = event.ts.toISOString();
			});
			let navigationTiming = null;
			const navigationTimings = window.performance.getEntriesByType('navigation');
			if (!$_.isEmpty(navigationTimings) && navigationTimings[0]) {
				navigationTiming = {
					type: navigationTimings[0].type,
					duration: `${parseInt(navigationTimings[0].duration / 1000)}s`,
				};
			}
			const heapSize = (
				`${this.formatSize(window.performance.memory.usedJSHeapSize)} / ` +
				`${this.formatSize(window.performance.memory.totalJSHeapSize)}`
			);
			const errorObject = {
				ts: (new Date()).toISOString(),
				roleUid: this.$user.role_uid || null,
				message: error.message,
				url: location.href,
				uid: this.uid,
				file: `${error.filename}:${error.colno}:${error.lineno}`,
				browser: `(${this.browserName}) ${navigator.userAgent}`,
				heapSize,
				navigationTiming,
				events: sortedEvents,
			};
			this.lastClickedElements = [];
			this.lastPressedShortcuts = [];
			if (this.clientErrors.length < this.maxClientErrorsPerBatch) {
				this.clientErrors.push(errorObject);
				localStorage.setItem('clientErrors', JSON.stringify(this.clientErrors));
			} else {
				console.warn('[root.mixin.ClientError](onClientError) client error buffer is full:', this.maxClientErrorsPerBatch);
			}
			return false;
		},
		onShortcut(shortcut) {
			if ($_.isEmpty(shortcut)) {
				return;
			}
			const shortcutInfo = {
				ts: new Date(),
				roleUid: this.$user.role_uid || null,
				url: location.href,
				type: 'shortcut',
				info: shortcut,
			};
			this.lastPressedShortcuts.push(shortcutInfo);
			if (this.lastPressedShortcuts > this.maxLastPressedShortcuts) {
				this.lastPressedShortcuts = this.lastPressedShortcuts.slice(-this.maxlastPressedShortcuts);
			}
		},
		onClick(event) {
			if (!event || !event.target) {
				return;
			}
			const elementInfo = {
				ts: new Date(),
				roleUid: this.$user.role_uid || null,
				url: location.href,
				type: 'click',
				info: this.getDomPath(event.target),
			};
			this.lastClickedElements.push(elementInfo);
			if (this.lastClickedElements > this.maxLastClickedElements) {
				this.lastClickedElements = this.lastClickedElements.slice(-this.maxLastClickedElements);
			}
		},
		initClientErrorListener() {
			this.clientErrorModel = new ClientErrorModel(this.$http);
			this.clientErrorSendIntervalId = setInterval(this.sendClientErrors, this.clientErrorSendInterval);
			this.sendClientErrors();
		},
		async sendClientErrors() {
			if (
				this.clientErrorSendPromise != null ||
				$_.isEmpty(this.clientErrors) ||
				!this.logClientErrors ||
				(
					this.clientErrorsLogPauseTo != null &&
					new Date() < this.clientErrorsLogPauseTo
				)
			) {
				return;
			}
			this.clientErrorsLogPauseTo = null;
			this.clientErrorSendPromise = new Promise((resolve) => {
				const errorsInfo = {
					firstClientError: this.firstClientError,
					errors: this.clientErrors,
				};
				const now = new Date();
				now.setMinutes(0);
				now.setSeconds(0);
				now.setMilliseconds(0);
				this.clientErrorModel.sendErrors(errorsInfo)
					.then(() => {
						this.clientErrors = [];
						this.lastClickedElements = [];
						this.lastPressedShortcuts = [];
						localStorage.setItem('clientErrors', JSON.stringify(this.clientErrors));
						resolve();
					})
					.catch((error) => {
						if ($_.get(error, 'response.data.error') === 'Logging disabled') {
							console.debug(`[root.mixin.ClientError](initClientErrorListener) logging client errors disabled`);
							this.logClientErrors = false;
						} else if ($_.get(error, 'response.data.error') === 'Logging overflow') {
							console.debug(
								`[root.mixin.ClientError](initClientErrorListener) logging client errors overflow, try in following hour`
							);
							this.clientErrorsLogPauseTo = now.getTime() + 3600 * 1000;
						} else {
							console.error(`[root.mixin.ClientError](initClientErrorListener) failed to send errors:`, error);
						}
						resolve();
					});
			});
			try {
				await this.clientErrorSendPromise;
			} catch (error) {
				console.error(`[root.mixin.ClientError](initClientErrorListener)`, error);
			}
			this.clientErrorSendPromise = null;
		},
	},
};
