<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired || isRequiredGroup" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<ValidationProvider :ref="inputRef" :name="$getLocale(label)" :rules="rules" v-slot="{ invalid, errors }" immediate>
			<span style="display: flex;">
				<input v-model="byteValue" style="display: none" />
				<input
					:data-cy="`${currentComponentId}__inputText`"
					type="number"
					:min="minValueInput"
					:max="maxValueInput"
					v-model="showValue"
					v-bind="bindOptions()"
					:disabled="readOnly"
					class="showInvalidTooltip"
					:class="{
						'lba-invalid': (invalid && dirty),
						fieldIsInvalid: (showRecommendation)
					}"
					style="flex: 1 1 70%;"
					@input="onEvent('input', parseString)"
					@change="onEvent('change', parseString)"
					@blur="$emit('blur')"
				>
				<select
					:data-cy="`${currentComponentId}__select`"
					v-model="selectedUnit"
					style="min-width: 40px; flex: 0;"
					v-bind="bindOptions()"
					:name="attribute.name"
					:title="title || ''"
					:disabled="readOnly"
				>
					<option v-for="(option, index) in selectUnit"
						:key="index"
						:data-cy="`${currentComponentId}__select__option${index}`"
						:value="option.value"
						:selected="selectedUnit === option.value">
						{{ $getLocale(option.label) }}
					</option>
				</select>
			</span>
			<span class="lba-messages static" v-if="error">{{ error }}</span>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputBytes',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			init: false,
			computedValue: 0,
			selectUnit: [
				{ value: 1, label: 'B', show: false },
				{ value: 1024, label: 'kiB', show: true },
				{ value: 1024 * 1024, label: 'MiB', show: true },
				{ value: 1024 * 1024 * 1024, label: 'GiB', show: true },
				{ value: 1024 * 1024 * 1024 * 1024, label: 'TiB', show: true },
			],
			selectedUnit: 1024,
			selectedUnitText: 'kiB',
			byteValue: 0,
			showValue: 0,
			origUnit: 'B',
			minValue: 0,
			maxValue: Infinity,
			error: '',
			minValueInput: 0,
			maxValueInput: 0,
		};
	},
	computed: {
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && !this.data[this.key]) {
				return true;
			}
			return false;
		},
	},
	watch: {
		selectedUnit(newValue) {
			if (!this.init) {
				return;
			}

			this.byteValue = newValue * this.showValue;
			if (newValue) {
				this.data[this.key] = this.byteValue / this.getMultiply(this.origUnit);
			}
			this.selectedUnitText = (this.selectUnit.find((u) => u.value === this.selectedUnit)).label;
			this.checkMinMax();
		},
		showValue(newValue) {
			if (!this.init) {
				return;
			}

			this.byteValue = newValue * this.selectedUnit;
			if (newValue) {
				this.data[this.key] = this.byteValue / this.getMultiply(this.origUnit);
			}
			this.checkMinMax();
		},
		'attribute.options.maxValue'(newValue) {
			this.maxValue = newValue;
			this.maxValue = this.maxValue * this.getMultiply(this.origUnit);
			this.checkMinMax();
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		if (this.attribute && this.attribute.options) {
			if (this.attribute.options.origUnit) {
				this.origUnit = this.attribute.options.origUnit;
			}
			if (this.attribute.options.minValue) {
				this.minValue = this.attribute.options.minValue * this.getMultiply(this.origUnit);
				this.rules.min_value = this.minValue;
			}
			if (this.attribute.options.maxValue) {
				this.maxValue = this.attribute.options.maxValue * this.getMultiply(this.origUnit);
				this.rules.max_value = this.maxValue;
			}
		}
		this.byteValue = this.data[this.key] * this.getMultiply(this.origUnit);

		this.showValue = this.data[this.key];
		this.selectedUnit = this.getMultiply(this.origUnit);

		this.trimAvailableUnits();

		while (this.showValue > 0 && !(this.showValue % 1024)) {
			this.selectedUnit *= 1024;
			this.showValue /= 1024;
		}
		this.init = true;
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
		getMultiply(unit_) {
			const unit = this.selectUnit.find((u) => u.label === unit_);
			if (unit) {
				return unit.value;
			}

			return 1;
		},
		trimAvailableUnits() {
			this.selectUnit = this.selectUnit.filter((unit) => {
				return unit.value >= this.selectedUnit;
			});
		},
		checkMinMax() {
			this.minValueInput = this.minValue / this.selectedUnit || 0;
			this.maxValueInput = Infinity;
			if (!$_.isNaN(Math.floor(this.maxValue / this.selectedUnit))) {
				this.maxValueInput = Math.floor(this.maxValue / this.selectedUnit);
			}
			if (this.byteValue < this.minValue) {
				this.error = this.$t('validation.minValue', {
					min: Math.floor(this.minValue / this.selectedUnit) + ' ' + this.selectedUnitText,
				});
				this.$set(this.rules, 'min_value', Math.floor(this.minValue / this.selectedUnit));
				return;
			}
			if (this.byteValue > this.maxValue) {
				this.error = this.$t('validation.maxValue', {
					max: Math.floor(this.maxValue / this.selectedUnit) + ' ' + this.selectedUnitText,
				});
				this.$set(this.rules, 'max_value', Math.floor(this.maxValue / this.selectedUnit));
				return;
			}
			this.error = '';
		},
	},
};
</script>
