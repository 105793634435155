const Emitter = require('tiny-emitter');
const globalEventBus = new Emitter();

function getChecker(callback, vm, onInactive) {
	return (...args) => {
		/* eslint-disable-next-line no-underscore-dangle */
		if (onInactive || (!onInactive && !vm._inactive)) {
			/* eslint-disable-next-line standard/no-callback-literal */
			callback(...args);
		}
	};
}

const mixinName = '[lbadmin.mixins.EventBus]';

export default {
	data() {
		return {
			localEventBus: new Emitter(),
		};
	},
	created() {
		this.localEmit('created', this);
	},
	beforeMount() {
		this.localEmit('beforeMount', this);
	},
	mounted() {
		this.localEmit('mounted', this);
	},
	beforeDestroy() {
		this.localEmit('beforeDestroy', this);
	},
	destroyed() {
		this.localEmit('destroyed', this);
	},
	activated() {
		this.localEmit('activated', this);
	},
	deactivated() {
		this.localEmit('deactivated', this);
	},
	methods: {
		// Local Event Bus //
		localEmit(event, ...args) {
			if (event == null) {
				console.error(`${mixinName}(localEmit) missing event, params:`, event, ...args);
				throw new Error('INVALID_PARAMS');
			}
			this.$emit(event, ...args);
			this.localEventBus.emit(event, ...args);
		},
		localOn(event, callback, vm = this, onInactive = false) {
			if (event == null) {
				console.error(`${mixinName}(localOn) missing event, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(localOn) missing callback, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (this.$checkDestroyed(this, false, true)) {
				/* DEVELOPMENT ONLY - BEGIN
				console.error(
					`${mixinName}(localOn) trying to listen on local event (${event}) in already destroyed component: `, this,
					'try using "this.$checkDestroyed(this);" on created and after first await'
				);
				/* DEVELOPMENT ONLY - END */
				return;
			}

			const inactiveChecker = getChecker(callback, vm);
			this.localEventBus.on(event, inactiveChecker, vm);
		},
		localOnce(event, callback, vm = this, onInactive = false) {
			if (event == null) {
				console.error(`${mixinName}(localOnce) missing event, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(localOnce) missing callback, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (this.$checkDestroyed(this, false, true)) {
				/* DEVELOPMENT ONLY - BEGIN
				console.error(
					`${mixinName}(localOnce) trying to listen on local event (${event}) in already destroyed component: `, this,
					'try using "this.$checkDestroyed(this);" on created and after first await'
				);
				/* DEVELOPMENT ONLY - END */
				return;
			}

			const inactiveChecker = getChecker(callback, vm);
			this.localEventBus.once(event, inactiveChecker, vm);
		},
		localOff(event, callback) {
			if (event == null) {
				console.error(`${mixinName}(localOff) missing event, params:`, event, callback);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(localOff) missing callback, params:`, event, callback);
				throw new Error('INVALID_PARAMS');
			}
			this.localEventBus.off(event, callback);
		},

		// Global Event Bus //
		globalEmit(event, ...args) {
			if (event == null) {
				console.error(`${mixinName}(globalEmit) missing event, params:`, event, ...args);
				throw new Error('INVALID_PARAMS');
			}
			globalEventBus.emit(event, ...args);
		},
		globalOn(event, callback, vm = this, onInactive = false) {
			if (event == null) {
				console.error(`${mixinName}(globalOn) missing event, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(globalOn) missing callback, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (this.$checkDestroyed(this, false, true)) {
				/* DEVELOPMENT ONLY - BEGIN
				console.error(
					`${mixinName}(globalOn) trying to listen on global event (${event}) in already destroyed component: `, this,
					'try using "this.$checkDestroyed(this);" on created and after first await'
				);
				/* DEVELOPMENT ONLY - END */
				return;
			}

			const inactiveChecker = getChecker(callback, vm);
			globalEventBus.on(event, inactiveChecker, vm);
			this.localEventBus.once('beforeDestroy', () => {
				globalEventBus.off(event, inactiveChecker);
			});
		},
		globalOnce(event, callback, vm = this, onInactive = false) {
			if (event == null) {
				console.error(`${mixinName}(globalOnce) missing event, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(globalOnce) missing callback, params:`, event, callback, vm, onInactive);
				throw new Error('INVALID_PARAMS');
			}
			if (this.$checkDestroyed(this, false, true)) {
				/* DEVELOPMENT ONLY - BEGIN
				console.error(
					`${mixinName}(globalOnce) trying to listen on global event (${event}) in already destroyed component: `, this,
					'try using "this.$checkDestroyed(this);" on created and after first await'
				);
				/* DEVELOPMENT ONLY - END */
				return;
			}

			const inactiveChecker = getChecker(callback, vm);
			globalEventBus.once(event, inactiveChecker, vm);
			this.localEventBus.once('beforeDestroy', () => {
				globalEventBus.off(event, inactiveChecker);
			});
		},
		globalOff(event, callback) {
			if (event == null) {
				console.error(`${mixinName}(globalOff) missing event, params:`, event, callback);
				throw new Error('INVALID_PARAMS');
			}
			if (callback == null) {
				console.error(`${mixinName}(globalOff) missing callback, params:`, event, callback);
				throw new Error('INVALID_PARAMS');
			}
			globalEventBus.off(event, callback);
		},
	},
};
