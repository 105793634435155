/*
 * __back__
 *
 * Pomocné funkce pro práci s komponentou
 *
 */

export default {
	data() {
		return {
			contentKey: {
				global: 0,
			},
			viewErrorsHandlers: {
				'view-not-found': (event) => this.onViewError('view-not-found', event),
				'view-permission-denied': (event) => this.onViewError('view-permission-denied', event),
				'view-server-error': (event) => this.onViewError('view-server-error', event),
				'view-record-deleted': (event) => this.onViewError('view-record-deleted', event),
				'view-missing-parameter': (event) => this.onViewError('view-missing-parameter', event),
				'view-wrong-parameter': (event) => this.onViewError('view-wrong-parameter', event),
			},
		};
	},
	methods: {
		// mixin: Component
		forceRerender(key) {
			if (!this.contentKey[key]) {
				this.contentKey[key] = 0;
			}
			this.contentKey[key] += 1;
		},
		// mixin: Component
		onViewError(eventType, eventData) {
			this.$emit(eventType, eventData);
		},
	},
};
