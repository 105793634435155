<template>
	<button
		v-if="enableFavouritePage && isFavouritePageEnabled() && loaded && rootTag === 'button'"
		:data-cy="`${currentComponentId}__next`"
		name="pageControls-next"
		type="button"
		v-tooltip="isUserFavourite ? $t('removeFavourite') : $t('addFavourite')"
		@click="isUserFavourite ? deleteFavouritePage() : addFavouritePage()"
	>
		<i v-if="isUserFavourite" class="icon-star yellow"></i>
		<i v-else class="icon-star"></i>
	</button>
	<component
		:is="rootTag"
		v-else-if="enableFavouritePage && isFavouritePageEnabled() && loaded"
		class="favourite-container"
	>
		<span :key="favKey" class="hover-pointer">
			<i
				v-if="isUserFavourite"
				class="icon-star yellow"
				v-tooltip="$t('removeFavourite')"
				@click.stop="deleteFavouritePage()"
			></i>
			<i
				v-else
				class="icon-star silver"
				v-tooltip="$t('addFavourite')"
				@click.stop="addFavouritePage()"
			></i>
		</span>
	</component>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import FavouritePagesModel from '../models/FavouritePages';

let favouritePages = null;
let loadingPromise = null;

export default {
	name: 'LbaFavouritePage',
	mixins: [ComponentIdentifier],
	props: {
		rootTag: {
			type: String,
			default: 'div',
		},
	},
	data() {
		return {
			loaded: false,
			isUserFavourite: false,
			favouritePageModel: null,
			favKey: this.$generateUID(),
			socket: null,
			enableFavouritePage: true,
		};
	},
	watch: {
		'$route.path'(newVal) {
			this.checkFavouriteStatus(newVal);
		},
	},
	beforeDestroy() {
		if (this.socket) {
			this.socket.unsubscribe();
		}
	},
	async created() {
		const isDashboardLoaded = (
			this.$user.loadedModules &&
			this.$user.loadedModules.includes('dashboard')
		);

		if (!isDashboardLoaded) return;

		this.favouritePageModel = new FavouritePagesModel(this.$http);
		this.$root.$listen('favourite-pages-widget.pages-updated', this.prepareFavouritePages, this);
		this.$root.$listen('favourite-pages.name-updated', this.updateFavouritePages, this);
		await this.fetch();
		this.checkFavouriteStatus(this.$route.path);

		const wsRegistrationData = {
			roleUid: this.$root.$user.role_uid,
		};
		this.socket = this.$socket.on('/lbadmin/favourite-page-changed', wsRegistrationData, this.onFavouriteChanged);

		this.loaded = true;
	},
	methods: {
		prepareFavouritePages(pages) {
			favouritePages = pages.map((page) => {
				return { route: page.route, favourite_page_uid: page.favourite_page_uid };
			});
		},
		async fetch() {
			if (!loadingPromise) {
				loadingPromise = this.favouritePageModel.query({ _all: true })
					.then((result) => {
						this.prepareFavouritePages(result.data);
						loadingPromise = null;
					})
					.catch((error) => {
						console.error(error);
					});
			}
			await loadingPromise;
		},
		async deleteFavouritePage() {
			const { favourite_page_uid } = favouritePages.find((page) => (
				page.route === this.$route.path
			));
			if (favourite_page_uid) {
				try {
					const { data } = await this.favouritePageModel.remove({ favourite_page_uid });
					this.prepareFavouritePages(data);
					this.checkFavouriteStatus(this.$route.path);
					this.$notify.success(this.$t('favouritePage.removed'));

				} catch (error) {
					console.error(error);
					this.$notify.warn(this.$t('favouritePage.removeFailed'));
				}
			}
		},
		getPageInfo() {
			const pageHeader = document.querySelector('.page-header');
			if (pageHeader) {
				const params = {};
				const labelParts = [];

				const pageTitle = pageHeader.querySelector('h1');
				if (pageTitle) {
					const pageTitleInnerText = pageTitle.innerText.trim();
					if (!$_.isEmpty(pageTitleInnerText)) {
						labelParts.push(pageTitleInnerText);
					}
				}

				const optionsControls = document.querySelector('.options-controls');
				if (optionsControls) {
					const optionsHeader = optionsControls.querySelector('h1');
					if (optionsHeader) {
						const optionsHeaderInnerText = optionsHeader.innerText.trim();
						if (!$_.isEmpty(optionsHeaderInnerText)) {
							labelParts.push(optionsHeaderInnerText);
						}
					}
				}

				const pageType = [];
				if (this.$route.meta) {
					if (this.$route.meta.definition && this.$route.meta.definition.label) {
						pageType.push(this.$route.meta.definition.label);
					}
					if (this.$route.meta.definition && this.$route.meta.definition.label_entry) {
						pageType.push(this.$route.meta.definition.label_entry);
					}

					if (this.$route.meta.icon) {
						params.page_icon = this.$route.meta.icon;
					}
				} else {
					pageType.push(this.$route.name);
				}

				if (labelParts.length === 0) {
					console.error('FavouritePage: addFavouritePage - could not get page label');
					this.$notify.warn(this.$t('favouritePage.addFailed'));
				}

				params.type = pageType.join(' - ');
				params.label = labelParts.join(' / ');
				params.route = this.$route.path;

				return params;
			}
			return null;
		},
		async addFavouritePage() {
			const params = this.getPageInfo();
			if (params) {
				try {
					const { data } = await this.favouritePageModel.add(params);
					favouritePages.push({
						route: data.route,
						favourite_page_uid: data.favourite_page_uid,
					});
					this.checkFavouriteStatus(data.route);
					this.$notify.success(this.$t('favouritePage.added'));

				} catch (error) {
					console.error(error);
					this.$notify.warn(this.$t('favouritePage.addFailed'));
				}
			}
		},
		async updateFavouritePages() {
			await this.$nextTick();
			const params = this.getPageInfo();
			if (params) {
				try {
					await this.favouritePageModel.updatePages(params);
				} catch (error) {
					console.error(error);
				}
			}
		},
		checkFavouriteStatus(newRoute) {
			this.isUserFavourite = !!(favouritePages?.find(
				(page) => (page.route === newRoute)
			));
			const routeParams = this.$route && this.$route.params;
			const isNew = $_.some(routeParams, (value, key) => (typeof value === 'string' && value.startsWith('+')));

			this.enableFavouritePage = !isNew;
		},
		async onFavouriteChanged(message) {
			if (message && ['added', 'removed'].includes(message.status)) {
				await this.fetch();
				this.checkFavouriteStatus(this.$route.path);
			}
		},
		isFavouritePageEnabled() {
			const { resolved } = this.$router.resolve(this.$root.homeRoute);
			return (
				this.$user &&
				this.$user.loaded &&
				this.$user.loadedModules &&
				this.$user.loadedModules.includes('dashboard') &&
				this.$route.path !== resolved.path
			);
		},
	},
};
</script>

<style scoped>
	.favourite-container {
		position: absolute;
		top: 10px;
		right: 45px;
	}
</style>
