import Autofocus from '../directives/Autofocus.vue';
import LbaDialogClose from '../directives/LbaDialogClose.vue';
import LbaDialogOpen from '../directives/LbaDialogOpen.vue';
import LbaExpander from '../directives/LbaExpander.vue';
import LbaLoading from '../directives/LbaLoading.vue';
import LbaMovable from '../directives/LbaMovable.vue';
import LbaRightPanelResizer from '../directives/LbaRightPanelResizer.vue';
import LbaScrollTo from '../directives/LbaScrollTo.vue';
import LbaSizeClass from '../directives/LbaSizeClass.vue';
import Popper from '../directives/Popper.vue';

export default function registerDirectives(Vue) {

	Vue.directive('autofocus', Autofocus);
	Vue.directive('lba-dialog-close', LbaDialogClose);
	Vue.directive('lba-dialog-open', LbaDialogOpen);
	Vue.directive('lba-expander', LbaExpander);
	Vue.directive('lba-loading', LbaLoading);
	Vue.directive('lba-movable', LbaMovable);
	Vue.directive('lba-right-panel-resizer', LbaRightPanelResizer);
	Vue.directive('lba-scroll-to', LbaScrollTo);
	Vue.directive('lba-size-class', LbaSizeClass);
	Vue.directive('popper', Popper);

};
