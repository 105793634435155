export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.post('lbadmin/query-roles', { params });
	}

	queryCustomers(params) {
		return this.$http.get('lbadmin/customers', { params });
	}

	get(id) {
		return this.$http.get(`lbadmin/roles/${id}`);
	}

	getRoles() {
		return this.$http.get('lbadmin/all-roles');
	}

	getProfiles() {
		return this.$http.get('lbadmin/profiles');
	}

	save(params) {
		return this.$http.post('lbadmin/roles', { params });
	}

	delete(id) {
		return this.$http.delete(`lbadmin/roles/${id}`);
	}

	bulkDelete(params) {
		return this.$http.post(`lbadmin/roles-bulk-delete`, params);
	}

}
