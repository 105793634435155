<template>
	<div id="content">
		<div class="page-header">
			<h1>{{ $t('settings.menu.users') }}</h1>
		</div>
		<div
			v-if="isLoaded"
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
		>
			<lba-grid
				v-bind:collection="users"
				:rowId="['role_uid']"
				:filterAttributes="filterAttributes"
				hideableColumns
				ref="grid"
				gridName="lbadmin.users_user"
				:dynamicHeight="false"
				sendVisibleColumnNames
			>
				<template v-slot:toolbar="props">
					<lba-toolbar-button
						:parentComponentId="props.parentComponentId"
						componentId="new"
						v-if="!readOnly"
						v-on:click="openNew"
						v-bind:label="$t('new')"
						normalColor
					></lba-toolbar-button>
				</template>
				<template v-slot:columns="props">
					<lba-grid-column
						id="user_name"
						:data-cy="`${props.parentComponentId}__userName`"
						v-bind:label="$t('settings.user.login')"
						sortable
						searchable
						filterable
						width="300"
					>
						<a @click="openDetail(props.row.role_uid)" :data-cy="`${props.parentComponentId}__userName__openDetail`">
							{{ props.row.user_name }}
						</a>
					</lba-grid-column>
					<lba-grid-column
						id="firstname"
						:data-cy="`${props.parentComponentId}__firstName`"
						:label="$t('settings.user.firstname')"
						sortable
						searchable
						filterable
						width="150"
					>
						{{ props.row.firstname }}
					</lba-grid-column>
					<lba-grid-column
						id="lastname"
						:data-cy="`${props.parentComponentId}__lastName`"
						v-bind:label="$t('settings.user.lastname')"
						sortable
						searchable
						filterable
						width="150"
					>
						{{ props.row.lastname }}
					</lba-grid-column>
					<lba-grid-column
						:removed="!showExten"
						id="extension"
						:data-cy="`${props.parentComponentId}__extension`"
						v-bind:label="$t('settings.user.extension')"
						sortable
						searchable
						filterable
						width="80"
					>
						{{ props.row.extension }}
					</lba-grid-column>
					<lba-grid-column
						id="company"
						:data-cy="`${props.parentComponentId}__company`"
						v-bind:label="$t('settings.user.company')"
						width="120"
						sortable
						searchable
						filterable
						hidden
					>
						{{ props.row.company }}
					</lba-grid-column>
					<lba-grid-column
						id="email"
						:data-cy="`${props.parentComponentId}__email`"
						v-bind:label="$t('settings.user.email')"
						sortable
						searchable
						filterable
						width="180"
						hidden
					>
						{{ props.row.email }}
					</lba-grid-column>
					<lba-grid-column
						id="profile_name"
						:data-cy="`${props.parentComponentId}__profileName`"
						v-bind:label="$t('settings.profile')"
						sortable
						searchable
						filterable
						width="200"
						:removed="servercareLoaded && $user.extraParams && !$user.extraParams.customer_super"
					>
						{{ getProfiles(props.row.profiles) }}
					</lba-grid-column>
					<lba-grid-column
						id="customer_profile_name"
						:data-cy="`${props.parentComponentId}__customerProfileName`"
						v-bind:label="$t('settings.profile')"
						searchable
						width="200"
						:removed="!servercareLoaded || !$user.extraParams || $user.extraParams.customer_super"
					>
						{{ getCustomerProfile(props.row.customers) }}
					</lba-grid-column>
					<lba-grid-column
						id="customers"
						:data-cy="`${props.parentComponentId}__customers`"
						v-bind:label="$t('settings.customers')"
						searchable
						width="200"
						:removed="
							!servercareLoaded ||
							(servercareLoaded &&
							$root.$user.extraParams &&
							!$root.$user.extraParams.customer_super)
						"
					>
						{{ getRoles(props.row.customers) }}
					</lba-grid-column>
					<lba-grid-column
						id="roles"
						:data-cy="`${props.parentComponentId}__roles`"
						v-bind:label="$t('settings.menu.groups')"
						sortable
						searchable
						filterable
						width="200"
						:removed="servercareLoaded"
					>
						{{ getRoles(props.row.roles) }}
					</lba-grid-column>
					<lba-grid-column
						id="description"
						:data-cy="`${props.parentComponentId}__description`"
						v-bind:label="$t('settings.user.description')"
						sortable
						searchable
						filterable
						width="250"
					>
						{{ props.row.description }}
					</lba-grid-column>
					<lba-grid-column
						id="lang"
						:data-cy="`${props.parentComponentId}__lang`"
						v-bind:label="$t('settings.user.lang')"
						sortable
						width="80"
						hidden
						searchable
						filterable
					>
						{{ props.row.lang }}
					</lba-grid-column>
					<lba-grid-column
						id="last_login"
						:data-cy="`${props.parentComponentId}__lastLogin`"
						v-bind:label="$t('settings.user.lastLogin')"
						sortable
						filterable
						width="150"
						hidden
					>
						<lba-datetime :date="props.row.last_login" dateFormat="short" isInGrid />
					</lba-grid-column>
					<lba-grid-column
						id="created"
						:data-cy="`${props.parentComponentId}__created`"
						v-bind:label="$t('settings.user.created')"
						sortable
						filterable
						width="150"
						hidden
					>
						<lba-datetime :date="props.row.created" dateFormat="short" isInGrid />
					</lba-grid-column>
					<lba-grid-column
						id="valid_until"
						:data-cy="`${props.parentComponentId}__validUntil`"
						v-bind:label="$t('settings.user.validUntil')"
						sortable
						filterable
						width="150"
						hidden
					>
						<lba-datetime :date="props.row.valid_until" dateFormat="short" isInGrid />
					</lba-grid-column>
					<lba-grid-column
						id="password_expiration"
						:data-cy="`${props.parentComponentId}__passwordExpiration`"
						v-bind:label="$t('settings.user.passExpiration')"
						sortable
						filterable
						width="150"
						hidden
					>
						<lba-datetime :date="props.row.password_expiration" dateFormat="short" isInGrid />
					</lba-grid-column>
					<lba-grid-column
						id="auth_method"
						:data-cy="`${props.parentComponentId}__authMethod`"
						v-bind:label="$t('settings.user.authMethod')"
						sortable
						filterable
						width="70"
						hidden
					>
						{{ getAuthMethod(props.row) }}
					</lba-grid-column>
					<lba-grid-column
						id="can_login"
						:data-cy="`${props.parentComponentId}__canLogin`"
						v-bind:label="$t('settings.user.allowLogin')"
						sortable
						filterable
						width="150"
						hidden
					>
						<span v-show="props.row.can_login">{{ $t('yes') }}</span>
						<span v-show="!props.row.can_login">{{ $t('no') }}</span>
					</lba-grid-column>
				</template>
			</lba-grid>
		</div> <!-- /.page-content -->
	</div>
</template>

<script>
import UserModel from '../models/User';
import OnScroll from '../mixins/OnScroll';

export default {
	name: 'Users',
	mixins: [
		OnScroll,
	],
	data() {
		return {
			users: [],
			rowSelectedId: parseInt(this.$route.params.id, 10),
			rowSelected: null,
			rowChecked: null,
			authMethodsList: {
				lba: 'LB Admin',
				ldap: 'LDAP',
				ad: 'Active Directory',
				pam: 'PAM',
				gAuth: 'Google Authenticator',
				agent: 'PBX Agent',
			},
			extraSettings: [],
			showExten: false,
			readOnly: false,
			isLoaded: false,
		};
	},
	computed: {
		servercareLoaded() {
			return (
				this.$user &&
				this.$user.loaded &&
				this.$user.loadedModules &&
				this.$user.loadedModules.includes('servercare')
			);
		},
		authMethodsOptions() {
			return $_.map(
				Object.keys(this.authMethodsList),
				(key) => ({ value: key, label: this.authMethodsList[key] })
			);
		},
		filterAttributes() {
			return {
				module: null,
				name: '',
				columns: [
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.login'),
						maxViewOptions: null,
						name: 'user_name',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.firstname'),
						maxViewOptions: null,
						name: 'firstname',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.lastname'),
						maxViewOptions: null,
						name: 'lastname',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.extension'),
						maxViewOptions: null,
						name: 'extension',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.company'),
						maxViewOptions: null,
						name: 'company',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.email'),
						maxViewOptions: null,
						name: 'email',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.profile'),
						maxViewOptions: null,
						name: 'profile_name',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.menu.groups'),
						maxViewOptions: null,
						name: 'roles',
						required: false,
						type: 'text',
					},
					{
						condition: 'contains',
						default: null,
						label: this.$t('settings.user.description'),
						maxViewOptions: null,
						name: 'description',
						required: false,
						type: 'text',
					},
					{
						condition: 'equal',
						default: null,
						label: this.$t('settings.user.lang'),
						maxViewOptions: null,
						name: 'lang',
						required: false,
						type: 'select',
						options: [
							{ value: 'cs', label: 'cs' },
							{ value: 'en', label: 'en' },
						],
					},
					{
						condition: 'period',
						default: null,
						label: this.$t('settings.user.lastLogin'),
						maxViewOptions: null,
						name: 'last_login',
						required: false,
						type: 'datetime',
					},
					{
						condition: 'period',
						default: null,
						label: this.$t('settings.user.created'),
						maxViewOptions: null,
						name: 'created',
						required: false,
						type: 'datetime',
					},
					{
						condition: 'period',
						default: null,
						label: this.$t('settings.user.validUntil'),
						maxViewOptions: null,
						name: 'valid_until',
						required: false,
						type: 'future-datetime',
					},
					{
						condition: 'period',
						default: null,
						label: this.$t('settings.user.passExpiration'),
						maxViewOptions: null,
						name: 'password_expiration',
						required: false,
						type: 'future-datetime',
					},
					{
						condition: 'equal',
						default: null,
						label: this.$t('settings.user.authMethod'),
						maxViewOptions: null,
						name: 'auth_method',
						required: false,
						options: this.authMethodsOptions,
						type: 'select',
					},
					{
						condition: 'equal',
						default: null,
						label: this.$t('settings.user.allowLogin'),
						maxViewOptions: null,
						name: 'can_login',
						required: false,
						options: [
							{ value: true, label: this.$t('yes') },
							{ value: false, label: this.$t('no') },
						],
						type: 'select',
					},
				],
			};
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.userModel = new UserModel(this.$http);
		this.users = new this.$Collection(this.userModel, {
			params: {
				_order: 'firstname',
				_order_dir: 'asc',
			},
		});
		this.users.attach(this, 'users');

		if (this.$root.$user.extraParams && this.$root.$user.extraParams.customer_uid && !this.$root.$user.extraParams.customer_super) {
			this.users.hiddenSearch.customer_uid = this.$root.$user.extraParams.customer_uid;
		}

		const response = await this.$http.get('lbadmin/extra-settings');
		this.extraSettings = response.data;
		this.extraSettings.map(async (el) => {
			if (el.name === 'extension') {
				this.showExten = true;
			}
		});
		this.permissionsReload();
		this.isLoaded = true;
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.user.write');
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'settings-user',
				params: {
					id,
					openNewTab: true,
				},
			});
		},
		openNew() {
			this.$routerWrap.push({
				name: 'settings-user',
				params: {
					id: '+',
					openNewTab: true,
				},
			});
		},
		getRoles(roles) {
			if (!roles || !roles.length) return '';
			const role = [];
			roles.map((el) => {
				if (el.profile_name) {
					role.push(`${el.role_name} (${el.profile_name})`);
				} else {
					role.push(`${el.role_name}`);
				}
			});
			return role.join(', ');
		},
		getAuthMethod(row) {
			if (!row) return null;
			return this.authMethodsList[row.auth_method || 'lba'];
		},
		getProfiles(profiles) {
			const pr = $_.map(profiles, (el) => { return el.profile_name; });
			return pr.join(', ');
		},
		getCustomerProfile(customers) {
			if (!this.$user.extraParams || !this.$user.extraParams.customer_uid || !customers) return '';
			const currentCustomer = $_.find(customers, { role_uid: this.$user.extraParams.customer_uid });
			let pr = [];
			if (currentCustomer) {
				pr = $_.map(currentCustomer.profiles, (el) => { return el.profile_name; });
			} else {
				$_.forEach(customers, (c) => {
					$_.forEach(c.profiles, (p) => {
						pr.push(p.profile_name);
					});
				});
			}
			return pr.join(', ');
		},
	},
};
</script>
