<template>
	<div id="content" class="text" v-if="!loading">
		<lba-grid
			ref="grid"
			hideableColumns
			gridName="lbadmin.import_records"
			:collection="collection"
			:rowId="['import_record_uid']"
			:title="$t('importRecords')"
			:filterAttributes="filterAttributes"
			:dynamicHeight="false"
			@row-selected="onRowSelected"
			sendVisibleColumnNames
		>
			<template v-slot:selected="props">
				<button
					v-if="removeRow && !removeRow.data_deleted && removeRow.bulk_delete_pg_callback"
					class="buttonTransparent"
					:data-cy="`${props.parentComponentId}__removeImportedData`"
					@click="openRemoveDialog"
				>
					{{ $t('removeImportedData') }}
				</button>
			</template>
			<template v-slot:columns="props">
				<lba-grid-column
					id="file_name"
					:data-cy="`${props.parentComponentId}__fileName`"
					:label="$t('fileName')"
					searchable
					width="260"
				>
					<a @click="openDetail(props.row.import_record_uid)" :data-cy="`${props.parentComponentId}__fileName__openDetail`">
						{{ getOriginalFileName(props.row.file_name) }}
					</a>
				</lba-grid-column>
				<lba-grid-column
					id="created"
					:data-cy="`${props.parentComponentId}__created`"
					:label="$t('created')"
					width="150"
				>
					<lba-datetime :date="props.row.created" dateFormat="fullDigitsMedium" isInGrid />
				</lba-grid-column>
				<lba-grid-column
					id="created_by"
					:data-cy="`${props.parentComponentId}__createdBy`"
					:label="$t('createdByUser')"
					width="150"
				>
					{{ getName(props.row) }}
				</lba-grid-column>
				<lba-grid-column
					id="title"
					:data-cy="`${props.parentComponentId}__title`"
					:label="$t('importedTo')"
					width="150"
				>
					{{ $getLocale(props.row.title) }}
				</lba-grid-column>
				<lba-grid-column
					id="row_count"
					:data-cy="`${props.parentComponentId}__rowCount`"
					:label="$t('rowCount')"
					width="150"
					style="text-align: right"
					:headerStyle="'text-align: right'"
				>
					{{ props.row.row_count }}
				</lba-grid-column>
				<lba-grid-column
					id="deleted"
					:data-cy="`${props.parentComponentId}__deleted`"
					:label="$t('form.removed').replace('.', '')"
					width="150"
					style="text-align: center"
					:headerStyle="'text-align: center'"
				>
					<i class="icon-ok green" v-if="props.row.data_deleted"></i>
				</lba-grid-column>
				<lba-grid-column
					id="actions"
					:data-cy="`${props.parentComponentId}__actions`"
					:label="$t('actions')"
					width="60"
					class="actions"
					style="justify-content: right"
				>
					<a
						style="flex: 1"
						:data-cy="`${props.parentComponentId}__actions__showInCard`"
						@click.stop="openDetail(props.row.import_record_uid)"
					>
						<i class="icon-new-tab" v-tooltip="$t('showInCard')"></i>
					</a>
					<a
						v-if="(
							$permissions.checkPermission('lbadmin.import-records.write') &&
							!props.row.data_deleted &&
							props.row.bulk_delete_pg_callback
						)"
						:data-cy="`${props.parentComponentId}__actions__openDialogRemoveEntry`"
						@click.stop="openRemoveDialog(props.row)"
					>
						<i class="icon-delete" v-tooltip="$t('removeImportedData')"></i>
					</a>
				</lba-grid-column>
			</template>
		</lba-grid>
		<lba-dialog-modal
			parentComponentId="importRecords"
			componentId="removeEntry"
			name="removeEntry"
			:title="$t('removeImportedData')"
			@close="removeRow = null"
		>
			<template #default="props">
				<div class="popup-body">
					<p>{{ $t('removeImportedDataInfo') }}</p>
				</div>
				<div class="popup-footer">
					<button class="buttonRed" type="button" @click="confirmRemove"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						{{ $t('remove') }}
					</button>
					<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
	</div>
</template>

<script>
import Model from '../models/ImportRecords';

export default {
	data() {
		return {
			loading: true,
			model: null,
			collection: null,
			filterAttributes: {},
			removeRow: null,
			removeSelected: null,
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.model = new Model(this.$http);
		this.collection = new this.$Collection(this.model);
		this.collection.attach(this, 'collection');
		this.loading = false;
	},
	methods: {
		getOriginalFileName(fileName) {
			if (!fileName) return '';
			return fileName.split('-').filter((item, index) => index > 0).join('-');
		},
		getName(row) {
			if (row.firstname && row.lastname) {
				return `${row.firstname} ${row.lastname}`;
			}
			if (row.firstname) {
				return row.firstname;
			}
			if (row.lastname) {
				return row.lastname;
			}
			return row.user_name;
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'import-record',
				params: { id, openNewTab: true },
			});
		},
		openRemoveDialog(row) {
			this.removeRow = row;
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		onRowSelected(row) {
			this.removeRow = row;
		},
		async confirmRemove() {
			if (this.removeRow) {
				await this.model.removeData(this.removeRow.import_record_uid);
				this.$notify.success(this.$t('form.removed'));
				this.$refs.grid.reload(this.$refs.grid.id);
			}
			this.removeRow = null;
			this.$root.$emit('dialog-close', 'removeEntry');
		},
	},
};
</script>
