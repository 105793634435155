export default {
	created() {
		this.$root.$listen('breadcrumb-set', this.setBreadcrumb, this);
	},
	methods: {
		setBreadcrumb(breadcrumbInfo) {
			const remainingIndex = this.activeTab.remainingBreadcrumbs.findIndex(
				(remaining) => remaining === breadcrumbInfo.name
			);
			const breadcrumb = this.activeTab.breadcrumbs.find(
				(active) => active.name === breadcrumbInfo.name
			);

			if (breadcrumb) {
				$_.assignIn(breadcrumb, breadcrumbInfo);

				if (breadcrumb.keepLast) {
					const cachedBreadcrumb = this.getBreadcrumb(
						this.$route.meta.cachedBreadcrumbs, breadcrumb.name
					);

					if (cachedBreadcrumb) {
						cachedBreadcrumb.translated = breadcrumb.translated;
					}
				}

				// if from remaining, remove it
				if (remainingIndex >= 0) {
					breadcrumb.initDone = true;
					this.activeTab.remainingBreadcrumbs.splice(remainingIndex, 1);
				}

				this.$root.$emit('breadcrumb-update');
			}
		},
		getTranslation(key) {
			if (this.$te(key)) {
				return this.$t(key);
			}
			return key;
		},
		translate(key) {
			if (key) {
				if (key.constructor === String) {
					return this.getTranslation(key);
				}

				if (key.constructor === Array) {
					const translated = [];
					key.forEach((keyPart) => {
						translated.push(this.getTranslation(keyPart));
					});
					return translated.join(' - ');
				}
			}
			return null;
		},
		breadcrumbExists(breadcrumbs, name) {
			return breadcrumbs.find((breadcrumb) => breadcrumb.name === name) != null;
		},
		getBreadcrumb(breadcrumbs, name) {
			return breadcrumbs.find((breadcrumb) => breadcrumb.name === name);
		},
		getRouteParamsNames(name) {
			const fakeRoute = this.$router.resolve({ name }).route;
			const lastMatch = fakeRoute.matched[fakeRoute.matched.length - 1];
			if ($_.isEmpty(lastMatch)) return [];
			const pathResult = lastMatch.path.match(lastMatch.regex);
			const params = pathResult.splice(1);

			for (let i = 0; i < params.length; i += 1) {
				params[i] = params[i].replace(':', '');
			}

			return params;
		},
		setupActiveTabBreadcrumbs() {
			let breadcrumbs = [];

			// join all breadcrumbs from each matched route
			this.$route.matched.forEach((match) => {
				if (match.meta.breadcrumb) {
					breadcrumbs = breadcrumbs.concat(match.meta.breadcrumb());
				}
			});

			// not assingning directly, so we can preserve params etc.
			// check if breadcrumb exist in this.activeTab.breadcrumbs, if not add it
			breadcrumbs.forEach((breadcrumb) => {
				if (!this.breadcrumbExists(this.activeTab.breadcrumbs, breadcrumb.name)) {
					this.activeTab.breadcrumbs.push(breadcrumb);
				}
			});

			// check if activeTab breadcrumbs contains some children routes
			// use case when goin from child route to parent
			for (let i = this.activeTab.breadcrumbs.length - 1; i > -1; i -= 1) {
				const activeBreadcrumb = this.activeTab.breadcrumbs[i];

				if (!this.breadcrumbExists(breadcrumbs, activeBreadcrumb.name)) {
					this.activeTab.breadcrumbs.splice(i, 1);
				}
			}
		},
		initBreadcrumbs() {
			if (!this.activeTab) return;

			this.setupActiveTabBreadcrumbs();
			const { breadcrumbs } = this.activeTab;

			for (let i = 0; i < breadcrumbs.length; i += 1) {
				const breadcrumb = breadcrumbs[i];
				const paramsNames = this.getRouteParamsNames(breadcrumb.name);
				breadcrumb.translated = this.translate(breadcrumb.text);

				if (paramsNames.length > 0) {
					breadcrumb.params = {};
				}

				if (this.breadcrumbExists(this.$route.matched, breadcrumb.name)) {
					paramsNames.forEach((paramName) => {
						breadcrumb.params[paramName] = this.$route.params[paramName];
					});
				} else if (paramsNames.length > 0) {
					const remainingExists = this.activeTab.remainingBreadcrumbs.find(
						(remaining) => remaining === breadcrumb.name
					);

					if (!remainingExists) {
						breadcrumb.initDone = false;
						this.activeTab.remainingBreadcrumbs.push(breadcrumb.name);
					}
				}

				if (breadcrumb.keepLast) {
					if (!this.$route.meta.cachedBreadcrumbs) {
						this.$route.meta.cachedBreadcrumbs = [];
					}

					const cachedBreadcrumb = this.getBreadcrumb(
						this.$route.meta.cachedBreadcrumbs, breadcrumb.name
					);

					if (cachedBreadcrumb) {
						breadcrumb.translated = cachedBreadcrumb.translated;
					} else {
						this.$route.meta.cachedBreadcrumbs.push({
							name: breadcrumb.name,
							translated: breadcrumb.translated,
						});
					}
				}
			}
		},
	},
};
