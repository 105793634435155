export default class {
	constructor($http) {
		this.$http = $http;
	}

	getOptions() {
		return this.$http.get(`lbadmin/options`);
	}

	setOptions(params) {
		const config = { headers: { 'Content-Type': `multipart/form-data` } };
		return this.$http.post(`lbadmin/options`, params, config);
	}

	getAuth() {
		return this.$http.get(`lbadmin/options/auth-methods`);
	}

}
