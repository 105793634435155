<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<span v-for="(option, index) in options.options"
			:key="index"
			style="display: inline-block; margin-right: 10px;"
		>
			<span
				v-if="option.tooltip"
				v-tooltip="option.tooltip"
			>{{ option.label }}:&nbsp;</span>
			<template v-else>
				{{ option.label }}:&nbsp;
			</template>
			<i v-if="option.checked" class="icon-ok" :data-cy="`${currentComponentId}__${option.value}__checked`"></i>
			<i v-else class="icon-cancel" :data-cy="`${currentComponentId}__${option.value}__notChecked`"></i>
		</span>
	</component>
	<s
		v-else-if="options.options.length > 0"
		:class="[options.class]"
		:style="style"
		class="wide showInvalidTooltip"
		:data-cy="currentComponentId"
	>
		<small v-if="label">
			{{ label }}
			<span v-if="rules.required" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<div
			:data-cy="`${currentComponentId}__radio`"
			class="showInvalidTooltip"
			:class="{
						'lba-invalid': (showRequirement),
						'showWarning': (showRecommendation)
					}"
		>
			<span v-for="(option, okey) in options.options"
				:key="okey"
				style="display: inline-block; margin-right: 10px;"
			>
				<fake-provider :name="`${$getLocale(options.label)} - ${option.label || option.value}`" :rules="validationRules"
					:data-required-recommended="requiredRecommended"
				>
					<label
						v-if="!readOnly || option.value === data[key]"
						:data-cy="`${currentComponentId}__${option.value}__label`"
						class="radio"
					>
						<input
							:data-cy="`${currentComponentId}__${option.value}__input`"
							:value="option.value"
							v-model="data[key]"
							type="radio"
							:name="attribute.name"
							:title="$getLocale(option.label || '')"
							:disabled="readOnly"
							@input="onEvent('input')"
							@change="onEvent('change')"
						>
						<span class="checkmark"></span>
						<span
							v-if="option.tooltip"
							v-tooltip="$getLocale(option.tooltip)"
						>{{ $getLocale(option.label) }}</span>
						<span
							v-else
							class="label"
						>{{ $getLocale(option.label) }}</span>
					</label>
				</fake-provider>
			</span>
			<span v-if="rules.required && !options.label" class="required-sign"></span>
		</div>
		<template v-if="dirty">
			<span v-if="rules.required" class="lba-messages" :data-cy="`${currentComponentId}__error`">
				{{ $t('validation.schemaRequired', { attribute: options.label }) }}
			</span>
			<span v-if="showRecommendation" class="lba-messages warning" :data-cy="`${currentComponentId}__warning`">
				{{ $t('validation.requiredRecommended', { attribute: options.label }) }}
			</span>
		</template>
	</s>
	<span v-else-if="options.options.length === 0">
		{{ $t('crm.noEntries') }}
	</span>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputRadio',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	computed: {
		showRecommendation() {
			return (
				this.dirty &&
				this.requiredRecommended &&
				this.attribute.options.options &&
				this.showRecommended &&
				!this.hasValue()
			);
		},
		showRequirement() {
			return (
				this.dirty &&
				this.rules.required &&
				this.attribute.options.options &&
				!this.hasValue()
			);
		},
		validationRules() {
			let rules = {};
			if (this.rules.required) {
				rules = { requiredMulticheckbox: true };
				rules.requiredMulticheckbox = { checkboxes: this.attribute.options.options };
			}
			return rules;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		hasValue() {
			return $_.some(this.attribute.options.options, (option) => option.value === this.data[this.key]);
		},
	},
};
</script>
