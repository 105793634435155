<template>
	<div class="inputTimeRange">
		<span style="color: #666; margin-right: 10px;">
			{{ $t('timeRange') }}:
		</span>
		<span v-lba-expander class="timeRangeLink" ref="expander" :data-cy="`${currentComponentId}__timeRangeOptionsExpander`">
			<i class="icon-clock"></i>
			{{ activeRangeName }}
		</span>
		<div class="timeRangeOptions expand timeRangeBorder" :data-cy="`${currentComponentId}__timeRangeOptions`">
			<div :class="{ timeRangeWrap: !narrow, timeRangeWrapBlock: narrow }">
				<div class="timeRangeCol">
					<h4>{{ $t('customRange') }}</h4>
					<div class="form">
						<s>
							<small>{{ $t('from') }}</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="dateTimeFrom"
								v-model="internalDateTimeFrom"
							></lba-datepicker>
						</s>
						<br>
						<s>
							<small>{{ $t('to') }}</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="dateTimeTo"
								v-model="internalDateTimeTo"
							></lba-datepicker>
						</s>
						<br>
						<span class="errorMessage" :data-cy="`${currentComponentId}__errorMessage`">
							{{ errorMessage }}
						</span>
						<br>
						<br>
						<button :disabled="!correctRange" type="button" class="buttonSmall" @click="setRange()"
							:data-cy="`${currentComponentId}__apply`"
						>
							{{ $t('apply') }}
						</button>
						<button type="button" class="buttonSmall buttonInverse" style="margin-left: 10px;" @click="hideExpander()"
							:data-cy="`${currentComponentId}__close`"
						>
							{{ $t('close') }}
						</button>
					</div>
				</div>
				<div class="timeRangeCol" :style="quickRangeStyle">
					<h4>{{ $t('quickRanges') }}</h4>
					<div class="timeRangeWrap">
						<ul>
							<li
								:data-cy="`${currentComponentId}__quickRanges__lastHour`"
								@click="setInternalRange('lastHour')"
								:class="{ active: internalRangeName === 'lastHour' }"
							>
								{{ $t('lastHour') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last6Hours`"
								@click="setInternalRange('last6Hours')"
								:class="{ active: internalRangeName === 'last6Hours' }"
							>
								{{ $t('last6Hours') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last12Hours`"
								@click="setInternalRange('last12Hours')"
								:class="{ active: internalRangeName === 'last12Hours' }"
							>
								{{ $t('last12Hours') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last24Hours`"
								@click="setInternalRange('last24Hours')"
								:class="{ active: internalRangeName === 'last24Hours' }"
							>
								{{ $t('last24Hours') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last2Days`"
								@click="setInternalRange('last2Days')"
								:class="{ active: internalRangeName === 'last2Days' }"
							>
								{{ $t('last2Days') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last7Days`"
								@click="setInternalRange('last7Days')"
								:class="{ active: internalRangeName === 'last7Days' }"
							>
								{{ $t('last7Days') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__last30Days`"
								@click="setInternalRange('last30Days')"
								:class="{ active: internalRangeName === 'last30Days' }"
							>
								{{ $t('last30Days') }}
							</li>
						</ul>
						<ul>
							<li
								:data-cy="`${currentComponentId}__quickRanges__today`"
								@click="setInternalRange('today')"
								:class="{ active: internalRangeName === 'today' }"
							>
								{{ $t('today') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__thisWeek`"
								@click="setInternalRange('thisWeek')"
								:class="{ active: internalRangeName === 'thisWeek' }"
							>
								{{ $t('thisWeek') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__thisMonth`"
								@click="setInternalRange('thisMonth')"
								:class="{ active: internalRangeName === 'thisMonth' }"
							>
								{{ $t('thisMonth') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__yesterday`"
								@click="setInternalRange('yesterday')"
								:class="{ active: internalRangeName === 'yesterday' }"
							>
								{{ $t('yesterday') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__dayBeforeYesterday`"
								@click="setInternalRange('dayBeforeYesterday')"
								:class="{ active: internalRangeName === 'dayBeforeYesterday' }"
							>
								{{ $t('dayBeforeYesterday') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__lastWeek`"
								@click="setInternalRange('lastWeek')"
								:class="{ active: internalRangeName === 'lastWeek' }"
							>
								{{ $t('lastWeek') }}
							</li>
							<li
								:data-cy="`${currentComponentId}__quickRanges__lastMonth`"
								@click="setInternalRange('lastMonth')"
								:class="{ active: internalRangeName === 'lastMonth' }"
							>
								{{ $t('lastMonth') }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="timeRangeLink" @click="refresh()" style="margin-left: 10px;" :data-cy="`${currentComponentId}__reload`">
			<i class="icon-reload"></i>
			<span>{{ $t('reload') }}</span>
		</div>
	</div>
</template>

<style>
.timeRangeLink {
	cursor: pointer;
	color: #0077db;
	display: inline-block;
	margin-left: 5px;
	text-transform: uppercase;
	font-size: 11px;
}
.timeRangeLink:hover {
	color: #0060b1;
}
.timeRangeOptions {
	position: absolute;
	z-index: 999;
	/* right: 0px; */
	top: 30px;
	padding: 10px;
	background-color: #f7f7f7;
}
.timeRangeOptions:before {
	content: "";
	border-bottom: 12px solid #f7f7f7;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	position: absolute;
	top: -12px;
	right: 105px;
	z-index: 999;
}
.timeRangeOptions .timeRangeWrap {
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.timeRangeOptions .timeRangeWrapBlock {
	display: block;
	flex-wrap: nowrap;
}
.timeRangeOptions .timeRangeCol {
	padding: 0 10px;
}
.timeRangeOptions small {
	color: #999;
	text-transform: none;
	text-align: left;
}
.timeRangeOptions .timeRangeCol ul {
	list-style-type: none;
	color: #444;
	min-width: 130px;
	padding: 0;
}
.timeRangeOptions .timeRangeCol ul li:hover {
	color: #0077db;
	text-decoration: underline;
	cursor: pointer;
}
.timeRangeBorder {
	border: 1px solid #dedede;
}
.timeRangeBorder:before {
	border-bottom: 12px solid #dedede !important;
}
.inputTimeRange {
	font-size: .87em;
	position: relative;
}
.inputTimeRange .icon-clock,
.inputTimeRange .icon-reload {
	font-size: .8em;
	position: relative;
	top: 3px;
	margin-right: 5px;
}

</style>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		dateTimeFrom: [String, Number, Date],
		dateTimeTo: [String, Number, Date],
		applyQuickRanges: Boolean,
		closeOnQuickRanges: Boolean,
		closeOnApply: Boolean,
		defaultFrom: [String, Number, Date],
		defaultTo: [String, Number, Date],
		defaultRangeName: String,
		narrow: Boolean,
		rangeMinLimit: {
			type: Number,
			// 5 minutes
			default: 5 * 60 * 1000,
		},
		rangeMaxLimit: {
			type: Number,
			// 90 days
			default: 90 * 24 * 60 * 60 * 1000,
		},
	},
	data() {
		return {
			internalDateTimeFrom: null,
			internalDateTimeTo: null,
			internalRangeName: null,

			quickRangeStyle: null,
			quickDateTimeFrom: null,
			quickDateTimeTo: null,

			activeRangeName: null,
			correctRange: true,
			errorMessage: null,
		};
	},
	watch: {
		internalDateTimeFrom() {
			this.checkRange();
		},
		internalDateTimeTo() {
			this.checkRange();
		},
	},
	created() {
		this.$root.$listen('lba-input-time-range.refresh', () => {
			this.$forceUpdate();
		}, this);

		if (this.defaultFrom) {
			this.internalDateTimeFrom = this.dateExtension(this.defaultFrom);
		} else {
			this.internalDateTimeFrom = this.dateExtension(new Date()).subtract(1, 'hours');
		}
		if (this.defaultTo) {
			this.internalDateTimeTo = this.dateExtension(this.defaultTo);
		} else {
			this.internalDateTimeTo = this.dateExtension(new Date());
		}
		if (this.defaultFrom || this.defaultTo) {
			this.internalRangeName = 'custom';
			this.resolveRangeName();
		} else {
			this.internalRangeName = 'lastHour';
		}
		if (this.defaultRangeName) {
			this.internalRangeName = this.defaultRangeName;
		}

		this.quickRangeStyle = (!this.narrow) ? 'margin-left: 20px;' : '';

		this.quickDateTimeFrom = this.internalDateTimeFrom;
		this.quickDateTimeTo = this.internalDateTimeTo;

		this.changeActiveRangeName();

		if (!this.dateTimeFrom) {
			if (this.defaultRangeName) {
				this.setInternalRange(this.defaultRangeName);
			}
			this.setRange();
		}
	},
	methods: {
		resolveRangeName() {
			if (Math.abs(this.internalDateTimeTo.getTime() - (new Date()).getTime()) < 60000) {
				const diff_hour = Math.round(
					(this.internalDateTimeTo.getTime() - this.internalDateTimeFrom.getTime()) / 1000 / 60 / 6
				) / 10;
				const diff_days = Math.round(diff_hour / 2.4) / 10;
				switch (diff_hour) {
				case 1:
					this.internalRangeName = 'lastHour';
					break;
				case 6:
					this.internalRangeName = 'last6Hours';
					break;
				case 12:
					this.internalRangeName = 'last12Hours';
					break;
				case 24:
					this.internalRangeName = 'last24Hours';
					break;
				}
				switch (diff_days) {
				case 2:
					this.internalRangeName = 'last2Days';
					break;
				case 7:
					this.internalRangeName = 'last7Days';
					break;
				case 30:
					this.internalRangeName = 'last30Days';
					break;
				}
			}
		},
		setInternalRange(rangeName) {
			const from = this.dateExtension(new Date());
			const to = this.dateExtension(new Date());

			from.set(0, 'milliseconds')
				.set(0, 'seconds');
			to.set(0, 'milliseconds')
				.set(0, 'seconds');

			if (rangeName === 'today') {
				from.startOf('days');
				to.endOf('days');
			} else if (rangeName === 'thisWeek') {
				from.startOf('weeks');
				to.endOf('weeks');
			} else if (rangeName === 'thisMonth') {
				from.startOf('months');
				to.endOf('months');
			} else if (rangeName === 'yesterday') {
				from.subtract(1, 'days')
					.startOf('days');
				to.subtract(1, 'days')
					.endOf('days');
			} else if (rangeName === 'dayBeforeYesterday') {
				from.subtract(2, 'days')
					.startOf('days');
				to.subtract(2, 'days')
					.endOf('days');
			} else if (rangeName === 'lastWeek') {
				from.subtract(7, 'days')
					.startOf('weeks');
				to.subtract(7, 'days')
					.endOf('weeks');
			} else if (rangeName === 'lastMonth') {
				from.subtract(1, 'months')
					.startOf('months');
				to.subtract(1, 'months')
					.endOf('months');
			} else if (rangeName === 'lastHour') {
				from.subtract(1, 'hours');
			} else if (rangeName === 'last6Hours') {
				from.subtract(6, 'hours');
			} else if (rangeName === 'last12Hours') {
				from.subtract(12, 'hours');
			} else if (rangeName === 'last24Hours') {
				from.subtract(24, 'hours');
			} else if (rangeName === 'last2Days') {
				from.subtract(2, 'days');
			} else if (rangeName === 'last7Days') {
				from.subtract(7, 'days');
			} else if (rangeName === 'last30Days') {
				from.subtract(30, 'days');
			}

			this.quickDateTimeFrom = from;
			this.quickDateTimeTo = to;
			this.internalDateTimeFrom = from;
			this.internalDateTimeTo = to;
			this.internalRangeName = rangeName;

			if (this.applyQuickRanges) {
				this.setRange(this.closeOnQuickRanges);
			}
		},
		hideExpander() {
			// const element = iElement[0].querySelector('.timeRangeLink');
			const element = this.$refs.expander;
			if (element && element.classList && element.classList.contains('expanded')) {
				element.classList.remove('expanded');
			}
		},
		changeActiveRangeName() {
			const key = `${this.internalRangeName}`;
			let name = this.$t(key);
			if (key === name) {
				name = this.$t('custom');
			}
			this.activeRangeName = name;
		},
		checkRange() {
			const fromUnixMs = this.internalDateTimeFrom.getTime();
			const toUnixMs = this.internalDateTimeTo.getTime();
			if (fromUnixMs === toUnixMs) {
				this.correctRange = false;
				this.errorMessage = this.$t('rangeSameValue');
			} else if (toUnixMs < fromUnixMs) {
				this.correctRange = false;
				this.errorMessage = this.$t('rangeLowerThan');
			} else if (toUnixMs - fromUnixMs < this.rangeMinLimit) {
				this.correctRange = false;
				this.errorMessage = this.$t('rangeMinLimit');
			// } else if (toUnixMs - fromUnixMs > this.rangeMaxLimit) {
			// 	this.correctRange = false;
			// 	this.errorMessage = this.$t('rangeMaxLimit');
			} else {
				this.correctRange = true;
				this.errorMessage = '';
			}
		},
		setRange(hide) {
			if (this.quickDateTimeFrom.getTime() !== this.internalDateTimeFrom.getTime() ||
				(Math.abs(this.quickDateTimeTo.getTime() - this.internalDateTimeTo.getTime()) > 60000)
			) { // fix for rounding seconds to zero
				this.internalRangeName = 'custom';
				this.resolveRangeName();
			}
			this.changeActiveRangeName();

			if (this.internalDateTimeTo.getTime() <= this.internalDateTimeFrom.getTime()) {
				this.correctRange = false;
				return;
			}
			this.correctRange = true;

			this.$emit('update:dateTimeFrom', this.internalDateTimeFrom);
			this.$emit('update:dateTimeTo', this.internalDateTimeTo);
			this.$emit('change', { dateTimeFrom: this.internalDateTimeFrom, dateTimeTo: this.internalDateTimeTo });

			if (this.closeOnApply || hide) {
				this.hideExpander();
			}
		},
		refresh() {
			if (this.internalRangeName === 'custom') {
				this.setRange(true);
				this.resolveRangeName();
			} else {
				this.setInternalRange(this.internalRangeName);
			}
		},
		dateExtension(dateObject) {
			function dateFunctions(add) {
				return (count, what) => {
					count = (add) ? add * count : count;
					switch (what.replace(/s$/, '')) {
					case 'year':
						dateObject.setFullYear((add) ? dateObject.getFullYear() + count : count);
						break;
					case 'month':
						dateObject.setMonth((add) ? dateObject.getMonth() + count : count);
						break;
					case 'week':
						return $_(count * 7, 'days');
					case 'day':
						dateObject.setDate((add) ? dateObject.getDate() + count : count);
						break;
					case 'hour':
						dateObject.setHours((add) ? dateObject.getHours() + count : count);
						break;
					case 'minute':
						dateObject.setMinutes((add) ? dateObject.getMinutes() + count : count);
						break;
					case 'second':
						dateObject.setSeconds((add) ? dateObject.getSeconds() + count : count);
						break;
					case 'millisecond':
						dateObject.setMilliseconds((add) ? dateObject.getMilliseconds() + count : count);
						break;
					default:
						break;
					}
					return dateObject;
				};
			}

			function startOf(what) {
				what = what.replace(/s$/, '');
				dateObject.set(0, 'milliseconds');
				if (what === 'second') return dateObject;
				dateObject.set(0, 'seconds');
				if (what === 'minute') return dateObject;
				dateObject.set(0, 'minutes');
				if (what === 'hour') return dateObject;
				dateObject.set(0, 'hours');
				if (what === 'week') {
					const day = dateObject.getDay();
					dateObject.subtract((day === 0 ? 6 : day - 1), 'days');
					return dateObject;
				}
				if (what === 'day') return dateObject;
				dateObject.set(1, 'days');
				if (what === 'month') return dateObject;
				dateObject.set(0, 'month');
				return dateObject;
			}

			function endOf(what) {
				what = what.replace(/s$/, '');
				dateObject.set(999, 'milliseconds');
				if (what === 'second') return dateObject;
				dateObject.set(59, 'seconds');
				if (what === 'minute') return dateObject;
				dateObject.set(59, 'minutes');
				if (what === 'hour') return dateObject;
				dateObject.set(23, 'hours');
				if (what === 'week') {
					const day = dateObject.getDay();
					dateObject.add((day === 0 ? 1 : 7 - day), 'days');
					return dateObject;
				}
				if (what === 'day') return dateObject;
				dateObject.add(1, 'months')
					.set(1, 'days')
					.subtract(1, 'days');
				if (what === 'month') return dateObject;
				dateObject.set(12, 'months');
				return dateObject;
			}

			dateObject.add = dateFunctions(1);
			dateObject.subtract = dateFunctions(-1);
			dateObject.set = dateFunctions(null);
			dateObject.startOf = startOf;
			dateObject.endOf = endOf;
			return dateObject;
		},
	},
};
</script>
