<template>
	<ValidationProvider
		v-if="isAnyRuleActive"
		ref="provider"
		:name="name"
		:vid="vid"
		:data-required-recommended="requiredRecommended"
		:rules="rules"
		:customMessages="customMessages"
		:immediate="immediate"
		v-slot="slotProps"
	>
		<slot v-bind="slotProps"></slot>
	</ValidationProvider>
	<span v-else>
		<slot v-bind="{ invalid: false, errors: [] }"></slot>
	</span>
</template>

<script>
export default {
	name: 'FakeProvider',
	props: {
		rules: Object,
		customMessages: Object,
		name: String,
		vid: String,
		requiredRecommended: Boolean,
		immediate: Boolean,
	},
	computed: {
		isAnyRuleActive() {
			return !$_.isEmpty(this.rules) && $_.some(this.rules, (value, key) => value != null && value !== false);
		},
	},
};
</script>
