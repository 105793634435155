<template>
	<div
		ref="slotList"
		:style="`min-height: ${minHeight}`"
		:class="{ 'inline-list': inlineList }"
		:data-cy="currentComponentId"
		@dragenter="slotListDragEnter"
		@dragover.prevent
	>
		<slot name="before" :parentComponentId="`${currentComponentId}__slotBefore`"></slot>
		<slot name="list" :list="list" :parentComponentId="`${currentComponentId}__slotList`"></slot>
		<slot name="after" :parentComponentId="`${currentComponentId}__slotAfter`"></slot>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

function move(list, item, from, to) {
	if (to < 0 || to >= list.length || from < 0 || from >= list.length) {
		return;
	}

	list.splice(from, 1);
	list.splice(to, 0, item);
}

function addClassesCSS(element, classesCSS) {
	if (typeof (classesCSS) === 'string') {
		element.classList.add(classesCSS);
	} else {
		classesCSS.forEach((classCSS) => {
			element.classList.add(classCSS);
		});
	}
}

function removeClassesCSS(element, classesCSS) {
	if (typeof (classesCSS) === 'string') {
		element.classList.remove(classesCSS);
	} else {
		classesCSS.forEach((classCSS) => {
			element.classList.remove(classCSS);
		});
	}
}

class ItemMoveHandler {
	constructor() {
		this.component = null;
		this.group = null;
		this.list = null;
		this.item = null;
		this.index = null;
		this.element = null;
		this.classCSS = null;
		this.staticItems = null;
		this.staticOrder = null;
		this.dragEnteredItem = false;
	}

	setDragInfo(component, group, list, item, index, element, classCSS, staticItems, staticOrder) {
		this.component = component;
		this.group = group;
		this.list = list;
		this.item = item;
		this.index = index;
		this.element = element;
		this.classCSS = classCSS;
		this.staticItems = staticItems;
		this.staticOrder = staticOrder;
	}

	itemEnteredSameList(component) {
		return component === this.component;
	}

	itemEnteredSameGroup(group) {
		return this.group === group && group != null && this.group != null;
	}

	isDescendant(targetElement) {
		let target = targetElement;

		while (target && target !== document.body) {
			if (target === this.element) {
				return true;
			}

			target = target.parentElement;
		}

		return false;
	}

	moveItem(index, element) {
		this.dragEnteredItem = true;

		if (this.staticOrder) {
			return;
		}

		move(this.list, this.item, this.index, index);
		this.index = index;
		removeClassesCSS(this.element, this.classCSS);
		this.element = element;
		addClassesCSS(this.element, this.classCSS);
		this.component.$emit('update:list', this.list);
		this.component.$emit('change', { order: this.item });
	}

	moveItemBetweenLists(
		component, group, list, index, element, classCSS, staticItems, staticOrder, beforeSlotsLength
	) {
		let { item } = this;
		let newIndex = index;
		let newElement = element;
		let changeOccured1 = false;
		let changeOccured2 = false;
		const tempList = this.list;
		const tempComponent = this.component;

		if (!this.staticItems) {
			this.list.splice(this.index, 1);
			changeOccured1 = true;
		}

		if (!this.staticItems || !staticItems) {
			removeClassesCSS(this.element, this.classCSS);
		}

		if (!staticItems) {
			if (staticOrder) {
				newIndex = list.length;
			}

			list.splice(newIndex, 0, this.item);

			if (newElement) {
				addClassesCSS(newElement, this.classCSS);
			}
			changeOccured2 = true;
		} else {
			// highlight item in static list
			const staticIndex = list.indexOf(this.item);
			newIndex = null;

			if (staticIndex >= 0) {
				newIndex = staticIndex;
				item = list[newIndex];
				newElement = component.$refs.slotList.children[newIndex + beforeSlotsLength];
				addClassesCSS(newElement, this.classCSS);
			}
		}

		this.setDragInfo(
			component, group, list, item, newIndex, newElement, classCSS, staticItems, staticOrder
		);

		if (changeOccured1) {
			tempComponent.$emit('update:list', tempList);
			tempComponent.$emit('change', { removed: item });
		}
		if (changeOccured2) {
			this.component.$emit('update:list', this.list);
			this.component.$emit('change', { added: item });
		}
	}

	resetIndex(beforeSlotsLength) {
		// reset called because of dragging, not by bulk moving
		if (this.item) {
			if (this.element) {
				removeClassesCSS(this.element, this.classCSS);
			}

			this.index = this.list.indexOf(this.item);
			this.element = this.component.$refs.slotList.children[this.index + beforeSlotsLength];
			this.element.classList.add(this.classCSS);
		}
	}

	reset() {
		removeClassesCSS(this.element, this.classCSS);
		this.setDragInfo(null, null, null, null, null, null, null, null);
	}
}

const itemMoveHandler = new ItemMoveHandler();

export default {
	mixins: [ComponentIdentifier],
	props: {
		list: {
			type: Array,
			required: true,
		},
		staticListItems: {
			type: Boolean,
			required: false,
			default: false,
		},
		staticListOrder: {
			type: Boolean,
			required: false,
			default: false,
		},
		group: {
			type: String,
			required: false,
		},
		minHeight: {
			type: String,
			default: '20px',
		},
		draggedItemClass: {
			type: [String, Array],
			required: false,
			default: 'lba-dragged',
		},
		checkedList: {
			type: Array,
			required: false,
		},
		notDraggable: {
			type: Boolean,
			default: false,
		},
		inlineList: {
			type: Boolean,
			default: false,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			observer: null,
			isHandleGrabbed: false,
			usingHandles: false,
		};
	},
	computed: {
		beforeSlotsLength() {
			let beforeSlotsLength = 0;

			if (this.$scopedSlots.before) {
				const beforeSlots = this.$scopedSlots.before();

				if (beforeSlots && beforeSlots.length > 0) {
					beforeSlotsLength = beforeSlots.length;
				}
			}

			return beforeSlotsLength;
		},
		afterSlotsLength() {
			let afterSlotsLength = 0;

			if (this.$scopedSlots.after) {
				const afterSlots = this.$scopedSlots.after();

				if (afterSlots && afterSlots.length > 0) {
					afterSlotsLength = afterSlots.length;
				}
			}

			return afterSlotsLength;
		},
	},
	mounted() {
		const observerConfig = { childList: true };
		if (!this.readOnly) {
			this.observer = new MutationObserver((mutations) => this.mutationCallback(mutations));
			this.observer.observe(this.$refs.slotList, observerConfig);
			Array.prototype.forEach.call(this.$refs.slotList.children, this.setChildEventListeners);
		}
	},
	methods: {
		getParent(targetElement) {
			let target = targetElement;

			while (target && target !== document.body) {
				if (target.parentElement === this.$refs.slotList) {
					return target;
				}

				target = target.parentElement;
			}

			return null;
		},
		mutationCallback(mutations) {
			let anyAdded = false;

			mutations.forEach((mutation, index) => {
				if (mutation.addedNodes.length > 0) {
					anyAdded = true;
					const childIndex = this.$refs.slotList.children.length - (index + 1 + this.afterSlotsLength);
					const child = this.$refs.slotList.children[childIndex];
					this.setChildEventListeners(child, childIndex);
				}
			});

			if (anyAdded) {
				itemMoveHandler.resetIndex(this.beforeSlotsLength);
			}

			this.$emit('mutation');
		},
		setChildEventListeners(child, index) {
			const itemIndex = index - this.beforeSlotsLength;
			if (index < this.beforeSlotsLength || itemIndex >= this.list.length) return;
			if ($_.isUndefined(child)) return;

			if (!this.notDraggable) {
				child.addEventListener('dragstart', (event) => this.dragStart(itemIndex, event));
				child.addEventListener('dragenter', (event) => this.dragEnter(itemIndex, event));
				child.addEventListener('dragover', (event) => event.preventDefault());
				child.addEventListener('dragend', this.dragEnd);
			}

			child.setAttribute('draggable', !this.notDraggable);
			const handle = child.querySelector('[lba-dnd-handle]');
			const moveUp = child.querySelector('[lba-dnd-list-up]');
			const moveDown = child.querySelector('[lba-dnd-list-down]');
			const remove = child.querySelector('[lba-dnd-list-del]');
			const checkbox = child.querySelector('[lba-dnd-list-checkbox]');

			if (handle) {
				this.usingHandles = true;
				handle.addEventListener('mousedown', () => { this.isHandleGrabbed = true; });
			}
			if (moveUp && !this.staticListOrder) {
				moveUp.addEventListener('click', () => {
					move(this.list, this.list[itemIndex], itemIndex, itemIndex - 1);
					this.$emit('update:list', this.list);
					this.$emit('change', { order: this.list[itemIndex - 1] });
				});
			}
			if (moveDown && !this.staticListOrder) {
				moveDown.addEventListener('click', () => {
					move(this.list, this.list[itemIndex], itemIndex, itemIndex + 1);
					this.$emit('update:list', this.list);
					this.$emit('change', { order: this.list[itemIndex + 1] });
				});
			}
			if (remove && !this.staticListItems) {
				remove.addEventListener('click', () => {
					this.list.splice(itemIndex, 1);
				});
			}
			if (checkbox) {
				checkbox.addEventListener(
					'change', (event) => this.$emit('checkbox-change', { event, index })
				);
			}
		},
		dragStart(index, event) {
			if (this.usingHandles && !this.isHandleGrabbed) {
				event.preventDefault();
				return;
			}

			this.$emit('drag-start', event);

			const directChild = this.getParent(event.target);
			/* chrome bug, where you can manipulate DOM AFTER dragstart (CSS class might change DOM)
				https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
			*/
			setTimeout(() => addClassesCSS(directChild, this.draggedItemClass), 0);
			itemMoveHandler.setDragInfo(
				this, this.group, this.list, this.list[index], index, directChild,
				this.draggedItemClass, this.staticListItems, this.staticListOrder
			);
		},
		dragEnter(index, event) {
			if (this.list[index].isPinned !== itemMoveHandler.item.isPinned) {
				event.preventDefault();
				return;
			}

			const itemEnteredSameList = itemMoveHandler.itemEnteredSameList(this);
			const itemEnteredSameGroup = itemMoveHandler.itemEnteredSameGroup(this.group);
			const isSameItemElement = itemMoveHandler.isDescendant(event.target);
			const directChild = this.getParent(event.target);

			if (isSameItemElement && itemEnteredSameList) {
				itemMoveHandler.dragEnteredItem = true;
				return;
			}

			// not same element OR it is same element but entered from different list
			if (!isSameItemElement || (isSameItemElement && !itemEnteredSameList)) {
				// item is moved in same list
				if (itemEnteredSameList) {
					itemMoveHandler.moveItem(index, directChild);
					// item is moved between different lists
				} else if (itemEnteredSameGroup) {
					itemMoveHandler.dragEnteredItem = true;
					itemMoveHandler.moveItemBetweenLists(
						this, this.group, this.list, index, directChild, this.draggedItemClass,
						this.staticListItems, this.staticListOrder, this.beforeSlotsLength
					);
				}
			}
		},
		dragEnd(e) {
			this.isHandleGrabbed = false;
			this.$emit('drag-end', e);
			itemMoveHandler.reset();
		},
		slotListDragEnter() {
			if (itemMoveHandler.dragEnteredItem) {
				itemMoveHandler.dragEnteredItem = false;
			} else {
				const itemEnteredSameGroup = itemMoveHandler.itemEnteredSameGroup(this.group);
				const itemExists = this.list.indexOf(itemMoveHandler.item) >= 0;

				if (itemExists) {
					return;
				}

				if (itemEnteredSameGroup) {
					itemMoveHandler.moveItemBetweenLists(
						this, this.group, this.list, this.list.length, null, this.draggedItemClass,
						this.staticListItems, this.staticListOrder, this.beforeSlotsLength
					);
				}
			}
		},
	},
};
</script>
