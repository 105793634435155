/*
---EXAMPLE---
<lba-section
	componentId=campaign
	currentComponentId=lba-section__campaign
>
	<batch-view
		parentComponentId=lba-section__campaign
		componentId=batch1
		currentComponentId=lba-section__campaign__batch-view__batch1
	>
		<tag
			parentComponentId=lba-section__campaign__batch-view__batch1
			componentId=card1
			currentComponentId=lba-section__campaign__batch-view__batch1__tag__card1
		></tag>
		<tag
			parentComponentId=lba-section__campaign__batch-view__batch1
			componentId=card2
			currentComponentId=lba-section__campaign__batch-view__batch1__tag__card2
		></tag>
	</batch-view>
	<batch-view
		parentComponentId=lba-section__campaign
		componentId=batch2
		currentComponentId=lba-section__campaign__batch-view__batch2
	>
		<tag
			parentComponentId=lba-section__campaign__batch-view__batch2
			componentId=card1
			currentComponentId=lba-section__campaign__batch-view__batch2__tag__card1
		></tag>
		<tag
			parentComponentId=lba-section__campaign__batch-view__batch2
			componentId=card2
			currentComponentId=lba-section__campaign__batch-view__batch2__tag__card2
		></tag>
	</batch-view>
</lba-section>
*/

export default {
	props: {
		componentId: {
			type: String,
		},
		parentComponentId: {
			type: String,
		},
		appendComponentName: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			currentComponentId: null,
		};
	},
	created() {
		if ($_.isEmpty(this.parentComponentId) && $_.isEmpty(this.componentId)) {
			/* DEVELOPMENT ONLY - BEGIN
			console.warn('[ComponentIdentifier] missing parentComponentId or componentId', this.$options.name, this.$options.propsData);
			/* DEVELOPMENT ONLY - END */

		} else if (!$_.isEmpty(this.parentComponentId) && !$_.isEmpty(this.componentId)) {
			if (this.appendComponentName) {
				this.currentComponentId = `${this.parentComponentId}__${this.$options.name}__${this.componentId}`;
			} else {
				this.currentComponentId = `${this.parentComponentId}__${this.componentId}`;
			}

		} else if ($_.isEmpty(this.parentComponentId)) {
			if (this.appendComponentName) {
				this.currentComponentId = `${this.$options.name}__${this.componentId}`;
			} else {
				this.currentComponentId = this.componentId;
			}

		} else if ($_.isEmpty(this.componentId)) {
			if (this.appendComponentName) {
				this.currentComponentId = `${this.parentComponentId}__${this.$options.name}`;
			} else {
				this.currentComponentId = this.parentComponentId;
			}

		}
	},
};
