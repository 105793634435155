<template>
	<lba-dialog
		:parentComponentId="currentComponentId"
		:name="`${name}.import-dialog`"
		:title="title"
		:classList="['fixed-size-dialog import-dialog']"
		@open="dirty = false"
		@close="resetSteps(true); deleteUnusedFile()"
		:warningOnEscape="dirty"
	>
		<lba-content-tabs
			:parentComponentId="currentComponentId"
			ref="contentTabs"
			nextPrevious
			:tabs="tabs"
			:defaultTab="defaultTab"
			:beforeTabSelected="beforeTabSelected"
			@active-tab-changed="setActiveTab"
		>
			<!--STEP 0: DESCRIPTION-->
			<div style="text-align: center; line-height: 1.8">
				<p>
					{{ description }}
				</p>

				<a :data-cy="`${currentComponentId}__downloadExampleCsv`" @click="downloadExampleFile('csv')" class="link-icon mb-3 mt-5">
					<i class="icon-file"></i>
					{{ $t('downloadExampleFile') }} CSV
				</a>
				<br>
				<a :data-cy="`${currentComponentId}__downloadExampleXlsx`" @click="downloadExampleFile('xlsx')" class="link-icon">
					<i class="icon-file-ms-excel"></i>
					{{ $t('downloadExampleFile') }} XLSX
				</a>
				<a style="display: none" :download="exampleFileName" ref="exampleFile" :href="exampleFileHref"></a>
			</div>

			<!--STEP 1.0: FORMAT AND MAPPING-->
			<div>
				<div v-if="isChecking" class="row">
					<span class="note col">{{ $t('importValidating') + ": " + formattedCheckingTime }}</span>
					<template v-if="progressEnabled">
						<span class="note col">{{ $t('processedRowsCount') }}: {{ validatedRows }}</span>
						<span v-if="validationInfo" class="note col">{{ $t(validationInfo) }}</span>
					</template>
				</div>
				<template v-else-if="!isChecking">
					<!--CSV & XSLX-->
					<h3>{{ $t('settings.settings') }}</h3>
					<s>
						<small>{{ $t('chooseFile') }}</small>
						<lba-input-file
							:parentComponentId="currentComponentId"
							componentId="chooseFile"
							v-model="files"
							inputClassList="alignLeft"
							:id="`table-import.file-${name}`"
							:disabled="isParsing"
							@input="onFileInput"
							:fileLabel="(files && files[0].name) || ''"
						/>
					</s>
					<s>
						<small>{{ $t('importExportSettingTemplates') }}</small>
						<select
							:data-cy="`${currentComponentId}__templates__select`"
							v-model="usedImportExportSettingUid"
							:disabled="isParsing"
							@change="prepareImportExportSettings"
						>
							<option
								v-for="(setting, index) in importExportSettings"
								:key="index"
								:value="setting.def_import_export_setting_uid"
								:data-cy="`${currentComponentId}__templates__select__option${index}`"
							>{{ setting.label }}</option>
							<option :value="null" :data-cy="`${currentComponentId}__templates__select__optionNull`"></option>
						</select>
					</s>
					<s style="width: 160px;">
						<small>{{ $t('fileType') }}</small>
						<label class="checkbox radio" :data-cy="`${currentComponentId}__fileTypeCsv__label`">
							<input
								:data-cy="`${currentComponentId}__fileTypeCsv__input`"
								type="radio"
								value="csv"
								v-model="fileType"
								:name="`${name}.file-type`"
								:disabled="isParsing"
								@change="setTabChanged(1); prepareColumnMapping()"
							>
							<span class="checkmark"></span>
							<span class="label">CSV</span>
						</label>
						<label class="checkbox radio" :data-cy="`${currentComponentId}__fileTypeXlsx__label`">
							<input
								:data-cy="`${currentComponentId}__fileTypeXlsx__input`"
								type="radio"
								value="xlsx"
								v-model="fileType"
								:name="`${name}.file-type`"
								:disabled="isParsing"
								@change="setTabChanged(1); prepareColumnMapping()"
							>
							<span class="checkmark"></span>
							<span class="label">XLSX</span>
						</label>
					</s>
					<s class="half">
						<small>{{ $t('containsHeader') }}</small>
						<lba-switch-simple
							:data-cy="`${currentComponentId}__containsHeader__switch`"
							:key="`containsHeader`"
							name="containsHeaderSwitch"
							v-model="containsHeader"
							:disabled="isParsing"
							@change="containsHeaderSetByUser = true; setTabChanged(1); prepareColumnMapping()"
							style="min-height: 30px;"
						></lba-switch-simple>
					</s>
					<s>
						<small>
							{{ $t('dateFormat') }}
							<i class="icon-tooltip" v-tooltip="$t('dateFormatHelp')"></i>
						</small>
						<input
							type="text"
							v-model="dateFormat"
							@input="onDateFormatChange(); setTabChanged(1);"
						>
					</s>
					<s>
						<small>
							{{ $t('dateFormatExample') }}
						</small>
						<div style="height: 30px; display: flex; flex-direction: row; align-items: center; margin-top: 2px;">
							{{ moment().format(dateFormat || 'DD.MM.YYYY HH:mm:ss') }}
						</div>
					</s>
					<br/>
					<s class="size-0" v-if="updateEnabled">
						<small>
							{{ $t('updateRecords') }}
						</small>
						<lba-checkbox
							v-model="updateRecords"
							:parentComponentId="currentComponentId"
							:name="`${name}.table-import.update-records`"
							@change="dirty = true"
						></lba-checkbox>
					</s>
					<s class="size-0" v-if="deleteEnabled && updateEnabled">
						<small>
							{{ $t('deleteRecords') }}
							<i class="icon-tooltip" v-tooltip="$t('deleteRecordsHelp')"></i>
						</small>
						<lba-checkbox
							v-model="deleteRecords"
							:parentComponentId="currentComponentId"
							:name="`${name}.table-import.deleterecords`"
							:disabled="!updateRecords"
							@change="dirty = true"
						></lba-checkbox>
					</s>
					<s v-if="customColumn">
						<small>{{ $t('unknownColumn') }}</small>
						<label class="checkbox radio" :data-cy="`${currentComponentId}__unknownColumnDoNotImport__label`">
							<input
								:data-cy="`${currentComponentId}__unknownColumnDoNotImport__input`"
								type="radio"
								value="doNotImport"
								v-model="unknownColumnAction"
								:name="`${name}.unknown-column`"
								:disabled="isParsing || usedImportExportSettingUid != null"
								@change="changeUnknownColumnAction"
							>
							<span class="checkmark"></span>
							<span class="label">{{ $t('doNotImport') }}</span>
						</label>
						<label class="checkbox radio" :data-cy="`${currentComponentId}__unknownColumnCustomColumn__label`">
							<input
								:data-cy="`${currentComponentId}__unknownColumnCustomColumn__input`"
								type="radio"
								value="customColumn"
								v-model="unknownColumnAction"
								:name="`${name}.unknown-column`"
								:disabled="isParsing || usedImportExportSettingUid != null"
								@change="changeUnknownColumnAction"
							>
							<span class="checkmark"></span>
							<span class="label">{{ customColumn.title }}</span>
						</label>
					</s>

					<!--ONLY CSV-->
					<template v-if="fileType === 'csv'">
						<s class="half">
							<small>{{ $t('columnDelimiter') }}</small>
							<select
								:data-cy="`${currentComponentId}__columnDelimiter__select`"
								v-model="columnDelimiter"
								:disabled="isParsing"
								@change="setTabChanged(1); prepareColumnMapping()"
							>
								<option
									v-for="(delimiter, index) in columnDelimiters"
									:key="index"
									:value="delimiter.value"
									:data-cy="`${currentComponentId}__columnDelimiter__select__option${index}`"
								>
									{{ delimiter.label }}
								</option>
							</select>
						</s>
						<s class="half">
							<small>{{ $t('quoteCharacter') }}</small>
							<select
								:data-cy="`${currentComponentId}__quoteCharacter__select`"
								v-model="quoteCharacter"
								:disabled="isParsing"
								@change="setTabChanged(1); prepareColumnMapping()"
							>
								<option
									v-for="(character, index) in quoteCharacters"
									:key="index"
									:value="character.value"
									:data-cy="`${currentComponentId}__quoteCharacter__select__option${index}`"
								>
									{{ character.label }}
								</option>
							</select>
						</s>
						<s class="half">
							<small>{{ $t('encoding') }}</small>
							<select
								:data-cy="`${currentComponentId}__encoding__select`"
								v-model="encoding"
								:disabled="isParsing"
								@change="setTabChanged(1); prepareColumnMapping()"
							>
								<option
									v-for="(encode, index) in encodings"
									:key="index"
									:value="encode"
									:data-cy="`${currentComponentId}__encoding__select__option${index}`"
								>
									{{ encode }}
								</option>
							</select>
						</s>
					</template>

					<div v-if="isParsing" class="row">
						<span class="note">{{ $t('fileIsLoading') }} {{ formattedParsingTime }}</span>
					</div>

					<div v-if="fileCanNotBeRead" class="error-wrap" style="margin-top: 10%">
						<div class="msg msg-danger m-0">
							{{ $t('fileCanNotBeRead') }}
						</div>
					</div>
					<div v-if="fileDoesNotExist" class="error-wrap" style="margin-top: 10%">
						<div class="msg msg-danger m-0">
							{{ $t('fileDoesNotExist') }}
						</div>
					</div>
					<div v-if="fileIsMissingData" class="error-wrap" style="margin-top: 10%">
						<div class="msg msg-danger m-0">
							{{ $t('fileIsMissingData') }}
							<template v-if="fileType === 'xlsx'">
								<br>
								{{ $t('xlsxFileDataInfo') }}
							</template>
						</div>
					</div>

					<!--STEP 1.1: MAPOVÁNÍ SLOUPCŮ-->
					<template v-if="
						files &&
						files.length > 0 &&
						!fileContainsInvalidCharacters &&
						!isParsing &&
						!fileCanNotBeRead &&
						!fileDoesNotExist &&
						!fileIsMissingData
					">
						<h3>{{ $t('importPreview') }}</h3>
						<div class="content-table-wrapper">
							<table class="content-table preview-table mt-1">
								<tr>
									<th>{{ $t('rowNumber') }}</th>
									<template v-for="(field, index) in headerFields">
										<th v-if="containsHeader" :key="index">{{ field }}</th>
										<th v-else :key="index">{{ $t('column') }} {{ field + 1 }}</th>
									</template>
								</tr>
								<tr>
									<td>#</td>
									<td v-for="(mapped, index) in headerMap" :key="index" style="overflow: visible;">
										<select
											:data-cy="`${currentComponentId}__mappedColumn${index}__select`"
											v-model="mapped.column"
											class="input"
											:class="{ 'lba-invalid': !isMappedColumnValid(mapped) }"
											v-tooltip="getColumnError(mapped)"
											@change="columnMapChange(mapped)"
										>
											<option
												v-for="(column, columnIndex) in columns"
												:key="`${index}-${column.name}`"
												:value="column.name"
												:data-cy="`${currentComponentId}__mappedColumn${index}__select__option${columnIndex}`"
											>
												{{ column.title }}
											</option>
											<option
												:value="null"
												:data-cy="`${currentComponentId}__mappedColumn${index}__select__optionNull`"
											>-- {{ $t('doNotImport') }} --</option>
										</select>
									</td>
								</tr>
								<tr v-for="(row, index) in tablePreview" :key="index">
									<td>{{ row.$index }}</td>
									<td v-for="(field, headerIndex) in headerFields" :key="`${previewKey}-${index}-${field}`">
										{{ getPreviewRowValue(row, field, headerIndex) }}
									</td>
								</tr>
							</table>
						</div>
						<br>

						<div class="row">
							<div class="col-6">
								<h3>{{ $t('importedColumns') }}</h3>
								<ul class="column-list">
									<li
										v-for="(column, index) in importedColumns"
										:key="index"
									>
										<template v-if="containsHeader">{{ column.header }}</template>
										<template v-else>{{ $t('column') }} {{ column.header + 1 }}</template>
									</li>
								</ul>
							</div>

							<div class="col-3" v-if="notImportedColumns && notImportedColumns.length > 0">
								<h3>{{ $t('notImportedColumns') }}</h3>
								<ul class="column-list">
									<li
										v-for="(column, index) in notImportedColumns"
										:key="index"
									>
										<template v-if="containsHeader">{{ column.header }}</template>
										<template v-else>{{ $t('column') }} {{ column.header + 1 }}</template>
									</li>
								</ul>
							</div>

							<div class="col-3" v-if="notAssignedRequiredColumns && notAssignedRequiredColumns.length > 0">
								<h3>{{ $t('notAssignedRequiredColumns') }}</h3>
								<ul class="column-list">
									<template v-for="(column, index) in notAssignedRequiredColumns">
										<li
											:data-cy="`${currentComponentId}__notAssignedRequiredColumns__${column.name}`"
											:class="{
												red: !inRequiredGroup(column.name),
												orange: inRequiredGroup(column.name)
											}"
											:key="index"
										>
											{{ column.title }}
										</li>
									</template>
								</ul>
							</div>
						</div>
					</template>
					<template v-else-if="fileContainsInvalidCharacters">
						<div class="row">
							{{ $t('fileContainsInvalidCharacters') }}
						</div>
					</template>
				</template>
			</div>

			<!--STEP 3: KONTROLA DAT-->
			<div>
				<template v-if="!areDataValid && rowErrorCount === 0">
					<span class="msg msg-danger" style="margin: 0px auto 20px auto;">{{ $t('validationFailed') }}</span>
					<br>
				</template>

				<p v-else-if="dataCheckDescription">{{ dataCheckDescription }}</p>

				<template v-else>
					<div class="content-table-wrapper" style="max-height: 500px;">
						<table class="content-table mt-1">
							<tr>
								<th>{{ $t('rowNumber') }}</th>
								<template v-for="(mapped, index) in previewHeaderMap">
									<th :key="index">{{ mapped.title }}</th>
								</template>
							</tr>

							<template v-for="(row, index) in tableContent">
								<tr
									:class="{ 'lba-invalid': row.$rowErrors && row.$rowErrors.length > 0 }"
									v-tooltip="getRowErrors(row)"
									:data-cy="`${currentComponentId}__tableContentRow${index}`"
									:key="index"
								>
									<td>{{ row.$index }}</td>
									<template v-for="(mapped, previewIndex) in previewHeaderMap">
										<td
											:key="`${mapped.name}-${previewIndex}`"
											:data-cy="`${currentComponentId}__tableContentRow${index}__${mapped.name}${previewIndex}`"
											:class="{ 'lba-invalid': !$_.isEmpty(getColumnErrors(row, mapped, previewIndex)) }"
											v-tooltip="getColumnErrors(row, mapped, previewIndex)"
										>
											<span
												class="input"
												:class="{ 'lba-invalid': !$_.isEmpty(getColumnErrors(row, mapped, previewIndex)) }"
											>
												{{ getRowValue(row, mapped, previewIndex) }}
											</span>
										</td>
									</template>
								</tr>
							</template>
						</table>
					</div>
					<span class="note displayBlock mt-1" v-if="dataErrorCount === 0">
						{{ $t('shownNumberOfRecordsOutOfTotal', null, { number: tableContent.length, total: totalCount }) }}
						</span>
					<span class="note displayBlock mt-1" v-else>
						{{ $t('shownNumberOfErrorsOutOfTotal', null, { number: tableContent.length, total: dataErrorCount }) }}.
						{{ $t('totalRows', null, { attribute: totalCount }) }}
					</span>
					<div v-if="areDataValid && !rowErrorCount && (externalIdCheck !== null)" class="externalIdsCheck">
						{{ this.$t('importResultPreview', {
							add: externalIdCheck.add,
							update: externalIdCheck.update,
							delete: externalIdCheck.delete
						}) }}
					</div>
					<br>
				</template>
			</div>

			<!--STEP 4: VÝSLEDEK ZE SERVERU-->
			<div class="height100">
				<template v-if="isImporting">
					<div v-if="progressEnabled" class="displayFlex center column height100">
						<div class="note mb-3">{{ $t('importingData') }}</div>
						<lba-progress
							:value="importingProgress"
							type="line"
							:tooltip="$t('importingData')"
							:info="$t(importingProgressInfo)"
							class="lba-progress-line-big"
						/>
					</div>
					<template v-else>
						<span class="note">{{ $t('importingData') }} {{ formattedImportingTime }}</span>
						<br>
					</template>
				</template>
				<template v-if="importRequestDone">
					<template v-if="importErrors.length === 0 && rowErrorCount === 0">
						<span class="msg" style="margin: 0px auto 20px auto;">{{ $t('importSuccesful') }}</span>
						<template v-if="hasImportExportSettingChanged()">
							<div class="row mb-3">
								<div class="col-3">
									<h3 class="mt-0">{{ $t('saveSettings') }}</h3>
									<s>
										<small>{{ $t('name') }}</small>
										<input
											type="text"
											v-model="importExportSettingName"
											:data-cy="`${currentComponentId}__importExportSettingName__input`"
											@input="dirty = true"
										>
									</s>
									<br>
									<button
										v-if="usedImportExportSettingUid != null"
										type="button"
										class="mb-3 mr-2 displayInlineBLock"
										:disabled="savingImportExportSetting || importExportSettingSaved"
										:data-cy="`${currentComponentId}__saveImportExportSetting`"
										@click="saveImportExportSetting"
									>
										{{ $t('save') }}
									</button>
									<button
										type="button"
										class="mb-3"
										:disabled="savingImportExportSetting || importExportSettingSaved"
										:data-cy="`${currentComponentId}__createImportExportSetting`"
										@click="createImportExportSetting"
									>
										{{ $t('createNew') }}
									</button>
								</div>
								<div class="col-9">
									<div class="well">
										<h3 class="mt-0">{{ $t('formatSettings') }}</h3>
										<template v-if="fileType === 'csv'">
											<s class="half">
												<small>{{ $t('columnDelimiter') }}</small>
												<span
													:data-cy="`${currentComponentId}__columnDelimiterLabel`"
												>{{ getColumnDelimiter().label }}</span>
											</s>
											<s class="half">
												<small>{{ $t('quoteCharacter') }}</small>
												<span
													:data-cy="`${currentComponentId}__quoteCharacterLabel`"
												>{{ getQuoteCharacter().label }}</span>
											</s>
											<s class="half">
												<small>{{ $t('encoding') }}</small>
												<span
													:data-cy="`${currentComponentId}__encodingLabel`"
												>{{ encoding }}</span>
											</s>
										</template>
										<s>
											<lba-checkbox
												:parentComponentId="currentComponentId"
												componentId="containsHeader"
												v-model="containsHeader"
												disabled
												:name="`${name}.contains-header`"
												:label="$t('containsHeader')"
												@change="dirty = true"
											></lba-checkbox>
										</s>
										<s>
											<small>{{ $t('dateFormat') }}</small>
											{{ dateFormat }}
										</s>

										<h3>{{ $t('columnMapping') }}</h3>
										<div class="export-column-list">
											<span v-for="(mapped, index) in mappedColumns" :key="index"
												:data-cy="`${currentComponentId}__columnMapping__${index}`"
											>
												<em>{{ mapped.title }}:</em> {{ mapped.header }}
											</span>
										</div>
									</div>
								</div>
							</div>
						</template>
					</template>
					<template v-else-if="importFailed && importTriesCount < 2">
						<span class="msg msg-danger" style="margin: 0px auto 20px auto;">{{ $t('importFailed') }}</span>
						<button
							type="button"
							style="display: block; margin: 0px auto 20px auto;"
							:data-cy="`${currentComponentId}__prepareImportResult`"
							@click="prepareImportResult"
						>{{ $t('importOnlyValidRows') }}</button>
					</template>
					<template v-else-if="importFailed && importTriesCount < 3">
						<span class="msg msg-danger" style="margin: 0px auto 20px auto;">{{ $t('importFailed') }}</span>
						<br>
					</template>
					<template v-else>
						<span class="msg msg-danger" style="margin: 0px auto 20px auto;">{{ $t('importWithErrors') }}</span>
						<br>
						<span v-for="(error, index) in importErrors" :key="index">
							{{ error }}
						</span>
						<br>
					</template>
					<div class="content-table-wrapper">
						<table class="content-table mt-1">
							<tr>
								<th>{{ $t('rowNumber') }}</th>
								<template v-for="(mapped, index) in previewHeaderMap">
									<th :key="index">{{ mapped.title }}</th>
								</template>
								<th></th>
							</tr>

							<template v-for="(row, index) in importPreview">
								<tr
									:class="{ 'lba-invalid': row.$rowErrors && row.$rowErrors.length > 0 }"
									:data-cy="`${currentComponentId}__importPreviewRow${index}`"
									v-tooltip="getRowErrors(row)"
									:key="index"
								>
									<td>{{ row.$index }}</td>
									<template v-for="(mapped, previewIndex) in previewHeaderMap">
										<td
											:data-cy="`${currentComponentId}__importPreviewRow${index}__${mapped.name}${previewIndex}`"
											:key="`${mapped.name}-${previewIndex}`"
											:class="{ 'lba-invalid': !$_.isEmpty(getColumnErrors(row, mapped, previewIndex)) }"
											v-tooltip="getColumnErrors(row, mapped, previewIndex)"
										>
											<span
												class="input"
												:class="{ 'lba-invalid': !$_.isEmpty(getColumnErrors(row, mapped, previewIndex)) }"
											>
												{{ getRowValue(row, mapped, previewIndex) }}
											</span>
										</td>
									</template>
									<td></td>
								</tr>
							</template>
						</table>
					</div>
					<span v-if="rowErrorCount === 0" class="note displayBlock mt-1">
						{{ $t('shownNumberOfRecordsOutOfTotal', null, { number: importPreview.length, total: importTotalCount }) }}
					</span>
					<span v-else class="note displayBlock mt-1">
						{{ $t('shownNumberOfErrorsOutOfTotal', null, { number: importPreview.length, total: rowErrorCount }) }}.
						{{ $t('totalRows', null, { attribute: totalCount }) }}
					</span>
					<div></div>
				</template>
			</div>
			<template
				v-if="
					activeTabId === tabs[3].id &&
					importRequestDone &&
					importErrors.length === 0 &&
					rowErrorCount === 0
				"
				v-slot:arrows
			>
				<button v-lba-dialog-close="`${name}.import-dialog`" :data-cy="`${currentComponentId}__closeDialogImport`">
					{{ $t('close') }}
				</button>
			</template>
		</lba-content-tabs>
	</lba-dialog>
</template>

<style scoped>
tr.lba-invalid {
	background-color: #ffc1c7;
	border: 1px solid #ffaab3;
}
.externalIdsCheck {
	display: block;
	text-align: center;
}
.externalIdsCheck p {
	display: inline-block;
	margin: 0 10px;
}
</style>

<script>
import TableImportModel from './models/TableImport';
import ImportExportSettingsModel from '../../models/ImportExportSettings';
import ComponentIdentifier from '../../mixins/ComponentIdentifier';

export default {
	name: 'LbaTableImport',
	mixins: [ComponentIdentifier],
	props: {
		// dialog props
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default() { return this.$t('modules.import'); },
		},
		modal: {
			type: Boolean,
			default: true,
		},
		// import props
		description: String,
		mappingDescription: String,
		dataCheckDescription: String,
		resources: {
			type: Object,
			validator(value) {
				return (
					!$_.isEmpty(value) &&
					'importSettings' in value &&
					'importExportDefinition' in value
				);
			},
		},
		importType: String,
		parentUid: String,
		progressEnabled: {
			type: Boolean,
			default: false,
		},
		/* columns: Array,
		schema: Object, */
	},
	data() {
		return {
			dirty: false,
			importExportSettingsModel: null,
			tableImportModel: null,
			tabs: [
				{
					id: 0,
					label: `1 ${this.$t('descriptionAndExample')}`,
					changed: false,
					tooltipNext: null,
				},
				{
					id: 1,
					label: `2 ${this.$t('formatSettings')} ${this.$t('and')} ${this.$t('columnMapping').toLowerCase()}`,
					changed: false,
					tooltipNext: null,
				},
				{
					id: 2,
					label: `3 ${this.$t('dataControl')}`,
					disabled: true,
					disabledArrowNext: false,
					changed: false,
					tooltipNext: null,
					next: this.$t('doImport'),
				},
				{
					id: 3,
					label: `4 ${this.$t('importResult')}`,
					disabled: true,
					disabledTab: true,
					changed: false,
					tooltipNext: null,
				},
			],
			defaultTab: 0,
			columns: [],
			schema: {},
			usedImportExportSettingUid: null,
			importExportSettingName: null,
			importExportSettings: [],
			importExportDefinition: null,

			// STEP 0: Description and example
			exampleFileHref: null,
			exampleFileName: null,

			// STEP 1: Format settings
			previewKey: false,
			lastFile: null,
			files: null,
			fileType: 'csv',
			unknownColumnAction: 'doNotImport',
			fileContainsInvalidCharacters: false,
			columnDelimiter: '',
			columnDelimiters: [
				{ label: this.$t('tab'), value: `\t` },
				{ label: `,`, value: `,` },
				{ label: `;`, value: `;` },
				{ label: this.$t('autoDetect'), value: '' },
			],

			quoteCharacter: '"',
			quoteCharacters: [
				{ label: `"`, value: `"` },
				{ label: `'`, value: `'` },
				{ label: `-- ${this.$t('blank')} --`, value: null },
			],

			// https://github.com/whatwg/encoding/blob/main/encodings.json
			encoding: 'UTF-8',
			encodings: [
				'UTF-8',
				'IBM866',
				'ISO-8859-2',
				'ISO-8859-3',
				'ISO-8859-4',
				'ISO-8859-5',
				'ISO-8859-6',
				'ISO-8859-7',
				'ISO-8859-8',
				// 'ISO-8859-8-I',
				'ISO-8859-10',
				'ISO-8859-13',
				'ISO-8859-14',
				'ISO-8859-15',
				'ISO-8859-16',
				// 'KOI8-R',
				// 'KOI8-U',
				'macintosh',
				'windows-874',
				'windows-1250',
				'windows-1251',
				'windows-1252',
				'windows-1253',
				'windows-1254',
				'windows-1255',
				'windows-1256',
				'windows-1257',
				'windows-1258',
				// 'x-mac-cyrillic',
				// 'GBK',
				// 'gb18030',
				// 'Big5',
				// 'EUC-JP',
				// 'ISO-2022-JP',
				// 'Shift_JIS',
				// 'EUC-KR',
				// 'replacement',
				'UTF-16BE',
				'UTF-16LE',
				// 'x-user-defined',
			],
			containsHeader: null,
			containsHeaderSetByUser: false,
			dateFormat: null,
			validateData: false,

			headerFields: [],
			headerMap: [],
			previewHeaderMap: [],
			requiredColumns: [],
			fileUid: null,

			isParsing: false,
			parsingTimeStart: 0,
			formattedParsingTime: '',

			fileDoesNotExist: false,
			fileCanNotBeRead: false,
			fileIsMissingData: false,

			// STEP 2: Data control
			tableContent: [],
			tablePreview: [],
			totalCount: 0,
			validatedRows: 0,
			validationInfo: null,

			validate: null,

			isChecking: false,
			checkingTimeStart: 0,
			formattedCheckingTime: '',

			firstStep: true,
			abortParsing: false,
			dataErrorCount: 0,

			columnsMapped: false,
			uploadRequestSent: false,
			uploadRequestDone: false,
			areDataValid: false,

			// STEP 3: Import result
			isImporting: false,
			importingTimeStart: 0,
			formattedImportingTime: '',

			importErrors: [],
			importPreview: [],
			rowErrorCount: 0,
			importTotalCount: 0,
			importRequestSent: false,
			importRequestDone: false,
			importFailed: false,
			importTriesCount: 0,
			activeTabId: 0,
			importingProgress: 0,
			importingProgressInfo: null,

			scriptId: null,
			defImportExportUid: null,
			savingImportExportSetting: false,
			importExportSettingSaved: false,
			externalIdCheck: null,
			updateEnabled: false,
			deleteEnabled: false,
			updateRecords: false,
			deleteRecords: false,
			importFileName: null,
			importFileUid: null,
		};
	},
	computed: {
		importedColumns() {
			return this.headerMap.filter((mapped) => mapped.doImport);
		},
		notImportedColumns() {
			return this.headerMap.filter((mapped) => !mapped.doImport);
		},
		notAssignedRequiredColumns() {
			return this.requiredColumns.filter((requiredColumn) => {
				const isMapped = this.headerMap.some((mapped) => mapped.column === requiredColumn.name);

				if (!isMapped) {
					// not assigned when in required group and header map does not include any item from that group
					if (this.inRequiredGroup(requiredColumn.name)) {
						const group = this.importExportDefinition.validation_schema.requiredGroups
							.find((item) => item.includes(requiredColumn.name));
						return !group.some((item) => this.headerMap.some((mapped) => mapped.column === item));
					}
					return true;
				}
				return false;
			});
		},
		customColumn() {
			return this.columns.find((column) => column.custom);
		},
		mappedColumns() {
			const mappedColumns = [];
			let customColumn = null;
			this.columns.forEach((column) => {
				if (column.custom) {
					customColumn = column;
				}
				const c = this.headerMap.find((mapped) => mapped.column === column.name);
				mappedColumns.push({ title: column.title, header: c ? c.header : null });
			});

			if (customColumn) {
				let first = true;
				this.headerMap.forEach((mapped) => {
					if (first && mapped.custom) {
						first = false;
						return;
					}
					if (mapped.custom) {
						mappedColumns.push({ title: customColumn.title, header: mapped.header });
					}
				});
			}
			return mappedColumns;
		},
		currentImportExportSetting() {
			const currentImportExportSetting = {
				name: this.name,
				quote_character: this.quoteCharacter,
				column_delimiter: this.columnDelimiter,
				encoding: this.encoding || null,
				contains_header: this.containsHeader === true,
				date_format: this.dateFormat || null,
				header_map: this.headerMap || null,
				label: this.importExportSettingName,
				file_type: this.fileType || null,
				type: 'IMPORT',
			};

			if (currentImportExportSetting.header_map) {
				currentImportExportSetting.header_map = currentImportExportSetting.header_map
					.map((mapped) => ({ column: mapped.column, header: mapped.header }));
			}
			return currentImportExportSetting;
		},
	},
	watch: {
		updateRecords(newVal) {
			if (newVal === false) {
				this.deleteRecords = false;
			}
		},
		columns() {
			this.prepareColumns();
		},
	},
	async created() {
		this.importExportSettingsModel = new ImportExportSettingsModel(this.$http);
		this.tableImportModel = new TableImportModel(this.$http);

		if (this.$i18n.locale === 'cs') {
			this.dateFormat = 'DD.MM.YYYY';
		} else if (this.$i18n.locale === 'en') {
			this.dateFormat = 'MM.DD.YYYY';
		}
		this.scriptId = `validation-code-${this.name}`;
		let validationCode = document.getElementById(this.scriptId);

		if (validationCode != null) {
			document.head.removeChild(validationCode);
		}

		validationCode = document.createElement('script');
		const url = `${location.origin}${this.$http.defaults.baseURL}lbadmin/table-import/validation-code/${this.name}`;
		validationCode.setAttribute('src', url);
		validationCode.setAttribute('type', 'module');
		validationCode.setAttribute('id', this.scriptId);
		document.head.appendChild(validationCode);

		if (!$_.isEmpty(this.resources) && this.resources.importSettings != null && this.resources.importExportDefinition != null) {
			this.importExportSettings = this.resources.importSettings;
			this.importExportDefinition = this.resources.importExportDefinition;
		} else {
			this.importExportSettings = (await this.importExportSettingsModel.query({ tableName: this.name, _type: 'IMPORT' })).data;
			const result = await this.tableImportModel.getImportExportDefinition(this.name);
			this.importExportDefinition = result.data;
		}

		this.columns = this.importExportDefinition.columns.columns.filter((column) => column.allowImport);
		this.columns.forEach((column) => {
			column.title = $getLocale(column.title);
		});
		this.schema = this.importExportDefinition.validation_schema;
		this.defImportExportUid = this.importExportDefinition.def_import_export_uid;
		this.prepareColumns();

		this.updateEnabled = !!this.importExportDefinition.update_enabled;
		this.deleteEnabled = !!this.importExportDefinition.delete_enabled;

		this.updateRecords = this.updateEnabled && !!this.importExportDefinition.update_default;
		this.deleteRecords = this.deleteEnabled && !!this.importExportDefinition.delete_default;

		this.$root.$listen('table-import.import-result', this.onImportDone, this);
	},
	methods: {
		validateDate(value, dateFormat) {
			if (value == null) return true;
			if (value.constructor === Date) return !isNaN(value.getTime());
			if (value.constructor === String) {
				let dateValue = moment(value, dateFormat || 'DD.MM.YYYY HH:mm:ss').toDate();
				if (!isNaN(dateValue.getTime())) return true;
				dateValue = new Date(value);
				if (!isNaN(dateValue.getTime())) return true;
			}
			return false;
		},
		parseDate(value, columnIsDate = false, columnDateFormat = null, dateFormat = null, locale = 'en', format = false) {
			if (value == null) return null;

			// format date - saved as string - not date!
			if (!$_.isEmpty(dateFormat) && !columnIsDate) {
				const numberRe = /^[+-]?[0-9\s]+([,.][0-9]*)?$/gm;
				const gmtRe = /GMT$/gm;
				const utcRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/gm;
				const validCharsRe = /^[0-9.\-/:\s]+$/gm;

				if (value.constructor === Date) {
					value = moment(value).locale(locale).format(dateFormat);
				} else if (
					value.constructor === String &&
					!numberRe.test(value) && (
						(
							validCharsRe.test(value) && (
								(value.match(/\./g) || []).length === 2 ||
								(value.match(/\./g) || []).length === 3 ||
								(value.match(/-/g) || []).length === 2 ||
								(value.match(/\//g) || []).length === 2
							)
						) ||
						gmtRe.test(value) ||
						utcRe.test(value)
					)
				) {
					const mmnt = moment(value, columnDateFormat || 'DD.MM.YYYY HH:mm:ss');
					let dateValue = mmnt.toDate();
					if (mmnt._isValid && !isNaN(dateValue.getTime())) {
						value = moment(dateValue).locale(locale).format(dateFormat || 'DD.MM.YYYY HH:mm:ss');
					} else {
						dateValue = new Date(value);
						if (!isNaN(dateValue.getTime())) {
							value = moment(dateValue).locale(locale).format(dateFormat || 'DD.MM.YYYY HH:mm:ss');
						}
					}
				}
			// format date - saved as date - formatted in gui!
			} else if (columnIsDate) {
				if (value.constructor === Date) {
					if (format) {
						value = moment(value).locale(locale).format(columnDateFormat || 'DD.MM.YYYY HH:mm:ss');
					} else {
						value = value.toUTCString();
					}

				} else if (value.constructor === String) {
					const mmnt = moment(value, columnDateFormat || 'DD.MM.YYYY HH:mm:ss');
					let dateValue = mmnt.toDate();
					if (mmnt._isValid && !isNaN(dateValue.getTime())) {
						if (format) {
							value = moment(dateValue).locale(locale).format(columnDateFormat || 'DD.MM.YYYY HH:mm:ss');
						} else {
							value = dateValue.toUTCString();
						}
					} else {
						dateValue = new Date(value);
						if (!isNaN(dateValue.getTime())) {
							if (format) {
								value = moment(dateValue).locale(locale).format(columnDateFormat || 'DD.MM.YYYY HH:mm:ss');
							} else {
								value = dateValue.toUTCString();
							}
						}
					}
				}
			}

			// nothing matched so return original value
			return value;
		},
		onDateFormatChange() {
			if ($_.isEmpty($_.trim(this.dateFormat))) {
				this.dateFormat = null;
			}
		},
		toBoolean(value, allowNull = true, allowUndefined = true) {
			if (value === null) {
				if (allowNull) {
					return value;
				}
				return false;
			}
			if (value === undefined) {
				if (allowUndefined) {
					return value;
				}
				return false;
			}
			if (value.constructor === Boolean) return value;
			if (value.constructor === String) {
				if (value === 'null') {
					if (allowNull) {
						return value;
					}
					return false;
				}
				if (value === 'undefined') {
					if (allowUndefined) {
						return value;
					}
					return false;
				}
				// true OR t OR any number except 0
				return /^true|t|(\+|-)?[1-9][0-9]*$/i.test(value);
			}
			return !$_.isEmpty(value);
		},
		hasValue(value, allowSpace = false) {
			if (value == null) return false;
			if (value.constructor === Boolean) return true;
			if (value.constructor === Date) return true;
			if (value.constructor === String) return allowSpace ? !$_.isEmpty(value) : !$_.isEmpty($_.trim(value));
			if (value.constructor === Number) return !isNaN(value);
			return !$_.isEmpty(value);
		},
		setActiveTab(id) {
			this.activeTabId = id;
		},
		getColumnDelimiter() {
			return this.columnDelimiters.find((item) => item.value === this.columnDelimiter);
		},
		getQuoteCharacter() {
			return this.quoteCharacters.find((item) => item.value === this.quoteCharacter);
		},
		getColumnError(mapped) {
			if (!this.isMappedColumnValid(mapped)) {
				const tooltip = {
					content: this.$t('columnDuplicate', null, { attribute: mapped.title }),
					classes: ['lba-messages'],
					html: true,
				};
				return tooltip;
			}
		},
		getRowErrors(row) {
			if ($_.isEmpty(row) || $_.isEmpty(row.$rowErrors)) {
				return;
			}

			const tooltip = { content: '', classes: ['lba-messages'], html: true };
			row.$rowErrors.forEach((error) => {
				let message = null;
				const attrs = error.attributes;
				if (error.attributes.errorSubject) {
					message = this.$t(error.message, null, {
						errorSubject: this.$t(attrs.errorSubject),
						rowError: this.$t(attrs.rowError),
					});
				} else {
					message = this.$t(error.message, null, {
						errorSubject: '',
						rowError: this.$t(attrs.rowError),
					});
				}
				tooltip.content += `${message}<br>`;
			});
			return tooltip;
		},
		getColumnErrors(row, columnDefinition, mappedIndex) {
			const columnName = columnDefinition.name;
			if (
				$_.isEmpty(row) ||
				$_.isEmpty(row.$errors) ||
				$_.isEmpty(row.$errors[columnName])
			) {
				return;
			}

			const column = row[columnName];
			let currentIndex = null;

			if ((columnDefinition.multiple || (column && column.constructor === Array))) {
				currentIndex = 0;
				for (let i = 0; i < mappedIndex; i += 1) {
					currentIndex += (this.previewHeaderMap[i].name === columnName);
				}
			}

			const tooltip = { content: '', classes: ['lba-messages'], html: true };
			row.$errors[columnName].forEach((error) => {
				if (!!currentIndex && currentIndex >= 0 && error.index >= 0 && error.index !== currentIndex) return;
				error.attributes.attribute = $getLocale(error.attributes.attribute);
				const message = this.$t(error.message, null, error.attributes);
				tooltip.content = `${message}<br>`;
			});
			if ($_.isEmpty(tooltip.content)) return;
			return tooltip;
		},
		setTabChanged(step, changed = true) {
			this.dirty = true;

			// console.log(`(setTabChanged) step: ${step}, changed: ${changed}`);
			this.tabs[step].changed = changed;

			if (changed) {
				this.resetSteps();
			}
		},
		getPreviewRowValue(row, field, index) {
			if (row[field]) {
				let columnDefinition = null;
				if (row[field] && this.headerMap[index] && this.headerMap[index].column) {
					columnDefinition = this.importExportDefinition.columns.columns.find(
						(column) => column.name === this.headerMap[index].column
					);
				}

				return this.parseDate(
					row[field],
					columnDefinition && columnDefinition.type === 'date',
					columnDefinition && columnDefinition.date_format,
					this.dateFormat,
					this.$i18n.locale,
					true
				) || '-';
			}
			return row[field] || '-';
		},
		getRowValue(row, columnDefinition, mappedIndex) {
			const columnName = columnDefinition.name;

			if (
				$_.isEmpty(row) ||
				row[columnName] == null ||
				row[columnName] === '' ||
				(
					$_.isEmpty(row[columnName]) &&
					row[columnName].constructor !== Number &&
					row[columnName].constructor !== Boolean &&
					row[columnName].constructor !== Date
				)
			) return '-';
			const column = row[columnName];

			if (this.customColumn && this.customColumn.name === columnName) {
				const keys = Object.keys(column);
				const result = [];
				keys.forEach((key) => {
					if (this.customColumn.excludeExportKeys && this.customColumn.excludeExportKeys.includes(key)) return;
					result.push(`${key}: "${column[key] || ''}"`);
				});
				return result.join('; ');
			}
			if (columnDefinition.multiple || column.constructor === Array) {
				if (columnDefinition.spreadIntoMultipleColumns && mappedIndex >= 0) {
					let count = 0;
					for (let i = 0; i < mappedIndex; i += 1) {
						count += (this.previewHeaderMap[i].name === columnName);
					}

					const value = column[count];
					if (value != null && value !== '') {
						return value;
					}
					return '-';
				}
				return column.join(',');
			}
			if (columnDefinition.type === 'date') {
				const mmnt = moment(column, 'DD.MM.YYYY HH:mm:ss');
				let value = mmnt.toDate();
				if (mmnt._isValid && !isNaN(value.getTime())) {
					return moment(value).locale(this.$i18n.locale).format(columnDefinition.date_format || 'DD.MM.YYYY HH:mm:ss');
				}

				value = new Date(column);
				if (!isNaN(value.getTime())) {
					return moment(value).locale(this.$i18n.locale).format(columnDefinition.date_format || 'DD.MM.YYYY HH:mm:ss');
				}

				console.warn('[LbaTableImport](getRowValue) failed to parse date:', column);
			}
			return column;
		},

		resetStepsFrom(step) {
			// console.log(`(resetStepsFrom) step: ${step}`);
			// format settings and column mapping
			if (step <= 1) {
				this.fileType = 'csv';
				this.files = null;
				this.columnDelimiter = '';
				this.quoteCharacter = '"';
				this.encoding = 'UTF-8';
				this.containsHeader = null;
				this.containsHeaderSetByUser = false;
				if (this.$i18n.locale === 'cs') {
					this.dateFormat = 'DD.MM.YYYY';
				} else if (this.$i18n.locale === 'en') {
					this.dateFormat = 'MM.DD.YYYY';
				}
				this.headerFields = [];
				this.headerMap = [];
				this.tablePreview = [];
				this.validateData = false;
				this.fileUid = null;
				this.uploadRequestSent = false;
				this.uploadRequestDone = false;
				this.usedImportExportSettingUid = null;
				this.importExportSettingName = null;
				this.activeTabId = 0;
				this.columnsMapped = false;
				this.tabs[1].changed = false;
			}
			// data control
			if (step <= 2) {
				this.tableContent = [];
				this.totalCount = 0;
				this.validatedRows = 0;
				this.validationInfo = null;
				this.dataErrorCount = 0;
				this.validateData = true;
				this.firstStep = true;
				this.tabs[2].changed = false;

				if (step !== 2) this.tabs[2].disabled = true;
			}
			// import result
			if (step <= 3) {
				this.importErrors = [];
				this.importPreview = [];
				this.rowErrorCount = 0;
				this.importTotalCount = 0;
				this.importRequestSent = false;
				this.importRequestDone = false;
				this.importFailed = false;
				this.importTriesCount = 0;
				this.savingImportExportSetting = false;
				this.importExportSettingSaved = false;
				this.importingProgress = 0;
				this.importingProgressInfo = null;
				this.tabs[3].changed = false;

				if (step !== 3) this.tabs[3].disabled = true;
			}
		},
		resetSteps(force = false) {
			// console.log(`(resetSteps) force: ${force}`);
			const needsReload = this.importTotalCount > 0;
			const changedTabIndex = this.tabs.findIndex((tab) => tab.changed);
			if (changedTabIndex < 1 && !force) return;

			const resetFrom = force ? 0 : changedTabIndex + 1;
			this.resetStepsFrom(resetFrom);

			if (force) {
				this.$emit('close', { needsReload });
			}
		},

		async beforeTabSelected(fromStep, toStep) {
			if (toStep < 2) {
				if (toStep < 2 && fromStep === 2 && this.fileType === 'csv') {
					this.deleteUnusedFile();
				}
				return true;
			};
			if (toStep === 2 && this.columnsMapped) {
				if (this.tabs[1].changed) {
					await this.prepareValidation();
				}
				return true;
			}
			if (toStep === 3 && this.areDataValid) {
				this.prepareImportResult();
				return true;
			}

			return false;
		},

		// STEP 0
		async downloadExampleFile(fileType) {
			const header = [];
			const row = [];

			this.columns.forEach((column) => {
				if (column.custom) {
					row.push(...(Object.values(column.example)));
				} else {
					row.push(column.example);
				}
			});

			if (fileType === 'csv') {
				this.columns.forEach((column) => {
					if (column.custom) {
						header.push(...(Object.keys(column.example)));
					} else {
						header.push(column.title);
					}
				});

				const csv = this.$papa.unparse([header, row], { newline: '\n' });
				this.exampleFileHref = `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`;
				this.exampleFileName = 'example.csv';
			} else {
				const workbook = new this.$exceljs.Workbook();
				const worksheet = workbook.addWorksheet(`${this.$t('sheet')} 1`);

				this.columns.forEach((column) => {
					if (column.custom) {
						header.push(...(Object.keys(column.example).map((key) => ({ key, header: key }))));
					} else {
						header.push({ key: column.name, header: column.title });
					}
				});
				worksheet.columns = header;
				worksheet.addRow(row);
				const data = await workbook.xlsx.writeBuffer();
				const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
				const url = window.URL.createObjectURL(blob);
				this.exampleFileHref = url;
				this.exampleFileName = 'example.xlsx';
			}

			await this.$nextTick();
			this.$refs.exampleFile.click();
		},

		// STEP 1
		changeUnknownColumnAction() {
			this.setTabChanged(1);
			this.autoMapHeader();
		},
		async prepareImportExportSettings() {
			this.dirty = true;
			if (this.usedImportExportSettingUid == null) return;

			const importExportSetting = this.importExportSettings.find(
				(setting) => setting.def_import_export_setting_uid === this.usedImportExportSettingUid
			);
			this.quoteCharacter = importExportSetting.quote_character;
			this.columnDelimiter = importExportSetting.column_delimiter || '';
			this.encoding = importExportSetting.encoding;
			this.containsHeader = importExportSetting.contains_header;
			this.containsHeaderSetByUser = false;
			this.dateFormat = importExportSetting.date_format;
			this.importExportSettingName = importExportSetting.label;
			await this.prepareColumnMapping();
			if (this.fileContainsInvalidCharacters) return;

			if (!this.fileCanNotBeRead && !this.fileDoesNotExist && !this.fileIsMissingData) {
				this.mapWithImportExportSetting(importExportSetting, true);
			}
		},
		mapWithImportExportSetting(setting, setFileType = false) {
			let headerMap = $_.cloneDeep(setting.header_map);
			headerMap = headerMap.filter((mapped) => this.headerFields.includes(mapped.header));

			if (headerMap.length !== 0) {
				headerMap.forEach((mapped) => {
					const m1 = this.headerMap.find((m) => m.header === mapped.header);
					m1.column = mapped.column;
					this.columnMapChange(m1);
				});
			}

			if (setFileType && setting.file_type) {
				this.fileType = setting.file_type;
			}
		},

		isMappedColumnValid(mappedColumn) {
			if (mappedColumn.column == null) return true;

			const column = this.columns.find((col) => col.name === mappedColumn.column);
			if (column.custom || column.multiple) return true;

			return this.headerMap.find((mapped) => mapped.column === mappedColumn.column && mapped !== mappedColumn) == null;
		},
		validateMapping() {
			const required = [];
			const duplicates = [];

			for (let i = 0; i < this.headerMap.length; i += 1) {
				const mapped = this.headerMap[i];
				const column = this.columns.find((col) => col.name === mapped.column);

				if (column) {
					if (!column.custom && !column.multiple) {
						const duplicate = this.headerMap.find((mappedDuplicate) => (
							mappedDuplicate.column === mapped.column &&
							mappedDuplicate !== mapped
						));

						if (duplicate) {
							duplicates.push(column);
						}
					}

					if (column.require && !required.includes(column.name)) {
						required.push(column.name);

					} else if (this.inRequiredGroup(column.name) && !required.includes(column.name)) {
						const group = this.importExportDefinition.validation_schema.requiredGroups
							.find((item) => item.includes(column.name));
						required.push(...group);

					}
				}
			}

			if (required.length !== this.requiredColumns.length || duplicates.length > 0) {
				this.columnsMapped = false;
				this.tabs[2].disabled = true;
				this.tabs[1].tooltipNext = this.$t('mappingError');
				console.error(
					'[LbaTableImport](validateMapping) missing required columns or found duplicates,',
					'required:', required,
					'duplicates:', duplicates
				);
			} else {
				this.columnsMapped = true;
				this.tabs[2].disabled = false;
				this.tabs[1].tooltipNext = null;
			}
		},
		columnMapChange(mapped) {
			this.setTabChanged(1);

			if (mapped.column == null) {
				mapped.doImport = false;
				mapped.title = null;
				mapped.multiple = false;
				mapped.required = false;
				mapped.custom = false;
			} else {
				const column = this.columns.find((col) => col.name === mapped.column);
				mapped.doImport = true;
				mapped.title = column.title;
				mapped.required = column.required === true;
				mapped.multiple = column.multiple === true;
				mapped.custom = column.custom === true;
				mapped.isDate = column.type === 'date';
				mapped.dateFormat = column.date_format || null;
			}

			this.tabs[2].disabled = true;
			this.tabs[1].tooltipNext = this.$t('mappingError');
			this.validateMapping();
			this.previewKey = !this.previewKey;
		},

		async detectHeader() {
			if (!this.files || this.files.length === 0) return null;

			if (this.fileType === 'csv') {
				let anyEmpty = -1;
				await this.parseCSV(1, false, (result, parser) => {
					anyEmpty = result.data.findIndex((columnData) => columnData == null || columnData.toString().trim() === '');
					parser.abort();
				});

				if (anyEmpty >= 0) {
					return false;
				}
				return true;
			} else if (this.fileType === 'xlsx') {
				await this.uploadXLSX();
				return this.containsHeader;
			}
			return null;
		},

		async prepareColumnMapping() {
			await this.$nextTick();
			this.isParsing = true;
			this.parsingTimeStart = (new Date()).getTime();
			this.setParsingTime();

			this.headerFields = [];
			this.headerMap = [];
			this.previewHeaderMap = [];
			this.tablePreview = [];
			this.validateData = false;
			this.uploadRequestSent = false;
			this.uploadRequestDone = false;
			this.fileContainsInvalidCharacters = false;

			if (
				!this.containsHeaderSetByUser &&
				this.usedImportExportSettingUid == null &&
				this.containsHeader == null
			) {
				const containsHeader = await this.detectHeader();

				if (this.fileContainsInvalidCharacters) {
					this.isParsing = false;
					return;
				}

				this.containsHeader = containsHeader;
			} else if (this.fileType === 'xlsx') {
				await this.parse(5);
			}

			if (this.fileType === 'csv') {
				await this.parse(5);
				if ($_.isEmpty(this.tablePreview)) {
					this.fileIsMissingData = true;
				}
			}

			if (!this.fileCanNotBeRead && !this.fileDoesNotExist && !this.fileIsMissingData) {
				if (this.usedImportExportSettingUid) {
					const importExportSetting = this.importExportSettings.find(
						(setting) => setting.def_import_export_setting_uid === this.usedImportExportSettingUid
					);
					if (importExportSetting) {
						this.mapWithImportExportSetting(importExportSetting);
					}
				}
			}
			this.isParsing = false;
		},
		async uploadXLSX() {
			let result = null;

			if (this.fileUid) {
				const params = {
					containsHeader: this.containsHeader,
					fileType: this.fileType,
					detectHeader: !this.containsHeaderSetByUser,
					dateFormat: this.dateFormat,
					locale: this.$i18n.locale,
					totalCount: this.totalCount,
				};
				result = await this.tableImportModel.getFileDataInfo(this.fileUid, params);
			} else {
				const data = new FormData();
				data.append(this.files[0].name, this.files[0]);
				data.append('fileType', this.fileType);
				data.append('detectHeader', !this.containsHeaderSetByUser);
				data.append('dateFormat', this.dateFormat);
				data.append('locale', this.$i18n.locale);
				data.append('totalCount', this.totalCount);
				data.append('options', JSON.stringify({ containsHeader: this.containsHeader }));
				this.uploadRequestSent = true;
				result = await this.tableImportModel.uploadXLSX(data);
			}

			if (result.data.errors && result.data.errors.length > 0) {
				result.data.errors.forEach((error) => {
					if (error.fileCanNotBeRead) {
						this.fileCanNotBeRead = true;
					}
					if (error.fileDoesNotExist) {
						this.fileDoesNotExist = true;
					}
					if (error.fileIsMissingData) {
						this.fileIsMissingData = true;
					}
				});
				return;
			}

			if (!this.containsHeaderSetByUser && 'containsHeader' in result.data) {
				this.containsHeader = result.data.containsHeader;
			}

			this.fileUid = result.data.uid;
			this.headerFields = result.data.header;
			this.tablePreview = result.data.rows.map((item) => {
				if (item.values.constructor === Object) {
					return item.values;
				}
				const obj = { $index: item.$index };
				item.values.forEach((value, index) => {
					obj[index] = value;
				});
				return obj;
			});
			this.autoMapHeader();
		},
		async validateXLSX() {
			const params = {
				columnsMap: JSON.stringify(this.headerMap),
				gridName: this.name,
				containsHeader: this.containsHeader,
				fileType: this.fileType,
				dateFormat: this.dateFormat,
				locale: this.$i18n.locale,
				updateRecords: this.updateRecords,
				deleteRecords: this.deleteRecords,
				importType: this.importType,
				parent_uid: this.parentUid,
				totalCount: this.totalCount,
			};
			const checkStatusParams = {
				checkStatus: true,
				gridName: this.name,
				fileType: this.fileType,
			};
			try {
				const { data } = await this.$fetchCached(
					this,
					() => this.tableImportModel.validateFileData(this.fileUid, params),
					() => this.tableImportModel.validateFileData(this.fileUid, checkStatusParams),
					1000,
					true,
					this.onValidationResponse.bind(this)
				);
				this.importFileName = data.importFileName.ts_name;
				this.importFileUid = data.importRecordUid;
				this.setImportTableContent(data, this.fileType);
			} catch (error) {
				console.error('[lbadmin.LbaTableImport](validateXLSX)', error);
				this.areDataValid = false;
			}
		},
		inRequiredGroup(columnName) {
			return (
				!$_.isEmpty(this.importExportDefinition.validation_schema.requiredGroups) &&
				this.importExportDefinition.validation_schema.requiredGroups.some((group) => group.includes(columnName))
			);
		},
		prepareColumns() {
			this.requiredColumns = [];
			this.columns.forEach((column) => {
				column.require = (
					this.schema &&
					this.schema.required &&
					this.schema.required.includes(column.name)
				);

				if (column.require || this.inRequiredGroup(column.name)) {
					this.requiredColumns.push(column);
				}
			});

		},
		async onFileInput() {
			this.setTabChanged(1);
			await this.$nextTick();

			if (!this.files || this.files.length === 0) {
				this.tabs[2].disabled = true;
				this.tabs[3].disabled = true;
				this.tabs[1].tooltipNext = this.$t('fileMustBeSelected');
				return;
			}

			const fileName = this.files[0].name;
			const splitted = fileName.split('.');

			if (this.lastFile !== this.files[0]) {
				this.fileUid = null;
				this.lastFile = this.files[0];
			}

			if (splitted.length > 1) {
				if (splitted[splitted.length - 1].toLowerCase() === 'csv') {
					this.fileType = 'csv';
				} else if (splitted[splitted.length - 1].toLowerCase() === 'xlsx') {
					this.fileType = 'xlsx';
				} else {
					this.fileType = null;
				}
			}

			if (this.fileType != null) {
				await this.prepareColumnMapping();
			}
		},
		async parse(preview = null) {
			if (!this.files || this.files.length === 0) return;
			this.fileCanNotBeRead = false;
			this.fileDoesNotExist = false;
			this.fileIsMissingData = false;

			try {
				if (this.fileType === 'csv') await this.parseCSV(preview);
				if (this.fileType === 'xlsx') await this.uploadXLSX();
			} catch (error) {
				console.error(error);
				this.fileCanNotBeRead = true;
			}
		},
		formatTime(from) {
			const now = (new Date()).getTime();
			const diff = Math.round((now - from) / 1000);

			const minutes = (Math.floor(diff / 60));
			const seconds = diff % 60;
			return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
		},
		setParsingTime() {
			this.formattedParsingTime = this.formatTime(this.parsingTimeStart);

			if (this.isParsing) {
				setTimeout(this.setParsingTime, 1000);
			}
		},
		setCheckingTime() {
			this.formattedCheckingTime = this.formatTime(this.checkingTimeStart);

			if (this.isChecking) {
				setTimeout(this.setCheckingTime, 1000);
			}
		},
		setImportingTime() {
			this.formattedImportingTime = this.formatTime(this.importingTimeStart);

			if (this.isImporting) {
				setTimeout(this.setImportingTime, 1000);
			}
		},
		stringHasInvalidChars(string) {
			if (string == null || string.constructor !== String) return false;

			for (let i = 0; i < string.length; i += 1) {
				const charCode = string.charCodeAt(i);

				if (
					charCode < 32 &&
					charCode !== 9 &&	// TAB
					charCode !== 10 &&	// LF
					charCode !== 13		// CR
				) {
					return true;
				}
			}

			return false;
		},
		parseCSV(preview, header = this.containsHeader, step = this.onParseStep) {
			return new Promise((resolve, reject) => {
				// console.log(
				// 	`(parseCSV) preview: ${preview}, header: ${header}, delimiter: ${this.columnDelimiter},`,
				// 	`quoteCharacter: ${this.quoteCharacter}, encoding: ${this.encoding}`
				// );
				let rowNumber = 0;

				const config = {
					delimiter: this.columnDelimiter,
					quoteChar: this.quoteCharacter,
					header,
					// transformHeader: undefined,
					// preview: undefined,
					encoding: this.encoding,
					// worker: true,
					// comments: false,
					step: (result, parser) => {
						this.columnDelimiter = result.meta.delimiter;

						try {
							rowNumber += 1;

							const row = result.data;
							let invalidColumn = null;

							if (row.constructor === Object) {
								const columns = Object.keys(row);
								invalidColumn = columns.find((column) => (
									this.stringHasInvalidChars(column) ||			// check column header for invalid chars
									this.stringHasInvalidChars(row[column])		// check column value for invalid chars
								));
							} else {
								invalidColumn = row.find((column) => this.stringHasInvalidChars(column));
							}

							if (invalidColumn) {
								console.warn('[LbaTableImport](parseCSV) invalid data');
								this.fileContainsInvalidCharacters = true;
								parser.abort();
							}

							// https://github.com/mholt/PapaParse/issues/618
							if (preview != null && rowNumber >= preview) {
								parser.abort();
							}
							step(result, parser, rowNumber);
						} catch (error) {
							console.error(error);
							parser.abort();
						}
					},
					complete: () => {
						if (preview == null) {
							this.totalCount = rowNumber;
						}
						resolve();
					},
					error: reject,
					// download: false,
					// downloadRequestHeaders: undefined,
					// downloadRequestBody: undefined,
					skipEmptyLines: 'greedy',
					// chunk: undefined,
					// chunkSize: 1024 * 1024 * 5,
					// fastMode: undefined,
					// beforeFirstChunk: undefined,
					// withCredentials: undefined,
					transform: (value) => {
						if (value === '') return null;
						return value;
					},
				};
				this.firstStep = true;
				this.fileContainsInvalidCharacters = false;
				this.$papa.parse(this.files[0], config);
			});
		},
		getValue(value, schemaValidation = null, dateFormat = null, locale = 'en', column = null) {
			if (value == null) return null;
			if (!$_.isEmpty(schemaValidation) && !$_.isEmpty(schemaValidation.type)) {
				if (schemaValidation.type.includes('string')) {
					// it does not mutate cell if it is not date
					value = this.parseDate(value, column && column.isDate, column && column.dateFormat, dateFormat, locale);
					return String(value);
				}
				if (schemaValidation.type.includes('integer')) {
					return parseInt(value, 10);
				}
				if (schemaValidation.type.includes('number')) {
					return Number(value);
				}
				if (schemaValidation.type.includes('boolean') && value.constructor !== Boolean) {
					return this.toBoolean(value);
				}
			}
			return value;
		},
		mapRow(row) {
			const newRow = {};
			this.headerMap.forEach((mapped) => {
				if (!mapped.doImport) return;

				let value = this.getValue(
					row[mapped.header], $_.get(this.schema, `properties.${mapped.column}`), this.dateFormat, this.$i18n.locale, mapped
				);

				if (mapped.custom) {
					if (!newRow[mapped.column]) {
						newRow[mapped.column] = {};
					}

					if (
						mapped.header.constructor === Number ||
						mapped.header.toLowerCase() === this.customColumn.title.toLowerCase() ||
						mapped.header.toLowerCase() === this.customColumn.name.toLowerCase()
					) {
						if (!this.hasValue(value)) return;

						try {
							value = JSON.parse(value);
						} catch (error) {}

						if (value && value.constructor === Object) {
							Object.keys(value).forEach((key) => {
								// it does not mutate cell if it is not date
								value[key] = this.parseDate(
									value[key], mapped.isDate, mapped.dateFormat, this.dateFormat, this.$i18n.locale
								);
							});
							newRow[mapped.column] = { ...newRow[mapped.column], ...value };
						} else if (!value || value.constructor !== Object) {
							// it does not mutate cell if it is not date
							value = this.parseDate(value, mapped.isDate, mapped.dateFormat, this.dateFormat, this.$i18n.locale);
							newRow[mapped.column][mapped.header] = value;
						}
					} else {
						// it does not mutate cell if it is not date
						value = this.parseDate(value, mapped.isDate, mapped.dateFormat, this.dateFormat, this.$i18n.locale);
						newRow[mapped.column][mapped.header] = value;
					}
				} else if (mapped.multiple) {
					if (newRow[mapped.column] === undefined) {
						newRow[mapped.column] = null;
					}
					if (this.hasValue(value)) {
						let matches = null;
						// also excludes type number
						if (!$_.isEmpty(mapped.header)) {
							matches = mapped.header.match(/\d+$/g);
						}
						let inGroup = false;
						if (!$_.isEmpty(this.importExportDefinition.columns) && !$_.isEmpty(this.importExportDefinition.columns.groups)) {
							inGroup = this.importExportDefinition.columns.groups.some((group) => group.includes(mapped.column));
						}
						if (!$_.isEmpty(matches) && inGroup) {
							const valueIndex = parseInt(matches[0]) - 1;
							if (valueIndex >= 0) {
								const itemValue = this.getValue(value.trim(), $_.get(this.schema, `properties.${mapped.column}.items`));
								if (itemValue != null && itemValue !== '') {
									if ($_.isEmpty(newRow[mapped.column])) newRow[mapped.column] = [];
									newRow[mapped.column][valueIndex] = itemValue;
								}
							}
						} else {
							const values = [];
							let hasNonNullValue = false;
							value.split(',')
								.forEach((item) => {
									const itemValue = this.getValue(item.trim(), $_.get(this.schema, `properties.${mapped.column}.items`));

									// we need to preserve length (abook2 address)
									if (this.hasValue(value)) {
										hasNonNullValue = true;
										values.push(itemValue);
									} else {
										values.push(null);
									}
								});
							if (hasNonNullValue) {
								if ($_.isEmpty(newRow[mapped.column])) newRow[mapped.column] = [];
								newRow[mapped.column].push(...values);
							}
						}
					}
				} else {
					newRow[mapped.column] = value;
				}
			});
			Object.keys(newRow).forEach((key) => {
				if (!$_.isEmpty(newRow[key]) && newRow[key].constructor === Array) {
					for (let i = 0; i < newRow[key].length; i += 1) {
						if (newRow[key][i] === undefined) {
							newRow[key][i] = null;
						}
					}
				}
			});
			return newRow;
		},
		validateRow(row) {
			const valid = this.validate(row);

			if (!valid) {
				const validationErrors = $_.cloneDeep(this.validate.errors) || [];
				const errors = this.prepareRowErrors(validationErrors);
				return { errors, valid };
			}

			const dateErrors = [];
			Object.keys(row).forEach((columnName) => {
				const columnDefinition = this.columns.find((column) => column.name === columnName);
				if (columnDefinition && columnDefinition.type === 'date' && !this.validateDate(row[columnName], columnDefinition.format)) {
					const error = {
						instancePath: `/${columnName}`,
						keyword: 'format',
					};
					dateErrors.push(error);
				}
			});
			const errors = this.prepareRowErrors(dateErrors);
			return { errors, valid: dateErrors.length === 0 };
		},
		autoMapHeader() {
			let customColumn = null;

			if (this.unknownColumnAction === 'customColumn') {
				customColumn = this.columns.find((column) => column.custom);
			}

			this.headerMap = [];
			this.previewHeaderMap = [];
			const columnNameRe = /(?<columnName>\D+)\d+$/m;

			this.headerFields.forEach((field) => {
				const mappedColumn = {
					doImport: false,
					header: field,
					title: null,
					column: null,
					required: false,
					multiple: false,
					spreadIntoMultipleColumns: false,
					custom: false,
					isDate: false,
					dateFormat: null,
				};
				let column = null;

				if (field.constructor === Number) {
					column = this.columns[field];
				} else {
					const fieldIndexMatch = field.toLowerCase().match(columnNameRe);
					const columnName = $_.trim($_.get(fieldIndexMatch, 'groups.columnName'));
					column = this.columns.find((col) => (
						(
							columnName && col.multiple && (
								col.name.toLowerCase() === columnName ||
								col.title.toLowerCase() === columnName
							)
						) ||
						col.name.toLowerCase() === field.toLowerCase() ||
						col.title.toLowerCase() === field.toLowerCase()
					));
				}

				if (!column) {
					column = customColumn;
				}
				if (column) {
					mappedColumn.doImport = true;
					mappedColumn.title = column.title;
					mappedColumn.column = column.name;
					mappedColumn.required = column.required === true;
					mappedColumn.multiple = column.multiple === true;
					mappedColumn.spreadIntoMultipleColumns = column.spreadIntoMultipleColumns === true;
					mappedColumn.custom = column.custom === true;
					mappedColumn.isDate = column.type === 'date';
					mappedColumn.dateFormat = column.date_format;
				}

				this.headerMap.push(mappedColumn);
			});
			this.validateMapping();
		},
		onParseStep(result, parser, rowNumber) {
			try {
				if (this.abortParsing) {
					parser.abort();
					return;
				}

				const rawRow = result.data;

				if (this.firstStep) {
					this.firstStep = false;

					if (this.containsHeader) {
						this.headerFields = result.meta.fields;

						const invalidField = this.headerFields.find((headerField) => $_.isEmpty(headerField));

						if (invalidField) {
							console.error('Invalid header field found, probably does not contain header');
							parser.abort();
							return;
						}
					} else {
						this.headerFields = [];
						for (let i = 0; i < rawRow.length; i += 1) {
							this.headerFields.push(i);
						}
					}

					if (this.headerMap.length === 0) this.autoMapHeader();
				}

				if (this.validateData) {
					const row = this.mapRow(rawRow);
					const validation = this.validateRow(row);
					row.$index = rowNumber + this.containsHeader;

					if (!validation.valid) {
						this.dataErrorCount += 1;
						row.$errors = validation.errors;

						if (this.dataErrorCount === 1) {
							this.tableContent = [];
						}

						if (this.tableContent.length < 1000) {
							this.tableContent.push(row);
						}
					} else if (this.dataErrorCount === 0 && this.tableContent.length < 1000) {
						this.tableContent.push(row);
					}
				} else {
					rawRow.$index = rowNumber + this.containsHeader;
					this.tablePreview.push(rawRow);
				}
			} catch (error) {
				console.error(error);
			}
		},
		prepareRowErrors(validationErrors) {
			const errors = {};
			const reIndex = /\/(?<index>\d+)$/;
			this.headerMap.forEach((mapped) => {
				if (this.hasValue(mapped.column)) {
					errors[mapped.column] = [];
				}
			});
			validationErrors.forEach((validationError) => {
				// ignore anyOf because it contains required which should be in validationErrors
				if (validationError.schemaPath === '#/anyOf') return;
				let columnName = null;

				// in case required validation failed
				if ($_.isEmpty(validationError.instancePath) && !$_.isEmpty($_.get(validationError, 'params.missingProperty'))) {
					columnName = validationError.params.missingProperty;
				} else {
					columnName = validationError.instancePath.split('/')[1];
				}

				if ($_.isEmpty(columnName)) {
					throw new Error(`failed to get column name from validation error: ${JSON.stringify(validationError)}`);
				}

				const column = this.columns.find((col) => col.name === columnName);
				if ($_.isEmpty(column)) {
					throw new Error(
						`failed to find definition column with name: ${columnName} for validation error: ${JSON.stringify(validationError)}`
					);
				}

				/* if ($_.isEmpty(validationError.instancePath)) {
					console.debug('empty instance path:', validationError);
					return;
				} */

				// errors[columnName].push({ message: `validation.${validationError.keyword}`, attributes: { attribute: column.title } });
				const matchIndex = validationError.instancePath.match(reIndex);
				const error = {
					keyword: validationError.keyword,
					message: `validation.${validationError.keyword}`,
					attributes: {
						attribute: column.title,
						...validationError.attributes,
					},
				};
				if ($_.get(matchIndex, 'groups.index')) {
					const index = parseInt(matchIndex.groups.index, 10);
					if (index >= 0) {
						error.index = index;
					}
				}

				let group = null;
				let mappedWithErrorColumns = null;
				const isMapped = this.headerMap.some((item) => item.column === columnName);

				if (!$_.isEmpty(this.importExportDefinition.validation_schema.requiredGroups)) {
					group = this.importExportDefinition.validation_schema.requiredGroups.find((item) => item.includes(columnName));

					if (!$_.isEmpty(group)) {
						mappedWithErrorColumns = this.headerMap.filter((item) => group.includes(item.column));

						if (!$_.isEmpty(mappedWithErrorColumns)) {
							// required is in root of json schema, validates if key is present
							// require is in column of json schema and validates if key contains some value
							// both of those are present in anyOf so they might appear multiple times in validationErrors
							if (validationError.keyword === 'required' || validationError.keyword === 'require') {
								// already set when found column errors and some error contains require/d
								const errorAlreadySet = mappedWithErrorColumns.some((item) => (
									!$_.isEmpty(errors[item.column]) &&
									errors[item.column].some((columnError) => (
										columnError.keyword === 'required' ||
										columnError.keyword === 'require'
									))
								));
								if (errorAlreadySet) return;
							}

							if (!isMapped) {
								return mappedWithErrorColumns.forEach((item) => {
									error.attributes.attribute = item.title;
									errors[item.column].push(error);
								});
							}
						}
					}
				}

				// if column with error in map
				if (isMapped) {
					errors[columnName].push(error);
				} else {
					throw new Error(
						`failed to set error to column: ${columnName}, column not found in map`
					);
				}
			});
			return errors;
		},

		// STEP 3
		async prepareValidation() {
			this.validate = window[`validation${this.defImportExportUid}`];

			if (this.importRequestSent) return;

			// set mapping changed to false so it does not have to validate/import again when nothing changed
			this.tabs[1].changed = false;
			this.isChecking = true;
			this.tabs[1].disabledArrowNext = true;
			this.checkingTimeStart = (new Date()).getTime();
			this.setCheckingTime();
			this.validateData = true;
			this.totalCount = 0;
			this.dataErrorCount = 0;
			this.tableContent = [];

			try {
				if (this.fileType === 'csv') {
					await this.parseCSV();
					await this.validateCSV();
				} else if (this.fileType === 'xlsx') {
					await this.validateXLSX();
				}
			} catch (error) {
				console.error('[lbadmin.LbaTableImport](prepareValidation)', error);
				this.areDataValid = false;
			}

			if (this.areDataValid) {
				this.tabs[2].tooltipNext = null;
				this.tabs[2].disabledArrowNext = false;
				this.tabs[3].disabled = false;
				this.tabs[3].disabledTab = true;
			} else {
				this.tabs[2].tooltipNext = this.$t('invalidData');
				this.tabs[2].disabledArrowNext = true;
				this.tabs[3].disabled = true;
			}

			this.prepareHeader(this.tableContent);

			this.isChecking = false;
			this.tabs[1].disabledArrowNext = false;
		},
		// STEP 4
		prepareImportResult(validated = true) {
			this.tabs[3].disabledTab = false;

			if (
				(!this.fileType && this.totalCount === 0) ||
				(this.importRequestSent && !this.tabs[1].changed && !this.importFailed) ||
				(this.importRequestSent && !this.tabs[1].changed && this.importFailed && this.importTriesCount >= 2)
			) {
				return;
			}

			this.isImporting = true;
			this.importRequestDone = false;
			this.importingTimeStart = (new Date()).getTime();
			this.setImportingTime();
			const info = {
				file: this.files[0],
				fileType: this.fileType,
				columnsMap: this.headerMap,
				dateFormat: this.dateFormat,
				locale: this.$i18n.locale,
				totalCount: this.totalCount,
				uid: this.importFileUid,
				responseCallback: this.onImportResponse.bind(this),
			};

			if (this.updateEnabled) {
				info.updateRecords = this.updateRecords;
			}
			if (this.deleteEnabled) {
				info.deleteRecords = this.deleteRecords;
			}

			if (this.fileType === 'csv') {
				info.options = {
					delimiter: this.columnDelimiter,
					quoteChar: this.quoteCharacter,
					header: this.containsHeader,
					encoding: this.encoding,
				};
			} else {
				info.containsHeader = this.containsHeader;
				info.dateFormat = this.dateFormat;
				info.locale = this.$i18n.locale;
				info.uid = this.fileUid;
			}

			if (this.importTriesCount > 0) {
				info.abortOnError = false;
			}

			if (this.externalIdCheck && this.externalIdCheck.existing) {
				info.existing = this.externalIdCheck.existing;
			}

			if (validated) {
				this.$emit('submit', info);
				this.importTriesCount += 1;
				this.importRequestSent = true;
			} else {
				return info;
			}
		},
		onImportDone(result) {
			if (result.name === this.name) {
				this.importErrors = result.errors || [];
				this.importPreview = result.rows || [];
				this.rowErrorCount = result.rowErrorCount || 0;
				this.importTotalCount = result.totalRowCount || 0;

				// some fatal errors - no more tries
				if (!$_.isEmpty(this.importErrors) || this.rowErrorCount === this.importTotalCount) {
					this.importFailed = true;
					this.importTriesCount = 2;
				} else if (this.importTriesCount === 1 && this.rowErrorCount > 0) {
					this.importFailed = true;
				} else {
					this.importFailed = false;
				}

				this.importRequestDone = true;
				this.isImporting = false;
			}
		},
		hasImportExportSettingChanged() {
			if (this.usedImportExportSettingUid) {
				const keys = [
					'quote_character',
					'column_delimiter',
					'encoding',
					'contains_header',
					'date_format',
					'file_type',
					'name',
					'header_map',
				];
				const oldSetting = $_.find(this.importExportSettings, { def_import_export_setting_uid: this.usedImportExportSettingUid });
				const newHeaderMap = this.currentImportExportSetting.header_map;

				const diff = keys.find((key) => {
					if (key === 'header_map') {
						const oldHeaderMap = oldSetting[key];
						if (oldHeaderMap.length !== newHeaderMap.length) return true;

						if (this.currentImportExportSetting.contains_header) {
							const exist = oldHeaderMap.find((oldMapped) => (
								newHeaderMap.find((newMapped) => (
									oldMapped.column === newMapped.column &&
									oldMapped.header === newMapped.header
								))
							));

							if (!exist) return true;
						}
						return JSON.stringify(oldSetting[key]) !== JSON.stringify(newHeaderMap);
					}
					return oldSetting[key] !== this.currentImportExportSetting[key];
				});

				return diff != null;
			}

			return true;
		},
		async createImportExportSetting() {
			try {
				this.savingImportExportSetting = true;
				const result = await this.importExportSettingsModel.create(this.currentImportExportSetting);
				this.importExportSettings.push(result.data);
				this.$notify.success(this.$t('form.saved'));
				this.importExportSettingSaved = true;
			} catch (error) {
				console.error(error);
				this.$notify.warn(this.$t('form.save_failed'));
			}
			this.savingImportExportSetting = false;
		},
		async saveImportExportSetting() {
			try {
				this.savingImportExportSetting = true;
				const result = await this.importExportSettingsModel.save(this.usedImportExportSettingUid, this.currentImportExportSetting);
				const index = this.importExportSettings.findIndex(
					(setting) => setting.def_import_export_setting_uid === this.usedImportExportSettingUid
				);
				this.importExportSettings.splice(index, 1, result.data);
				this.$notify.success(this.$t('form.saved'));
				this.importExportSettingSaved = true;
			} catch (error) {
				console.error(error);
				this.$notify.warn(this.$t('form.save_failed'));
			}
			this.savingImportExportSetting = false;
		},
		prepareHeader(data) {
			if ($_.isEmpty(data)) return;

			this.previewHeaderMap = $_.cloneDeep(
				this.importExportDefinition.columns.columns
					.filter((column) => column.allowImport && this.headerMap.find((mapped) => mapped.column === column.name))
			);

			// find columns with multiple and column spread
			const multipleColumns = this.previewHeaderMap.filter((column) => column.multiple && column.spreadIntoMultipleColumns);
			let columnsCount = {};

			// find count of new spread columns
			multipleColumns.forEach((column) => {
				columnsCount[column.name] = 0;
			});
			data.forEach((row) => {
				multipleColumns.forEach((column) => {
					if (!$_.isEmpty(row[column.name]) && row[column.name].length > columnsCount[column.name]) {
						columnsCount[column.name] = row[column.name].length;
					}
				});
			});

			for (let i = this.previewHeaderMap.length - 1; i >= 0; i -= 1) {
				const header = this.previewHeaderMap[i];
				if (header.multiple && header.spreadIntoMultipleColumns) {
					let max = columnsCount[header.name];

					if (!$_.isEmpty(this.importExportDefinition.columns.groups)) {
						// find group and max length item of it
						const group = this.importExportDefinition.columns.groups.find((item) => item.includes(header.name));
						group?.forEach((column) => {
							if (columnsCount[column] > max) {
								max = columnsCount[column];
							}
						});
					}

					// add number to column only when more than 1 spread columns
					if (max > 1) {
						header.originalHeader = header.title;
						header.title = `${header.originalHeader} 1`;
						header.spreadIntoMultipleColumns = true;
						// add new spreaded columns
						for (let j = 1; j < max; j += 1) {
							const newHeader = $_.cloneDeep(header);
							// number starting from 1
							newHeader.title = `${newHeader.originalHeader} ${j + 1}`;
							newHeader.spreadIntoMultipleColumns = true;
							this.previewHeaderMap.splice(i + j, 0, newHeader);
						}
					}
				}
			}

			const flatHeaderMap = this.previewHeaderMap.map((header) => header.name);
			// remake it again, so it is updated
			columnsCount = $_.countBy(flatHeaderMap);
			const uniqueFlatHeaderMap = $_.uniq(flatHeaderMap);

			this.importExportDefinition.columns.groups.forEach((group) => {
				let firstIndex = null;
				let lastIndex = null;
				let distinctColumnsCount = 0;
				let lastHeader = null;
				const totalGroupColumns = uniqueFlatHeaderMap.filter((item) => group.includes(item));
				if ($_.isEmpty(totalGroupColumns)) return;

				// find some item from group
				// once found, check all exported items from group are next to each other
				for (let i = 0; i < this.previewHeaderMap.length; i += 1) {
					const header = this.previewHeaderMap[i];

					if (firstIndex == null && group.includes(header.name)) {
						firstIndex = i;
						lastIndex = i;
						lastHeader = header.name;
						distinctColumnsCount += 1;
					} else if (firstIndex !== null) {
						if (!group.includes(header.name)) {
							break;
						}
						if (lastHeader !== header.name) {
							lastHeader = header.name;
							distinctColumnsCount += 1;
						}
						lastIndex = i;
					}
				}

				// they are all in next to each other
				if (distinctColumnsCount === totalGroupColumns.length) {
					// group columns together
					// find column length
					let max = 0;
					group.find((column) => {
						if (columnsCount[column] > max) {
							max = columnsCount[column];
						}
					});

					const originalMap = $_.cloneDeep(this.previewHeaderMap);
					this.previewHeaderMap.splice(firstIndex, lastIndex + 1 - firstIndex);
					lastIndex = firstIndex;

					for (let i = 0; i < max; i += 1) {
						totalGroupColumns.forEach((groupColumn, index) => {
							const originalColumn = $_.cloneDeep(originalMap.find((item) => item.name === groupColumn));
							// add number only when there is more than 1 value
							if (max !== 1) {
								originalColumn.title = `${originalColumn.originalHeader || originalColumn.title} ${i + 1}`;
							}
							this.previewHeaderMap.splice(lastIndex, 0, originalColumn);
							lastIndex += 1;
						});
					}

				}
			});
		},
		setImportTableContent(data, type) {
			if (type === 'xlsx') {
				this.rowErrorCount = data.rowErrorCount;
				this.tableContent = data.rows;
				this.areDataValid = data.errors.length === 0 && data.rowErrorCount === 0;
				this.dataErrorCount = data.rowErrorCount;
				this.totalCount = data.totalRowCount;
				if (
					data.checkExternal &&
					(data.checkExternal.add ||
					data.checkExternal.update ||
					data.checkExternal.delete)
				) {
					this.externalIdCheck = {
						add: data.checkExternal.add,
						update: data.checkExternal.update,
						delete: data.checkExternal.delete,
						existing: data.checkExternal.existing,
					};
				}
			} else if (type === 'csv') {
				this.rowErrorCount = data.rowErrorCount;
				this.tableContent = [...data.rows];
				this.dataErrorCount = this.rowErrorCount;
				if (this.rowErrorCount > 0) {
					this.areDataValid = false;
					this.rowErrorCount = [...this.tableContent];
				} else {
					this.areDataValid = true;
				}
				if (
					data.checkExternal &&
					(data.checkExternal.add ||
					data.checkExternal.update ||
					data.checkExternal.delete)
				) {
					this.externalIdCheck = {
						add: data.checkExternal.add,
						update: data.checkExternal.update,
						delete: data.checkExternal.delete,
						existing: data.checkExternal.existing,
					};
				}
			}
		},
		onValidationResponse(response) {
			if (response?.headers?.['x-lbadmin-progress'] != null) {
				const validatedRows = parseInt(response.headers['x-lbadmin-progress']);
				if (validatedRows >= 0) {
					this.validatedRows = validatedRows;
				}
			}
			if (response?.headers?.['x-lbadmin-progress-info'] != null) {
				this.validationInfo = response.headers['x-lbadmin-progress-info'];
			}
		},
		onImportResponse(response) {
			if (response?.headers?.['x-lbadmin-progress'] != null) {
				this.importingProgress = Math.round(parseFloat(response.headers['x-lbadmin-progress']) * 100) / 100;
			}
			if (response?.headers?.['x-lbadmin-progress-info'] != null) {
				this.importingProgressInfo = response.headers['x-lbadmin-progress-info'];
			}
		},
		async validateCSV() {
			const info = this.prepareImportResult(false);
			const formData = new FormData();
			const cacheUid = Math.random();
			formData.append(info.file.name, info.file);
			formData.append('columnsMap', JSON.stringify(info.columnsMap));
			formData.append('options', JSON.stringify(info.options));
			formData.append('gridName', this.name);
			formData.append('parentUid', this.parentUid);
			formData.append('importType', this.importType);
			formData.append('abortOnError', info.abortOnError);
			formData.append('updateRecords', info.updateRecords);
			formData.append('deleteRecords', info.deleteRecords);
			formData.append('locale', info.locale);
			formData.append('dateFormat', info.dateFormat);
			formData.append('totalCount', info.totalCount);
			formData.append('cacheUid', cacheUid);
			const checkStatusData = new FormData();
			checkStatusData.append('checkStatus', true);
			checkStatusData.append('gridName', this.name);
			checkStatusData.append('cacheUid', cacheUid);
			try {
				const { data } = await this.$fetchCached(
					this,
					() => this.tableImportModel.validateCSVData(formData, info.options.encoding),
					() => this.tableImportModel.validateCSVData(checkStatusData, info.options.encoding),
					500,
					true,
					this.onValidationResponse.bind(this)
				);
				this.importFileName = data.importFileName.ts_name;
				this.importFileUid = data.importRecordUid;
				this.setImportTableContent(data, 'csv');
				this.areDataValid = this.dataErrorCount === 0 && !this.fileContainsInvalidCharacters;
			} catch (error) {
				console.error('[lbadmin.LbaTableImport](validateCSV)', error);
				this.areDataValid = false;
			}
		},
		async deleteUnusedFile() {
			if (this.importFileName && this.importFileUid) {
				await this.tableImportModel.deleteFile({
					uid: this.importFileUid,
					name: this.importFileName,
				});
			}
		},
	},
};
</script>
