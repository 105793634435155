<template>
	<div id="content">
		<div class="page-header">
			<h1>{{ $t('settings.menu.groups') }}</h1>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
		>
			<lba-grid
				:collection="groups"
				:rowId="['role_uid']"
				:filterAttributes="filterAttributes"
				multiSelectAll
				ref="grid"
				hideableColumns
				gridName="settings.groups"
				:dynamicHeight="false"
				sendVisibleColumnNames
			>
				<template v-slot:toolbar="props">
					<button
						v-if="!readOnly"
						:data-cy="`${props.parentComponentId}__new`"
						@click="openNew()">
						{{ $t("new") }}
					</button>
				</template>
				<template v-slot:selected="props">
					<button
						v-if="!readOnly"
						class="buttonInverse"
						:data-cy="`${props.parentComponentId}__remove`"
						@click="openRemoveDialog">
						{{ $t("remove") }}
					</button>
				</template>
				<template v-slot:columns="props">
					<lba-grid-column
						id="role_name"
						:label="$t('name')"
						searchable
						sortable
						width="200"
						:data-cy="`${props.parentComponentId}__role_name`"
					>
						<a :data-cy="`${props.parentComponentId}__role_name__openDetail`" @click="openDetail(props.row.role_uid)">
							{{ props.row.role_name }}
						</a>
					</lba-grid-column>

					<lba-grid-column
						id="members"
						:label="$t('settings.assignedUsers')"
						searchable
						sortable
						width="500"
						:title="props.row.members"
						:data-cy="`${props.parentComponentId}__assigned_users`"
					>
						{{ props.row.members }}
					</lba-grid-column>

					<lba-grid-column
						id="team_members"
						:label="$t('settings.assignedGroups')"
						searchable
						sortable
						width="500"
						:title="props.row.team_members"
						:data-cy="`${props.parentComponentId}__assigned_teams`"
					>
						{{ props.row.team_members }}
					</lba-grid-column>

				</template>
			</lba-grid>
			<lba-confirm-remove-dialog
				parentComponentId="groups"
				componentId="remove"
				name="settings-groups-remove-dialog"
				:title="$t('settings.deleteGroups')"
				:on-confirm="removeSelected"
			></lba-confirm-remove-dialog>
		</div>
	</div>
</template>

<script>
import GroupModel from '../models/Group';
import OnScroll from '../mixins/OnScroll';

export default {
	name: 'Groups',
	mixins: [
		OnScroll,
	],
	data() {
		return {
			groups: [],
			loaded: false,
			selectedItems: {},
			filterAttributes: {},
			groupModel: null,
			readOnly: false,
		};
	},
	created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.groupModel = new GroupModel(this.$http);
		this.groups = new this.$Collection(this.groupModel, {
			params: {
				_order: 'role_name',
				_order_dir: 'asc',
			},
		});

		this.groups.attach(this, 'groups');

		this.filterAttributes = {
			module: 'settings.groups',
			columns: [
				{
					name: 'role_name',
					label: this.$t('name'),
					type: 'text',
				},
				{
					name: 'members',
					label: this.$t('settings.assignedUsers'),
					type: 'text',
				},
				{
					name: 'team_members',
					label: this.$t('settings.assignedGroups'),
					type: 'text',
				},
			],
		};
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.team.write');
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'settings-group',
				params: { id: id, openNewTab: true },
			});
		},

		openNew() {
			this.$routerWrap.push({
				name: 'settings-group',
				params: { id: '+', openNewTab: true },
			});
		},

		async removeSelected() {
			if (this.selectedItems) {
				try {
					await this.groupModel.bulkDelete(this.selectedItems);
					this.$root.$emit('content.removed', { noBack: true });
					this.$root.$emit('dialog-close', 'settings-groups-remove-dialog');
				} catch (err) {
					this.$root.$emit('content.removeFailed');
				}
			}
		},
		openRemoveDialog() {
			this.selectedItems = this.$refs.grid.getChecked();
			if (this.selectedItems) {
				this.$root.$emit('dialog-open', {
					name: 'settings-groups-remove-dialog',
					gridChecked: this.selectedItems,
				});
			}
		},
	},
};
</script>
