<template>
	<div id="content" v-if="isPageLoaded">
		<div class="page-header">
			<h1>
				{{ $t('documentation') }}
			</h1>
			<div class="page-controls">
				<span @click="tabReload" data-cy="documentation__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			ref="pageContent"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<template v-if="$_.isEmpty(tableOfContent)">
				<div class="error-wrap">
					<h1>{{ $t('noDocumentationAvailable') }}</h1>
				</div>
			</template>
			<div v-else class="markdown-body markdown-index" ref="markdown" v-html="tableOfContent"></div>
		</div>
	</div>
</template>

<script>
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import DocumentationModel from '../models/Documentation';

export default {
	name: 'Documentation',
	mixins: [
		Page,
		OnScroll,
	],
	data() {
		return {
			documentationModel: null,
			isPageLoaded: false,
			isLoadingData: true,
			tableOfContent: null,
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}

		this.documentationModel = new DocumentationModel(this.$http);

		try {
			await this.fetch();
			this.$root.$listen('user.updated', this.fetch, this);
			this.isPageLoaded = true;
			await this.$nextTick();
			if (this.$refs && this.$refs.pageContent) {
				this.$refs.pageContent.focus();
				this.$refs.pageContent.scrollTo(0, 0);
			}
			this.prepareLinks();
		} catch (error) {
			console.error('[lbadmin.Documentation](created) error:', error);
		}
	},
	methods: {
		prepareLinks() {
			if (!this.$refs || !this.$refs.markdown) {
				return;
			}

			const links = this.$refs.markdown.querySelectorAll('a');
			$_.forEach(links, (link) => {
				const linkAddress = new URL(link.href);
				link.href = '';
				const splittedPath = linkAddress.pathname.split('/');
				if ($_.isEmpty(splittedPath) || splittedPath.length !== 4) {
					console.error(
						'[lbadmin.Documentation](prepareLinks) invalid link path:', linkAddress.pathname,
						'expected: /documentation/:module/:file'
					);
					return;
				}

				const moduleName = splittedPath[2];
				if ($_.isEmpty(moduleName)) {
					console.error(
						'[lbadmin.Documentation](prepareLinks) invalid link path:', linkAddress.pathname,
						'missing module, expected: /documentation/:module/:file'
					);
					return;
				}

				const fileName = splittedPath[3];
				if ($_.isEmpty(fileName)) {
					console.error(
						'[lbadmin.Documentation](prepareLinks) invalid link path:', linkAddress.pathname,
						'missing file, expected: /documentation/:module/:file'
					);
					return;
				}

				link.addEventListener('click', (event) => {
					event.preventDefault();
					this.$routerWrap.push({
						name: 'documentation-part',
						params: {
							module: moduleName,
							fileName,
							openNewTab: event.ctrlKey,
						},
					});
				});
			});
		},
		async fetch() {
			this.isLoadingData = true;
			const { data } = await this.$axiosFetch(this, this.documentationModel.getTableOfContent({ locale: this.$user.lang }));
			if ($_.isEmpty(data)) {
				throw new Error('Received empty data');
			}
			this.tableOfContent = data;
			this.isLoadingData = false;
		},
	},
};
</script>
