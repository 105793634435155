<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired || isRequiredGroup" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<span>{{ data[key] }}</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputSimpleText',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	computed: {
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && !this.data[this.key]) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
	},
};
</script>
