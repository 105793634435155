<template>
	<component
		v-if="timeFormat === 'relative'"
		:is="rootTag"
		v-tooltip="(date && $d(new Date(date), dateFormat)) || ''"
	>
		{{ dateBeforeAfter }}
	</component>
	<component
		v-else
		:is="rootTag"
		v-tooltip="(dateBeforeAfter || '')"
	>
		{{ date && $d(new Date(date), dateFormat) }}
	</component>
</template>

<script>
export default {
	name: 'LbaDatetime',
	props: {
		date: [String, Number, Date],
		rootTag: {
			type: String,
			default: 'span',
		},
		dateFormat: {
			type: String,
			default: 'fullDigitsMedium',
		},
		beforeAfterFormat: {
			type: String,
			default: 'long',
			validator(value) {
				return $_.isEmpty(value) || ['long', 'short'].includes(value);
			},
		},
		useBeforePrefix: {
			type: Boolean,
			default: true,
		},
		useAfterPrefix: Boolean,
		datetimeFormat: {
			type: String,
			validator(value) {
				return [null, 'absolute', 'relative'].includes(value);
			},
		},
		isInGrid: Boolean,
	},
	data() {
		return {
			timeFormat: 'absolute',
			interval: null,
			dateBeforeAfter: null,
		};
	},
	watch: {
		datetimeFormat() {
			this.setTimeFormat();
		},
	},
	created() {
		if (this.isInGrid) {
			this.setTimeFormat();
			this.$root.$listen('user.updated', this.setTimeFormat, this, true);
		}

		this.dateBeforeAfter = this.$timeBeforeAfter(this.date, this.beforeAfterFormat, this.useBeforePrefix, this.useAfterPrefix);
		this.interval = setInterval(() => {
			this.dateBeforeAfter = this.$timeBeforeAfter(this.date, this.beforeAfterFormat, this.useBeforePrefix, this.useAfterPrefix);
		}, 15000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		setTimeFormat() {
			if (this.datetimeFormat != null) {
				this.timeFormat = this.datetimeFormat;
			} else if (
				this.$user &&
				this.$user.grid_time_format &&
				this.$user.grid_time_format === 'relative'
			) {
				this.timeFormat = this.$user.grid_time_format;
			} else {
				this.timeFormat = 'absolute';
			}
		},
	},
};
</script>
