import ContentOptions from '../components/ContentOptions.vue';
import Controls from '../components/Controls.vue';
import CustomValue from '../components/CustomValues/CustomValue.vue';
import FakeProvider from '../components/CustomValues/FakeProvider.vue';
import InputAction from '../components/CustomValues/InputAction.vue';
import InputBoolean from '../components/CustomValues/InputBoolean.vue';
import InputBreak from '../components/CustomValues/InputBreak.vue';
import InputBytes from '../components/CustomValues/InputBytes.vue';
import InputCheckbox from '../components/CustomValues/InputCheckbox.vue';
import InputCKEditor from '../components/CustomValues/InputCKEditor.vue';
import InputCode from '../components/CustomValues/InputCode.vue';
import InputColor from '../components/CustomValues/InputColor.vue';
import InputDate from '../components/CustomValues/InputDate.vue';
import InputDatetime from '../components/CustomValues/InputDatetime.vue';
import InputFile from '../components/CustomValues/InputFile.vue';
import InputIcon from '../components/CustomValues/InputIcon.vue';
import InputInterval from '../components/CustomValues/InputInterval.vue';
import InputLbaSelect from '../components/CustomValues/InputLbaSelect.vue';
import InputLink from '../components/CustomValues/InputLink.vue';
import InputLinkFormat from '../components/CustomValues/InputLinkFormat.vue';
import InputMultiCheckbox from '../components/CustomValues/InputMultiCheckbox.vue';
import InputMultiSelect from '../components/CustomValues/InputMultiSelect.vue';
import InputNumber from '../components/CustomValues/InputNumber.vue';
import InputOptions from '../components/CustomValues/InputOptions.vue';
import InputPeriodPicker from '../components/CustomValues/InputPeriodPicker.vue';
import InputPhoneNumber from '../components/CustomValues/InputPhoneNumber.vue';
import InputProgressbar from '../components/CustomValues/InputProgressbar.vue';
import InputRadio from '../components/CustomValues/InputRadio.vue';
import InputRange from '../components/CustomValues/InputRange.vue';
import InputSelect from '../components/CustomValues/InputSelect.vue';
import InputSimpleText from '../components/CustomValues/InputSimpleText.vue';
import InputSwitch from '../components/CustomValues/InputSwitch.vue';
import InputTexArray from '../components/CustomValues/InputTextArray.vue';
import InputText from '../components/CustomValues/InputText.vue';
import InputTextarea from '../components/CustomValues/InputTextarea.vue';
import InputTime from '../components/CustomValues/InputTime.vue';
import InputTimeRange from '../components/InputTimeRange.vue';
import InputTwoLists from '../components/CustomValues/InputTwoLists.vue';
import InputValueWithUnit from '../components/CustomValues/InputValueWithUnit.vue';
import LastChange from '../components/LastChange.vue';
import LbaAudio from '../components/LbaAudio.vue';
import LbaBreadcrumb from '../components/LbaBreadcrumb.vue';
import LbaButtonSave from '../components/LbaButtonSave.vue';
import LbaCheckbox from '../components/LbaCheckbox.vue';
import LbaCKEditor from '../components/LbaCKEditor.vue';
import LbaCkEditorDialog from '../components/LbaCkEditorDialog.vue';
import LbaCode from '../components/LbaCode';
import LbaCombobox from '../components/LbaCombobox.vue';
import LbaConfirmRemoveDialog from '../components/LbaConfirmRemoveDialog.vue';
import LbaContentTabs from '../components/LbaContentTabs.vue';
import LbaCustomMasonry from '../components/LbaCustomMasonry/LbaCustomMasonry';
import LbaCustomValue from '../components/LbaCustomValue.vue';
import LbaDatepicker from '../components/LbaDatepicker.vue';
import LbaDatetime from '../components/LbaDatetime.vue';
import LbaDialog from '../components/LbaDialog.vue';
import LbaDialogConfirm from '../components/LbaDialogConfirm.vue';
import LbaDialogEdit from '../components/LbaDialogEdit.vue';
import LbaDialogModal from '../components/LbaDialogModal.vue';
import LbaDialogPopup from '../components/LbaDialogPopup.vue';
import LbaDialogRecommendEmpty from '../components/LbaDialogRecommendEmpty.vue';
import LbaDragAndDropList from '../components/LbaDragAndDropList.vue';
import LbaDragAndDropList2 from '../components/LbaDragAndDropList2.vue';
import LbaDragAndDropTwoLists from '../components/LbaDragAndDropTwoLists.vue';
import LbaExtraSetting from '../components/LbaExtraSetting.vue';
import LbaFavouritePage from '../components/LbaFavouritePage.vue';
import LbaProgress from '../components/LbaProgress.vue';
import LbaGrid from '../components/LbaGrid/LbaGrid.vue';
import LbaGridColumn from '../components/LbaGridColumn.vue';
import LbaHistoryBar from '../components/LbaHistoryBar.vue';
import LbaIconPicker from '../components/LbaIconPicker.vue';
import LbaImageSlider from '../components/LbaImageSlider/LbaImageSlider.vue';
import LbaInputFile from '../components/LbaInputFile.vue';
import LbaInputPassword from '../components/LbaInputPassword.vue';
import LbaInputRange from '../components/LbaInputRange.vue';
import LbaKeepAlive from '../components/LbaKeepAlive.vue';
import LbaLocale from '../components/LbaLocale.vue';
import LbaLocaleEdit from '../components/LbaLocaleEdit.vue';
import LbaLogReader from '../components/LbaLogReader/LbaLogReader.vue';
import LbaMenu from '../components/LbaMenu.vue';
import LbaMenuTree from '../components/LbaMenuTree.vue';
import LbaNumber from '../components/LbaNumber.vue';
import LbaObjectHasPermissions from '../components/LbaObjectHasPermissions.vue';
import LbaObjectSetPermissions from '../components/LbaObjectSetPermissions.vue';
import LbaPeriodPicker from '../components/LbaPeriodPicker.vue';
import LbaReloadButton from '../components/LbaReloadButton';
import LbaResizableTextarea from '../components/LbaResizableTextarea.vue';
import LbaSaveExitButton from '../components/LbaSaveExitButton.vue';
import LbaFulltextSearchContentTab from '../components/LbaFulltextSearchContentTab.vue';
import LbaSection from '../components/LbaSection.vue';
import LbaSelect from '../components/LbaSelect.vue';
import LbaSwitch from '../components/LbaSwitch.vue';
import LbaSwitchSimple from '../components/LbaSwitchSimple.vue';
import LbaTableExport from '../components/LbaTableExport/LbaTableExport.vue';
import LbaTableImport from '../components/LbaTableImport/LbaTableImport.vue';
import LbaTabs from '../components/LbaTabs/LbaTabs.vue';
import LbaToolbarButton from '../components/LbaToolbarButton.vue';
import LbaUserForm from '../components/LbaUserForm.vue';
import LbaValueOrder from '../components/LbaValueOrder';
import LbaWindow from '../components/LbaWindow.vue';
import ListContent from '../components/ListContent.vue';
import ListOptions from '../components/ListOptions.vue';
import ModuleNotActive from '../components/ModuleNotActive.vue';
import OptionsPanel from '../components/OptionsPanel.vue';
import LoginPage from '../components/LoginPage.vue';

import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import vuedraggable from 'vuedraggable';
import VueGridLayout from 'vue-grid-layout';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default function registerComponents(Vue, VeeValidate) {

	Vue.component('content-options', ContentOptions);
	Vue.component('controls', Controls);
	Vue.component('custom-value', CustomValue);

	Vue.component('fake-provider', FakeProvider);
	Vue.component('lba-progress', LbaProgress);

	Vue.component('grid-item', VueGridLayout.GridItem);
	Vue.component('grid-layout', VueGridLayout.GridLayout);

	Vue.component('input-action', InputAction);
	Vue.component('input-boolean', InputBoolean);
	Vue.component('input-break', InputBreak);
	Vue.component('input-bytes', InputBytes);
	Vue.component('input-checkbox', InputCheckbox);
	Vue.component('input-ckeditor', InputCKEditor);
	Vue.component('input-code', InputCode);
	Vue.component('input-color', InputColor);
	Vue.component('input-date', InputDate);
	Vue.component('input-datetime', InputDatetime);
	Vue.component('input-file', InputFile);
	Vue.component('input-icon', InputIcon);
	Vue.component('input-interval', InputInterval);
	Vue.component('input-lba-select', InputLbaSelect);
	Vue.component('input-link-format', InputLinkFormat);
	Vue.component('input-link', InputLink);
	Vue.component('input-multi-checkbox', InputMultiCheckbox);
	Vue.component('input-multi-select', InputMultiSelect);
	Vue.component('input-number', InputNumber);
	Vue.component('input-options', InputOptions);
	Vue.component('input-period-picker', InputPeriodPicker);
	Vue.component('input-phone', InputPhoneNumber);
	Vue.component('input-progressbar', InputProgressbar);
	Vue.component('input-radio', InputRadio);
	Vue.component('input-range', InputRange);
	Vue.component('input-select', InputSelect);
	Vue.component('input-simple-text', InputSimpleText);
	Vue.component('input-switch', InputSwitch);
	Vue.component('input-text', InputText);
	Vue.component('input-textarea', InputTextarea);
	Vue.component('input-textarray', InputTexArray);
	Vue.component('input-time', InputTime);
	Vue.component('input-two-lists', InputTwoLists);
	Vue.component('input-value-with-unit', InputValueWithUnit);
	Vue.component('last-change', LastChange);
	Vue.component('lba-audio', LbaAudio);
	Vue.component('lba-breadcrumb', LbaBreadcrumb);
	Vue.component('lba-button-save', LbaButtonSave);
	Vue.component('lba-checkbox', LbaCheckbox);
	Vue.component('lba-ckeditor-dialog', LbaCkEditorDialog);
	Vue.component('lba-ckeditor', LbaCKEditor);
	Vue.component('lba-code', LbaCode);
	Vue.component('lba-combobox', LbaCombobox);
	Vue.component('lba-confirm-remove-dialog', LbaConfirmRemoveDialog);
	Vue.component('lba-content-tabs', LbaContentTabs);
	Vue.component('lba-custom-masonry', LbaCustomMasonry);
	Vue.component('lba-custom-value', LbaCustomValue);
	Vue.component('lba-datepicker', LbaDatepicker);
	Vue.component('lba-datetime', LbaDatetime);
	Vue.component('lba-dialog-confirm', LbaDialogConfirm);
	Vue.component('lba-dialog-edit', LbaDialogEdit);
	Vue.component('lba-dialog-modal', LbaDialogModal);
	Vue.component('lba-dialog-popup', LbaDialogPopup);
	Vue.component('lba-dialog-recommend-empty', LbaDialogRecommendEmpty);
	Vue.component('lba-dialog', LbaDialog);
	Vue.component('lba-dnd-list', LbaDragAndDropList);
	Vue.component('lba-dnd-list2', LbaDragAndDropList2);
	Vue.component('lba-dnd-two-lists', LbaDragAndDropTwoLists);
	Vue.component('lba-extra-setting', LbaExtraSetting);
	Vue.component('lba-favourite-page', LbaFavouritePage);
	Vue.component('lba-grid-column', LbaGridColumn);
	Vue.component('lba-grid', LbaGrid);
	Vue.component('lba-history-bar', LbaHistoryBar);
	Vue.component('lba-icon-picker', LbaIconPicker);
	Vue.component('lba-image-slider', LbaImageSlider);
	Vue.component('lba-input-file', LbaInputFile);
	Vue.component('lba-input-password', LbaInputPassword);
	Vue.component('lba-input-range', LbaInputRange);
	Vue.component('lba-input-time-range', InputTimeRange);
	Vue.component('lba-keep-alive', LbaKeepAlive);
	Vue.component('lba-locale-edit', LbaLocaleEdit);
	Vue.component('lba-locale', LbaLocale);
	Vue.component('lba-log-reader', LbaLogReader);
	Vue.component('lba-menu-tree', LbaMenuTree);
	Vue.component('lba-menu', LbaMenu);
	Vue.component('lba-number', LbaNumber);
	Vue.component('lba-object-has-permissions', LbaObjectHasPermissions);
	Vue.component('lba-object-set-permissions', LbaObjectSetPermissions);
	Vue.component('lba-period-picker', LbaPeriodPicker);
	Vue.component('lba-reload-button', LbaReloadButton);
	Vue.component('lba-resizable-textarea', LbaResizableTextarea);
	Vue.component('lba-fulltext-search-content-tab', LbaFulltextSearchContentTab);
	Vue.component('lba-save-exit-button', LbaSaveExitButton);
	Vue.component('lba-section', LbaSection);
	Vue.component('lba-select', LbaSelect);
	Vue.component('lba-switch', LbaSwitch);
	Vue.component('lba-switch-simple', LbaSwitchSimple);
	Vue.component('lba-table-export', LbaTableExport);
	Vue.component('lba-table-import', LbaTableImport);
	Vue.component('lba-tabs', LbaTabs);
	Vue.component('lba-toolbar-button', LbaToolbarButton);
	Vue.component('lba-user-form', LbaUserForm);
	Vue.component('lba-value-order', LbaValueOrder);
	Vue.component('lba-window', LbaWindow);
	Vue.component('list-content', ListContent);
	Vue.component('list-options', ListOptions);
	Vue.component('module-not-active', ModuleNotActive);

	Vue.component('multiselect', Multiselect);
	Vue.component('options-panel', OptionsPanel);

	Vue.component('login-page', LoginPage);

	Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
	Vue.component('ValidationProvider', VeeValidate.ValidationProvider);

	Vue.component('vue-draggable', vuedraggable);
	Vue.component('VueSlider', VueSlider);
};
