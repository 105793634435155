/* eslint-disable no-underscore-dangle */
export default class {
	constructor() {
		this.permissions = {};
		this.objectPermissions = {};
		this.vm = null;
	}

	/**
	 * Load permissions from server
	 */
	async setPermissions() {
		const response = await this.vm.$http.get('/lbadmin/user-perms');

		this.permissions = response && response.data && response.data.permissions;
	}

	updatePermissions(data) {
		if (data.type === 'permissions') {
			this.permissions = data.data;

		} else if (data.type === 'objectPermissions' && data.objectUid) {
			this.objectPermissions[data.objectUid] = data.data;

		}

		this.vm.$emit('permissions-reload');
	}

	getModulePermissions(module_name) {
		let permissions_ = [];
		if (this.permissions.global) {
			permissions_ = $_.filter(this.permissions.global, (perm) => {
				return perm.match(`^${module_name}.module.`);
			});
		}

		return permissions_;
	};

	/**
	 * Check if user have wanted permission on object
	 * @param {String} acl_type_name - required, permission name (lbadmin.menu.options.enable)
	 * @param {String} object_uid - required, check permission for specific object
	 * @param {String} team_uid - optional, check permission in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	async checkObjectPermission(acl_type_name, object_uid, team_uid) {
		// ************************************
		// *     PermissionsModel
		// ************************************

		if (!$_.has(this.objectPermissions, object_uid)) {
			const response = await this.vm.$http.get(`/lbadmin/user-object-perms/${object_uid}`);
			this.objectPermissions[object_uid] = response.data.permissions;
		}

		if (this.objectPermissions[object_uid] && this.objectPermissions[object_uid].length > 0) {
			return this.objectPermissions[object_uid].includes(acl_type_name);

		}

		return this.checkPermission(acl_type_name, team_uid);
	};

	/**
	 * Check if user have wanted permissions on object
	 * @param {String} acl_type_names - required, permission names (lbadmin.menu.options.enable)
	 * @param {String} object_uid - required, check permission for specific object
	 * @param {String} team_uid - optional, check permission in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	async checkObjectPermissions(acl_type_names, object_uid, team_uid) {
		// ************************************
		// *     PermissionsModel
		// ************************************

		if (!$_.has(this.objectPermissions, object_uid)) {
			const response = await this.vm.$http.get(`/lbadmin/user-object-perms/${object_uid}`);
			this.permissions[object_uid] = response.data.permissions;
		}

		if (this.objectPermissions[object_uid] && this.objectPermissions[object_uid].length > 0) {
			let perm = true;

			$_.forEach(acl_type_names, (acl_type_name) => {
				if (!(this.objectPermissions[object_uid].includes(acl_type_name))) {
					perm = false;
				}
			});

			return perm;
		}

		return this.checkPermissions(acl_type_names, team_uid);
	};

	/**
	 * Check if user have wanted permission
	 * @param {String} acl_type_name - required, permission name (lbadmin.menu.options.enable)
	 * @param {String} team_uid - optional, check permission in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	checkPermission(acl_type_name, team_uid) {
		if (!team_uid && this.vm.$user.extraParams.customer_uid) {
			team_uid = this.vm.$user.extraParams.customer_uid;
		}
		if (!team_uid || (this.permissions.global && this.permissions.global.includes('lbadmin.all-teams.enable'))) {
			return this.permissions.global && this.permissions.global.includes(acl_type_name);
		}

		return this.permissions[team_uid] && this.permissions[team_uid].includes(acl_type_name);
	};

	/**
	 * Check if user have wanted permissions
	 * @param {Array} acl_type_name - required, array of strings, permissions names (lbadmin.menu.options.enable)
	 * @param {String} team_uid - optional, check permissions in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	checkPermissions(acl_type_names, team_uid) {
		let perm = true;
		if (!team_uid && this.vm.$user.extraParams.customer_uid) {
			team_uid = this.vm.$user.extraParams.customer_uid;
		}
		if (!team_uid || (this.permissions.global && this.permissions.global.includes('lbadmin.all-teams.enable'))) {
			$_.forEach(acl_type_names, (acl_type_name) => {
				if (!(this.permissions.global && this.permissions.global.includes(acl_type_name))) {
					perm = false;
				}
			});
		} else {
			$_.forEach(acl_type_names, (acl_type_name) => {
				if (!(this.permissions[team_uid] && this.permissions[team_uid].includes(acl_type_name))) {
					perm = false;
				}
			});
		}

		return perm;
	};
};
