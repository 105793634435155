<template>
	<div class="home-page" data-cy="home">
		<span v-if="loaded" class="labelCenter labelLogo">
			<span class="brand-logo"></span>
		</span>
	</div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			loaded: false,
			waitingForUserModules: false,
		};
	},
	created() {
		this.$root.$listen('home-route-changed', this.checkAndRedirect, this, true);
		this.$root.$listen('user-modules-loaded', this.onUserModulesLoaded, this, true);
		this.checkAndRedirect();
	},
	methods: {
		async checkAndRedirect() {
			this.loaded = false;

			if (this.$root.homeRoute.name === 'dashboard') {
				if (!this.$user.loadedModules) {
					this.waitingForUserModules = true;
					this.loaded = true;
					return;

				} else if (!this.$user.loadedModules.includes('dashboard')) {
					// dashboard is set as home route and user has loaded modules, but dashboard is not one of them
					this.loaded = true;
					return;

				}
			}

			if (this.$root.homeRoute.name !== 'home') {
				await this.$nextTick();
				this.$routerWrap.push(this.$root.homeRoute);
				return;
			}
			this.loaded = true;
		},
		onUserModulesLoaded() {
			if (this.waitingForUserModules) {
				this.waitingForUserModules = false;
				this.checkAndRedirect();
			}
		},
	},
};
</script>
