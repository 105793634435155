export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.post('lbadmin/query-import-export/settings', { params });
	}

	get(uid) {
		return this.$http.get(`lbadmin/import-export/settings/${uid}`);
	}

	save(uid, params) {
		return this.$http.post(`lbadmin/import-export/settings/${uid}`, { params });
	}

	create(params) {
		return this.$http.post(`lbadmin/import-export/settings`, { params });
	}

	remove(uid, params) {
		return this.$http.delete(`lbadmin/import-export/settings/${uid}`);
	}

	removeSelected(params) {
		return this.$http.delete(`lbadmin/import-export/settings-selected`, { data: params });
	}

	tableList() {
		return this.$http.get(`lbadmin/import-export/table-list`);
	}
};
