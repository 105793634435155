export default {
	locale: 'en',
	pluralizationRules: {
		cs: (choice) => {
			if (choice === 0) {
				return 0;
			}
			if (choice === 1) {
				return 1;
			}
			if (choice === (choice | 0) && choice >= 2 && choice <= 4) {
				return 2;
			}
			return 3;
		},
	},
	dateTimeFormats: {
		cs: {
			timeDiff: { minute: 'numeric', second: 'numeric' },
			timeShort: { hour: 'numeric', minute: 'numeric' },
			timeLong: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
			shortyShort: {
				month: 'numeric', day: 'numeric',
			},
			short: {
				year: 'numeric', month: 'numeric', day: 'numeric',
			},
			medium: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			},
			long: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			},
			fullDigitsShort: {
				year: 'numeric', month: '2-digit', day: '2-digit',
			},
			fullDigitsMedium: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			},
			fullDigitsLong: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			},
		},
		en: {
			timeDiff: { minute: 'numeric', second: 'numeric' },
			timeShort: { hour: 'numeric', minute: 'numeric' },
			timeLong: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
			shortyShort: {
				month: 'numeric', day: 'numeric',
			},
			short: {
				year: 'numeric', month: 'numeric', day: 'numeric',
			},
			medium: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			},
			long: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			},
			fullDigitsShort: {
				year: 'numeric', month: '2-digit', day: '2-digit',
			},
			fullDigitsMedium: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			},
			fullDigitsLong: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			},
		},
	},
};
