<template>
	<div id="content" v-if="loaded">
		<div class="page-header">
			<h1>
				{{ $t("settings.profile") }}
				<span class="gray">{{ meta.name }}</span>
			</h1>
			<div class="page-controls">
				<button
					v-if="!isNew && !readOnly"
					name="openRemoveDialog"
					class="buttonTransparent"
					data-cy="profile__remove"
					@click.stop="deleteProfile"
				>
					{{ $t('remove') }}
				</button>

				<lba-button-save
					ref="saveButton"
					:id="saveButtonId"
					:inputDirty="inputDirtyForSave"
					name="save"
					data-cy="profile__save__button"
					@click.stop="submit"
				/>
				<span v-if="!isNew" @click="tabReload" data-cy="profile__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<masonry
				:cols="{default: 2, 1000: 1}"
				:gutter="{default: '20px', 1000: '15px'}"
			>
				<div>
					<template
						v-for="(section, index) in permissions"
					>
						<lba-section
							v-if="(profile.permissions.includes(
								section.acl_type_uid) &&
								(section.subpermissions.length || (section.menu && section.menu.length))) ||
									section.acl_type_module === 'lbadmin'"
							:key="`section-${index}`"
							parentComponentId="profile"
							:componentId="`permission${index}`"
							:id="`module-${section.acl_type_module}`"
							:title="getSectionTitle(section.acl_type_module)"
							slotType="form"
							expanded
							scrollable
						>
							<template v-if="!readOnly" v-slot:controls="{ openSection, parentComponentId }">
								<span
									v-lba-expander.closeOnClick
									class="fieldsetMenuExpander"
									:data-cy="`${parentComponentId}__expandMenu`"
									@click="openSection()"
								>
									<i class="icon-plus-circle" v-tooltip="$t('options')"></i>
								</span>
								<div class="fieldsetMenu expand" data-close>
									<span class="link"
										:data-cy="`${parentComponentId}__expandMenu__checkAll`"
										@click="markItems('section', true, section)"
									>
										{{ $t('checkAll') }}
									</span>
									<span class="link"
										:data-cy="`${parentComponentId}__expandMenu__uncheckAll`"
										@click="markItems('section', false, section)"
									>
										{{ $t('uncheckAll') }}
									</span>
								</div>
							</template>
							<template #default="props">
								<s v-if="section.menu && section.menu.length" class="wide scroll-x mr-3 mt-1">
									<h3>{{ $t('settings.menuItems') }}</h3>
									<table
										class="content-table wide stripedOdd mt-1"
									>
										<tr>
											<th>{{ $t('settings.item') }}</th>
											<th style="text-align: center; width: 65px;">
												<span
													style="display: flex; justify-content: center;"
												>
													{{ $t('settings.access') }}
													<template v-if="!readOnly">
														<i
															v-lba-expander.closeOnClick
															class="icon-plus-circle pl-1 fieldsetMenuExpander"
															v-tooltip="$t('options')"
														></i>
														<div class="fieldsetMenu expand" data-close>
															<span class="link"
																:data-cy="`${props.parentComponentId}__menuItems__checkAll`"
																@click="markItems('menu', true, section.menu)"
															>
																{{ $t('checkAll') }}
															</span>
															<span class="link"
																:data-cy="`${props.parentComponentId}__menuItems__uncheckAll`"
																@click="markItems('menu', false, section.menu)"
															>
																{{ $t('uncheckAll') }}
															</span>
														</div>
													</template>
												</span>
											</th>
										</tr>
										<template v-for="(menu, index) in section.menu">
											<tr
												v-if="menu.acl_type_uid"
												:key="`menu-${index}`"
											>
												<td><a :name="menu.acl_type_uid"></a>{{ getName(menu) }}</td>
												<td style="text-align: center;">
													<label
														class="checkbox"
														:key="`menu-${menu.acl_type_object_name}-${index}`"
														:data-cy="`${props.parentComponentId}__menuItems__item${index}__checkboxLabel`"
													>
														<input
															:data-cy="`${props.parentComponentId}__menuItems__item${index}__inputCheckbox`"
															type="checkbox"
															v-model="profile.permissions"
															:value="menu.acl_type_uid"
															@change="setDirty(true);"
															:disabled="!menu.acl_type_uid || readOnly"
														>
														<i class="icon-ok"></i>
													</label>
												</td>
											</tr>
										</template>
									</table>
								</s>

								<s v-if="section.subpermissions.length" class="wide scroll-x mr-3 mt-1">
									<h3>{{ $t('settings.modulePermissions') }}</h3>
									<table
										class="content-table wide stripedOdd mt-1"
									>
										<thead>
											<tr>
												<th>{{ $t('settings.permName') }}</th>
												<template
													v-for="(permType, pindex) in permissionTypes"
												>
													<th
														:key="`header-${pindex}`"
														v-if="isPermInSubpermissions(section.subpermissions, permType.type)"
														style="text-align: center; width: 65px;"
													>
														<span
															style="display: flex; justify-content: center;"
														>
															{{ permType.label }}
															<template v-if="!readOnly">
																<i
																	v-lba-expander.closeOnClick
																	class="icon-plus-circle pl-1 fieldsetMenuExpander"
																	v-tooltip="$t('options')"></i>
																<div class="fieldsetMenu expand" data-close>
																	<span class="link"
																		@click="markItems(
																			'type', true, permType.type, section.subpermissions
																		)"
																		:data-cy="
																			`${props.parentComponentId}__modulePermissions` +
																			`__permission${pindex}__checkAll`
																		"
																	>
																		{{ $t('checkAll') }}
																	</span>
																	<span class="link"
																		@click="markItems(
																			'type', false, permType.type, section.subpermissions
																		)"
																		:data-cy="
																			`${props.parentComponentId}__modulePermissions` +
																			`__permission${pindex}__uncheckAll`
																		"
																	>
																		{{ $t('uncheckAll') }}
																	</span>
																</div>
															</template>
														</span>
													</th>
												</template>
											</tr>
										</thead>

										<tbody>
											<tr
												v-for="(permission, i) in getSubpermissions(section.subpermissions)"
												:key="`perm-${index}-${i}`"
											>
												<td>{{ permission.name }}</td>
												<template
													v-for="(permType, pindex) in permissionTypes"
												>
													<td
														:key="`row-${pindex}-${index}`"
														v-if="isPermInSubpermissions(section.subpermissions, permType.type)"
														style="text-align: center;">

														<label
															v-if="permission[permType.type]"
															class="checkbox"
															:key="`menu-${permission[permType.type].acl_type_uid}-${index}`"
															:data-cy="
																`${props.parentComponentId}__subpermission${i}` +
																`__permission${pindex}__checkboxLabel`
															"
														>
															<a :name="permission[permType.type].acl_type_uid"></a>
															<input
																:data-cy="
																	`${props.parentComponentId}__subpermission${i}` +
																	`__permission${pindex}__inputCheckbox`
																"
																type="checkbox"
																:id="permission[permType.type].acl_type_name"
																:value="permission[permType.type].acl_type_uid"
																v-model="profile.permissions"
																@change="setDirty(true);"
																:disabled="readOnly"
															>
															<i class="icon-ok"></i>
														</label>
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</s>
							</template>
						</lba-section>
					</template>
				</div>
				<div>
					<ValidationObserver ref="form">
						<form name="form" lba-size-class="{'contentWide': 700}">
							<lba-section
								parentComponentId="profile"
								componentId="settings"
								:title="$t('settings.profileSettings')"
								slotType="form"
								expanded
							>
								<template #default="props">
									<ValidationProvider
										:name="$t('settings.profileName')"
										v-slot="{ invalid, errors }"
										:rules="{ required: true }"
									>
										<s>
											<small>
												{{ $t('settings.profileName') }}
												<span class="required-sign"></span>
											</small>
											<input
												:data-cy="`${props.parentComponentId}__profileName__inputText`"
												type="text"
												name="profile_name"
												@input="setInputDirty(true);"
												@change="setDirty(true);"
												v-model="profile.profile_name"
												:class="{ 'lba-invalid': invalid && dirty }"
												:disabled="readOnly"
											/>
											<template v-if="dirty">
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`${props.parentComponentId}__profileName__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</template>
										</s>
									</ValidationProvider>
									<!--<s>
										<small>
											{{ $t('settings.profileModule') }}
										</small>
										<input
											type="text"
											name="profile_module"
											@change="dirty = true"
											v-model="profile.profile_module"
											:class="{ 'lba-invalid': invalid && dirty }"
										/>
										<template v-if="dirty">
											<span
												v-for="(err, index) in errors"
												:key="index"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s> -->
									<s>
										<small>
											{{ $t(`settings.profileEnabled`) }}
										</small>
										<label class="checkbox">
											<input
												type="checkbox"
												v-model="profile.profile_visible"
												:data-cy="`${props.parentComponentId}__profile_visible__inputCheckbox`"
												name="profile_visible"
												:disabled="readOnly"
												@change="setInputDirty(true);"
											>
											<i class="icon-ok"></i>
										</label>
									</s>
								</template>
							</lba-section>
						</form>
					</ValidationObserver>
					<lba-section
						name="userModules"
						parentComponentId="profile"
						componentId="userModules"
						:key="`section-modules-all`"
						:title="$t('settings.user.modules')"
						slotType="form"
						expanded
					>
						<template #default="props">
							<template v-for="(section, index) in permissions">
								<template v-if="section.acl_type_module !== 'lbadmin'">
									<label
										class="checkbox"
										style="margin-right: 10px;"
										:key="`module-check-${index}`"
										:data-cy="`${props.parentComponentId}__section${index}__checkboxLabel`"
									>
										<a :name="section.acl_type_uid"></a>
										<input
											:data-cy="`${props.parentComponentId}__section${index}__inputCheckbox`"
											type="checkbox"
											v-model="profile.permissions"
											:value="section.acl_type_uid"
											@change="setDirty(true);"
											:disabled="readOnly"
										>
										<i class="icon-ok"></i>
										<span class="label">
											{{
												$t(`${section.acl_type_module}.menu.${section.acl_type_module}`) ||
												section.acl_type_module
											}}
										</span>
									</label>
									<a
										v-if="(profile.permissions.indexOf(section.acl_type_uid) >= 0) &&
											((section.menu && section.menu.length) ||
											(section.subpermissions &&
											section.subpermissions.length))"
										:data-cy="`${props.parentComponentId}__section${index}__scrollToSection`"
										@click.stop="scrollToSection(`module-${section.acl_type_module}`)"
										v-tooltip="$t('jumpOnItem')"
										:key="`module-jump-${index}`"
									>
										<i  class="icon-settings" style="top: 5px;"></i>
									</a>
									<br :key="`module-br-${index}`">
								</template>
							</template>
						</template>
					</lba-section>

					<lba-section
						name="userProfileRights"
						parentComponentId="profile"
						componentId="userProfileRights"
						:key="`section-profiles-all`"
						:title="$t('settings.user.profilesRights')"
						slotType="form"
						expanded
					>
						<template v-if="!readOnly" v-slot:controls="{ openSection, parentComponentId }">
							<span
								v-lba-expander.closeOnClick
								class="fieldsetMenuExpander"
								:data-cy="`${parentComponentId}__expanderProfiles`"
								@click="openSection()"
							>
								<i class="icon-plus-circle" v-tooltip="$t('options')"></i>
							</span>
							<div class="fieldsetMenu expand" data-close>
								<span class="link"
									:data-cy="`${parentComponentId}__expanderProfiles__checkAll`"
									@click="markItems('profiles', true)"
								>
									{{ $t('checkAll') }}
								</span>
								<span class="link"
									:data-cy="`${parentComponentId}__expanderProfiles__uncheckAll`"
									@click="markItems('profiles', false)"
								>
									{{ $t('uncheckAll') }}
								</span>
							</div>
						</template>
						<template #default="props">
							<p>{{ $t('settings.user.profilesRightsInfo') }}</p>
							<template v-for="(prof, index) in allProfiles">
								<label
									class="checkbox"
									style="margin-right: 10px;"
									:key="`profile-check-${index}`"
									:data-cy="`${props.parentComponentId}__profile${index}__checkboxLabel`"
								>
									<input
										:data-cy="`${props.parentComponentId}__profile${index}__inputCheckbox`"
										type="checkbox"
										v-model="profile.allowed"
										:value="prof.profile_uid"
										@change="setDirty(true);"
										:disabled="isProfAllowed(prof) || readOnly"
									>
									<i class="icon-ok"></i>
									<span class="label">
										{{ prof.profile_name }}
									</span>
								</label>
								<br :key="`profile-check-br-${index}`">
							</template>
						</template>
					</lba-section>

					<lba-section
						v-if="!profile.profile_uid"
						name="profileRightsToAdministrateMe"
						parentComponentId="profile"
						componentId="profileRightsToAdministrateMe"
						:key="`section-profiles-allow-me`"
						:title="$t('settings.user.profileRightsToAdministrateMe')"
						slotType="form"
						expanded
					>
						<template #default="props">
							<p>{{ $t('settings.user.profileRightsToAdministrateMeInfo') }}</p>
							<template v-for="(prof, index) in $user.allow_set_profiles">
								<label
									class="checkbox"
									style="margin-right: 10px;"
									:key="`profile-allow-me-check-${index}`"
									:data-cy="`${props.parentComponentId}__profile${index}__checkboxLabel`"
								>
									<input
										:data-cy="`${props.parentComponentId}__profile${index}__inputCheckbox`"
										type="checkbox"
										v-model="profile.allow_myself_to"
										:value="prof.profile_uid"
										@change="setDirty(true);"
									>
									<i class="icon-ok"></i>
									<span class="label">
										{{ prof.profile_name }}
									</span>
								</label>
								<br :key="`profile-check-br-${index}`">
							</template>
						</template>
					</lba-section>

					<lba-section
						v-if="!$_.isEmpty(permissionsBundles)"
						name="profilePermissionsBundles"
						parentComponentId="profile"
						componentId="profilePermissionsBundles"
						:key="`section-profiles-permissions-bundles`"
						:title="$t('settings.user.parentRoles')"
						slotType="form"
						scrollable
						expanded
					>
						<template #default="props">
							<div class="pr-3">
								<table class="content-table wide stripedOdd mt-1">
									<tbody
										v-for="(modulesBundles, index) in permissionsBundles"
										:key="index"
									>
										<tr class="content-table-heading">
											<td>
												<strong>
													{{ $t(`${modulesBundles[0].bundle_module}.menu.${modulesBundles[0].bundle_module}`) }}
												</strong>
											</td>
											<td style="text-align: center; padding-right: 10px;">{{ $t('active') }}</td>
											<td style="text-align: center; padding-right: 10px;" colspan="2">{{ $t('actions') }}</td>
										</tr>

										<tr
											v-for="(bundle, bundleIndex) in modulesBundles"
											:key="`${bundle.bundle_module}-${bundleIndex}`"
										>
											<td>
												<span
													class="hover-pointer gray"
													@click="bundle.arePermissionsExpanded = !bundle.arePermissionsExpanded"
												>
													<i
														:class="{
															'icon-arrow-down silver': bundle.arePermissionsExpanded,
															'icon-arrow-right silver': !bundle.arePermissionsExpanded,
														}"
														style="font-size: 10px; top: 3px;"
													></i>
													{{ $getLocale(bundle.bundle_label) }}
												</span>
												<div v-show="bundle.arePermissionsExpanded" class="mt-2">
													<div
														v-for="(permission, permissionIndex) in bundle.bundle_permissions"
														:key="`${bundle.bundle_module}-${bundleIndex}-${permissionIndex}`"
														style="display: flex"
													>
														<template v-if="!$_.isEmpty(permission.uid) && !$_.isEmpty(permission.name)">
															<a style="flex: 0.8" :href="`#${permission.uid}`">{{ permission.name }}</a>
															<span v-if="permission.uid" style="flex: 0.2; text-align: right;">
																<label
																	class="checkbox"
																	:key="`bundlePermission__${permission.uid}`"
																	:data-cy="`bundlePermission__${permission.uid}__checkboxLabel`"
																>
																	<input
																		:data-cy="`bundlePermission__${permission.uid}__inputCheckbox`"
																		type="checkbox"
																		v-model="profile.permissions"
																		:value="permission.uid"
																		v-on:change="dirty = true"
																		:disabled="readOnly"
																	>
																	<i class="icon-ok"></i>
																</label>
															</span>
														</template>
														<span v-else class="gray" v-tooltip="$t('permissionNotFoundInList')">
															{{ permission.name }}
														</span>
													</div>
												</div>
											</td>
											<td style="text-align: center; padding-right: 10px;">
												<span
													v-if="isBundleActive(bundle.bundle_permissions)"
													v-tooltip="$t('allPermissionsInBundleActive')"
												>
													<i class="icon-ok blue"></i>
												</span>
												<span v-else>{{ getBundleActiveCountText(bundle.bundle_permissions) }}</span>
											</td>
											<td style="text-align: center;  padding-right: 10px;">
												<span
													v-if="!isBundleActive(bundle.bundle_permissions)"
													@click="addPermissionsBundle(bundle.bundle_permissions)"
													class="hover-pointer"
													:class="{ disabled: readOnly }"
													v-tooltip="$t('addPermissionsBundle')"
												>
													<i class="icon-plus green"></i>
												</span>
											</td>
											<td style="text-align: center; padding-right: 10px;">
												<span
													v-if="isBundleActive(bundle.bundle_permissions)"
													@click="removePermissionsBundle(bundle)"
													class="hover-pointer"
													:class="{ disabled: readOnly }"
													v-tooltip="$t('removePermissionsBundle')"
												>
													<i class="icon-minus red"></i>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</template>
					</lba-section>

					<lba-section
						v-if="!$_.isEmpty(activePermissions)"
						name="profileOtherActivePermissions"
						parentComponentId="profile"
						componentId="profileOtherActivePermissions"
						:key="`section-profiles-other-active-permissions`"
						:title="`${$t('otherActivePermissions')} (${activePermissions.count})`"
						slotType="form"
						scrollable
					>
						<template #default="props">
							<div class="pr-3">
								<table class="content-table wide stripedOdd mt-1">
									<tbody
										v-for="(modulePermissions, index) in activePermissions.permissions"
										:key="`${modulePermissions.module}-${index}`"
									>
										<tr>
											<td colspan="2"><strong>{{ modulePermissions.label }}</strong></td>
										</tr>

										<tr
											v-for="(permission) in modulePermissions.permissions"
											:key="`${modulePermissions.module}-${index}-${permission.uid}`"
										>
											<td>
												<a v-if="permission.label" :href="`#${permission.uid}`" v-tooltip="permission.name">
													{{ permission.label }}
												</a>
												<a v-else :href="`#${permission.uid}`">{{ permission.name }}</a>
											</td>
											<td style="text-align: center; padding-right: 10px;">
												<span
													class="hover-pointer"
													@click="removePermission(permission.uid)"
													v-tooltip="$t('removeSelectedPermission')"
												>
													<i class="icon-minus red"></i>
												</span>
											</td>
										</tr>

									</tbody>
								</table>
							</div>
						</template>
					</lba-section>
				</div>
			</masonry>
		</div>
		<lba-confirm-remove-dialog
			parentComponentId="profile"
			componentId="confirmDelete"
			name="profile-confirm-delete"
			:title="$t('settings.deleteProfile')"
			:message="`${profile.profile_name} ?`"
			:on-confirm="deleteProfileConfirm"
		></lba-confirm-remove-dialog>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import ProfileModel from '../models/Profile';
import SaveEntry from '../mixins/SaveEntry';

export default {
	name: 'Profile',
	mixins: [
		Titles,
		Page,
		OnScroll,
		SaveEntry,
	],
	data() {
		return {
			profile: {
				permissions: [],
				profile_visible: true,
				allowed: [],
				allow_myself_to: [],
			},
			permissionsBundles: {},
			allProfiles: [],
			allowedProfiles: [],
			permissions: [],
			loaded: false,
			dirty: false,
			profileModel: null,
			permissionTypes: [
				{
					type: 'read',
					label: this.$t('settings.permRead'),
				},
				{
					type: 'write',
					label: this.$t('settings.permWrite'),
				},
				{
					type: 'export',
					label: this.$t('settings.permExport'),
				},
				{
					type: 'import',
					label: this.$t('settings.permImport'),
				},
				{
					type: 'wallboard',
					label: this.$t('settings.permWallboard'),
				},
				{
					type: 'run',
					label: this.$t('settings.permRun'),
				},
				{
					type: 'all',
					label: this.$t('settings.permAll'),
				},
				{
					type: 'enable',
					label: this.$t('settings.permEnable'),
				},
			],
			isNew: true,
			readOnly: false,
			meta: {
				name: this.$t('settings.newProfile'),
				path: 'settings-profile',
				tooltipPrefix: this.$t('settings.profile'),
			},
			saveButtonId: 'lbadmin.profile',
		};
	},
	computed: {
		activePermissions() {
			const activeBundlesPermissions = [];
			$_.forEach(this.permissionsBundles, (modulePermissions) => {
				$_.forEach(modulePermissions, (bundle) => {
					$_.forEach(bundle.bundle_permissions, (permission) => {
						if (this.isPermissionActive(permission.uid)) {
							activeBundlesPermissions.push(permission.uid);
						}
					});
				});
			});

			let count = 0;
			const activePermissions = [];
			$_.forEach(this.permissions, (modulePermissions) => {
				const moduleActivePermissions = [];
				const moduleLabel = this.$t(`${modulePermissions.acl_type_module}.menu.${modulePermissions.acl_type_module}`);
				if (
					this.isPermissionActive(modulePermissions.acl_type_uid) &&
					!activeBundlesPermissions.includes(modulePermissions.acl_type_uid)
				) {
					moduleActivePermissions.push({
						name: modulePermissions.acl_type_name,
						uid: modulePermissions.acl_type_uid,
						label: moduleLabel,
					});
				}

				$_.forEach(modulePermissions.menu, (menuPermission) => {
					if (
						this.isPermissionActive(menuPermission.acl_type_uid) &&
						!activeBundlesPermissions.includes(menuPermission.acl_type_uid)
					) {
						const permissionName = `${modulePermissions.acl_type_module}.${menuPermission.acl_type_object_name}.enable`;
						moduleActivePermissions.push({
							name: permissionName,
							uid: menuPermission.acl_type_uid,
							label: this.getName(menuPermission),
						});
					}
				});
				$_.forEach(modulePermissions.subpermissions, (subPermission) => {
					if (
						this.isPermissionActive(subPermission.acl_type_uid) &&
						!activeBundlesPermissions.includes(subPermission.acl_type_uid)
					) {
						moduleActivePermissions.push({
							name: subPermission.acl_type_name,
							uid: subPermission.acl_type_uid,
						});
					}
				});

				if (!$_.isEmpty(moduleActivePermissions)) {
					count += moduleActivePermissions.length;
					activePermissions.push({
						module: modulePermissions.acl_type_module,
						label: moduleLabel,
						permissions: moduleActivePermissions,
					});
				}
			});

			if (!$_.isEmpty(activePermissions)) {
				return {
					count,
					permissions: activePermissions,
				};
			}
			return null;
		},
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.isNew = (this.$route.params.id === '+');
		this.profileModel = new ProfileModel(this.$http);

		// get all available permissions
		let resp = await this.profileModel.getTypes();
		this.permissions = resp.data;

		// get all available permissions
		resp = await this.profileModel.getPermissionsBundles(this.$i18n.locale);
		$_.forEach(resp.data, (modulePermissions) => {
			$_.forEach(modulePermissions, (bundle) => {
				this.$set(bundle, 'arePermissionsExpanded', false);
				bundle.bundle_permissions = $_.map(bundle.bundle_permissions, (permissionName) => {
					return {
						name: permissionName,
						uid: this.getPermissionTypeUid(permissionName),
					};
				});
			});
		});
		this.permissionsBundles = resp.data;

		// get all profiles
		resp = await this.profileModel.query({ all: true });
		this.allProfiles = resp.data;

		// get profiles I am allowed to add
		resp = await this.profileModel.getProfilesToSet();
		this.allowedProfiles = resp.data;

		await this.fetch();
		this.loaded = true;

		// sort permissions
		this.sortPerms();

		this.permissionsReload();
	},
	methods: {
		isBundleActive(bundlePermissions) {
			return $_.every(bundlePermissions, (permission) => this.isPermissionActive(permission.uid));
		},
		getBundleActiveCountText(bundlePermissions) {
			const activeCount = $_.filter(bundlePermissions, (permission) => this.isPermissionActive(permission.uid)).length;
			const totalCount = bundlePermissions.length;
			return `(${activeCount} / ${totalCount})`;
		},
		getPermissionTypeUid(permissionName) {
			let permissionTypeUid = null;
			$_.some(this.permissions, (permission) => {
				if (permission.acl_type_name === permissionName) {
					permissionTypeUid = permission.acl_type_uid;
					return true;
				}

				const found = $_.some(permission.menu, (menuPermission) => {
					if (`${permission.acl_type_module}.${menuPermission.acl_type_object_name}.enable` === permissionName) {
						permissionTypeUid = menuPermission.acl_type_uid;
						return true;
					}
				});
				if (found) {
					return true;
				}

				return $_.some(permission.subpermissions, (subPermission) => {
					if (subPermission.acl_type_name === permissionName) {
						permissionTypeUid = subPermission.acl_type_uid;
						return true;
					}
				});
			});
			return permissionTypeUid;
		},
		isPermissionActive(permissionTypeUid) {
			return this.profile?.permissions?.includes(permissionTypeUid);
		},
		addPermissionsBundle(bundlePermissions) {
			$_.forEach(bundlePermissions, (permission) => {
				if ($_.isEmpty(permission.uid)) {
					console.error('[lbadmin.view.Profile](isPermissionActive) permission with name', permission.name, 'not found');
					return;
				}
				if (this.profile.permissions && !this.profile.permissions.includes(permission.uid)) {
					this.profile.permissions.push(permission.uid);
				}
			});
			this.dirty = true;
		},
		removePermission(permissionUid) {
			const index = this.profile.permissions.indexOf(permissionUid);
			if (index >= 0) {
				this.profile.permissions.splice(index, 1);
			}
		},
		removePermissionsBundle(bundleToRemove) {
			let activeBundlesPermissions = [];
			$_.forEach(this.permissionsBundles, (modulePermissions) => {
				$_.forEach(modulePermissions, (bundle) => {
					if (bundle !== bundleToRemove && this.isBundleActive(bundle.bundle_permissions)) {
						activeBundlesPermissions = $_.concat(activeBundlesPermissions, bundle.bundle_permissions);
					}
				});
			});

			const permissionsToRemove = $_.differenceBy(bundleToRemove.bundle_permissions, activeBundlesPermissions, 'uid');
			$_.forEach(permissionsToRemove, (permission) => {
				this.removePermission(permission.uid);
			});
			this.dirty = true;
		},
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.profile.write');
		},
		async fetch() {
			if (this.$route.params.id != null && this.$route.params.id !== '+') {
				const response = await this.profileModel.get(this.$route.params.id, this.$i18n.locale);
				this.profile = response.data;
				this.meta.name = this.profile.profile_name;
				this.refreshTitle();
			} else {
				this.setDirty(true);
				if (this.$route.params.clone) {
					const response = await this.profileModel.get(this.$route.params.clone, this.$i18n.locale);
					this.profile.permissions = response.data.permissions;
				}
			}
		},
		async submit() {
			this.setDirty(true);
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.inputDirtyForSave = false;
				this.$root.$emit('content.validationFailed', { id: this.saveButtonId });
				return;
			}

			this.setDirty(false);

			this.profileModel
				.save(this.profile)
				.then(async (response) => {
					this.$root.$emit('content.saved', { id: this.saveButtonId });
					await this.$nextTick();

					this.meta.name = this.profile.profile_name;
					this.refreshTitle();

					this.$root.$emit('favourite-pages.name-updated');

					if (this.$route.params.id !== response.data.resid) {
						this.$routerWrap.push({
							name: 'settings-profile',
							params: { id: response.data.resid },
						});
					}
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed', { id: this.saveButtonId });
				});
		},
		deleteProfile() {
			this.$root.$emit('dialog-open', { name: 'profile-confirm-delete' });
		},
		deleteProfileConfirm() {
			this.$root.$emit('tab-editted', false);
			this.profileModel
				.delete(this.profile.profile_uid)
				.then(() => {
					this.$root.$emit('close-current-tab');
					this.$root.$emit('content.removed');
				})
				.catch(() => {
					this.$root.$emit('content.removeFailed');
				});
		},
		getSubpermissions(subpermissions) {
			// when trying to sort directly subpermissions -> infinite render loop
			const subPermissions = $_.cloneDeep(subpermissions);
			const sub = {};
			subPermissions.sort((a, b) => {
				if (a.acl_type_object_name < b.acl_type_object_name) return -1;
				if (a.acl_type_object_name > b.acl_type_object_name) return 1;
				return 0;
			});
			subPermissions.forEach((el) => {
				if (!sub[el.acl_type_object_name]) {
					sub[el.acl_type_object_name] = { name: el.acl_type_object_name, acl_type_uid: el.acl_type_uid };
				}
				sub[el.acl_type_object_name][el.acl_type_perm] = { acl_type_uid: el.acl_type_uid, acl_type_name: el.acl_type_name };
			});
			return sub;
		},
		sortPerms() {
			// sort permissions according to menu position
			const newPerms = $_.cloneDeep(this.$user.menuModulesSorted);
			this.permissions.forEach((perm) => {
				if (newPerms.indexOf(perm.acl_type_module) === -1) {
					newPerms.push(perm);
				} else {
					newPerms[newPerms.indexOf(perm.acl_type_module)] = perm;
				}
			});
			this.permissions = newPerms;

			// sort menu items according to menu position
			const helper = {};
			const translations = {};
			this.$user.wholeMenu.forEach((e) => {
				if (e.dynamic) return;
				// create key in helper and translations if it is not there yet
				if (!helper[e.module]) {
					helper[e.module] = [];
					translations[e.module] = [];
				}

				if (e.name === 'administration') {
					// list throught all administration items
					e.items.forEach((item) => {
						if (item.dynamic) return;
						if (!item.items || item.items.length === 0) {
							// put simple item to helper key and its translations to translations key
							if (!helper[item.group]) {
								helper[item.group] = [];
								translations[item.group] = [];
							}
							helper[item.group].push(item.name);
							translations[item.group].push(`a;${item.translatedName}`);
						} else {
							item.items.forEach((i) => {
								// create key in helper and translations if it is not there yet
								if (!helper[i.group]) {
									helper[i.group] = [];
									translations[i.group] = [];
								}
								// put simple item to helper key and its translations to translations key
								helper[i.group].push(i.name);
								translations[i.group].push(`a;${item.translatedName} - ${i.translatedName}`);
							});
						}
					});
				} else {
					if (!e.items || e.items.length === 0) {
						// put simple item to helper key and its translations to translations key
						helper[e.module].push(e.name);
						translations[e.module].push(e.translatedName);
					} else {
						e.items.forEach((item) => {
							if (item.dynamic) return;
							// put simple item to helper key and its translations to translations key
							helper[e.module].push(item.name);
							translations[e.module].push(`${e.translatedName} - ${item.translatedName}`);
						});
					}
				}
			});

			this.permissions.forEach((perm) => {
				perm.menu.forEach((menuItem) => {
					if (helper[perm.acl_type_module]) {
						const index = helper[perm.acl_type_module].indexOf(menuItem.acl_type_object_name.replace('menu.', ''));
						if (index !== -1) {
							// create new menu items into permissions.menu (acl_types)
							helper[perm.acl_type_module][index] = menuItem;
							helper[perm.acl_type_module][index].translatedName = translations[perm.acl_type_module][index];
						} else {
							// item from permissions in DB was not in menu files
							helper[perm.acl_type_module].push(menuItem);
						}
					}
				});
				perm.menu = helper[perm.acl_type_module];

				perm.subpermissions = $_.sortBy(perm.subpermissions, ['acl_type_object_name', 'acl_type_name', 'acl_type_uid']);
			});
		},
		getName(menu) {
			if (menu.translatedName && menu.translatedName.includes('a;')) {
				return this.$t(`administration`) + ' - ' + menu.translatedName.replace('a;', '');
			} else {
				return menu.translatedName || menu.acl_type_object_name || menu;
			}
		},
		isPermInSubpermissions(subpermissions, type_perm) {
			const a = subpermissions.filter((el) => {
				if (el.acl_type_perm === type_perm) return el;
			});
			return (a.length > 0);
		},
		getSectionTitle(name) {
			if (name === 'lbadmin') {
				return 'LbAdmin';
			}
			return this.$t(`${name}.menu.${name}`);
		},
		isProfAllowed(profile) {
			const allowed = this.allowedProfiles.find((el) => el.profile_uid === profile.profile_uid);
			return !(allowed && allowed.profile_uid === profile.profile_uid && profile.profile_uid !== this.profile.profile_uid);
		},
		markMenuItems(data) {
			$_.forEach(data, (p) => {
				if (p.acl_type_uid && !$_.find(this.profile.permissions, (e) => e === p.acl_type_uid)) {
					this.profile.permissions.push(p.acl_type_uid);
				}
			});
		},
		unmarkMenuItems(data) {
			$_.forEach(data, (p) => {
				$_.remove(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid));
			});
		},
		markModuleItems(data, type) {
			$_.forEach(data, (p) => {
				if (type && p.acl_type_perm !== type) {
					return;
				}
				if (!$_.find(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid))) {
					this.profile.permissions.push(p.acl_type_uid);
				}
			});
		},
		unmarkModuleItems(data, type) {
			$_.forEach(data, (p) => {
				if (type && p.acl_type_perm !== type) {
					return;
				}
				$_.remove(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid));
			});
		},
		markProfiles() {
			$_.forEach(this.allowedProfiles, (p) => {
				if (!$_.find(this.profile.allowed, (e) => (p.profile_uid && e === p.profile_uid))) {
					this.profile.allowed.push(p.profile_uid);
				}
			});
		},
		unmarkProfiles() {
			$_.forEach(this.allowedProfiles, (p) => {
				$_.remove(this.profile.allowed, (e) => (p.profile_uid && e === p.profile_uid));
			});
		},
		markItems(type, state, data1, data2) {
			this.setDirty(true);
			if (type === 'menu') {
				if (state) {
					this.markMenuItems(data1);
				} else {
					this.unmarkMenuItems(data1);
				}
			} else if (type === 'type') {
				if (state) {
					this.markModuleItems(data2, data1);
				} else {
					this.unmarkModuleItems(data2, data1);
				}
			} else if (type === 'section') {
				if (state) {
					this.markMenuItems(data1.menu);
					this.markModuleItems(data1.subpermissions, null);
				} else {
					this.unmarkMenuItems(data1.menu);
					this.unmarkModuleItems(data1.subpermissions, null);
				}
			} else if (type === 'profiles') {
				if (state) {
					this.markProfiles();
				} else {
					this.unmarkProfiles();
				}
			}
			this.$forceUpdate();
		},
		scrollToSection(id) {
			const el = document.getElementById(id);
			if (!el) return;
			el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		},
	},
};
</script>
