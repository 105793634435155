var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPageLoaded)?_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"page-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('releaseNotes'))+" ")]),_c('div',{staticClass:"page-controls"},[_c('span',{attrs:{"data-cy":"changelog__reload"},on:{"click":_vm.tabReload}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('refreshData')),expression:"$t('refreshData')"}],staticClass:"icon-reset"})])])]),_c('div',{directives:[{name:"autofocus",rawName:"v-autofocus"}],ref:"pageContent",staticClass:"page-content",attrs:{"tabindex":"0"},on:{"scroll":_vm.setScroll}},[(_vm.$_.isEmpty(_vm.releaseNotes))?[_vm._v(" "+_vm._s(_vm.$t('noReleaseNotesAvailable'))+" ")]:[_c('nav',{staticClass:"grid-paginaton"},[_c('ul',[_c('li',{staticClass:"p-item prev",class:{ 'disabled': _vm.currentPage === 1 || _vm.isLoadingData },on:{"click":_vm.setPrevPage}},[_c('i',{staticClass:"icon-collapse-all"})]),_c('li',{staticClass:"p-item next",class:{ 'disabled': _vm.currentPage === _vm.pageCount || _vm.isLoadingData },on:{"click":_vm.setNextPage}},[_c('i',{staticClass:"icon-collapse-all"})]),_vm._l((_vm.pages),function(page,index){return _c('li',{key:("page-" + index),class:{
							'dots': page === '...',
							'p-item': page !== '...',
							'active': _vm.currentPage === page,
							'disabled': _vm.isLoadingData,
						},on:{"click":function($event){return _vm.setCurrentPage(page)}}},[_vm._v(_vm._s(page))])})],2)]),_c('div',{staticClass:"markdown-body",domProps:{"innerHTML":_vm._s(_vm.releaseNotes)}}),_c('nav',{staticClass:"grid-paginaton"},[_c('ul',[_c('li',{staticClass:"p-item prev",class:{ 'disabled': _vm.currentPage === 1 || _vm.isLoadingData },on:{"click":_vm.setPrevPage}},[_c('i',{staticClass:"icon-collapse-all"})]),_c('li',{staticClass:"p-item next",class:{ 'disabled': _vm.currentPage === _vm.pageCount || _vm.isLoadingData },on:{"click":_vm.setNextPage}},[_c('i',{staticClass:"icon-collapse-all"})]),_vm._l((_vm.pages),function(page,index){return _c('li',{key:("page-" + index),class:{
							'dots': page === '...',
							'p-item': page !== '...',
							'active': _vm.currentPage === page,
							'disabled': _vm.isLoadingData,
						},on:{"click":function($event){return _vm.setCurrentPage(page)}}},[_vm._v(_vm._s(page))])})],2)])]],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }