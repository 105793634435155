const mime = require('mime');

export default {
	install(Vue) {
		const fileTypeMap = {
			'ms-word/word': ['doc', 'docx'],
			'ms-excel/excel': ['xls', 'xlsx'],
			'ms-powerpoint/powerpoint': ['ppt', 'pptx'],
		};
		mime.define(fileTypeMap, true);

		window.mime = mime;
		Vue.prototype.$mime = mime;
	},
};
