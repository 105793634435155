import { render, staticRenderFns } from "./LbaGrid.vue?vue&type=template&id=47819c42&scoped=true"
import script from "./LbaGrid.js?vue&type=script&lang=js&external"
export * from "./LbaGrid.js?vue&type=script&lang=js&external"
import style0 from "./LbaGrid.vue?vue&type=style&index=0&id=47819c42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47819c42",
  null
  
)

export default component.exports