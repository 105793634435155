<template>
	<component :is="rootTag" v-if="visible">
		<br v-if="options.break === 'before'">

		<!-- TEXT -->
		<s
			v-if="(!options.ui_type || options.ui_type === 'text') && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<input
					type="text"
					v-model="data[key]"
					v-bind="bindOptions()"
					:class="{ 'lba-invalid': invalid && dirty }"
					@input="onEvent('input')"
					@blur="$emit('blur')"
					@change="onEvent('change')"
				>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>
		<template v-if="(!options.ui_type || options.ui_type === 'text') && noInput">
			{{ data[key] }}
		</template>

		<!-- TEXTAREA -->
		<s
			v-if="options.ui_type === 'textarea' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<resizable-textarea ref="resize">
					<textarea
						v-model="data[key]"
						v-bind="bindOptions()"
						:class="{ 'lba-invalid': invalid && dirty }"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					</textarea>
				</resizable-textarea>

				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>

		<!-- LINK FORMAT -->
		<s
			v-if="options.ui_type === 'link_format' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }} xxx
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<span
					class="has-icon"
					:class="{ 'lba-invalid': invalid && dirty }"
				>
					<input
						type="text"
						v-model="data[key]"
						v-bind="bindOptions()"
						:class="{ 'lba-invalid': invalid && dirty }"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					<a :href="formattedLink" target="_blank">
						<i class="icon-new-tab"></i>
					</a>
				</span>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>

		<!-- LINK -->
		<s
			v-if="options.ui_type === 'link' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<span
					class="has-icon"
					:class="{ 'lba-invalid': invalid && dirty }"
				>
					<input
						type="text"
						v-model="data[key]"
						v-bind="bindOptions()"
						:class="{ 'lba-invalid': invalid && dirty }"
						class="input-has-icon"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					<a :href="data[key]" target="_blank">
						<i class="icon-new-tab"></i>
					</a>
				</span>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>

		<!-- NUMBER -->
		<s v-if="options.ui_type === 'number' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<input
					type="number"
					v-model="data[key]"
					v-bind="bindOptions()"
					:class="{ 'lba-invalid': invalid && dirty }"
					@input="onEvent('input')"
					@blur="$emit('blur')"
					@change="onEvent('change')"
				>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>

		<!-- TIME -->
		<s v-if="options.ui_type === 'time' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<!--datepicker does not emit change-->
				<lba-datepicker
					v-model="data[key]"
					:mode="'time'"
					:class="{ 'lba-invalid': invalid && dirty }"
					@input="onEvent('change');"
				></lba-datepicker>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>
		<template v-if="options.ui_type === 'time' && noInput">
			{{ $d(new Date(data[key]), options.dateFormat || 'timeLong') }}
		</template>

		<!-- DATETIME -->
		<s v-if="options.ui_type === 'datetime' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<!--datepicker does not emit change-->
				<lba-datepicker
					v-model="data[key]"
					:class="{ 'lba-invalid': invalid && dirty }"
					@input="onEvent('change')"
				></lba-datepicker>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>
		<template v-if="options.ui_type === 'datetime' && noInput">
			{{ $d(new Date(data[key]), options.dateFormat || 'timeLong') }}
		</template>

		<!-- INTERVAL -->
		<s v-if="options.ui_type === 'interval' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<!--datepicker does not emit change-->
				<input-time
					v-model="data[key]"
					:mode="mode"
					:class="{ 'lba-invalid': invalid && dirty }"
					@input="onEvent('input')"
					@change="onEvent('change')"
					class="input-has-icon"
				/>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages">
						{{ err }}
					</span>
				</template>
			</ValidationProvider>
		</s>
		<template v-if="options.ui_type === 'datetime' && noInput">
			{{ $d(new Date(data[key]), options.dateFormat || 'timeLong') }}
		</template>

		<!-- CHECKBOX -->
		<s v-if="options.ui_type === 'checkbox' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<label class="checkbox">
				<input
					type="checkbox"
					v-model="data[key]"
					v-bind="bindOptions()"
					@input="onEvent('input')"
					@blur="$emit('blur')"
					@change="onEvent('change')"
				>
				<i class="icon-ok"></i>
			</label>
		</s>
		<template v-if="options.ui_type === 'checkbox' && noInput">
			<i v-if="data[key]" class="icon-ok"></i>
			<i v-else class="icon-cancel"></i>
		</template>

		<!-- SELECT -->
		<s v-if="options.ui_type == 'select' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<span>
				<select
					v-model="data[key]"
					:name="attribute.name"
					:title="options.title || ''"
					:disabled="readOnly"
					@input="onEvent('input')"
					@blur="$emit('blur')"
					@change="onEvent('change')"
				>
					<option v-for="(option, index) in options.options"
						:key="index"
						:value="option.value"
						:selected="data[key] === option.value">
						{{ option.label }}
					</option>
				</select>
			</span>
		</s>

		<!-- BOOLEAN -->
		<s v-if="options.ui_type == 'boolean' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<template>
				<label class="checkbox radio">
					<input type="radio"
						v-model="data[key]"
						:value="true"
						:disabled="readOnly"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					<span class="checkmark"></span>
					<span class="label">{{$t('crm.yes')}}</span>
				</label>
				<label class="checkbox radio">
					<input type="radio"
						v-model="data[key]"
						:value="false"
						:disabled="readOnly"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					<span class="checkmark"></span>
					<span class="label">{{$t('crm.no')}}</span>
				</label>
			</template>
		</s>
		<template v-if="options.ui_type === 'boolean' && noInput">
			<i v-if="data[key]" class="icon-ok"></i>
			<i v-else class="icon-cancel"></i>
		</template>

		<!-- MULTI CHECKBOX -->
		<s v-if="options.ui_type == 'multi_checkbox' && options.options.length > 0 && !noInput"
			:class="[options.class]"
			class="wide"
		>
			<span v-for="(option, okey) in options.options"
				:key="okey"
				style="display: inline-block; margin-right: 10px;"
			>
				<label class="checkbox">
					<input
						v-model="data[key][option.value]" type="checkbox"
						:name="attribute.name"
						:title="option.label || ''"
						:disabled="readOnly"
						@input="onEvent('input')"
						@blur="$emit('blur')"
						@change="onEvent('change')"
					>
					<i class="icon-ok"></i>
					<span class="label">{{ option.label }}</span>
				</label>
			</span>
		</s>
		<span v-if="options.ui_type == 'multi_checkbox' && options.options.length === 0 && !noInput">
			{{ $t('crm.noEntries') }}
		</span>
		<template v-if="options.ui_type === 'multi_checkbox' && noInput">
			<span v-for="(option, index) in options.options"
				:key="index"
				style="display: inline-block; margin-right: 10px;"
			>
				{{ option.label }}:&nbsp;
				<i v-if="data[key][option.value]" class="icon-ok"></i>
				<i v-else class="icon-cancel"></i>
			</span>
		</template>

		<!-- ICON -->
		<s v-if="options.ui_type === 'icon' && !noInput"
			:class="options.class"
		>
			<small>
				{{ label }}
			</small>

			<span class="icon-sample">
				<i v-if="data[key]"
					:key="'icon' + elementKey"
					:class="[data[key], 'hover-pointer']"
					v-lba-dialog-open="id"
					v-tooltip="$t('changeIcon')">
				</i>
				<button v-else type="button"
					v-lba-dialog-open="id"
					class="buttonTransparent"
				>{{ $t('set') }}</button>
				<lba-icon-picker
					v-model="data[key]"
					:dialogName="id"
					:disabled="readOnly"
					@input="onEvent('input')"
					@blur="$emit('blur')"
					@change="onEvent('change')"
				/>
			</span>
		</s>
		<template v-if="options.ui_type === 'icon' && noInput">
			<i v-if="!$_.isEmpty(data[key]) && data[key].constructor === String" :class="data[key]"></i>
			<template v-else>
				<i v-for="(icon, index) in data[key]" class="mr-2" :class="icon" :key="index"></i>
			</template>
		</template>

		<!-- TWO LISTS -->
		<s v-if="options.ui_type === 'two_lists' && !noInput"
			:class="[options.class]"
		>
			<small>
				{{ label }}
			</small>

			<ValidationProvider :name="label" :rules="rules" v-slot="{ invalid, errors }">
				<lba-dnd-two-lists
					:name="label"
					:dialogTitle="label"
					:list1.sync="twoList1"
					:list2.sync="twoList2"
					:listTitle1="$t('crm.available')"
					:listTitle2="$t('crm.used')"
					:staticListItems1="false"
					:staticListItems2="false"
					:staticListOrder1="false"
					:staticListOrder2="false"
					@save="saveTwoLists"
				>
					<template v-slot:list1="props1">
						<div v-for="(opt1, okey1) in props1.list"
							:key="okey1"
							class="flex-row inner border"
						>
							<label class="checkbox">
								<input type="checkbox" lba-dnd-list-checkbox />
								<i class="icon-ok"></i>
								<span class="label">{{ opt1.label }}</span>
							</label>
						</div>
					</template>
					<template v-slot:list2="props2">
						<div v-for="(opt2, okey2) in props2.list"
							:key="okey2"
							class="flex-row inner border"
						>
							<label class="checkbox">
								<input type="checkbox" lba-dnd-list-checkbox />
								<i class="icon-ok"></i>
								<span class="label">{{ opt2.label }}</span>
							</label>
						</div>
					</template>
				</lba-dnd-two-lists>
				<span>
					{{ twoListLabels.join(', ') }}
				</span>
				<span v-if="!hideSettings" v-lba-dialog-open="label" class="ml-2">
					<i class="icon-settings hover-pointer" v-tooltip="$t('crm.change')"></i>
				</span>
			</ValidationProvider>
		</s>
		<template v-if="options.ui_type === 'two_lists' && noInput">
			{{ $t('crm.available') }}:&nbsp;
			<span v-for="(item, index) in twoLists1" :key="index">{{ item }}&nbsp;</span>
			<br>
			{{ $t('crm.used') }}:&nbsp;
			<span v-for="(item, index) in twoLists2" :key="index">{{ item }}&nbsp;</span>
		</template>

		<template v-if="options.ui_type && !customTypes.includes(options.ui_type) && noInput">
			{{ data[key] }}
		</template>

		<br v-if="options.break === 'after'">
	</component>
</template>

<script>
export default {
	name: 'LbaCustomValue',
	props: {
		/*
			{
				options: Object,
				data: [Object, Array],
				key: [String, Number],
				validationSchema: Object,
			}
		*/
		attribute: {
			type: Object,
			default: () => ({}),
		},
		rootTag: {
			type: String,
			default: 'div',
		},
		noInput: Boolean,
		dirty: Boolean,
	},
	computed: {
		label() {
			if (this.options && this.options.label) {
				if (this.options.label.constructor === String) return this.options.label;
				if (this.options.label.constructor === Object) return this.options.label[this.$i18n.locale];
			}
			return null;
		},
	},
	data() {
		return {
			options: null,
			/*
				{
					break: ['before', 'after']
					ui_type // typ prvku
					class // css classy pro S-ko
					label // label v smallu
					title // pro select
					options // pro select
					hideSettings // dva listy, schování nastavení
					required // požadované
					validation // regex
					visible // jestli to jde vidět
					attributes // mergovací props
					read_only
					min
					max
					name // jméno prvku
					placeholder // placeholder pro inputy
					format // pro link
					default // defaultní hodnota
					dateFormat // format pro datum, default timeLong
					iconAllowEmpty // allowEmpty pro lba-icon-picker
					style // styly pro non-inputy
				}
			*/
			data: null,
			key: null,
			rules: {},
			readOnly: false,
			callback: null,
			visible: false,
			formattedLink: '#',
			id: null,
			twoList1: [],
			twoList2: [],
			twoListLabels: [],
			elementKey: 0,
			customTypes: ['text', 'time', 'checkbox', 'boolean', 'multi_checkbox', 'icon', 'two_lists', 'datetime'],
		};
	},
	created() {
		this.id = this.$generateUID();
		this.options = this.attribute.options;
		this.data = this.attribute.data;
		this.key = this.attribute.key;
		this.originalValue = this.data[this.key];
		this.hideSettings = this.options.hideSettings || false;

		if (this.attribute.validationSchema) {
			this.rules = this.attribute.validationSchema;
		} else {
			this.rules = {};

			if (this.options.required) {
				this.rules.required = true;
			}
			if (this.options.validation) {
				this.rules.regex = this.options.validation;
			}
		}

		if (this.propertyExist(this.options, 'visible') && !this.options.visible) {
			this.visible = false;
		} else {
			this.visible = true;
		}

		if (this.options.attributes) {
			$_.each(Object.keys(this.options.attributes), (key) => {
				this.options[key] = this.options.attributes[key];
			});
		}

		this.prepareMultiCheckbox();
		this.prepareLinkFormat();
		this.prepareTwoList();
		this.prepareDefaultValue();
		this.prepareSelectOptions();

		this.readOnly = (!!this.attribute.readOnly) || (!!this.options.read_only);
	},
	methods: {
		onEvent(eventName) {
			if ((!this.originalValue && !this.data[this.key]) ||
				this.originalValue === this.data[this.key]
			) {
				return;
			}
			this.$emit(eventName, this.data[this.key]);
			this.prepareLinkFormat();
			this.elementKey += 1;
			this.$forceUpdate();
		},
		bindOptions() {
			const extended = {};

			if (this.options) {
				if (this.options.ui_type === 'number') {
					if ('min' in this.options) {
						extended.min = this.options.min;
					}
					if ('max' in this.options) {
						extended.max = this.options.max;
					}
				}
			}

			return $_.extend({
				name: this.options.name,
				placeholder: this.options.placeholder || '',
				disabled: this.readOnly,
				title: this.options.title || '',
			}, extended);
		},
		propertyExist(data, key) {
			return (data && Object.prototype.hasOwnProperty.call(data, key));
		},
		prepareMultiCheckbox() {
			if (this.options && this.options.ui_type === 'multi_checkbox') {
				if (!this.data[this.key]) {
					this.data[this.key] = {};
				}
				if (this.readOnly && this.options.options) {
					this.options.options = $_.filter(this.options.options, (option) => {
						return this.data[this.key][option.value];
					});
				}
			}
		},
		prepareLinkFormat() {
			if (this.options && this.options.ui_type === 'link_format' && this.options.format) {
				this.formattedLink = this.options.format.replace(/{value}/, this.data[this.key]);
			} else {
				this.formattedLink = '#';
			}
		},
		prepareTwoList() {
			if (this.options && this.options.ui_type === 'two_lists') {
				this.twoList1 = [];
				this.twoList2 = [];
				this.twoListLabels = [];
				$_.each(this.data[this.key], (selected) => {
					$_.each(this.options.options, (available) => {
						if (available.value === selected) {
							this.twoList2.push(available);
							this.twoListLabels.push(available.label);
							available.used = true;
						}
					});
				});
				this.twoList1 = $_.filter(this.options.options, (opt) => !opt.used);
			}
		},
		saveTwoLists() {
			this.data[this.key] = $_.map(this.twoList2, (opt) => opt.value);
			this.twoListLabels = $_.map(this.twoList2, (opt) => opt.label);
			this.$root.$emit('dialog-close', this.label);
			this.onEvent('change');
		},
		prepareDefaultValue() {
			if (this.propertyExist(this.options, 'default') && !this.data[this.key]) {
				if (this.options.ui_type === 'time') {
					const hours = parseInt(this.options.default) / 60;
					const minutes = parseInt(this.options.default) % 60;
					const time = this.$moment().hours(hours).minutes(minutes).second(0);
					this.data[this.key] = time.toDate();
				} else {
					this.data[this.key] = this.options.default;
				}
			}
		},
		prepareSelectOptions() {
			if (this.options &&
				this.options.ui_type === 'select' &&
				this.options.options &&
				this.options.options[0] &&
				this.options.options[0].label &&
				this.options.options[0].label.constructor === Object
			) {
				$_.forEach(this.options.options, (opt) => {
					opt.label = (opt.label.constructor === Object) ? opt.label[this.$i18n.locale] : opt.label;
				});
			}
		},
	},
};
</script>
