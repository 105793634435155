import Ajv from 'ajv';
import addFormats from 'ajv-formats';

export default {
	install(Vue) {
		const ajv = new Ajv({ allowUnionTypes: true, allErrors: true });
		addFormats(ajv, { formats: ['email', 'date', 'time'] });
		const ajvSchemaMap = new Map();
		const ajvApi = {
			ajvInstance: ajv,
			prepareAjvSchema(name, schema) {
				if ($_.isEmpty(name)) throw new Error('invalid name:', name);
				if (ajvSchemaMap.has(name)) {
					const ajvSchema = ajvSchemaMap.get(name);
					if (!$_.isEqual(schema, ajvSchema.schema)) {
						ajvSchema.validate = ajv.compile(schema);
					}
					return ajvSchema;
				}
				const validate = ajv.compile(schema);
				const ajvSchema = { schema, validate };
				ajvSchemaMap.set(name, ajvSchema);
				return ajvSchema;
			},
			compile(name, schema) {
				const ajvSchema = this.prepareAjvSchema(name, schema);
				return ajvSchema.validate;
			},
			validate(name, schema, data) {
				const ajvSchema = this.prepareAjvSchema(name, schema);
				return ajvSchema.validate(data);
			},
		};
		Vue.prototype.$ajv = ajvApi;
	},
};
