<template>
	<content-options v-if="!loading">
		<template v-slot:content="contentOptionsProps">
			<div class="page-header">
				<h1>{{ getTableName(importExportConfiguration.name) }}</h1>

				<div class="page-controls">
					<button
						v-if="!isNew"
						class="buttonTransparent"
						:data-cy="`${contentOptionsProps.parentComponentId}__remove`"
						@click.stop="openRemoveEntry"
					>
						{{ $t('remove') }}
					</button>
					<button
						class="button"
						:data-cy="`${contentOptionsProps.parentComponentId}__save`"
						@click.stop="saveEntry"
						:disabled="!inputDirty"
					>
						{{ $t('save') }}
					</button>
					<span v-if="!isNew" @click="$root.$emit('tabs.reload')" :data-cy="`${contentOptionsProps.parentComponentId}__reload`">
						<i class="icon-reset" v-tooltip="$t('refreshData')"
						></i>
					</span>
				</div>
			</div>

			<div
				class="page-content"
				@scroll="setScroll"
				tabindex="0"
				v-autofocus
			>
				<ValidationObserver ref="form">
					<lba-section
						parentComponentId="importExportConfiguration"
						componentId=""
						expanded
						class="form-fieldset"
						:title="$t('settings.settings')"
					>
						<ValidationProvider
							v-slot="{ invalid, errors }"
							:name="$t('forTable')"
							:rules="{ required: true }"
						>
							<s>
								<small>
									{{ $t('forTable') }}
									<span class="required-sign"></span>
								</small>
								<input
									data-cy="importExportConfiguration__name__inputText"
									type="text"
									v-model="importExportConfiguration.name"
									:disabled="!isNew"
									:class="{ 'lba-invalid': invalid && dirty }"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								>
								<template v-if="dirty">
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`importExportConfiguration__name__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</template>
							</s>
						</ValidationProvider>
						<ValidationProvider
							v-slot="{ invalid, errors }"
							:name="$t('name')"
							:rules="{ localeRequired: true, locale: true }"
						>
							<s>
								<small>
									{{ $t('name') }}
									<span class="required-sign"></span>
								</small>
								<lba-locale
									parentComponentId="importExportConfiguration"
									v-model="importExportConfiguration.title"
									:localeTitle="$t('name')"
									:dirty="dirty"
									:invalid="invalid"
									:errors="errors"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								/>
							</s>
						</ValidationProvider>
						<s>
							<small>PG Import Callback</small>
							<input
								data-cy="importExportConfiguration__pgImportCallback__inputText"
								type="text"
								v-model="importExportConfiguration.import_finish_pg_callback"
								@change="dirty = true; inputDirty = true"
								@input="inputDirty = true"
							>
						</s>
						<s>
							<small>PG Delete Callback</small>
							<input
								data-cy="importExportConfiguration__pgDeleteCallback__inputText"
								type="text"
								v-model="importExportConfiguration.bulk_delete_pg_callback"
								@change="dirty = true; inputDirty = true"
								@input="inputDirty = true"
							>
						</s>
						<br />
						<s>
							<small>{{ $t('updateEnabled') }}</small>
							<label
								class="checkbox"
								style="margin-right: 10px; top: 4px;"
								:data-cy="`importExportConfiguration__updateEnabled__checkboxLabel`"
							>
								<input
									:data-cy="`importExportConfiguration__updateEnabled__inputCheckbox`"
									type="checkbox"
									v-model="importExportConfiguration.update_enabled"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>{{ $t('updateDefault') }}</small>
							<label
								class="checkbox"
								style="margin-right: 10px; top: 4px;"
								:data-cy="`importExportConfiguration__updateDefault__checkboxLabel`"
							>
								<input
									:data-cy="`importExportConfiguration__updateDefault__inputCheckbox`"
									type="checkbox"
									v-model="importExportConfiguration.update_default"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>{{ $t('deleteEnabled') }}</small>
							<label
								class="checkbox"
								style="margin-right: 10px; top: 4px;"
								:data-cy="`importExportConfiguration__deleteEnabled__checkboxLabel`"
							>
								<input
									:data-cy="`importExportConfiguration__deleteEnabled__inputCheckbox`"
									type="checkbox"
									v-model="importExportConfiguration.delete_enabled"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>{{ $t('deleteDefault') }}</small>
							<label
								class="checkbox"
								style="margin-right: 10px; top: 4px;"
								:data-cy="`importExportConfiguration__deleteDefault__checkboxLabel`"
							>
								<input
									:data-cy="`importExportConfiguration__deleteDefault__inputCheckbox`"
									type="checkbox"
									v-model="importExportConfiguration.delete_default"
									@change="dirty = true; inputDirty = true"
									@input="inputDirty = true"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
					</lba-section>
				</ValidationObserver>

				<lba-section
					parentComponentId="importExportConfiguration"
					componentId="columnSettings"
					expanded
					class="form-fieldset"
					:title="$t('settings.settings')"
				>
					<template #default="sectionProps">
						<lba-content-tabs
							:parentComponentId="sectionProps.parentComponentId"
							containsGrid
							:tabs="tabs"
							:defaultTab="defaultTab"
						>
							<div>
								<lba-grid
									v-if="
										!$_.isEmpty(importExportConfiguration) &&
										!$_.isEmpty(importExportConfiguration.def_import_export_uid)
									"
									ref="gridColumn"
									gridName="lbadmin.def_import_export_configuration-columns"
									hideableColumns
									:collection="collectionColumns"
								>
									<template v-slot:toolbar="props">
										<button
											@click="editMultipleColumnsGroups"
											:data-cy="`${props.parentComponentId}__groups`"
										>
											{{ $t('groups') }}
										</button>
										<button
											@click="newColumn"
											:data-cy="`${props.parentComponentId}__new`"
										>
											{{ $t('new') }}
										</button>
									</template>

									<template v-slot:columns="props">
										<lba-grid-column
											id="title"
											:label="$t('title')"
											searchable
											sortable
											width="200"
											:data-cy="`${props.parentComponentId}__title`"
										>
											{{ $getLocale(props.row.title) }}
										</lba-grid-column>
										<lba-grid-column
											id="name"
											:label="$t('internalName')"
											searchable
											sortable
											width="200"
											:data-cy="`${props.parentComponentId}__name`"
										>
											{{ props.row.name }}
										</lba-grid-column>
										<lba-grid-column
											id="example"
											:label="$t('example')"
											width="300"
											:data-cy="`${props.parentComponentId}__example`"
										>
											{{ props.row.example }}
										</lba-grid-column>
										<lba-grid-column
											id="custom"
											:label="$t('customData')"
											width="100"
											:data-cy="`${props.parentComponentId}__custom`"
										>
											<i class="icon-ok green" v-if="props.row.custom"></i>
										</lba-grid-column>
										<lba-grid-column
											id="multipleColumn"
											:label="$t('multipleColumn')"
											:tooltip="$t('multipleColumnHelp')"
											width="130"
											:data-cy="`${props.parentComponentId}__multipleColumn`"
										>
											<i class="icon-ok green" v-if="props.row.multiple"></i>
										</lba-grid-column>
										<lba-grid-column
											id="spreadIntoMultipleColumns"
											:label="$t('multipleColumnSpreadIntoMultipleColumns')"
											:tooltip="$t('multipleColumnSpreadIntoMultipleColumnsHelp')"
											width="130"
											:data-cy="`${props.parentComponentId}__spreadIntoMultipleColumns`"
										>
											<i class="icon-ok green" v-if="props.row.spreadIntoMultipleColumns"></i>
										</lba-grid-column>
										<lba-grid-column
											id="group"
											:label="$t('groupOfMultipleColumns')"
											width="130"
											:data-cy="`${props.parentComponentId}__group`"
										>
											<span v-if="!$_.isEmpty(props.row.group)">{{ props.row.group }}</span>
										</lba-grid-column>
										<lba-grid-column
											id="allowImport"
											:label="$t('allowImport')"
											width="130"
											:data-cy="`${props.parentComponentId}__allowImport`"
										>
											<i class="icon-ok green" v-if="props.row.allowImport"></i>
										</lba-grid-column>
										<lba-grid-column
											id="allowExport"
											:label="$t('allowExport')"
											width="130"
											:data-cy="`${props.parentComponentId}__allowExport`"
										>
											<i class="icon-ok green" v-if="props.row.allowExport"></i>
										</lba-grid-column>
										<lba-grid-column
											:id="'actions'"
											:label="$t('actions')"
											width="60"
											class="actions"
											style="justify-content: right"
											:data-cy="`${props.parentComponentId}__actions`"
										>
											<a
												style="flex: 1"
												:data-cy="`${props.parentComponentId}__actions__edit`"
												@click.stop="editColumn(props.row)"
											>
												<i class="icon-edit"
													v-tooltip="$t('edit')">
												</i>
											</a>
											<a
												@click.stop="openRemoveColumn(props.row)"
												:data-cy="`${props.parentComponentId}__actions__remove`"
											>
												<i class="icon-delete"
													v-tooltip="$t('remove')"
												>
												</i>
											</a>
										</lba-grid-column>
									</template>
								</lba-grid>
								<span v-else>{{ $t('editImportExportConfigurationColumnsAfterCreate') }}</span>
							</div>

							<div>
								<lba-grid
									ref="gridValidation"
									gridName="lbadmin.def_import_export_configuration-validation"
									hideableColumns
									:collection="collectionValidationSchema"
								>
									<template v-slot:toolbar="props">
										<button @click="editRequiredGroups"
											:data-cy="`${props.parentComponentId}__groups`"
										>
											{{ $t('groups') }}
										</button>
										<button @click="newValidation"
											:data-cy="`${props.parentComponentId}__new`"
										>
											{{ $t('new') }}
										</button>
									</template>

									<template v-slot:columns="props">
										<lba-grid-column
											id="name"
											:label="$t('internalName')"
											searchable
											sortable
											width="200"
											:data-cy="`${props.parentComponentId}__name`"
										>
											{{ props.row.name }}
										</lba-grid-column>
										<lba-grid-column
											id="required"
											:label="$t('mandatory')"
											width="100"
											:data-cy="`${props.parentComponentId}__required`"
										>
											<i class="icon-ok green" v-if="props.row.require"></i>
											<!--<i class="icon-cancel silver" v-if="!props.row.required"></i>-->
										</lba-grid-column>
										<lba-grid-column
											id="requiredGroup"
											:label="$t('requiredGroupsOfFields')"
											width="100"
											:data-cy="`${props.parentComponentId}__requiredGroup`"
										>
											<span v-if="!$_.isEmpty(props.row.requiredGroup)">{{ props.row.requiredGroup }}</span>
										</lba-grid-column>
										<lba-grid-column
											id="type"
											:label="$t('dataType')"
											width="200"
											:data-cy="`${props.parentComponentId}__dataType`"
										>
											{{ getValidationType(props.row.type) }}
										</lba-grid-column>
										<lba-grid-column
											id="format"
											:label="$t('format')"
											width="100"
											:data-cy="`${props.parentComponentId}__format`"
										>
											{{ props.row.format }}
										</lba-grid-column>
										<lba-grid-column
											id="pattern"
											:label="$t('pattern')"
											width="200"
											:data-cy="`${props.parentComponentId}__pattern`"
										>
											{{ props.row.pattern }}
										</lba-grid-column>
										<lba-grid-column
											id="actions"
											:label="$t('actions')"
											width="60"
											class="actions"
											style="justify-content: right"
											:data-cy="`${props.parentComponentId}__actions`"
										>
											<a
												style="flex: 1"
												:data-cy="`${props.parentComponentId}__actions__edit`"
												@click.stop="editValidation(props.row)"
											>
												<i class="icon-edit"
													v-tooltip="$t('edit')">
												</i>
											</a>
											<a
												:data-cy="`${props.parentComponentId}__actions__remove`"
												@click.stop="openRemoveValidation(props.row)"
											>
												<i class="icon-delete"
													v-tooltip="$t('remove')"
												>
												</i>
											</a>
										</lba-grid-column>
									</template>
								</lba-grid>
							</div>
						</lba-content-tabs>

						<lba-dialog-modal
							:parentComponentId="sectionProps.parentComponentId"
							componentId="removeEntry"
							name="removeEntry"
							:title="$t('removeEntry')"
						>
							<template #default="props">
								<div class="popup-footer">
									<button class="buttonRed" type="button" @click="removeEntry"
										:data-cy="`${props.parentComponentId}__remove`"
									>
										{{ $t('remove') }}
									</button>
									<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
										:data-cy="`${props.parentComponentId}__cancel`"
									>
										{{ $t('cancel') }}
									</button>
								</div>
							</template>
						</lba-dialog-modal>

						<lba-dialog-modal
							:parentComponentId="sectionProps.parentComponentId"
							componentId="editMultipleColumnsGroups"
							name="editMultipleColumnsGroups"
							:title="$t('editMultipleColumnsGroups')"
							@open="onEditGroupsOpen"
						>
							<template #default="props">
								<div class="form-fieldset" style="width: 600px; padding: 0 35px;">
									<p>{{ $t('multipleColumnsGroupsHelp') }}</p>
									<lba-dnd-list2
										:parentComponentId="props.parentComponentId"
										name="columns-groups"
										:list.sync="editedGroups"
										:staticListItems1="false"
										:staticListOrder1="true"
										:listMaxHeight="600"
									>
										<template v-slot:list>
											<div
												v-for="(group, index) in editedGroups"
												:key="index"
												class="mb-2 mx-auto"
												style="position: relative; text-align: left; width: 430px;"
											>
												<lba-select
													:parentComponentId="`${props.parentComponentId}__columnGroup${index}`"
													rootTag="div"
													v-model="editedGroups[index]"
													:opts="columnsAvailableForGroups"
													selectBoxValueStyle="width: 400px"
												>
												</lba-select>
												<span class="multiselect-clear" style="top: 0;">
													<span
														@click="removeGroup(index)"
														:data-cy="`${props.parentComponentId}__columnGroup${index}__remove`"
														class="multiselect-clear-icon ml-3"
													>
													</span>
												</span>
											</div>
										</template>
									</lba-dnd-list2>
									<button
										v-if="!$_.isEmpty(columnsAvailableForGroups)"
										:data-cy="`${props.parentComponentId}__addGroup`"
										type="button"
										class="button buttonTransparent marginTop10"
										style="margin-left: 10px"
										@click="addGroup"
									>
										<i class="icon-plus-circle"></i>{{ $t('addGroup') }}
									</button>
								</div>
								<div class="popup-footer">
									<button type="button" @click="saveGroups" :data-cy="`${props.parentComponentId}__save`">
										{{ $t('save') }}
									</button>
									<button class="buttonInverse" type="button" v-lba-dialog-close="'editMultipleColumnsGroups'"
										:data-cy="`${props.parentComponentId}__cancel`"
									>
										{{ $t('cancel') }}
									</button>
								</div>
							</template>
						</lba-dialog-modal>

						<lba-dialog-modal
							:parentComponentId="sectionProps.parentComponentId"
							name="editRequiredGroups"
							:title="$t('editRequiredGroups')"
							@open="onEditRequiredGroupsOpen"
						>
							<template #default="props">
								<div class="form-fieldset" style="width: 600px; padding: 0 35px;">
									<p>{{ $t('validationSchemaRequiredGroups') }}</p>
									<lba-dnd-list2
										:parentComponentId="props.parentComponentId"
										name="schema-required-groups"
										:list.sync="editedRequiredGroups"
										:staticListItems1="false"
										:staticListOrder1="true"
										:listMaxHeight="600"
									>
										<template v-slot:list>
											<div
												v-for="(group, index) in editedRequiredGroups"
												:key="index"
												class="row"
											>
												<lba-select
													:parentComponentId="props.parentComponentId"
													rootTag="div"
													v-model="editedRequiredGroups[index]"
													:opts="validationProperties"
													selectBoxValueStyle="width: 400px"
												>
												</lba-select>
												<span @click="removeRequiredGroup(index)"
													:data-cy="`${props.parentComponentId}__requiredGroup${index}__remove`"
												>
													<i class="icon-cancel"></i>
												</span>
											</div>
										</template>
									</lba-dnd-list2>
									<button
										v-if="!$_.isEmpty(validationProperties)"
										:data-cy="`${props.parentComponentId}__addGroup`"
										type="button"
										class="button buttonTransparent marginTop10"
										style="margin-left: 10px"
										@click="addRequiredGroup"
									>
										<i class="icon-plus-circle"></i>{{ $t('addGroup') }}
									</button>
								</div>
								<div class="popup-footer">
									<button type="button" @click="saveRequiredGroups" :data-cy="`${props.parentComponentId}__save`">
										{{ $t('save') }}
									</button>
									<button class="buttonInverse" type="button" v-lba-dialog-close="'editRequiredGroups'"
										:data-cy="`${props.parentComponentId}__closeDialog`"
									>
										{{ $t('cancel') }}
									</button>
								</div>
							</template>
						</lba-dialog-modal>

						<ValidationObserver ref="formColumn">
							<lba-dialog-edit
								:parentComponentId="sectionProps.parentComponentId"
								componentId="editColumn"
								name="editColumn"
								:title="$t('edit')"
								@submit="saveColumn"
								@close="formColumnDirty = false"
								:warningOnEscape="formColumnDirty"
							>
								<template #default="props">
									<div class="form-fieldset" style="width: 600px;">
										<ValidationProvider
											v-slot="{ invalid, errors }"
											:name="$t('title')"
											:rules="{ required: true }"
										>
											<s>
												<small>
													{{ $t('title') }}
													<span class="required-sign"></span>
												</small>
												<input
													:data-cy="`${props.parentComponentId}__title__inputText`"
													type="text"
													v-model="editedColumnTitle"
													:class="{ 'lba-invalid': invalid && formColumnDirty }"
													@change="formColumnDirty = true"
												>
												<template v-if="formColumnDirty">
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`${props.parentComponentId}__title__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</template>
											</s>
										</ValidationProvider>
										<ValidationProvider
											v-slot="{ invalid, errors }"
											:name="$t('internalName')"
											:rules="{ required: true }"
										>
											<s>
												<small>
													{{ $t('internalName') }}
													<span class="required-sign"></span>
												</small>
												<input
													:data-cy="`${props.parentComponentId}__internalName__inputText`"
													type="text"
													v-model="editedColumn.name"
													:class="{ 'lba-invalid': invalid && formColumnDirty }"
													@change="formColumnDirty = true"
												>
												<template v-if="formColumnDirty">
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`${props.parentComponentId}__internalName__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</template>
											</s>
										</ValidationProvider>
										<br>

										<ValidationProvider
											v-slot="{ invalid, errors }"
											:name="$t('example')"
											:rules="{ required: true }"
										>
											<s>
												<small>
													{{ $t('example') }}
													<span class="required-sign"></span>
												</small>
												<input
													:data-cy="`${props.parentComponentId}__example__inputText`"
													type="text"
													v-model="editedColumn.example"
													:class="{ 'lba-invalid': (invalid || columnExampleFormatError) && formColumnDirty }"
													@change="validateColumnExample(editedColumn, errors)"
												>
												<template v-if="formColumnDirty">
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`${props.parentComponentId}__example__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
													<span v-if="columnExampleFormatError" class="lba-messages"
														:data-cy="`${props.parentComponentId}__example__formatError`"
													>
														{{ columnExampleFormatError }}
													</span>
												</template>
											</s>
										</ValidationProvider>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnCustom"
												name="editedColumnCustom"
												v-model="editedColumn.custom"
												:label="$t('customData')"
												@change="formColumnDirty = true"
											/>
										</s>
										<br>

										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnMultiple"
												name="editedColumnMultiple"
												v-model="editedColumn.multiple"
												:label="$t('multipleColumn')"
												:tooltip="$t('multipleColumnHelp')"
												@change="formColumnDirty = true"
											/>
										</s>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnSpreadIntoMultipleColumns"
												name="editedColumnSpreadIntoMultipleColumns"
												v-model="editedColumn.spreadIntoMultipleColumns"
												:label="$t('multipleColumnSpreadIntoMultipleColumns')"
												:tooltip="$t('multipleColumnSpreadIntoMultipleColumnsHelp')"
												@change="formColumnDirty = true"
											/>
										</s>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnAllowImport"
												name="editedColumnAllowImport"
												v-model="editedColumn.allowImport"
												:label="$t('allowImport')"
												@change="formColumnDirty = true"
											/>
										</s>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnAllowExport"
												name="editedColumnAllowExport"
												v-model="editedColumn.allowExport"
												:label="$t('allowExport')"
												@change="formColumnDirty = true"
											/>
										</s>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedColumnIsDate"
												name="editedColumnIsDate"
												v-model="editedColumn.isDate"
												:label="$t('dataTypeDate')"
												@change="formColumnDirty = true; prepareDateFormat();"
											/>
										</s>
										<ValidationProvider
											v-if="editedColumn.isDate"
											v-slot="{ invalid, errors }"
											:name="$t('dateFormat')"
											:rules="{ required: true }"
										>
											<s>
												<small>
													{{ $t('dateFormat') }}
													<span class="required-sign"></span>
												</small>
												<input
													:data-cy="`${props.parentComponentId}__dateFormat__inputText`"
													type="text"
													v-model="editedColumn.dateFormat"
													:class="{ 'lba-invalid': invalid && formColumnDirty }"
													@change="formColumnDirty = true"
												>
												<template v-if="formColumnDirty">
													<span
														v-for="(err, index) in errors"
														:key="index"
														class="lba-messages"
														:data-cy="`${props.parentComponentId}__dateFormat__error${index}`"
													>{{ err }}</span>
												</template>
											</s>
										</ValidationProvider>
										<s v-if="editedColumn.isDate">
											<small>
												{{ $t('dateFormatExample') }}
											</small>
											{{ moment().format(editedColumn.dateFormat || 'DD.MM.YYYY HH:mm:ss') }}
										</s>
									</div>
								</template>
							</lba-dialog-edit>
						</ValidationObserver>
						<lba-dialog-modal
							:parentComponentId="sectionProps.parentComponentId"
							componentId="removeColumn"
							name="removeColumn"
							:title="`${$t('removeColumn', null, { attribute: $getLocale(columnToRemove.title) })}?`"
						>
							<template #default="props">
								<div class="popup-footer">
									<button class="buttonRed" type="button" @click="removeColumn"
										:data-cy="`${props.parentComponentId}__remove`"
									>
										{{ $t('remove') }}
									</button>
									<button class="buttonInverse" type="button" v-lba-dialog-close="'removeColumn'"
										:data-cy="`${props.parentComponentId}__closeDialog`"
									>
										{{ $t('cancel') }}
									</button>
								</div>
							</template>
						</lba-dialog-modal>

						<ValidationObserver ref="formValidation">
							<lba-dialog-edit
								:parentComponentId="sectionProps.parentComponentId"
								componentId="editValidation"
								name="editValidation"
								:title="$t('edit')"
								@submit="saveValidation"
								:warningOnEscape="formValidationDirty"
							>
								<template #default="props">
									<div class="form-fieldset" style="width: 600px;">
										<ValidationProvider
											v-slot="{ invalid, errors }"
											:name="$t('name')"
											:rules="{ required: true }"
										>
											<s>
												<small>{{ $t('name') }}</small>
												<input
													:data-cy="`${props.parentComponentId}__name__inputText`"
													type="text"
													v-model="editedValidation.name"
													:class="{ 'lba-invalid': invalid && formValidationDirty }"
													@change="formValidationDirty = true"
												>
												<template v-if="formValidationDirty">
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`${props.parentComponentId}__name__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</template>
											</s>
										</ValidationProvider>
										<s>
											<small>{{ $t('dataType') }} <span class="required-sign"></span></small>
											<lba-select
												:parentComponentId="props.parentComponentId"
												componentId="dataType"
												v-model="editedValidation.type"
												:opts="dataTypes"
												:class="{
													input: true,
													'lba-invalid': (
														$_.isEmpty(editedValidation.type) &&
														formValidationDirty
													)
												}"
												:required="true"
												@change="formValidationDirty = true"
												@input="prepareItems();"
											/>
											<template v-if="formValidationDirty">
												<span v-if="$_.isEmpty(editedValidation.type)" class="lba-messages"
													:data-cy="`${props.parentComponentId}__dataTypeEmpty`"
												>
													{{ $t('validation.required', null, { attribute: $t('dataType') }) }}
												</span>
											</template>
										</s>
										<br>

										<template v-if="hasOtherTypeThanArray">
											<s>
												<small>{{ $t('format') }}</small>
												<input
													:data-cy="`${props.parentComponentId}__format__inputText`"
													type="text"
													v-model="editedValidation.format"
													@change="formValidationDirty = true"
												>
											</s>
											<s>
												<small>{{ $t('pattern') }}</small>
												<input
													:data-cy="`${props.parentComponentId}__pattern__inputText`"
													type="text"
													v-model="editedValidation.pattern"
													@change="formValidationDirty = true"
												>
											</s>
											<br>
										</template>
										<s>
											<lba-checkbox
												:parentComponentId="props.parentComponentId"
												componentId="editedValidationRequired"
												name="editedValidationRequired"
												v-model="editedValidation.require"
												:label="$t('mandatory')"
												@change="formValidationDirty = true"
											/>
										</s>
										<template v-if="isTypeArray">
											<br>
											<strong>{{ $t('item') }}</strong>
											<br>
											<s>
												<small>{{ $t('dataType') }}</small>
												<lba-select
													:parentComponentId="props.parentComponentId"
													componentId="itemsDataType"
													v-model="editedValidation.items.type"
													:opts="itemsDataTypes"
													:class="{
														'lba-invalid': (
															$_.isEmpty(editedValidation.items.type) &&
															formValidationDirty
														)
													}"
													@change="formValidationDirty"
												/>
												<template v-if="formValidationDirty">
													<span
														v-if="$_.isEmpty(editedValidation.items.type)"
														class="lba-messages"
														:data-cy="`${props.parentComponentId}__itemsDataTypeEmpty`"
													>
														{{ $t('validation.required', null, { attribute: $t('dataType') }) }}
													</span>
												</template>
											</s>
											<s>
												<small>{{ $t('format') }}</small>
												<input
													:data-cy="`${props.parentComponentId}__itemsFormat__inputText`"
													type="text"
													v-model="editedValidation.items.format"
													@change="dirty = true; inputDirty = true"
													@input="inputDirty = true"
												>
											</s>
											<s>
												<small>{{ $t('pattern') }}</small>
												<input
													:data-cy="`${props.parentComponentId}__itemsPattern__inputText`"
													type="text"
													v-model="editedValidation.items.pattern"
													@change="formValidationDirty = true"
												>
											</s>
										</template>
									</div>
								</template>
							</lba-dialog-edit>
						</ValidationObserver>
						<lba-dialog-modal
							:parentComponentId="sectionProps.parentComponentId"
							componentId="removeValidation"
							name="removeValidation"
							:title="`${$t('removeValidation', null, { attribute: validationToRemove.name })}?`"
						>
							<template #default="props">
								<div class="popup-footer">
									<button class="buttonRed" type="button" @click="removeValidation"
										:data-cy="`${props.parentComponentId}__remove`"
									>
										{{ $t('remove') }}
									</button>
									<button class="buttonInverse" type="button" v-lba-dialog-close="'removeValidation'"
										:data-cy="`${props.parentComponentId}__closeDialog`"
									>
										{{ $t('cancel') }}
									</button>
								</div>
							</template>
						</lba-dialog-modal>
					</template>
				</lba-section>
			</div>
		</template>
	</content-options>
</template>

<script>
import Model from '../models/ImportExportConfigurations';
import OnScroll from '../mixins/OnScroll';

export default {
	mixins: [
		OnScroll,
	],
	data() {
		return {
			dirty: false,
			inputDirty: false,
			formColumnDirty: false,
			formValidationDirty: false,
			loading: true,
			model: null,
			importExportConfiguration: {
				def_import_export_uid: null,
				name: null,
				title: {},
				columns: [],
				validation_schema: { type: 'object', properties: {} },
				import_finish_pg_callback: null,
				bulk_delete_pg_callback: null,
			},
			tabs: [
				{
					id: 0,
					label: this.$t('columnsSetting'),
				},
				{
					id: 1,
					label: this.$t('validationSetting'),
				},
			],
			defaultTab: 0,

			dataTypes: [
				{ label: 'Null', value: 'null' },
				{ label: 'String', value: 'string' },
				{ label: 'Boolean', value: 'boolean' },
				{ label: 'Number', value: 'number' },
				{ label: 'Integer', value: 'integer' },
				{ label: 'Array', value: 'array' },
				// { label: 'Object', value: 'object' },
			],
			formats: [
				{ label: 'Email', value: 'email' },
			],

			collectionColumns: null,
			collectionValidationSchema: null,

			editedGroups: [],
			columnsAvailableForGroupsForceAll: null,

			editedRequiredGroups: [],
			validationPropertiesForceAll: null,

			editedColumn: {},
			editedValidation: {},

			editedColumnNew: false,
			editedValidationNew: false,

			columnToRemove: {},
			validationToRemove: {},

			columnExampleFormatError: null,
		};
	},
	computed: {
		columnsAvailableForGroups() {
			const columns = $_.get(this.importExportConfiguration, 'columns.columns');
			if (!$_.isEmpty(columns)) {
				if (this.columnsAvailableForGroupsForceAll) {
					return columns
						.filter((column) => column.multiple && column.spreadIntoMultipleColumns)
						.map((column) => ({ value: column.name, label: this.$getLocale(column.title) }));
				}
				const flatGroups = $_.flatten(this.editedGroups);
				return columns
					.filter((column) => !flatGroups.includes(column.name) && column.multiple && column.spreadIntoMultipleColumns)
					.map((column) => ({ value: column.name, label: this.$getLocale(column.title) }));
			}
			return [];
		},
		validationProperties() {
			const properties = $_.get(this.importExportConfiguration, 'validation_schema.properties');
			if (properties) {
				if (this.validationPropertiesForceAll) {
					return Object.keys(properties)
						.map((property) => ({ value: property, label: property }));
				}
				const flatRequiredGroups = $_.flatten(this.editedRequiredGroups);
				return Object.keys(properties)
					.filter((property) => !flatRequiredGroups.includes(property))
					.map((property) => ({ value: property, label: property }));
			}
			return [];
		},
		itemsDataTypes() {
			return this.dataTypes.slice(0, -1);
		},
		isNew() {
			return $_.isEmpty(this.$route.params.id) || this.$route.params.id === '+';
		},
		editedColumnTitle: {
			get() {
				return $getLocale(this.editedColumn.title);
			},
			set(value) {
				if (!this.editedColumn.title || (this.editedColumn.title && this.editedColumn.title.constructor !== Object)) {
					this.$set(this.editedColumn, 'title', {});
				}
				if ($_.isEmpty(value)) {
					this.$set(this.editedColumn.title, this.$i18n.locale, undefined);
				} else {
					this.$set(this.editedColumn.title, this.$i18n.locale, value);
				}
			},
		},
		isTypeArray() {
			if (!$_.isEmpty(this.editedValidation.type)) {
				if (this.editedValidation.type.constructor === Array) {
					return this.editedValidation.type.includes('array');
				}
				return this.editedValidation.type === 'array';
			}
			return false;
		},
		hasOtherTypeThanArray() {
			if (!$_.isEmpty(this.editedValidation.type)) {
				if (this.editedValidation.type.constructor === Array) {
					return !(
						this.editedValidation.type.includes('array') && (
							(this.editedValidation.type.includes('null') && this.editedValidation.type.length === 2) ||
							(this.editedValidation.type.length === 1)
						)
					);
				}
				return this.editedValidation.type !== 'array';
			}
			return true;
		},
	},
	watch: {
		dirty() {
			if (this.dirty) {
				this.$refs.form.validate();
			}
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.model = new Model(this.$http);
		this.collectionColumns = new this.$Collection({
			query: this.fakeQueryColumns,
			params: {
				_order: 'title',
				_order_dir: 'asc',
			},
		});
		this.collectionValidationSchema = new this.$Collection({
			query: this.fakeQueryValidationSchema,
			params: {
				_order: 'name',
				_order_dir: 'asc',
			},
		});

		if (!$_.isEmpty(this.$route.params.id) && this.$route.params.id !== '+') {
			const result = await this.model.get(this.$route.params.id);
			if ($_.isEmpty(result.data.columns)) {
				result.data.columns = { groups: [], columns: [] };
			} else {
				result.data.columns.columns = result.data.columns.columns || [];
				result.data.columns.groups = result.data.columns.groups || [];
			}
			result.data.validation_schema = result.data.validation_schema || { type: 'object', properties: {} };
			this.importExportConfiguration = result.data;
			if ($_.isEmpty(this.importExportConfiguration.validation_schema.requiredGroups)) {
				this.$set(this.importExportConfiguration.validation_schema, 'requiredGroups', []);
			}
		} else {
			if (!this.$permissions.checkPermission('lbadmin.import-export-configurations.read')) {
				this.$emit('view-permission-denied', '');
			}
		}

		this.loading = false;
	},
	methods: {
		reloadGrids() {
			if (this.$refs.gridColumn) this.$refs.gridColumn.reload(this.$refs.gridColumn.id);
			if (this.$refs.gridValidation) this.$refs.gridValidation.reload(this.$refs.gridValidation.id);
		},
		resetEdited() {
			this.editedRequiredGroups = [];
			this.editedColumn = {};
			this.editedValidation = {};
			this.editedColumnNew = false;
			this.editedValidationNew = false;
		},
		fakeOrder(params, data) {
			if (params._order && params._order_dir) {
				let f = -1;
				let s = 1;

				if (params._order_dir === 'asc') {
					f = 1;
					s = -1;
				}

				data.sort((a, b) => {
					if (a[params._order] < b[params._order]) return f;
					if (a[params._order] > b[params._order]) return s;
					return 0;
				});
			}
		},
		fakeQueryColumns(params) {
			const result = { headers: {} };

			result.data = $_.cloneDeep(this.importExportConfiguration.columns.columns);
			result.data.forEach((column, index) => {
				column.id = index;
				if (!$_.isEmpty(this.importExportConfiguration.columns.groups)) {
					const groupIndex = this.importExportConfiguration.columns.groups.findIndex((group) => group.includes(column.name));
					if (groupIndex >= 0) {
						column.group = `${this.$t('group')} ${groupIndex + 1}`;
					}
				}
			});

			if (params.search) {
				result.data = result.data.filter((column) => (
					column.name.includes(params.search) ||
					column.title.includes(params.search)
				));
			}

			if (params.offset == null) {
				result.headers = { 'x-lbadmin-count': result.data.length };
			}

			this.fakeOrder(params, result.data);
			return result;
		},
		fakeQueryValidationSchema(params) {
			const result = { headers: {}, data: [] };
			const schema = this.importExportConfiguration.validation_schema;

			const propertyKeys = Object.keys(schema.properties);
			propertyKeys.forEach((propertyKey, index) => {
				const property = $_.cloneDeep(schema.properties[propertyKey]);
				property.id = index;
				property.name = propertyKey;
				// fully required
				property.require = property.require && schema.required && schema.required.includes(propertyKey);

				if (!$_.isEmpty(schema.requiredGroups)) {
					const groupIndex = schema.requiredGroups.findIndex((group) => group.includes(propertyKey));
					if (groupIndex >= 0) {
						property.requiredGroup = `${this.$t('group')} ${groupIndex + 1}`;
					}
				}
				result.data.push(property);
			});

			if (params.search) {
				result.data = result.data.filter((property) => property.name.includes(params.search));
			}

			if (params.offset == null) {
				result.headers = { 'x-lbadmin-count': result.data.length };
			}

			this.fakeOrder(params, result.data);
			return result;
		},

		getTableName(name) {
			if ($_.isEmpty(name)) return;
			let schema = 'public';
			let table = null;
			const splittedByDot = name.split('.');

			if (splittedByDot.length > 1) {
				schema = splittedByDot[0];
				table = splittedByDot[1].split('_');
			} else {
				table = name.split('_');
			}

			return `${schema.toUpperCase()} - ${table.join(' ')}`;
		},
		getValidationType(type) {
			if (!type) return;
			if (type.constructor === String) return type;
			return type.join(', ');
		},

		validateColumnExample() {
			this.formColumnDirty = true;

			if (this.editedColumn.custom && this.editedColumn.example) {
				try {
					JSON.parse(this.editedColumn.example);
				} catch {
					this.columnExampleFormatError = `${this.$t('validation.format', null, { attribute: this.editedColumn.title })} JSON.`;
				}
			}
		},

		editMultipleColumnsGroups() {
			this.editedGroups = $_.cloneDeep(this.importExportConfiguration.columns.groups) || [];
			this.columnsAvailableForGroupsForceAll = true;
			this.$root.$emit('dialog-open', { name: 'editMultipleColumnsGroups' });
		},
		async onEditGroupsOpen() {
			await this.$nextTick();
			this.columnsAvailableForGroupsForceAll = false;
		},
		addGroup() {
			this.editedGroups.push([]);
		},
		removeGroup(index) {
			this.editedGroups.splice(index, 1);
		},
		async saveGroups() {
			this.importExportConfiguration.columns.groups = $_.cloneDeep(this.editedGroups);
			await this.saveEntry();
			this.$root.$emit('dialog-close', 'editMultipleColumnsGroups');
		},

		editRequiredGroups() {
			this.editedRequiredGroups = $_.cloneDeep($_.get(this.importExportConfiguration, 'validation_schema.requiredGroups')) || [];
			this.validationPropertiesForceAll = true;
			this.$root.$emit('dialog-open', { name: 'editRequiredGroups' });
		},
		async onEditRequiredGroupsOpen() {
			await this.$nextTick();
			this.validationPropertiesForceAll = false;
		},
		addRequiredGroup() {
			this.editedRequiredGroups.push([]);
		},
		removeRequiredGroup(index) {
			this.editedRequiredGroups.splice(index, 1);
		},
		async saveRequiredGroups() {
			this.importExportConfiguration.validation_schema.requiredGroups = $_.cloneDeep(this.editedRequiredGroups);
			await this.saveValidations();
			this.$root.$emit('dialog-close', 'editRequiredGroups');
		},

		async saveEntry() {
			this.dirty = true;
			this.inputDirty = true;
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			this.dirty = false;
			this.inputDirty = false;

			if (this.isNew) {
				const { data } = await this.model.create(this.importExportConfiguration);
				if (data && data.def_import_export_uid) {
					this.$notify.success(this.$t('form.saved'));
					this.$routerWrap.push({
						name: 'import-export-configuration',
						params: { id: data.def_import_export_uid },
					});
					this.$root.$emit('grid.insert-row', {
						gridName: 'lbadmin.def_import_export',
					});
				}
			} else {
				const configuration = (await this.model.save(
					this.$route.params.id,
					this.importExportConfiguration
				)).data;
				this.$set(this, 'importExportConfiguration', configuration);
				this.resetEdited();
				this.$notify.success(this.$t('form.saved'));
				this.reloadGrids();
			}
		},
		openRemoveEntry() {
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		async removeEntry() {
			if (this.importExportConfiguration.def_import_export_uid == null) return;

			try {
				await this.model.remove(this.importExportConfiguration.def_import_export_uid);
				this.$root.$emit('dialog-close', 'removeEntry');
				this.$notify.success(this.$t('form.removed'));
				this.$root.$emit('toggle-save-changes', { state: false });
				this.$root.$emit('close-current-tab', true);
				this.$root.$emit('grid.delete-row', {
					gridName: 'lbadmin.def_import_export',
					rowId: this.importExportConfiguration.def_import_export_uid,
				});
				this.$routerWrap.push({ name: 'import-export-configurations' });
			} catch (error) {
				this.$notify.warn(this.$t('form.removeFailed'));
			}
		},

		prepareDateFormat() {
			if (this.editedColumn.isDate && $_.isEmpty(this.editedColumn.dateFormat)) {
				this.editedColumn.dateFormat = this.editedColumn.date_format || 'DD.MM.YYYY HH:mm:ss';
			} else if (!this.editedColumn.isDate) {
				this.editedColumn.dateFormat = null;
			}
		},

		newColumn() {
			this.editedColumn = {
				name: null,
				title: null,
				example: null,
				custom: false,
				allowImport: true,
				allowExport: true,
				isDate: false,
				dateFormat: null,
			};
			this.editColumnNew = true;
			this.columnExampleFormatError = null;
			this.formColumnDirty = false;
			this.$root.$emit('dialog-open', { name: 'editColumn' });
		},
		editColumn(row) {
			this.editedColumn = $_.cloneDeep(row);
			this.editColumnNew = false;
			this.columnExampleFormatError = null;

			if (this.editedColumn.custom && this.editedColumn.example) {
				this.editedColumn.example = JSON.stringify(this.editedColumn.example);
			}

			if (this.editedColumn.type === 'date') {
				this.$set(this.editedColumn, 'isDate', true);
				if ($_.isEmpty(this.editedColumn.date_format)) {
					this.$set(this.editedColumn, 'dateFormat', 'DD.MM.YYYY HH:mm:ss');
				} else {
					this.$set(this.editedColumn, 'dateFormat', this.editedColumn.date_format);
				}
			} else {
				this.$set(this.editedColumn, 'isDate', false);
				this.$set(this.editedColumn, 'dateFormat', null);
			}

			this.formColumnDirty = false;
			this.$root.$emit('dialog-open', { name: 'editColumn' });
		},
		async saveColumn() {
			this.formColumnDirty = true;
			const valid = await this.$refs.formColumn.validate();

			if (!valid || this.columnExampleFormatError) {
				this.$root.$emit('content.validationFailed');
				return false;
			}

			this.formColumnDirty = false;
			const column = $_.cloneDeep(this.editedColumn);
			if (column.isDate) {
				column.type = 'date';
				column.date_format = column.dateFormat;
			} else {
				delete column.type;
				delete column.date_format;
			}
			delete column.isDate;
			delete column.dateFormat;
			delete column.id;

			if (column.custom && column.example) {
				column.example = JSON.parse(column.example);
			}

			if (this.editColumnNew) {
				this.importExportConfiguration.columns.columns.push(column);
			} else {
				this.importExportConfiguration.columns.columns.splice(this.editedColumn.id, 1, column);
			}
			this.saveColumns();
		},
		openRemoveColumn(row) {
			this.columnToRemove = row;
			this.$root.$emit('dialog-open', { name: 'removeColumn' });
		},
		async saveColumns() {
			const columns = this.importExportConfiguration.columns;
			if (!$_.isEmpty(columns) && !$_.isEmpty(columns.columns)) {
				$_.forEach(columns.columns, (column) => {
					delete column.totalIndex; // added by Collection
				});
			}
			if (this.isNew) {
				this.saveEntry();
			} else {
				const configuration = (await this.model.save(
					this.$route.params.id,
					{ columns: this.importExportConfiguration.columns }
				)).data;
				this.$set(this, 'importExportConfiguration', configuration);
				this.resetEdited();
				this.$notify.success(this.$t('form.saved'));
				this.reloadGrids();
			}
		},
		async removeColumn() {
			this.importExportConfiguration.columns.columns.splice(this.columnToRemove.id, 1);
			await this.saveColumns();
			this.$root.$emit('dialog-close', 'removeColumn');
			this.columnToRemove = {};
		},

		prepareItems() {
			if (this.isTypeArray && $_.isEmpty(this.editedValidation.items)) {
				this.editedValidation.items = { type: ['null'], format: null, pattern: null };
			} else if (!this.isTypeArray) {
				delete this.editedValidation.items;
			}
		},
		newValidation() {
			this.editedValidation = {
				name: null,
				type: ['null'],
				format: null,
				pattern: null,
				require: false,
			};
			this.editValidationNew = true;
			this.formValidationDirty = false;
			this.$root.$emit('dialog-open', { name: 'editValidation' });
		},
		editValidation(row) {
			this.editedValidation = $_.cloneDeep(row);
			delete this.editedValidation.requiredGroup;
			this.editValidationNew = false;

			if (this.editedValidation.type) {
				if (this.editedValidation.type.constructor === String) {
					this.editedValidation.type = [this.editedValidation.type];
				}
			} else {
				this.editedValidation.type = [];
			}

			this.prepareItems();
			this.formValidationDirty = false;
			this.$root.$emit('dialog-open', { name: 'editValidation' });
		},
		async saveValidation() {
			this.formValidationDirty = true;

			const valid = await this.$refs.formValidation.validate();

			if (!valid || this.editedValidation.type.length === 0) {
				this.$root.$emit('content.validationFailed');
				return false;
			}

			this.formValidationDirty = false;

			/* if (this.editedValidation.type) {
				const types = this.editedValidation.type.split(',');
				this.editedValidation.type = [];
				types.forEach((type) => {
					if (!$_.isEmpty(type)) {
						this.editedValidation.type.push(type.trim());
					}
				});
			} */

			const schema = this.importExportConfiguration.validation_schema;
			const property = $_.cloneDeep(this.editedValidation);
			delete property.id;
			delete property.name;

			if (this.isTypeArray) {
				if ($_.isEmpty(property.items.format)) {
					delete property.items.format;
				}
				if ($_.isEmpty(property.items.pattern)) {
					delete property.items.pattern;
				}
				delete property.format;
				delete property.pattern;
			}
			if (!this.isTypeArray) {
				if ($_.isEmpty(property.format)) {
					delete property.format;
				}
				if ($_.isEmpty(property.pattern)) {
					delete property.pattern;
				}
				delete property.items;
			}

			if (this.editValidationNew) {
				schema.properties[this.editedValidation.name] = property;
			} else {
				const propertyKeys = Object.keys(schema.properties);
				const originalName = propertyKeys[this.editedValidation.id];

				if (originalName !== this.editedValidation.name) {
					if (schema.required) {
						const index = schema.required.find((name) => name === originalName);
						if (index >= 0) schema.required.splice(index, 1);
					}
					delete schema.properties[originalName];
				}
				schema.properties[this.editedValidation.name] = property;
			}

			if (property.require && (!schema.required || schema.required.indexOf(this.editedValidation.name) < 0)) {
				if (schema.required) {
					schema.required.push(this.editedValidation.name);
				} else {
					schema.required = [this.editedValidation.name];
				}
			} else if (!property.require && schema.required) {
				const index = schema.required.indexOf(this.editedValidation.name);

				if (index >= 0) {
					schema.required.splice(index, 1);
				}
			}

			this.saveValidations();
		},
		openRemoveValidation(row) {
			this.validationToRemove = row;
			this.$root.$emit('dialog-open', { name: 'removeValidation' });
		},
		async saveValidations() {
			const schema = this.importExportConfiguration.validation_schema;
			if (!$_.isEmpty(schema) && !$_.isEmpty(schema.properties)) {
				$_.forEach(schema.properties, (property) => {
					delete property.totalIndex; // added by Collection
				});
			}
			if (this.isNew) {
				this.saveEntry();
			} else {
				const configuration = (await this.model.save(
					this.$route.params.id,
					{ validation_schema: this.importExportConfiguration.validation_schema }
				)).data;
				this.$set(this, 'importExportConfiguration', configuration);
				this.resetEdited();
				this.$notify.success(this.$t('form.saved'));
				this.reloadGrids();
			}
		},
		async removeValidation() {
			const propertyKeys = Object.keys(this.importExportConfiguration.validation_schema.properties);
			delete this.importExportConfiguration.validation_schema.properties[propertyKeys[this.validationToRemove.id]];
			await this.saveValidations();
			this.$root.$emit('dialog-close', 'removeValidation');
			this.validationToRemove = {};
		},
	},
};
</script>
