<template>
	<div id="content" v-if="isPageLoaded">
		<div class="page-header">
			<h1>
				{{ $t('releaseNotes') }}
			</h1>
			<div class="page-controls">
				<span @click="tabReload" data-cy="changelog__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			ref="pageContent"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<template v-if="$_.isEmpty(releaseNotes)">
				{{ $t('noReleaseNotesAvailable') }}
			</template>
			<template v-else>
				<nav class="grid-paginaton">
					<ul>
						<li
							class="p-item prev"
							:class="{ 'disabled': currentPage === 1 || isLoadingData }" @click="setPrevPage"
						>
							<i class="icon-collapse-all"></i>
						</li>
						<li
							class="p-item next"
							:class="{ 'disabled': currentPage === pageCount || isLoadingData }"
							@click="setNextPage"
						>
							<i class="icon-collapse-all"></i>
						</li>
						<li
							v-for="(page, index) in pages"
							:key="`page-${index}`"
							:class="{
								'dots': page === '...',
								'p-item': page !== '...',
								'active': currentPage === page,
								'disabled': isLoadingData,
							}"
							@click="setCurrentPage(page)"
						>{{ page }}</li>
					</ul>
				</nav>
				<div class="markdown-body" v-html="releaseNotes"></div>
				<nav class="grid-paginaton">
					<ul>
						<li
							class="p-item prev"
							:class="{ 'disabled': currentPage === 1 || isLoadingData }" @click="setPrevPage"
						>
							<i class="icon-collapse-all"></i>
						</li>
						<li
							class="p-item next"
							:class="{ 'disabled': currentPage === pageCount || isLoadingData }"
							@click="setNextPage"
						>
							<i class="icon-collapse-all"></i>
						</li>
						<li
							v-for="(page, index) in pages"
							:key="`page-${index}`"
							:class="{
								'dots': page === '...',
								'p-item': page !== '...',
								'active': currentPage === page,
								'disabled': isLoadingData,
							}"
							@click="setCurrentPage(page)"
						>{{ page }}</li>
					</ul>
				</nav>
			</template>
		</div>
	</div>
</template>

<script>
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import ChangeLogModel from '../models/Changelog';

export default {
	name: 'Changelog',
	mixins: [
		Page,
		OnScroll,
	],
	data() {
		return {
			changelogModel: null,
			offset: 0,
			releaseNotes: [],
			isPageLoaded: false,

			isLoadingData: true,
			currentPage: 1,
			pageCount: 0,
			pages: [],
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}

		this.changelogModel = new ChangeLogModel(this.$http);

		try {
			await this.fetch();
			this.$root.$listen('user.updated', this.fetch, this);
			this.isPageLoaded = true;
		} catch (error) {
			console.error('[lbadmin.Changelog](created) error:', error);
		}
	},
	methods: {
		async fetch() {
			this.isLoadingData = true;
			const { data } = await this.$axiosFetch(
				this, this.changelogModel.query({ page: this.currentPage - 1, locale: this.$user.lang })
			);
			if ($_.isEmpty(data)) {
				this.refreshPages();
				throw new Error('Received empty data');
			}
			this.releaseNotes = data.releaseNotes || [];
			this.pageCount = data.pageCount || 0;
			this.refreshPages();
			this.isLoadingData = false;
			await this.$nextTick();
			if (this.$refs && this.$refs.pageContent) {
				this.$refs.pageContent.scrollTo(0, 0);
			}
		},
		setPrevPage() {
			if (this.currentPage === 1 || this.isLoadingData) return;
			console.debug('[lbadmin.Changelog] (setPrevPage)');
			this.currentPage--;
			this.fetch();
		},
		setNextPage() {
			if (this.currentPage === this.pageCount || this.isLoadingData) return;
			console.debug('[lbadmin.Changelog] (setNextPage)');
			this.currentPage++;
			this.fetch();
		},
		setCurrentPage(page) {
			if (this.isLoadingData || this.currentPage === page || isNaN(page)) return;
			console.debug('[lbadmin.Changelog] (setCurrentPage) - ', page);
			this.currentPage = page;
			this.fetch();
		},
		async refreshPages() {
			if (this.pageCount < 7) {
				this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
			} else {
				this.pages = [1];
				if (this.currentPage - 2 > 2) {
					this.pages.push('...');
				}
				for (let i = (this.currentPage - 2); i <= (this.currentPage + 2); i++) {
					if (i > 1 && i < this.pageCount) {
						this.pages.push(i);
					}
				}

				if (this.currentPage + 2 < this.pageCount - 1) {
					this.pages.push('...');
				}
				if (this.pageCount > 1) {
					this.pages.push(this.pageCount);
				}
			}
		},
	},
};
</script>
