<template>
	<span
		v-if="!isNew"
		@click="onClick"
		:data-cy="`${componentId}__reload`"
		v-tooltip.left="{
			content: reloadingProposal ? $t('dataChangedPleaseRefreshPage') : $t('refreshData'),
			show: reloadingProposal,
			delay: { hide: 0 },
			container: false,
			html: true,
		}"
	>
		<i class="icon-reset"></i>
		<span
			v-if="reloadingProposal"
			class="badge"
			:style="reloadingProposalStyle && reloadingProposalStyle[componentId]"
			:data-cy="`${componentId}__reloadingProposal`"
		>
			<i class="icon-exclamation"></i>
		</span>
	</span>
</template>

<script>
export default {
	props: {
		isNew: {
			type: Boolean,
			default: false,
		},
		reloadingProposal: {
			type: Boolean,
		},
		componentId: {
			type: String,
		},
	},
	data() {
		return {
			reloadingProposalStyle: {
				'crm-taskPanel': 'margin-left: -7px; margin-top: -5px;',
			},
		};
	},
	methods: {
		onClick(event) {
			this.$emit('click', event);
		},
	},
};
</script>
