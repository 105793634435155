<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:class="{
			[classes]: true,
			'has-units': units.length > 0,
		}"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<fake-provider
			:ref="inputRef"
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<input
				:data-cy="`${currentComponentId}__inputNumber`"
				:type="inputType"
				v-model="data[key]"
				v-bind="bindOptions(numberOptions)"
				class="showInvalidTooltip"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				:disabled="readOnly"
				@input="onEvent('input', parseString)"
				@change="onEvent('change', parseNumber)"
				@blur="$emit('blur')"
			>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
			<span v-if="units.length > 0" class="num-units">{{ units }}</span>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputNumber',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			numberOptions: {},
			inputUid: '',
			units: '',
		};
	},
	computed: {
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && this.data[this.key] == null && this.showRecommended) {
				return true;
			}
			return false;
		},
		inputType() {
			if (this.rules?.numeric) {
				return 'text';
			} else {
				return 'number';
			}
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		this.prepareNumberOptions();
	},
	methods: {
		parseString() {
			if ($_.isEmpty($_.trim(this.data[this.key]))) {
				this.data[this.key] = null;
			}
		},
		parseNumber() {
			this.parseString();

			const number = parseFloat(this.data[this.key]);
			if (isNaN(number)) {
				this.$emit('dirty');
				return;
			}
			this.data[this.key] = number;
		},
		prepareNumberOptions() {
			let tooltipParts = [];

			if (this.options) {
				if (this.options.ui_type === 'number') {
					if ('min' in this.options) {
						this.numberOptions.min = this.options.min;
						this.rules.min_value = this.options.min;
						tooltipParts = [this.$t('inputNumberMin', this.numberOptions)];
					}
					if ('max' in this.options) {
						this.numberOptions.max = this.options.max;
						this.rules.max_value = this.options.max;
						tooltipParts = [this.$t('inputNumberMax', this.numberOptions)];
					}
					if ('min' in this.options && 'max' in this.options) {
						tooltipParts = [this.$t('inputNumberMinMax', this.numberOptions)];
					}

					if ('indentificator' in this.options) {
						this.inputUid = ` ${this.options.indentificator} `;
					}

					if ('units' in this.options) {
						this.units = this.options.units;
					}

					if ('after_title_hint' in this.options) {
						tooltipParts.push(this.options.after_title_hint);
					}
				}

				if (!this.options.title_hint && tooltipParts.length > 0 && !this.options.disable_title_hint) {
					this.options.title_hint = tooltipParts.join('<br>');
				}
			}
		},
	},
};
</script>
