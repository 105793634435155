<template>
	<lba-section
		:parentComponentId="currentComponentId"
		:title="$t('permissions')"
		:slotType="'form'"
		expanded
		:key="objKey"
	>
		<template v-slot:controls v-if="!readOnly && loaded">
			<span>
				<i
					class="icon-plus-circle"
					:data-cy="`${currentComponentId}__addGroup`"
					@click.stop="addGroup()"
					v-tooltip="$t('settings.addGroup')"
				>
				</i>
			</span>
		</template>

		<template v-if="objectPermissions.length > 0">
			<div
				class="formRow"
				v-for="(perm, index) in objectPermissions"
				:key="index"
			>
				<s class="size-2">
					<small>{{ $t('settings.groupOrUser') }}</small>
					<ValidationProvider
						ref="provider"
						:name="$t('settings.groupOrUser')"
						:rules="{ required: true }"
						:data-cy="`${currentComponentId}__slot`"
						v-slot="{ invalid, errors }"
					>
						<lba-select
							:data-cy="`${currentComponentId}__object${index}__group__select`"
							:name="`group-${perm.role_uid}`"
							@change="change"
							v-model="perm.role_uid"
							:class="{
								'lba-invalid': dirty && invalid,
							}"
							:opts="[
								...(disableAll ? [] : [{ value: null, label: `-- ${$t('all')} --` }]),
								...groupRoles.map((group) => ({
									value: group.role_uid,
									label: `${($t('settings.group') || '').toLowerCase()} - ${group.role_name}`,
								})),
								...users.map((user) => ({
									value: user.role_uid,
									label: `${($t('settings.user.user') || '').toLowerCase()} - ${$user.getName(user)}`,
								})),
							]"
							:multiple="false"
						></lba-select>
						<template v-if="dirty">
							<span v-for="(err, index) in errors" :key="index" class="lba-messages">
								{{ err }}
							</span>
						</template>
					</ValidationProvider>
				</s>
				<s class="size-2">
					<small>{{ $t('settings.profile') }}</small>
					<ValidationProvider
						ref="provider"
						:name="$t('settings.profile')"
						:rules="{ required: true }"
						:data-cy="`${currentComponentId}__slot`"
						v-slot="{ invalid, errors }"
					>
						<lba-select
							:data-cy="`${currentComponentId}__object${index}__profile__select`"
							:name="`profile-${perm.profile_uid}`"
							@change="change"
							v-model="perm.profile_uid"
							:class="{
								'lba-invalid': dirty && invalid,
							}"
							:opts="[
								...(disableAll ? [] : [{ value: null, label: `-- ${$t('all')} --` }]),
								...profiles.map((profile) => ({
									value: profile.profile_uid,
									label: profile.profile_name,
								})),
							]"
							:multiple="false"
						></lba-select>
						<template v-if="dirty">
							<span v-for="(err, index) in errors" :key="index" class="lba-messages">
								{{ err }}
							</span>
						</template>
					</ValidationProvider>
				</s>
				<div class="form-controls">
					<i
						class="icon-delete"
						:data-cy="`${currentComponentId}__object${index}__remove`"
						v-on:click="removePermission(index)"
						v-tooltip="$t('remove')"
					>
					</i>
				</div>
			</div>
		</template>
		<template v-else>
			{{ $t('noEntries') }}
		</template>
	</lba-section>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import PermissionsModel from '../models/Permissions';
import UserModel from '../models/User';

export default {
	mixins: [ComponentIdentifier],
	model: 'permissions',
	props: {
		objectUid: {
			type: String,
		},
		data: Object,
		objKey: [String, Number],
		dirty: Boolean,
		readOnly: Boolean,
		isNew: {
			type: Boolean,
			default: false,
		},
		disableAll: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			permissionsModel: null,
			moduleList: [],
			objectPermissions: [],
			groupRoles: [],
			users: [],
			profiles: [],
			addedGroup: null,
			loaded: false,
		};
	},
	async created() {
		this.permissionsModel = new PermissionsModel(this.$http);
		this.userModel = new UserModel(this.$http);
		await this.fetch();
	},
	methods: {
		async fetch() {
			const modules = await this.permissionsModel.getModules();
			this.moduleList = modules.data;

			const groupRoles = await this.permissionsModel.getGroupRoles();
			this.groupRoles = groupRoles.data;

			const profiles = await this.permissionsModel.getProfiles();
			this.profiles = profiles.data;

			const users = await this.userModel.query();
			this.users = users.data;

			if (!this.isNew) {
				if ($_.isEmpty(this.objectUid)) {
					console.error('[LbaObjectSetPermissions] (fetch) Missing objectUid');
					return;
				} else {
					const objectPermissions = await this.permissionsModel.getObjectSetPermissions(this.objectUid);
					this.objectPermissions = objectPermissions.data;
				}
			}
			this.loaded = true;
		},
		async change(force) {
			if (this.data && (force || !$_.isEqual(this.objectPermissions, this.data.objectPermissions))) {
				this.data.objectPermissions = $_.filter(this.objectPermissions, (perm) => perm.role_uid || perm.profile_uid);
			}
			this.$emit('change');

			await this.$nextTick();
			$_.forEach(this.$refs.provider, (provider) => {
				if (!$_.isEmpty(provider)) {
					provider.validate();
				}
			});
		},
		addGroup() {
			this.objectPermissions.push({
				acl_object_uid: this.objectUid,
				role_uid: null,
				profile_uid: null,
			});
			this.change(true);
		},
		removePermission(index) {
			this.objectPermissions.splice(index, 1);
			this.change(true);
		},
	},
};
</script>
