<template>
	<lba-dialog-modal :name="name" :parentComponentId="currentComponentId" @open="dialogOpened = true" @close="dialogOpened = false">
		<div class="popup-header">
			<h2>{{ title }}</h2>
		</div>
		<div class="popup-body">
		<p v-if="dialogMessage">{{ dialogMessage }}</p>
		</div>
		<div class="popup-footer">
			<button type="button" class="buttonBig buttonRed" @click="onConfirm" :data-cy="`${currentComponentId}__remove`">
				<!-- <i class="icon16 icon-delete"></i>&nbsp; -->
				<span>{{ $t('remove') }}</span>
			</button>
			<button
				type="button"
				class="buttonBig buttonInverse"
				@click="$emit('close')"
				v-lba-dialog-close="name"
				:data-cy="`${currentComponentId}__closeDialog`"
			>
				{{ $t('cancel') }}
			</button>
		</div>
	</lba-dialog-modal>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'LbaConfirmRemoveDialog',
	mixins: [ComponentIdentifier],
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: false,
		},
		onConfirm: {
			type: Function,
			required: true,
			default: () => {},
		},
	},
	data() {
		return {
			gridCheckedMessage: '',
			dialogOpened: false,
		};
	},
	created() {
		this.$root.$listen('keydown-enter', this.onEnter, this);
	},
	mounted() {
		this.$root.$listen('dialog-open', this.onOpen, this);
	},
	methods: {
		onEnter() {
			if (this.dialogOpened) {
				this.onConfirm();
			}
		},
		onOpen(arg) {
			if (arg.name !== this.name) {
				return;
			}
			if (arg.gridChecked) {
				this.gridCheckedMessage = '';
				if (arg.gridChecked.checkedAll) {
					if (arg.gridChecked.unchecked && Object.keys(arg.gridChecked.unchecked).length > 0) {
						this.gridCheckedMessage = this.$t(
							'confirmRemoveDialog.messageCheckedAllUnchecked', {
								uncheckedCount: Object.keys(arg.gridChecked.unchecked).length,
							}
						);
					} else {
						this.gridCheckedMessage = this.$t('confirmRemoveDialog.messageCheckedAll');
					}
				} else if (arg.gridChecked.filterCheckedAll) {
					this.gridCheckedMessage = this.$t('confirmRemoveDialog.messageFilteredAll');
				} else if (arg.gridChecked.checked && Object.keys(arg.gridChecked.checked).length > 0) {
					this.gridCheckedMessage = this.$t('confirmRemoveDialog.messageChecked', {
						checkedCount: Object.keys(arg.gridChecked.checked).length,
					});
				}
			}
		},
	},
	computed: {
		dialogMessage() {
			return this.message || this.gridCheckedMessage;
		},
	},
};
</script>
