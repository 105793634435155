async function fetchCached(vm, request, checkStatusRequest, waitTime = 500, increasingWaitTime = true, responseCallback = null) {
	let cancelled = false;
	let first = true;
	let usedRequest = request;
	let requestCounter = 0;

	if (vm) {
		vm.$on('hook:beforeDestroy', () => { cancelled = true; });
	}

	/* eslint-disable-next-line no-unmodified-loop-condition */
	while (!cancelled) {
		const response = await usedRequest();
		requestCounter += 1;

		if (responseCallback) {
			try {
				responseCallback(response);
			} catch (error) {
				console.error(`[fetchCahed] response callback failed:`, error);
			}
		}

		if (response.status === 202) {
			if (first) {
				first = false;
				if (checkStatusRequest) {
					usedRequest = checkStatusRequest;
				}

				if (!vm) {
					console.warn('[fetchCached] fetching from server response cache without vm (no beforeDestroy hook)');
				}
			}
			await $sleep(waitTime);

			if (increasingWaitTime && requestCounter === 4) { // original waitTime = 500 -> total wait time = 2s
				waitTime += waitTime / 2; // original waitTime = 500 -> waitTime = 750

			} else if (increasingWaitTime && requestCounter === 8) { // original waitTime = 500 -> total wait time = 5s
				waitTime += waitTime / 3; // original waitTime = 500 -> waitTime = 1s

			}
			continue;
		}
		return response;
	}
}

export default {
	install(Vue) {
		Vue.prototype.$fetchCached = fetchCached;
		window.$fetchCached = fetchCached;
	},
};
