import Vuelidate from 'vuelidate';
import VueVuelidateJsonSchema from 'vue-vuelidate-jsonschema';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';

export default {
	install(Vue) {
		Vue.use(VueVuelidateJsonSchema);
		Vue.use(Vuelidate);
		Vue.use(VuelidateErrorExtractor, {
			template: templates.singleErrorExtractor.foundation6,
			i18n: 'validation',
		});
	},
};
