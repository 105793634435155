import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/cs';

export default {
	install(Vue) {
		Vue.use(CKEditor);
		Vue.prototype.$ClassicEditor = ClassicEditor;
	},
};
