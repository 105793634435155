export default class {
	constructor(vm) {
		this.vm = vm;
	}

	files(moduleName) {
		return this.vm.$http.get(`${moduleName}/log/files`);
	}

	file(moduleName, filename, page, params) {
		const searchParams = new URLSearchParams(params);
		return this.vm.$http.get(`${moduleName}/log/files/${filename}/${page}?${searchParams}`);
	}
}
