<template>
	<div id="content-options" data-cy="content-options">
		<template v-if="onlyRouterView">
			<router-view
				:key="routeKey"
				:params="viewParams"
				:buttons="buttons"
				:match="match"
			></router-view>
			<options-panel
				v-if="!isActive()"
				:key="`empty-${routeKey}`"
				:params="viewParams"
				:buttons="buttons"
				:match="match"
			/>
		</template>
		<div
			v-else
			id="options"
			ref="options"
			:style="styleWidth"
			tabindex="0"
			class="optionsPin collapsed"
			:class="{
				active: isActive(),
				pinned,
				smsgwOptions: true,
			}"
			data-cy="content-options__options"
			v-lba-right-panel-resizer="{
				panel_width: width,
				panel_pinned: pinned
			}"
		>
			<span id="splitter" class="userSelectNone" data-cy="content-options__options__splitter">
				<i id="splitterIcon" class="icon24 icon-splitter"></i>
			</span>
			<div class="options-panel-controls">
				<controls
					parentComponentId="content-options__options"
					btnPin
					:btnCancel="isActive()"
					:btnPrev="(isActive() &&!getAnyError() && buttons.btnPrev) || null"
					:btnNext="(isActive() &&!getAnyError() && buttons.btnNext) || null"
					:btnShowInCard="isActive() &&!getAnyError() && buttons.btnShowInCard"
					:btnFavourite="(isActive() &&!getAnyError()) || null"
				>
					<template v-slot:controlsBefore>
						<slot v-if="!getAnyError()" name="controlsTitle"></slot>
					</template>
				</controls>
			</div>
			<div v-if="!getAnyError()" class="options-controls">
				<slot name="controls" parentComponentId="content-options__options__slotControls"></slot>
			</div>
			<div class="optionsContent">
				<router-view
					v-show="!getAnyError()"
					ref="view"
					:key="routeKey"
					:params="viewParams"
					@view-not-found="onError($event, 'notFound')"
					@view-permission-denied="onError($event, 'permissionDenied')"
					@view-server-error="onError($event, 'serverError')"
					@view-record-deleted="onError($event, 'recordDeleted')"
					@view-missing-parameter="onError($event, 'missingParameter')"
					@view-wrong-parameter="onError($event, 'wrongParameter')"
				></router-view>
				<div v-if="getAnyError()" class="error-wrap" data-cy="content-options__options__anyError">
					<h1 v-if="getErrorType() === 'notFound'" data-cy="content-options__options__viewNotFound">
						{{ $t('viewNotFound') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="content-options__options__viewPermissionDenied">
						{{ $t('viewPermissionDenied') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'serverError'" data-cy="content-options__options__viewServerError">
						{{ $t('viewServerError') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="content-options__options__viewRecordDeleted">
						{{ getError() || $t('viewRecordDeleted') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="content-options__options__viewMissingParameter">
						{{ $t('viewMissingParameter', { params: getError() }) }}
					</h1>
					<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="content-options__options__viewWrongParameter">
						{{ $t('viewWrongParameter', { params: getError() }) }}
					</h1>

					<button
						v-if="showReloadButton()"
						type="button"
						@click="reloadPath"
					>
						{{ $t('reload') }}
					</button>
				</div>
			</div>
		</div>

		<!--
			#fakeOptions - vytvari odsazeni #contentu zprava
			skutecne #options je pozicovano absolutne
		-->
		<div id="fakeOptions" :style="styleWidth"></div>
		<div id="content" data-cy="content-options__content">
			<slot name="content" parentComponentId="content-options__slotContent"></slot>
		</div>
	</div>
</template>

<script>
import RouteKey from '../mixins/RouteKey';
import TabErrors from '../mixins/TabErrors';

export default {
	mixins: [RouteKey, TabErrors],
	props: {
		onlyRouterView: Boolean,
		match: {
			type: Number,
			required: false,
			default: 1,
		},
		buttons: {
			type: Object,
			default: () => {
				return {};
			},
		},
		viewParams: Object,
	},
	data() {
		return {
			pinned: false,
			width: null,
			settings: null,
			componentName: 'ContentOptions',
		};
	},
	computed: {
		styleWidth() {
			return `width: ${this.width}px`;
		},
	},
	activated() {
		if (!this.onlyRouterView) {
			this.setRightPanel();
		}
	},
	created() {
		if (!this.onlyRouterView) {
			this.$root.$listen('content.pin', this.contentPin, this);
			this.$root.$listen('content.unpin', this.contentUnpin, this);
			this.$root.$listen('content.close-detail', this.closeDetail, this);
			this.$root.$listen('right-panel-resized', this.updateSettings, this);
		}
		this.$root.$listen('right-panel-width', this.updateWidth, this);
	},
	mounted() {
		if (!this.onlyRouterView) {
			this.setRightPanel();
		}
	},
	methods: {
		isActive() {
			return this.$route.matched.length > this.match;
		},
		closeDetail() {
			if (this.$refs.view && !this.pinned) {
				this.$routerWrap.push(this.$route.matched[this.$route.matched.length - 2]);
			}
		},
		contentPin() {
			this.pinned = true;
			this.updateSettings();
		},
		contentUnpin() {
			this.pinned = false;
			this.updateSettings();
		},
		routeKeyChanged() {
			this.$root.$emit('content-options-route-key-change', this.routeKey);
		},
		setRightPanel() {
			this.pinned = this.$pageSettings.get(this.componentName, 'pinned', true, false, Boolean);
			this.width = this.$pageSettings.get(this.componentName, 'width', true, null, Number);
			if (this.$refs && !this.$refs.options) {
				const minWidth = parseInt(window
					.getComputedStyle(this.$refs.options)
					.getPropertyValue('min-width')
				);
				this.width = Math.max(this.width, minWidth);
			}
		},
		updateWidth(width) {
			this.width = width;
		},
		updateSettings(width) {
			if (width) {
				this.width = width;
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'width',
					data: this.width,
				});
			} else {
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'pinned',
					data: this.pinned,
				});
			}
		},
	},
};
</script>
