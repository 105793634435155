export default {
	data() {
		return {
			allowedShortcutKeys: ['enter', 'backspace', 'escape', 'delete'],
			overwrittenShortcuts: [
				'ctrl-s',
			],
		};
	},
	created() {
		window.addEventListener('keydown', this.onKeyDownHandleShortcut);
	},
	methods: {
		onKeyDownHandleShortcut(event) {
			if ($_.isEmpty(event.key)) {
				return;
			}
			const altKey = event.altKey;
			const ctrlKey = event.ctrlKey;
			const shiftKey = event.shiftKey;
			const key = event.key.toLowerCase();

			if (!altKey && !ctrlKey && !this.allowedShortcutKeys.includes(key)) {
				return;
			}
			if (key === 'control' || key === 'alt') {
				return;
			}

			const nameParts = [];
			if (ctrlKey) {
				nameParts.push('ctrl');
			}
			if (altKey) {
				nameParts.push('alt');
			}
			if (shiftKey) {
				nameParts.push('shift');
			}
			nameParts.push(key);
			const name = nameParts.join('-');

			if (this.overwrittenShortcuts.includes(name)) {
				event.preventDefault();
			}
			const shortcut = `keydown-${name}`;
			console.debug(shortcut);
			this.$emit(shortcut, {});
			this.onShortcut(shortcut);
		},
	},
};
