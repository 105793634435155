var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noInput)?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId}},[(_vm.isCallEnabled)?[(_vm.isActionCallAllowed)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('crm.callNow')),expression:"$t('crm.callNow')"}],staticClass:"icon-phone hover-pointer",on:{"click":function($event){return _vm.onAction('call')}}}):_c('i',{staticClass:"icon-phone silver"})]:_vm._e(),(!(_vm.options && _vm.options.hideNumber))?[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")]:_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.options && _vm.options.label),expression:"options && options.label"}],staticClass:"silver"},[_vm._v("*** *** ***")])],2):_c('s',{class:_vm.classes,style:(_vm.style),attrs:{"data-cy":_vm.currentComponentId}},[_c('small',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired || _vm.isRequiredGroup)?_c('span',{staticClass:"required-sign"}):_vm._e(),(_vm.titleTooltip)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.titleTooltip),expression:"titleTooltip"}],staticClass:"icon-tooltip"}):_vm._e(),_vm._t("actions",null,{"customValue":this,"parentComponentId":_vm.currentComponentId})],2),_c('fake-provider',{ref:_vm.inputRef,attrs:{"name":_vm.$getLocale(_vm.label),"vid":((_vm.$getLocale(_vm.label)) + "-" + _vm.inputRef),"rules":_vm.rules,"data-required-recommended":_vm.requiredRecommended},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('div',{staticClass:"call-input"},[(_vm.isCallEnabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isActionCallAllowed && !invalid ? _vm.$t('call') : _vm.$t('cantCall')),expression:"isActionCallAllowed && !invalid ? $t('call') : $t('cantCall')"}],staticClass:"call-input-icon",class:[
					{
						active: _vm.isActionCallAllowed && !invalid
					},
					_vm.options.iconPhoneClasses
				],on:{"click":function($event){return _vm.onAction('call')}}},[_c('i',{staticClass:"icon-phone",class:_vm.options.iconPhoneClasses})]):_vm._e(),(_vm.isSmsEnabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isActionSmsAllowed && !invalid ? _vm.$t('sendSms') : _vm.$t('cantSendSms')),expression:"isActionSmsAllowed && !invalid ? $t('sendSms') : $t('cantSendSms')"}],staticClass:"call-input-icon",class:{ active: _vm.isActionSmsAllowed && !invalid },on:{"click":function($event){return _vm.onAction('sms')}}},[_c('i',{staticClass:"icon-sms"})]):_vm._e(),(_vm.isWhatsAppEnabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isActionWhatsAppAllowed && !invalid ? _vm.$t('whatsapp') : _vm.$t('cantWhatsApp')),expression:"isActionWhatsAppAllowed && !invalid ? $t('whatsapp') : $t('cantWhatsApp')"}],staticClass:"call-input-icon",class:{ active: _vm.isActionWhatsAppAllowed && !invalid },on:{"click":function($event){return _vm.onAction('whatsapp')}}},[_c('i',{staticClass:"icon-whatsapp"})]):_vm._e(),(!(_vm.options && _vm.options.hideNumber) || _vm.options.isNew)?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"showInvalidTooltip",class:{
					'lba-invalid': (invalid && _vm.dirty),
					'showWarning': (_vm.showRecommendation),
				},attrs:{"data-cy":(_vm.currentComponentId + "__inputPhone"),"type":"text","disabled":_vm.readOnly},domProps:{"value":(_vm.phoneNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value},function($event){return _vm.onEvent('input', _vm.parseString, true)}],"change":function($event){return _vm.onEvent('change', _vm.parsePhoneNumber, true)}}},'input',_vm.bindOptions(),false)):_c('input',{attrs:{"data-cy":(_vm.currentComponentId + "__inputPhone"),"type":"text","placeholder":(_vm.$_.isEmpty(_vm.phoneNumber)) ? '' : '*** *** ***',"readonly":""}})]),(_vm.dirty)?[_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(err)+" ")])}),_vm._l((_vm.customMessages),function(msg,index){return _c('span',{key:index,staticClass:"lba-messages warning",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(msg)+" ")])})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }