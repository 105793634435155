<template>
	<div class="switch-simple" :class="{ active }">
		<span v-if="showLabelOnLeftSide" class="leftOption" @click="toggle(!active)"
			:data-cy="`${currentComponentId}__leftOption`"
		>
			<span v-if="labelHtml" v-html="labelHtml"></span>
			<template v-else>{{ label }}</template>
			<i v-if="help" class="icon-tooltip" v-tooltip="help"></i>
		</span>
		<div class="switch-toggle">
			<input
				:data-cy="`${currentComponentId}__inputCheckbox`"
				type="checkbox"
				:id="`${name}-${nameUid}`"
				:name="`${name}-${nameUid}`"
				:disabled="disabled"
				:required="required"
				v-model="active"
				ref="checkbox"
			>
			<label :for="`${name}-${nameUid}`" :data-cy="`${currentComponentId}__checkboxLabel`"></label>
		</div>
		<span v-if="!showLabelOnLeftSide" class="rightOption" :class="{ active }" @click="toggle(!active)"
			:data-cy="`${currentComponentId}__rightOption`"
		>
			<span v-if="labelHtml" v-html="labelHtml"></span>
			<template v-else>{{ label }}</template>
			<i v-if="help" class="icon-tooltip" v-tooltip="help"></i>
		</span>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false,
		},
		name: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: false,
			default: '',
		},
		labelHtml: {
			type: String,
			required: false,
			default: null,
		},
		help: {
			type: String,
		},
		showLabelOnLeftSide: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			setCheckbox: true,
			nameUid: this.$generateUID(), // fixes conflict of multiple checkboxes on page with duplicated name
		};
	},
	computed: {
		active: {
			get() {
				return this.value;
			},
			set(value) {
				if (value !== this.value) {
					this.$emit('input', value);
					this.$emit('change', value);
				}
			},
		},
	},
	methods: {
		toggle(state) {
			if (this.disabled) {
				return;
			};
			if (state != null) {
				this.active = state;
			}
		},
	},
};
</script>
