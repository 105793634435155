export default {
	data() {
		return {
			appTitlePrefixDefault: 'LinuxBox',
			appTitleSuffixDefault: 'Admin4',
			appTitlePrefix: '',
			appTitleSuffix: '',
			appTitleUnder: null,
		};
	},
	async created() {
	},
	methods: {
		setAppTitle(isLoginPage = false, titlePrefix = null, justFillVariables = false) {
			const serverSettings = $_.first(this.$root.servers);

			if (serverSettings && serverSettings.app_title_long_under) {
				this.appTitleUnder = serverSettings.app_title_long_under;
			}

			if (isLoginPage && serverSettings && (serverSettings.app_title_long_prefix || serverSettings.app_title_long_suffix)) {
				this.appTitlePrefix = serverSettings.app_title_long_prefix || null;
				this.appTitleSuffix = serverSettings.app_title_long_suffix || null;

				if (!justFillVariables) document.title = `${serverSettings.app_title_prefix || ''}${serverSettings.app_title_suffix || ''}`;

			} else if (serverSettings && serverSettings.app_title_defined) {
				this.appTitlePrefix = serverSettings.app_title_prefix || null;
				this.appTitleSuffix = serverSettings.app_title_suffix || null;

				const title = `${this.appTitlePrefix || ''}${this.appTitleSuffix || ''}`;

				if (!justFillVariables) document.title = (titlePrefix) ? `${titlePrefix} - ${title}` : title;

			} else {
				this.appTitlePrefix = this.appTitlePrefixDefault;
				this.appTitleSuffix = this.appTitleSuffixDefault;

				const title = `${this.appTitlePrefix || ''}${this.appTitleSuffix || ''}`;

				if (!justFillVariables) document.title = (titlePrefix) ? `${titlePrefix} - ${title}` : title;
			}
		},
	},
};
