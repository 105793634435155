var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lba-section',{key:_vm.objKey,attrs:{"parentComponentId":_vm.currentComponentId,"title":_vm.$t('permissions'),"slotType":'form',"expanded":""},scopedSlots:_vm._u([(!_vm.readOnly && _vm.loaded)?{key:"controls",fn:function(){return [_c('span',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('settings.addGroup')),expression:"$t('settings.addGroup')"}],staticClass:"icon-plus-circle",attrs:{"data-cy":(_vm.currentComponentId + "__addGroup")},on:{"click":function($event){$event.stopPropagation();return _vm.addGroup()}}})])]},proxy:true}:null],null,true)},[(_vm.objectPermissions.length > 0)?_vm._l((_vm.objectPermissions),function(perm,index){return _c('div',{key:index,staticClass:"formRow"},[_c('s',{staticClass:"size-2"},[_c('small',[_vm._v(_vm._s(_vm.$t('settings.group')))]),_c('ValidationProvider',{ref:"provider",refInFor:true,attrs:{"name":_vm.$t('settings.group'),"rules":{ requiredGroup: { data: perm, paths: ['profile_uid'], labels: [_vm.$t('settings.profile')]} },"data-cy":(_vm.currentComponentId + "__slot")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(perm.role_uid),expression:"perm.role_uid"}],class:{
							'lba-invalid': _vm.dirty && invalid,
						},attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__group__select"),"name":("group-" + (perm.role_uid))},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(perm, "role_uid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.change]}},[_c('option',{attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__group__select__optionNull")},domProps:{"value":null}},[_vm._v(" -- "+_vm._s(_vm.$t('all'))+" -- ")]),_vm._l((_vm.groupRoles),function(group,index){return _c('option',{key:group.role_uid,attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__group__select__option" + index)},domProps:{"value":group.role_uid}},[_vm._v(" "+_vm._s(group.role_name)+" ")])})],2),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages"},[_vm._v(" "+_vm._s(err)+" ")])}):_vm._e()]}}],null,true)})],1),_c('s',{staticClass:"size-2"},[_c('small',[_vm._v(_vm._s(_vm.$t('settings.profile')))]),_c('ValidationProvider',{ref:"provider",refInFor:true,attrs:{"name":_vm.$t('settings.profile'),"rules":{ requiredGroup: { data: perm, paths: ['role_uid'], labels: [_vm.$t('settings.group')] } },"data-cy":(_vm.currentComponentId + "__slot")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var invalid = ref.invalid;
						var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(perm.profile_uid),expression:"perm.profile_uid"}],class:{
							'lba-invalid': _vm.dirty && invalid,
						},attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__profile__select"),"name":("profile-" + (perm.profile_uid))},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(perm, "profile_uid", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.change]}},[_c('option',{attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__profile__select__optionNull")},domProps:{"value":null}},[_vm._v(" -- "+_vm._s(_vm.$t('all'))+" -- ")]),_vm._l((_vm.profiles),function(profile,index){return _c('option',{key:profile.profile_uid,attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__profile__select__option" + index)},domProps:{"value":profile.profile_uid}},[_vm._v(" "+_vm._s(profile.profile_name)+" ")])})],2),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages"},[_vm._v(" "+_vm._s(err)+" ")])}):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-controls"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('remove')),expression:"$t('remove')"}],staticClass:"icon-delete",attrs:{"data-cy":(_vm.currentComponentId + "__object" + index + "__remove")},on:{"click":function($event){return _vm.removePermission(index)}}})])])}):[_vm._v(" "+_vm._s(_vm.$t('noEntries'))+" ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }