<template>
	<div id="content">
		<div class="page-header">
			<h1>{{ $t('settings.menu.profiles') }}</h1>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
			tabindex="0"
		>
			<lba-grid
				:collection="profiles"
				:rowId="['profile_uid']"
				:filterAttributes="filterAttributes"
				ref="grid"
				gridName="settings.profiles"
				hideableColumns
				multiSelectAll
				:dynamicHeight="false"
				sendVisibleColumnNames
			>
				<template v-slot:toolbar="props">
					<button
						v-if="!readOnly"
						:data-cy="`${props.parentComponentId}__new`"
						@click="openNew()"
					>
						{{ $t("new") }}
					</button>
				</template>
				<template v-slot:selected="props">
					<button
						v-if="!readOnly"
						class="buttonInverse"
						:data-cy="`${props.parentComponentId}__remove`"
						@click="openRemoveDialog">
						{{ $t("remove") }}
					</button>
				</template>
				<template v-slot:columns="props">
					<lba-grid-column
						id="profile_name"
						:data-cy="`${props.parentComponentId}__profileName`"
						:label="$t('name')"
						searchable
						sortable
						width="200"
					>
						<a @click="openDetail(props.row.profile_uid)" :data-cy="`${props.parentComponentId}__profileName__openDetail`">
							{{ props.row.profile_name }}
						</a>
					</lba-grid-column>

					<lba-grid-column
						id="users"
						:data-cy="`${props.parentComponentId}__users`"
						:label="$t('settings.menu.users')"
						searchable
						width="400"
					>
						<span
							v-tooltip="(props.row.users && props.row.users.length) ? props.row.users.join(', ') : ''"
						>
							{{ (props.row.users && props.row.users.length) ? props.row.users.join(', ') : '' }}
						</span>
					</lba-grid-column>

					<lba-grid-column
						id="groups"
						:data-cy="`${props.parentComponentId}__groups`"
						:label="$t('settings.menu.groups')"
						searchable
						width="400"
					>
						<span
							v-tooltip="(props.row.groups && props.row.groups.length) ? props.row.groups.join(', ') : ''"
						>
							{{ (props.row.groups && props.row.groups.length) ? props.row.groups.join(', ') : '' }}
						</span>
					</lba-grid-column>

					<lba-grid-column
						id="actions"
						:data-cy="`${props.parentComponentId}__actions`"
						width="50"
						label="actions"
						class="actions"
					>
						<a @click="cloneProfile(props.row.profile_uid)" :data-cy="`${props.parentComponentId}__actions__cloneProfile`">
							<i class="icon-duplicate"
								v-tooltip="$t('settings.duplicateProfile')"
							>
							</i>
						</a>
					</lba-grid-column>
				</template>
			</lba-grid>
			<lba-confirm-remove-dialog
				parentComponentId="profiles"
				componentId="remove"
				name="settings-profiles-remove-dialog"
				:title="$t('settings.deleteProfiles')"
				:on-confirm="removeSelected"
			></lba-confirm-remove-dialog>
		</div>
	</div>
</template>

<script>
import ProfileModel from '../models/Profile';
import OnScroll from '../mixins/OnScroll';

export default {
	name: 'Profiles',
	mixins: [
		OnScroll,
	],
	data() {
		return {
			profiles: [],
			loaded: false,
			selectedItems: {},
			filterAttributes: {},
			profileModel: null,
			readOnly: false,
		};
	},
	created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.profileModel = new ProfileModel(this.$http);
		this.profiles = new this.$Collection(this.profileModel, {
			params: {
				_order: 'profile_name',
				_order_dir: 'asc',
			},
		});

		this.profiles.attach(this, 'profiles');

		this.filterAttributes = {
			module: 'settings.profiles',
			columns: [
				{
					name: 'profile_name',
					label: this.$t('name'),
					type: 'text',
				},
				{
					name: 'users',
					label: this.$t('settings.menu.users'),
					type: 'text',
				},
				{
					name: 'groups',
					label: this.$t('settings.menu.groups'),
					type: 'text',
				},
			],
		};
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.profile.write');
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'settings-profile',
				params: { id: id, openNewTab: true },
			});
		},

		openNew() {
			this.$routerWrap.push({
				name: 'settings-profile',
				params: { id: '+', openNewTab: true },
			});
		},

		async removeSelected() {
			if (this.selectedItems) {
				try {
					await this.profileModel.bulkDelete(this.selectedItems);
					this.$root.$emit('content.removed', { noBack: true });
					this.$root.$emit('dialog-close', 'settings-profiles-remove-dialog');
				} catch (err) {
					console.error(err);
					this.$root.$emit('content.removeFailed');
				}
			}
		},
		openRemoveDialog() {
			this.selectedItems = this.$refs.grid.getChecked();
			if (this.selectedItems) {
				this.$root.$emit('dialog-open', {
					name: 'settings-profiles-remove-dialog',
					gridChecked: this.selectedItems,
				});
			}
		},
		cloneProfile(profile_uid) {
			this.$routerWrap.push({
				name: 'settings-profile',
				params: {
					id: '+',
					clone: profile_uid,
					openNewTab: true,
				},
			});
		},
	},
};
</script>
