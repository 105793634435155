export default {
	// in some cases, components (OptionsPanel) may contain props: anyViewError
	data() {
		return {
			anySelfViewError: false,
		};
	},
	methods: {
		registerViewErrorListeners() {
			this.$on('view-not-found', (event) => this.onError(event, 'notFound'));
			this.$on('view-permission-denied', (event) => this.onError(event, 'permissionDenied'));
			this.$on('view-server-error', (event) => this.onError(event, 'serverError'));
			this.$on('view-record-deleted', (event) => this.onError(event, 'recordDeleted'));
			this.$on('view-missing-parameter', (event) => this.onError(event, 'missingParameter'));
			this.$on('view-wrong-parameter', (event) => this.onError(event, 'wrongParameter'));
		},
		getError() {
			const pathInfo = this.$root.$tabs && this.$root.$tabs.getActiveTabViewError(this.currentPath || this.$route.path);
			return pathInfo && pathInfo.viewError;
		},
		getErrorType() {
			const pathInfo = this.$root.$tabs && this.$root.$tabs.getActiveTabViewError(this.currentPath || this.$route.path);
			return pathInfo && pathInfo.viewErrorType;
		},
		showReloadButton() {
			const pathInfo = this.$root.$tabs && this.$root.$tabs.getActiveTabViewError(this.currentPath || this.$route.path);
			return pathInfo && pathInfo.viewErrorShowReloadButton;
		},
		reloadPath() {
			this.$root.$emit('tabs.reload');
			this.$routerWrap.push(this.$route.path);
		},
		setError(data, errorType) {
			if (data && data.constructor === Object) {
				this.$root.$tabs.setTabViewError(data.key, data.error, errorType, data.showReloadButton);
			} else {
				this.$root.$tabs.setActiveTabViewError(data, errorType);
			}
			this.$forceUpdate();
		},
		getAnyError() {
			return !$_.isEmpty(this.getError()) || !$_.isEmpty(this.getErrorType()) || this.anyViewError;
		},

		onError(data, errorType) {
			this.setError(data, errorType);

			if (errorType === 'notFound') {
				console.error(`[${this.$options.name}] ERROR: Not found:`, data);

			} else if (errorType === 'permissionDenied') {
				console.error(`[${this.$options.name}] ERROR: Permission denied:`, data);

			} else if (errorType === 'serverError') {
				console.error(`[${this.$options.name}] ERROR: Server error:`, data);

			} else if (errorType === 'recordDeleted') {
				console.error(`[${this.$options.name}] ERROR: Record deleted:`, data);

			} else if (errorType === 'missingParameter') {
				console.error(`[${this.$options.name}] ERROR: Missing parameter:`, data);

			} else {
				console.error(`[${this.$options.name}] ERROR: Type:`, errorType, data);

			}

			this.anySelfViewError = this.getAnyError();
		},
	},
};
