/*
 * __back__
 *
 * Pomocné funkce pro práci se stránkou v tabu - scroll, history bar, reload
 * Vyžaduje mixin Component
 *
 */

export default {
	data() {
		return {
			reloadingProposal: false,
			reloadOnActive: false,
			reloadOnActiveWithForce: false,
			route: this.$route,
			updatedVariablePath: 'entry.updated',
			updatedByVariablePath: 'entry.updated_by',
			isValid: true,
		};
	},
	// mixin: Page
	deactivated() {
		this.unPrepareHistoryBar();
	},
	// mixin: Page
	activated() {
		this.prepareHistoryBar();
		if (this.reloadOnActive) {
			this.reloadOnActive = false;
			this.tabReload(this.reloadOnActiveWithForce);
		}
	},
	methods: {
		// mixin: Page
		tabReload(force = false) {
			this.$root.$emit('tabs.reload', force);
		},
		// mixin: Page
		prepareHistoryBar() {
			const updated = $_.get(this, this.updatedVariablePath) || null;
			const updatedBy = $_.get(this, this.updatedByVariablePath) || null;
			if (updated || updatedBy) {
				this.$route.meta.showHistoryChangesButton = false;
				this.$route.meta.showHistoryBar = true;
				this.$route.meta.historyBar = {
					userName: updatedBy,
					date: updated,
				};
			}
			this.$root.$emit('history-bar-update');
		},
		// mixin: Page
		unPrepareHistoryBar() {
			this.$route.meta.showHistoryBar = false;
			this.$route.meta.showHistoryChangesButton = false;
			this.$route.meta.historyBar = {};
			this.$root.$emit('history-bar-update');
		},
	},
};
