export default {
	data() {
		return {
			isActive: true,
			ignoreClick: false,
			hasClickedInDialog: false,
			hasAnySelection: false,
		};
	},
	computed: {
		rootClass() {
			if ($_.isEmpty(this.$el.classList)) {
				return null;
			}
			const classes = {};
			this.$el.classList.forEach((classItem) => {
				if (classItem !== 'v-portal') {
					classes[classItem] = true;
				}
			});
			return classes;
		},
	},
	mounted() {
		document.addEventListener('mousedown', this.onMouseDown);
		document.addEventListener('mouseup', this.onMouseUp);
	},
	beforeDestroy() {
		document.removeEventListener('mousedown', this.onMouseDown);
		document.removeEventListener('mouseup', this.onMouseUp);
	},
	activated() {
		this.isActive = true;
	},
	deactivated() {
		this.isActive = false;
	},
	methods: {
		isDescendant(targetElement) {
			let target = targetElement;

			while (target && target !== document.body) {
				if (target === this.$refs.popupContent) {
					return true;
				}

				target = target.parentElement;
			}

			return false;
		},
		onMouseDown(event) {
			this.hasClickedInDialog = this.isDescendant(event.target);
		},
		onMouseUp(event) {
			if (this.hasClickedInDialog && !this.isDescendant(event.target) && !$_.isEmpty(window.getSelection().toString())) {
				this.ignoreClick = true;
			}
		},
	},
};
