<template>
	<lba-dialog-modal
		:parentComponentId="currentComponentId"
		:name="name"
		@open="dialogOpened = true;"
		@close="dialogOpened = false; $emit('close');"
	>
		<div class="popup-header">
			<h2>{{ title || $t('remove') }}</h2>
		</div>
		<div class="popup-body">
			<p v-if="dialog && dialog !== ''">{{ dialog }}</p>
		</div>
		<div class="popup-footer">
			<button
				:data-cy="`${currentComponentId}__confirmAndClose`"
				name="confirm"
				type="button"
				:class="confirmClass"
				@click="submit"
			>
				{{ confirmButton || $t('remove') }}
			</button>
			<button
				:data-cy="`${currentComponentId}__cancelAndClose`"
				name="cancel"
				type="button"
				class="buttonInverse"
				v-lba-dialog-close="name"
			>
				{{ $t('cancel') }}
			</button>
		</div>
	</lba-dialog-modal>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'LbaDialogConfirm',
	mixins: [ComponentIdentifier],
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
		},
		confirmButton: {
			type: String,
		},
		dialog: {
			type: String,
		},
		confirmClass: {
			type: [String, Array, Object],
			default: 'buttonRed',
		},
	},
	data() {
		return {
			dialogOpened: false,
		};
	},
	created() {
		this.$root.$listen('keydown-enter', this.onEnter, this);
	},
	methods: {
		onEnter() {
			if (this.dialogOpened) {
				this.submit();
			}
		},
		submit() {
			this.$emit('confirm');
			this.$root.$emit('dialog-close', this.name);
		},
	},
};
</script>
