<template>
	<div id="login">
		<div class="tray-login-header" role="moveWindow">
			<div class="movable-area">
				<i class="icon-draggable"></i>
				{{ $t('login') }}
			</div>
			<button
				type="button"
				style="top: 6px;"
				class="button-cancel"
				@click="hideMainWindow()"
			></button>
		</div>
		<div id="loginBox">
			<div id="loginAppHeader">
				<slot name="loginAppLogo" v-if="$slots.loginAppLogo"></slot>
				<div v-else class="lb-logo login-logo mb-4 animate__animated animate__fadeIn animate__slow"></div>
				<h1 id="loginAppName">
					<span class="login-appname-company">{{ appTitlePrefix }}</span>
					<span class="login-appname-product">{{ appTitleSuffix }}</span>
				</h1>
				<h2>{{ appTitleUnder }}</h2>
			</div>
			<form id="loginForm" class="login-screen" v-on:submit.prevent="submit">
				<s id="loginError" v-show="failed">
					<div>{{ $t('loginErrors.generalErrorShort') }}</div>
				</s>
				<s id="loginError" v-if="blocked">
					<div>{{ $t('loginErrors.blocked') }}</div>
				</s>
				<s style="width: max-content; max-width: 500px;" id="loginError" v-if="screenErrorMessage">
					<div class="width100">{{ screenErrorMessage }}</div>
				</s>
				<div v-show="screen == screens.LOCAL_AUTH">
					<s id="loginInputLogin">
						<input type="text" v-model="username" v-bind:placeholder="$t('username')"
							v-bind:disabled="loading" @change="username = $_.trim(username)" autofocus
						>
						<i class="icon-login-name"></i>
					</s>
					<s id="loginInputPass">
						<input type="password" v-model="password" v-bind:placeholder="$t('password')"
							v-bind:disabled="loading"
						>
						<i class="icon-login-pass"></i>
					</s>
				</div>
				<div v-if="screen == screens.OTP">
					<template v-if="tfaMethods && tfaMethods.length > 1">
						<p>
							{{ $t(`tfaMethods.wizard.chooseMethod`) }}
						</p>
						<div class="mb-3 mb-sm-5">
							<label
								class="checkbox radio"
								v-for="tfa in tfaMethods" :key="tfa">
								<input type="radio" @click="tfaType = tfa" :checked="tfaType === tfa">
								<span class="checkmark"></span>
								<span class="label">{{ $t(`tfaMethods.methods.${tfa}`) }}</span>
							</label>
						</div>
					</template>
					<div class="displayFlex center wrap-mobile">
						<div v-if="tfaType === 'sms'" :key="smsKey">
							<button :disabled="waitingSMS" class="button buttonGreen login-button" @click="resend()" type="button">
								<i v-show="firstSmsSend" class="icon-reload"></i>
								{{ $t(`tfaMethods.sms.sendSms`) }} {{ firstSmsSend ? $t(`tfaMethods.sms.again`) : '' }}
								{{ waitingForSMS && waitingForSMS >=0 ? ` (${waitingForSMS + 1}s)` : '' }}
							</button>
						</div>
						<s id="loginInputToken">
							<input type="text" class="mb-0" v-model="token" v-bind:placeholder="$t('token')"
								v-bind:disabled="loading" autofocus
							>
							<i v-if="tfaType === 'sms'" class="icon-sms"></i>
							<i v-else class="icon-gauth"></i>
						</s>
					</div>
					<p v-if="tfaType === 'sms'" class="note" v-html="$t(`tfaMethods.sms.enterCodeHtml`)">
					</p>
					<p v-else class="note" v-html="$t(`tfaMethods.gauth.gauthInfo`)">
					</p>
					<p v-if="tooMuchSms" class="red">
						{{ $t(`tfaMethods.sms.tooMuchSmsLong`) }}
					</p>
				</div>
				<div v-if="screen == screens.AGENT_AUTH">
					<s id="loginInputToken">
						<input type="text" v-model="exten" v-bind:placeholder="$t('extension')"
							v-bind:disabled="loading" autofocus
						>
						<i class="icon-login-token"></i>
					</s>
				</div>
				<div v-if="screen == screens.BLOCKED">
					<s class="two">
						<p class="big" v-html="$t('loginBlockedInfo')"></p>
						<h4 class="blue" :key="countId">{{ getInterval() }}</h4>
					</s>
				</div>
				<div v-if="!blocked">
					<button type="submit" class="buttonBig mt-4" v-bind:disabled="loading">{{ $t('log_in') }}</button>
				</div>
			</form>
		</div>
		<div
			v-if="loginInfoShow && loginInfoText && loginInfoText.length"
			class="login-info"
			:class="loginInfoType || ''"
		>
			<i class="icon-circle-warning"></i>
			<span v-html="loginInfoText || ''"></span>
		</div>
	</div>
</template>

<script>
import LoginText from '../mixins/LoginText';
import AppTitle from '../mixins/AppTitle';

const SCREENS = {
	LOCAL_AUTH: 0,
	OTP: 1,
	AGENT_AUTH: 2,
	BLOCKED: 3,
};

export default {
	name: 'LoginPage',
	mixins: [
		AppTitle,
		LoginText,
	],
	data() {
		return {
			username: null,
			password: null,
			loading: false,
			failed: false,
			blocked: null,
			savepass: false,
			screen: SCREENS.LOCAL_AUTH,
			token: null,
			exten: null,
			screens: SCREENS,
			socket: null,
			extenWanted: false,
			countId: $generateUID(),
			countInterval: null,
			tfaMethods: null,
			tfaType: null,
			waitingSMS: false,
			waitingTimer: null,
			waitingForSMS: null,
			tooMuchSms: false,
			firstSmsSend: false,
			debugSMSCode: null,
			smsKey: this.$generateUID(),
			screenErrorMessage: null,
		};
	},
	watch: {
		blocked(newValue) {
			if (newValue) {
				this.countInterval = setInterval(() => {
					this.countId = this.$generateUID();
				}, 1000);
			} else {
				this.stopCountdownInterval();
			}
		},
	},
	async created() {
		if (this.$root.blocked) {
			this.screen = SCREENS.BLOCKED;
			this.blocked = this.$root.blocked;
		}
		document.body.classList.add('loginPage');
		this.setLogin();

		if (this.$user.user_name) {
			await this.$user.logout();
		}

		this.setAppTitle(true);
		this.setLoginText();
	},
	mounted() {
		this.$root.loadingLogin = false;
		document.body.classList.remove('ng-cloak');
	},
	beforeDestroy() {
		document.body.classList.remove('loginPage');
		this.stopCountdownInterval();
	},
	methods: {
		setLogin() {
			if ('passwd' in window.localStorage &&
				'username' in window.localStorage &&
				'token' in window.localStorage &&
				'exten' in window.localStorage
			) {
				this.username = window.localStorage.username;
				this.password = window.localStorage.passwd;
				this.token = window.localStorage.token;
				this.exten = window.localStorage.exten;
			}
		},
		clearLoginScreen() {
			this.screen = SCREENS.LOCAL_AUTH;
			this.username = null;
			this.password = null;
			this.token = null;
			this.loading = false;
			this.exten = false;

			this.setLogin();
		},
		async submit(event) {
			if (this.extenWanted) {
				this.extenWanted = false;
			}

			if (!this.username || !this.password) {
				this.$notify.warn(this.$t('loginErrors.missingCredentials'));
				return;
			}

			this.loading = true;
			this.failed = false;
			this.screenErrorMessage = null;

			if (this.savepass) {
				window.localStorage.passwd = this.password;
				window.localStorage.username = this.username;
				window.localStorage.token = this.token;
			}

			const loginError = await this.$user.login({
				user_name: this.username,
				password: this.password,
				token: this.token,
				exten: this.exten,
				tfa_type: this.tfaType,
			});

			this.loading = false;

			if (loginError == null) {
				document.body.classList.remove('loginPage');
				this.$root.$emit('user-changed');

				const firstURL = this.$root.firstURL;
				this.$root.firstURL = null;

				if (firstURL) {
					this.$routerWrap.push(firstURL);
				} else {
					this.$routerWrap.push({ name: 'home' });
				}
			} else {
				let message;

				if (loginError.data && loginError.data.error) {
					message = loginError.data.error;
				} else {
					message = 'loginErrors.generalError';
				}
				if (loginError.data && loginError.data.extension) {
					this.exten = loginError.data.extension;
					this.extenWanted = true;
				}

				if (message === 'quiet:loginErrors.missingToken' && this.screen === SCREENS.LOCAL_AUTH) {
					// user needs to fill One-Time-Password/Token
					this.screen = SCREENS.OTP;

					// got available 2fa methods for user
					if (loginError.data && loginError.data.tfa) {
						this.tfaMethods = loginError.data.tfa;
						this.tfaType = this.tfaMethods[0] || null;
					}
					return;
				}
				if (message === 'quiet:loginErrors.missingExtension' &&
					(this.screen === SCREENS.LOCAL_AUTH || this.screen === SCREENS.OTP)) {
					// agent needs to fill extension
					this.screen = SCREENS.AGENT_AUTH;
					return;
				}

				if (message.startsWith('quiet:loginErrors.missingTfa-')) {
					this.screenErrorMessage = message.replace('quiet:loginErrors.missingTfa-', '');
					if (this.screenErrorMessage.startsWith('translate-')) {
						this.screenErrorMessage = this.$t(this.screenErrorMessage.replace('translate-', ''));
					}
					this.password = '';
					this.token = '';
					this.exten = '';
					return;
				}

				if (loginError.data.blocked) {
					this.blocked = loginError.data.blocked;
					this.screen = SCREENS.BLOCKED;
					return;
				}

				this.failed = true;
				this.screen = SCREENS.LOCAL_AUTH;
				this.password = '';
				this.token = '';
				this.exten = '';
			}
		},
		getInterval() {
			function z(n) {
				return (n < 10 ? '0' : '') + n;
			}
			const sec = (new Date(this.blocked).getTime() - new Date().getTime()) / 1000;

			if (sec < 0) {
				this.blocked = null;
				this.$root.blocked = null;
				this.screen = SCREENS.LOCAL_AUTH;
				return '00s';
			}

			const hours = Math.floor(sec / 3600);
			const minutes = Math.floor((sec - (hours * 3600)) / 60);
			const seconds = Math.floor(sec - (hours * 3600) - (minutes * 60));
			let timeText = '';
			if (hours > 0) {
				timeText += z(hours) + 'h ';
			}
			if (hours > 0 || minutes > 0) {
				timeText += z(minutes) + 'm ';
			}
			timeText += z(seconds) + 's';
			return timeText;
		},
		stopCountdownInterval() {
			clearInterval(this.countInterval);
			this.countInterval = null;
		},
		async resend() {
			this.tooMuchSms = false;
			this.firstSmsSend = true;

			this.$http.post('lbadmin/tfa-sms-resend', { user_name: this.username })
				.then((data) => {
					this.smsWaitingStart();
				}).catch((err) => {
					if (err && err.response && err.response.data && err.response.data.error &&
						err.response.data.error === 'tfaMethods.sms.tooMuchSms'
					) {
						this.tooMuchSms = true;
					} else {
						this.sendFirstSMS = false;
					}
					this.smsKey = this.$generateUID();
				});
		},
		smsWaitingStart() {
			this.waitingForSMS = 9;
			this.waitingSMS = true;
			this.waitingTimer = setInterval(() => {
				this.waitingForSMS--;
				if (this.waitingForSMS < 0) {
					this.waitingSMS = false;
					clearInterval(this.waitingTimer);
				}
			}, 1000);
		},
		hideMainWindow() {
			if (typeof LBTray === 'object') {
				LBTray.hideMainWindow();
			}
		},
	},
};
</script>
