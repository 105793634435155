<template>
	<component :is="rootTag" v-if="visible">
		<br v-if="options.break === 'before'">

		<component
			:componentId="currentComponentId"
			v-if="show"
			ref="customValue"
			:is="getComponent()"
			:attribute="attribute"
			:noInput="noInput"
			:dirty="dirty"
			:showRecommended="showRecommended"
			:componentRef="componentRef"
			@input="onEvent('input')"
			@change="onEvent('change')"
			@blur="$emit('blur')"
			@dirty="$emit('dirty')"
			@action="$emit('action', $event)"
		>
			<template v-slot:actions="props">
				<slot name="actions" :customValue="props.customValue" :componentId="currentComponentId"></slot>
			</template>
		</component>

		<br v-if="options.break === 'after'">
	</component>
</template>

<script>
export default {
	name: 'CustomValue',
	props: {
		/**
		 * data
		 * key
		 * options
		 *   attributes - extends attribute.options object
		 *   class
		 *   label
		 *   title_hint
		 *   name
		 *   options
		 *   parentComponentId
		 *   required
		 *   rootTag
		 *   ui_type
		 *   visible
		 *   validation
		 *   validationRules
		 *   validationCustomMessages
		 * readOnly
		 */
		attribute: {
			type: Object,
		},
		noInput: Boolean,
		dirty: Boolean,
		showRecommended: Boolean,
		componentRef: String,
	},
	data() {
		return {
			currentComponentId: null,
			options: null,
			visible: false,
			rootTag: 'span',
			show: false,
			data: null,
			key: null,
			originalValue: null,
			lastValue: null,
			firstEmit: true,
			componentsList: {
				action: 'input-action',
				boolean: 'input-boolean',
				break: 'input-break',
				bytes: 'input-bytes',
				checkbox: 'input-checkbox',
				ckeditor: 'input-ckeditor',
				code: 'input-code',
				color: 'input-color',
				date: 'input-date',
				datetime: 'input-datetime',
				file: 'input-file',
				icon: 'input-icon',
				interval: 'input-interval',
				lba_select: 'input-lba-select',
				link_format: 'input-link-format',
				link: 'input-link',
				multi_checkbox: 'input-multi-checkbox',
				multi_select: 'input-multi-select',
				number: 'input-number',
				options: 'input-options',
				period_picker: 'input-period-picker',
				phone: 'input-phone',
				progressbar: 'input-progressbar',
				radio: 'input-radio',
				range: 'input-range',
				select: 'input-select',
				simple_text: 'input-simple-text',
				switch: 'input-switch',
				text_array: 'input-textarray',
				text: 'input-text',
				textarea: 'input-textarea',
				time: 'input-time',
				two_lists: 'input-two-lists',
				value_with_unit: 'input-value-with-unit',
			},
		};
	},
	created() {
		this.options = this.attribute.options;
		if (this.options && this.checkProperty(this.options, 'visible') && !this.options.visible) {
			this.visible = false;
		} else {
			this.visible = true;
		}

		this.data = this.attribute.data;
		this.key = this.attribute.key;
		this.originalValue = $_.cloneDeep(this.data[this.key]);
		this.lastValue = $_.cloneDeep(this.data[this.key]);

		if (this.options.attributes) {
			$_.each(Object.keys(this.options.attributes), (key) => {
				if (key === 'options') {
					if ($_.isEmpty(this.options.attributes.options)) {
						return;
					}
					const validOption = $_.find(this.options.attributes.options, (opt) => {
						return opt.label !== null && opt.value !== null;
					});

					if (!validOption) {
						return;
					}
				}
				this.options[key] = this.options.attributes[key];
			});
		}

		if (!$_.isEmpty(this.options.parentComponentId) && !$_.isEmpty(this.options.name)) {
			if (this.options.appendComponentName) {
				this.currentComponentId = `${this.options.parentComponentId}__${this.$options.name}__${this.options.name}`;
			} else {
				this.currentComponentId = `${this.options.parentComponentId}__${this.options.name}`;
			}

		} else if ($_.isEmpty(this.options.parentComponentId)) {
			if (this.options.appendComponentName) {
				this.currentComponentId = `${this.$options.name}__${this.options.name}`;
			} else {
				this.currentComponentId = this.options.name;
			}

		} else if ($_.isEmpty(this.options.name)) {
			if (this.options.appendComponentName) {
				this.currentComponentId = `${this.options.parentComponentId}__${this.$options.name}`;
			} else {
				this.currentComponentId = this.options.parentComponentId;
			}

		}
		this.show = true;
	},
	methods: {
		callValidate() {
			if (this.$refs && !$_.isEmpty(this.$refs.customValue)) {
				return this.$refs.customValue.callValidate();
			}
			return false;
		},
		getComponent() {
			return this.componentsList[(this.options && this.options.ui_type) || 'text'] || 'input-text';
		},
		onEvent(event) {
			if ((this.originalValue === undefined && this.data[this.key] === undefined) ||
				(
					$_.isEqual(this.originalValue, this.data[this.key]) &&
					$_.isEqual(this.lastValue, this.data[this.key]) &&
					event !== 'change'
				)
			) {
				return;
			}
			this.lastValue = $_.cloneDeep(this.data[this.key]);
			if (this.options && this.options.initEmpty && this.firstEmit) {
				this.firstEmit = false;
				return;
			}
			this.$emit(event, this.data[this.key]);
			this.elementKey += 1;
			this.$forceUpdate();
		},
		checkProperty(data, key) {
			return (data && Object.prototype.hasOwnProperty.call(data, key));
		},
	},
};
</script>
