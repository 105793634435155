<template>
	<lba-dialog
		:parentComponentId="parentComponentId"
		:componentId="componentId"
		:name="name"
		:title="$t('tfaMethods.tfaMethods')"
		:showClose="!logout"
		modal
		@open="load()"
	>
		<template #default="props">
			<div class="popup-body">
				<ValidationObserver ref="form">
					<div style="display: flex; min-width: 700px;">
						<!-- SMS -->
						<div
							v-if="availableTfa && availableTfa.includes('sms')"
							style="width: 50%; max-width: 400px;"
						>
							<div class="first-login-form step-2">
								<label class="checkbox" style="left: -10px;">
									<input type="checkbox" v-model="sms.enabled" />
									<i class="icon-ok"></i>
									<span class="label">{{ $t(`tfaMethods.sms.authenticateSms`) }}</span>
								</label>
								<br>
								<div class="my-auto" v-if="sms.enabled">
									<!-- Ověření čísla -->
									<div class="mt-4">
										<ValidationProvider
											:name="$t('tfaMethods.wizard.phoneForAuth')"
											v-slot="{ invalid, errors }"
											:rules="{ required: true, regex: /^\+(420|421)\d{9}$/ }"
											:vid="`first_login__sms_number`"
										>
											<s class="alignLeft">
												<small>{{ $t(`tfaMethods.wizard.phoneForAuth`) }}</small>
												<div class="input-button">
													<input
														id="phoneNumber"
														type="text"
														autocomplete="new-password"
														name="phone"
														v-model="sms.phone_number"
														placeholder="+420666777888"
														:class="{ 'lba-invalid': numberDirty && invalid }"
														@input="onPhoneNumberInput();"
													>
													<template v-if="invalid">
														<span
															v-for="(err, index) in errors"
															:key="index"
															class="lba-messages"
															style="left: 0; top: 50px;"
															:data-cy="`first_login__sms_number__error${index}`"
														>{{ err }}</span>
													</template>
													<button
														v-if="!sms.sendFirstSMS" @click="resend()"
														style="width: 85px"
													>
														{{ $t(`tfaMethods.wizard.send`) }}
													</button>
												</div>
											</s>
										</ValidationProvider>
									</div>
									<!-- Ověření SMS -->
									<div :key="smsKey">
										<s class="alignLeft mt-3">
											<small>{{ $t(`tfaMethods.sms.enterCode`) }}</small>
											<div class="input-button">
												<input
													type="text"
													v-model="sms.sms_code"
													:disabled="sms.sms_tested || sms.tooMuchSms"
												>
												<button
													type="button"
													:disabled="sms.sms_tested"
													@click="testSms()"
													style="width: 85px"
												>
													{{ $t(`tfaMethods.sms.authenticateSmsCode`) }}
												</button>
											</div>
										</s>
										<br>
										<s class="alignLeft mt-3" v-if="!sms.sms_tested">
											<button
												:disabled="sms.waitingSMS || sms.sms_tested"
												class="button buttonGreen login-button"
												@click="resend()"
												type="button"
											>
												<i class="icon-reload"></i>
												{{ $t(`tfaMethods.sms.sendAgain`) }}
												{{ sms.waitingForSMS && sms.waitingForSMS >= 0 ? ` (${sms.waitingForSMS+1} s)` : '' }}
											</button>
										</s>
										<br>
										<s id="loginError" class="wide mt-4" v-if="sms.sendError">
											<div>{{ $t(`tfaMethods.sms.sendError`) }}</div>
										</s>
										<s id="loginError" class="wide mt-4" v-else-if="sms.wrong_sms">
											<div>{{ $t(`tfaMethods.sms.wrongCode`) }}</div>
										</s>
										<s id="loginError" class="wide mt-4" v-else-if="sms.sms_expired">
											<div>{{ $t(`tfaMethods.sms.codeExpired`) }}</div>
										</s>
										<s id="loginError" class="wide mt-4" v-else-if="sms.tooMuchSms">
											<div>{{ $t(`tfaMethods.sms.tooMuchSmsLong`) }}</div>
										</s>
										<s id="loginError" class="wide mt-4 success" v-if="sms.sms_tested">
											<div>{{ $t(`tfaMethods.sms.phoneAuthenticated`) }}</div>
										</s>
									</div>
								</div>
							</div>
						</div>
						<!-- GAUTH -->
						<div
							v-if="availableTfa && availableTfa.includes('gauth')"
							style="width: 50%; max-width: 400px; line-height: 1.1;"
						>
							<label class="checkbox">
								<input type="checkbox" name="google-auth" v-model="gauth.enabled">
								<i class="icon-ok"></i>
								<span class="label">{{ $t(`tfaMethods.methods.gauth`) }}</span>
							</label>
							<i @click="openGauthInfo" class="icon-tooltip" style="top: 5px;"></i>

							<template v-if="gauth.enabled">
								<p v-html="$t('tfaMethods.methodsInfo.gauth')"></p>
								<div class="displayFlex center">
									<a
										href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
										target="_blank"
										style="margin-right: 15px"
									>
										<img src="/images/download-google.png" alt="Google Play (Android)">
									</a>
									<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605" target="_blank">
										<img src="/images/download-apple.png" alt="App Store (iOS)">
									</a>
								</div>
								<div :key="gauthKey">
									<p v-html="$t(`tfaMethods.gauth.afterDownload`, { token: gauth.token })"></p>
									<svg class="qrcode" v-bind:viewBox="`0 0 ${gauth.qr.size} ${gauth.qr.size}`">
										<path v-bind:d="gauth.qr.path" />
									</svg>
									<p>
										<a @click="newGauth()" class="link-icon">
											<i class="icon-reload"></i>
											{{ $t('tfaMethods.gauth.reload') }}
										</a>
										<i class="icon-tooltip" style="top: -10px;" v-tooltip="$t('tfaMethods.gauth.reloadInfo')"></i>
									</p>
									<s v-show="!gauth.gauth_tested" class="pr-0">
										<p>{{ $t(`tfaMethods.gauth.insertToken`) }}</p>
										<div class="input-button">
											<input
												type="text"
												name="code"
												v-model="gauth.gauth_code"
												@input="gauth.gauth_tested = null"
											/>
											<button
												type="button"
												name="certBtn"
												:disabled="gauth.gauth_tested"
												@click="testCode()"
											>
												{{ $t(`tfaMethods.gauth.authenticateCode`) }}
											</button>
										</div>
									</s>
									<s v-if="gauth.gauth_tested" id="loginError" class="wide mt-4 success">
										<div>{{ $t(`gAuthStatus.verified`) }}</div>
									</s>
									<s v-else-if="gauth.gauth_tested === false" id="loginError" class="wide mt-4">
										<div>{{ $t(`gAuthStatus.notVerified`) }}</div>
									</s>
								</div>
							</template>
							<lba-dialog-modal
								:name="'gauthInfo'"
								modal
							>
								<div class="popup-body alignCenter" style="max-width: 500px;">
									<p v-html="$t('tfaMethods.wizard.infoTwo', { app: `${appTitlePrefix}${appTitleSuffix}` })"></p>
								</div>
								<div class="popup-footer">
									<button type="button" class="buttonInverse" v-lba-dialog-close="'gauthInfo'"
									>
										{{ $t('ok') }}
									</button>
								</div>
							</lba-dialog-modal>
						</div>
					</div>
				</ValidationObserver>
			</div>
			<div class="popup-footer">
				<button
					type="button"
					class="button"
					@click="save"
					:data-cy="`${props.parentComponentId}__saveTfa`"
					:disabled="!isValid"
				>
					{{ $t('save') }}
				</button>
				<button
					v-if="logout"
					type="button"
					class="button buttonRed"
					@click="$emit('logout');"
					:data-cy="`${props.parentComponentId}__logout`"
				>
					{{ $t('logout') }}
				</button>
				<button
					v-else
					type="button"
					class="button buttonInverse"
					@click="cancel"
					:data-cy="`${props.parentComponentId}__logout`"
				>
					{{ $t('cancel') }}
				</button>
			</div>
		</template>
	</lba-dialog>
</template>

<script>
import UserModel from '../models/User';
import AppTitle from '../mixins/AppTitle';

export default {
	mixins: [AppTitle],
	name: 'TFASettings',
	props: {
		name: {
			type: String,
			required: true,
		},
		parentComponentId: {
			type: String,
			required: false,
			default: '',
		},
		componentId: {
			type: String,
			required: false,
			default: '',
		},
		logout: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			userModel: null,
			tfa: [],
			availableTfa: [],
			showLongInfo: false,
			smsKey: this.$generateUID(),
			gauthKey: this.$generateUID(),
			sms: {
				enabled: false,
				phone_number: '',
				sms_tested: false,
				sendFirstSMS: false,
				sms_code: '',
				too_much_sms: false,
				waiting_sms: false,
				wrong_sms: false,
				waitingTimer: null,
				waitingForSMS: null,
			},
			gauth: {
				enabled: false,
				token: '',
				qr: {},
				gauth_tested: null,
				gauth_code: '',
			},
			numberDirty: false,
			sms_code_dirty: false,
		};
	},
	computed: {
		isValid() {
			let valid = true;
			let validCount = 0;

			if (this.sms.enabled) {
				valid = (valid && this.sms.sms_tested === true);
				validCount++;
			}
			if (this.gauth.enabled) {
				valid = (valid && this.gauth.gauth_tested === true);
				validCount++;
			}

			if ((['forced', 'noLogin'].includes(this.$user.tfa_required) ||
				(this.$user.tfa_required === null && this.$user.tfa_required_global !== 'false')) && validCount === 0) {
				valid = false;
			}
			return valid;
		},
	},
	activated() {
		this.load();
	},
	mounted() {},
	beforeDestroy() {},
	created() {
		this.userModel = new UserModel(this.$http);
		this.setAppTitle(false, null, true);
	},
	methods: {
		load() {
			if (!this.$root.servers || !this.$root.servers.length) {
				this.$root.$once('servers-loaded', this.fetch);
			} else {
				this.fetch();
			}
		},
		async fetch() {
			this.sms = {
				enabled: false,
				phone_number: '',
				sms_tested: false,
				sendFirstSMS: false,
				sms_code: '',
				too_much_sms: false,
				waiting_sms: false,
				wrong_sms: false,
				waitingTimer: null,
				waitingForSMS: null,
			};
			this.gauth = {
				enabled: false,
				token: '',
				qr: {},
				gauth_tested: null,
				gauth_code: '',
			};
			this.availableTfa = $_.first(this.$root.servers).tfa;
			const resp = await this.userModel.getTfaSettings();
			this.tfa = resp.data;

			$_.forEach(this.tfa, (conf) => {
				if (conf.tfa_type === 'sms') {
					this.sms.enabled = true;
					this.sms.phone_number = conf.auth_secret;
					this.sms.sms_tested = true;
				} else if (conf.tfa_type === 'gauth') {
					this.gauth.enabled = true;
					this.gauth.qr = conf.qr.qr;
					this.gauth.token = conf.auth_secret;
					this.gauth.gauth_tested = true;
				}
			});

			if (!this.gauth.enabled) {
				const newGauth = await this.userModel.getGauthQr(true);
				this.gauth.qr = newGauth.data.qr;
				this.gauth.token = newGauth.data.token;
			}
		},
		async testCode() {
			this.gauth.gauth_tested = null;
			const gauth = await this.userModel.testGauthToken({
				token: this.gauth.gauth_code,
				auth_secret: this.gauth.token,
			});

			this.gauth.gauth_tested = (gauth && gauth.data && gauth.data.status === 'verified');
			this.gauthKey = this.$generateUID();
		},
		async resend() {
			if (this.sms.waitingSMS) return;

			this.numberDirty = true;
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			this.sms.sendFirstSMS = true;
			this.sms.too_much_sms = false;

			this.$http.post('lbadmin/first-login-sms-resend',
				{
					role_uid: this.$user.role_uid,
					number: this.sms.phone_number,
				})
				.then((data) => {
					this.sms.sms_tested = false;
					this.sms.wrong_sms = false;
					this.smsWaitingStart();
				}).catch((err) => {
					if (err && err.response && err.response.data && err.response.data.error &&
						err.response.data.error === 'tfaMethods.sms.tooMuchSms'
					) {
						this.sms.too_much_sms = true;
					} else {
						this.sendFirstSMS = false;
					}
					this.smsKey = this.$generateUID();
				});
		},
		async testSms() {
			this.sms_code_dirty = true;
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}
			this.$http.post('lbadmin/first-login-sms-test',
				{
					role_uid: this.$user.role_uid,
					code: this.sms.sms_code,
				})
				.then((data) => {
					this.sms.wrong_sms = false;
					this.sms.sms_tested = true;
					this.sms_code_dirty = false;
					this.smsWaitingStart();
				}).catch((err) => {
					console.error(err);
					this.sms.wrong_sms = true;
				});
		},
		smsWaitingStart() {
			this.sms.waitingForSMS = 9;
			this.sms.waitingSMS = true;
			this.sms.waitingTimer = setInterval(() => {
				this.sms.waitingForSMS--;
				if (this.sms.waitingForSMS < 0) {
					this.sms.waitingSMS = false;
					clearInterval(this.sms.waitingTimer);
				}
			}, 1000);

		},
		save() {
			const obj = {};

			if (this.sms.enabled) {
				obj.sms_number = this.sms.phone_number;
			}
			if (this.gauth.enabled) {
				obj.gauth_token = this.gauth.token;
			}

			this.userModel.saveTfaSettings(obj)
				.then(() => {
					window.location.reload();
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed');
				});
		},
		cancel() {
			this.$root.$emit('dialog-close', '2fa_change');
		},
		async newGauth() {
			this.gauth.gauth_tested = null;
			const resp = await this.userModel.getGauthQr(true);
			this.gauth.qr = resp.data.qr;
			this.gauth.token = resp.data.token;
		},
		onPhoneNumberInput() {
			this.sms.sms_tested = false;
			this.sms.sendFirstSMS = false;
			this.sms.sms_code = '';
			this.numberDirty = true;
		},
		openGauthInfo() {
			// console.log('open dialog');
			this.$root.$emit('dialog-open', { name: 'gauthInfo' });
		},
	},
};
</script>
