<template>
	<div id="content" v-if="isPageLoaded">
		<div class="page-header">
			<h1 v-if="header">
				<!-- {{ $t('documentation') }}: {{ $t(`${$route.params.module}.menu.${$route.params.module}`) }} - {{ header }} -->
				{{ $t('documentationUser') }}: {{ header }}
			</h1>
			<h1 v-else>
				{{ $t('documentationUser') }}: {{ $t(`${$route.params.module}.menu.${$route.params.module}`) }}
			</h1>
			<div class="page-controls">
				<span @click="tabReload" data-cy="documentation__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"></i>
				</span>
			</div>
		</div>
		<div
			class="page-content page-markdown"
			ref="pageContent"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<div v-if="messageCurrentLocaleUnavailable" class="msg msg-warning">
				{{ messageCurrentLocaleUnavailable }}
			</div>
			<div style="flex-basis: 100%; height: 0;"></div>
			<template v-if="$_.isEmpty(documentation)">
				<div class="error-wrap">
					<h1>{{ $t('noDocumentationAvailable') }}</h1>
				</div>
			</template>
			<div v-else class="markdown-body markdown-content" ref="markdown" v-html="documentation"></div>
			<div v-if="tableOfContent" class="markdown-body markdown-menu">
				<!-- <h1>{{ $t('content') }}</h1> -->
				<template v-for="(headerLink, index) in tableOfContent">
					<a
						:key="`a-${index}`"
						:class="`level${headerLink.lvl}`"
						@click="onLinkClick(headerLink.label || headerLink.content, $route.params.module, $route.params.fileName,
							`#${headerLink.slug}`)"
					>
						{{ headerLink.label || headerLink.content }}
					</a>
					<br :key="`br-${index}`">
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import DocumentationModel from '../models/Documentation';

const suffixRegex = /-\w\w.md/;

export default {
	name: 'DocumentationPart',
	mixins: [
		Page,
		OnScroll,
	],
	data() {
		return {
			documentationModel: null,
			isPageLoaded: false,
			isLoadingData: true,
			documentation: null,
			header: null,
			tableOfContent: null,
			headers: [],
			messageCurrentLocaleUnavailable: null,
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}

		this.documentationModel = new DocumentationModel(this.$http);

		try {
			await this.fetch();
			this.$root.$listen('user.updated', this.fetch, this);
			this.isPageLoaded = true;
			await this.$nextTick();
			if (this.$refs && this.$refs.pageContent) {
				this.$refs.pageContent.focus();
				this.$refs.pageContent.scrollTo(0, 0);
			}
			this.prepareLinks();
			this.checkRouteHash();
		} catch (error) {
			console.error('[lbadmin.DocumentationPart](created) error:', error);
		}
	},
	activated() {
		if (!$_.isEmpty(this.header)) {
			this.$route.meta.setName = this.header;
			this.$emit('route-meta-update');

			this.$root.$emit('breadcrumb-set', {
				name: 'documentation-part',
				translated: this.$route.meta.setName,
			});
		}
	},
	methods: {
		getLinkInfo(address) {
			if (!address) {
				return {
					moduleName: this.$route.params.module,
					fileName: this.$route.params.fileName,
				};
			}

			const hashLink = address.hash || null;

			if (address.pathname && address.pathname.indexOf('/') <= -1) {
				return {
					moduleName: this.$route.params.module,
					fileName: this.$route.params.fileName,
					hashLink: hashLink,
				};
			}

			const splittedPath = address.pathname.split('/');
			if ($_.isEmpty(splittedPath) || splittedPath.length !== 4) {
				console.error(
					'[lbadmin.DocumentationPart](getLinkInfo) invalid link path:', address.pathname,
					'expected: /documentation/:module/:file'
				);
				return;
			}

			const moduleName = splittedPath[2];
			if ($_.isEmpty(moduleName)) {
				console.error(
					'[lbadmin.DocumentationPart](getLinkInfo) invalid link path:', address.pathname,
					'missing module, expected: /documentation/:module/:file'
				);
				return;
			}

			const fileName = splittedPath[3];
			if ($_.isEmpty(fileName)) {
				console.error(
					'[lbadmin.DocumentationPart](getLinkInfo) invalid link path:', address.pathname,
					'missing file, expected: /documentation/:module/:file'
				);
				return;
			}

			return { moduleName, fileName, hashLink: hashLink };
		},
		getSourceInfo(address) {
			const splittedPath = address.pathname.split('/');
			if ($_.isEmpty(splittedPath) || splittedPath.length !== 5) {
				console.error(
					'[lbadmin.DocumentationPart](getSourceInfo) invalid link path:', address.pathname,
					'expected: /documentation/:module/:file'
				);
				return;
			}

			const moduleName = splittedPath[2];
			if ($_.isEmpty(moduleName)) {
				console.error(
					'[lbadmin.DocumentationPart](getSourceInfo) invalid link path:', address.pathname,
					'missing module, expected: /documentation/:module/:file'
				);
				return;
			}

			const sourceType = splittedPath[3];
			if ($_.isEmpty(sourceType)) {
				console.error(
					'[lbadmin.DocumentationPart](getSourceInfo) invalid link path:', address.pathname,
					'missing file, expected: /documentation/:module/:file'
				);
				return;
			}

			const fileName = splittedPath[4];
			if ($_.isEmpty(fileName)) {
				console.error(
					'[lbadmin.DocumentationPart](getSourceInfo) invalid link path:', address.pathname,
					'missing file, expected: /documentation/:module/:file'
				);
				return;
			}
			return { moduleName, sourceType, fileName };
		},
		prepareLinks() {
			if (!this.$refs || !this.$refs.markdown) {
				return;
			}
			const localHost = window.location && window.location.host;

			this.headers = this.$refs.markdown.querySelectorAll('h1, h2, h3, h4, h5, h6');
			const links = this.$refs.markdown.querySelectorAll('a');

			$_.forEach(links, async (link) => {
				const linkAddress = new URL(link.href);

				if (linkAddress && linkAddress.host && linkAddress.host !== localHost) {
					link.target = '_blank';
					return;
				}

				link.dataset.href = link.href;
				link.href = '';
				const linkInfo = this.getLinkInfo(linkAddress);
				if ($_.isEmpty(linkInfo)) {
					return;
				}

				const { fileName, hashLink } = linkInfo;

				if ($_.isEmpty(hashLink) && !suffixRegex.test(fileName) && linkAddress.protocol !== 'mailto:') {
					console.error(
						'[lbadmin.DocumentationPart](prepareLinks) invalid link path:', linkAddress.pathname,
						'file does not end with -<locale>.md'
					);
					return;
				}

				if (this.$route.hash && this.$route.hash === hashLink) {
					const headerElement = $_.find(this.headers, (header) => header.innerText === link.innerText);
					if (!headerElement) {
						console.error(
							'[lbadmin.DocumentationPart](prepareLinks) invalid link href:', linkAddress.href,
							'failed to find header:', link.innerText
						);
						return;
					}
					headerElement.scrollIntoView();
				}

				link.addEventListener('click', (event) => {
					event.preventDefault();
					if (event && event.target && event.target.dataset && event.target.dataset.href && linkAddress.protocol !== 'mailto:') {
						const linkAddressClick = new URL(event.target.dataset.href);
						const linkInfoClick = this.getLinkInfo(linkAddressClick);

						if (!$_.isEmpty(linkInfoClick)) {
							if ($_.isEmpty(linkInfoClick.hashLink)) {
								this.$routerWrap.push({
									name: 'documentation-part',
									params: {
										module: linkInfoClick.moduleName,
										fileName: linkInfoClick.fileName,
										openNewTab: event.ctrlKey,
									},
								});
							} else {
								this.onLinkClick(event.target.innerText, linkInfoClick.moduleName, linkInfoClick.fileName,
									linkInfoClick.hashLink);
							}
						}
					} else if (linkAddress.protocol === 'mailto:') {
						window.open(linkAddress.href, link.target);
					}
				});
			});

			const sources = this.$refs.markdown.querySelectorAll('[src]');
			$_.forEach(sources, (source) => {
				if ($_.isEmpty(source.src)) {
					return;
				}

				const linkAddress = new URL(source.src);
				source.src = `/api/lbadmin/lbadmin${linkAddress.pathname}`;
			});
		},
		headerHash(text) {
			return `#${this.$normalizeString(this.$uslug(text))}`;
		},
		checkRouteHash() {
			if (this.$route.hash) {
				const headerElement = $_.find(this.headers, (header) => this.headerHash(header.innerText) === this.$route.hash);
				if (headerElement) {
					headerElement.scrollIntoView();
				}
			}
		},
		onLinkClick(headerInnerText, moduleName, fileName, hashLink) {
			const newRoute = {
				name: this.$route.name,
				params: { module: moduleName, fileName: fileName },
				hash: hashLink,
			};

			this.$routerWrap.push(newRoute);

			const headerElement = $_.find(this.headers, (header) => this.headerHash(header.innerText) === hashLink);
			if (!headerElement) {
				console.error('[lbadmin.DocumentationPart](onLinkClick) failed to find header:', headerInnerText, 'with hash:', hashLink);
				return;
			}
			headerElement.scrollIntoView();
		},
		async fetch() {
			this.isLoadingData = true;
			const { data } = await this.$axiosFetch(
				this,
				this.documentationModel.get(this.$route.params.module, this.$route.params.fileName, { locale: this.$user.lang })
			);
			if ($_.isEmpty(data)) {
				throw new Error('Received empty data');
			}
			this.documentation = data.content;
			this.header = data.header;
			this.tableOfContent = data.tableOfContent;

			if (data.requestedLocale !== data.usedLocale) {
				this.messageCurrentLocaleUnavailable = this.$t('messageCurrentLocaleUnavailable');
			} else {
				this.messageCurrentLocaleUnavailable = null;
			}
			$_.forEach(this.tableOfContent, (headerLink) => {
				const labelMatch = headerLink.content.match(/^\[(.+)\]/);
				if (labelMatch && labelMatch[1]) {
					this.$set(headerLink, 'label', labelMatch[1]);
				}
			});
			if (!$_.isEmpty(this.header)) {
				this.$route.meta.setName = this.header;
				this.$emit('route-meta-update');

				this.$root.$emit('breadcrumb-set', {
					name: 'documentation-part',
					translated: this.$route.meta.setName,
				});
			}
			this.isLoadingData = false;
		},
	},
};
</script>
