/* eslint-disable no-bitwise, no-mixed-operators */

import io from 'socket.io-client';
import uuidv4 from './GeneratorUID';

export default class {
	constructor() {
		this.socket = null;
		this.connected = null;
		this.connecting = false;
		this.vm = null;

		this.channels = [];

		this.logout = this.logout.bind(this);
	}

	logout() {
		this.vm.$user.logout();
		const { href } = this.vm.$router.resolve({ name: 'login' });
		if (!this.vm.isFirstLogin) {
			window.history.pushState({}, null, href);
			window.location.reload();
		} else {
			this.vm.$routerWrap.push('/login');
		}
	}

	changeConnectedState(state) {
		if (state !== this.connected) {
			const firstlyConnected = this.connected === null && state;
			this.connected = state;
			if (firstlyConnected) {
				this.on('/lbadmin/logout', {}, this.logout);
				this.vm.$root.$emit('socket.connected');
			}
			this.vm.$root.$emit('socket.state-changed', this.connected);
		}
	}

	socketConnect() {
		if (this.socket != null) return;
		this.socket = io('/', { path: '/api/lbadmin/messaging' });
		console.log('SOCKET', this.socket);
		this.connecting = true;
		this.socket.on('connect', () => {
			this.changeConnectedState(true);
			this.connecting = false;
			this.channels.forEach((channel) => {
				this.on(channel.channel, channel.attrs, channel.callback, channel.id);
			});
			console.log('websocket connect');
			this.vm.$root.$emit('socket-opened');
		});
		this.socket.on('connect_error', (err) => {
			this.changeConnectedState(false);
			this.connecting = false;
			console.log('websocket connect error', err);
		});
		this.socket.on('connect_timeout', () => {
			this.changeConnectedState(false);
			this.connecting = false;
			console.log('websocket connect timeout');
		});
		this.socket.on('disconnect', async () => {
			console.log('websocket disconnect');
			this.changeConnectedState(false);
			this.connecting = false;
			this.vm.$root.$emit('socket.state-changed', this.connected);
		});

		this.socket.on('reconnect', async () => {
			try {
				const { data } = await this.vm.$root.$http('/lbadmin/user');
				if (
					$_.isEmpty(data) ||
					data.user_name !== this.vm.$user.user_name ||
					data.is_anonymous_user && !this.vm.$root.anonymousAccess
				) {
					this.vm.$root.firstUrl = this.vm.$root.$route.fullPath;
					return this.vm.$root.$routerWrap.push({ name: 'login' });
				}

				this.vm.$root.$emit('socket-opened');
			} catch (error) {
				console.error(error);
				if (error && error.response && error.response.status === 404) {
					this.logout();
				}
			}
		});
		this.socket.on('reconnecting', () => {
			console.log('websocket reconnecting');
			this.connecting = true;
		});
		this.socket.on('reconnect_error', (error) => {
			console.log('websocket reconnect error', error);
			this.changeConnectedState(false);
			this.connecting = false;
		});
		this.socket.on('reconnect_failed', () => {
			console.log('websocket reconnect failed');
			this.changeConnectedState(false);
			this.connecting = false;
		});
		this.socket.on('message', (message) => {
			if (message.type === 'versions') {
				this.vm.$versions.check(message.versions);
			}
		});
	}

	socketDisconnect() {
		if (this.socket) {
			console.log('websocket close');
			this.socket.close();
			this.socket = null;
		}
	}

	on(channel, attrs, callback, originalId = null) {
		const id = originalId || uuidv4();

		if (!originalId) {
			this.channels.push({ id, channel, attrs, callback });
		}

		this.socket.emit('join', id, channel, attrs, () => {});
		this.socket.on(channel, (forId, message, ack) => {
			ack('ack');
			if (forId === '*' || forId === id) {
				callback(message);
			}
		});
		return {
			send: (message) => {
				if (this.socket) {
					this.socket.emit('msg', channel, id, message, () => {});
				}
			},
			unsubscribe: () => {
				const index = this.channels.findIndex((item) => item.id === id && item.channel === channel);
				this.channels.splice(index, 1);
				if (this.socket) {
					this.socket.emit('leave', id, channel, () => {});
				}
			},
		};
	}
}
