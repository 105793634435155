<template>
	<div
		:parentComponentId="currentComponentId"
		:style="background"
		:class="{
			'lba-progress-pie': type === 'circle',
			'lba-progress-line': type === 'line',
		}"
		v-tooltip="tooltipStr"
	>
		<span>{{ value }}%</span>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
/*
 * LbaProgress
 *
 * Odkaz na volume
 *
 */

export default {
	name: 'LbaProgress',
	mixins: [
		ComponentIdentifier,
	],
	props: {
		value: {
			type: Number,
			required: true,
		},
		tooltip: {
			type: String,
		},
		info: {
			type: String,
		},
		color: {
			type: String,
			default: 'blue',
		},
		type: {
			type: String,
			default: 'circle',
			validator: (value) => ['circle', 'line'].includes(value),
		},
	},
	data() {
		return {
			background: '',
		};
	},
	computed: {
		tooltipStr() {
			let tooltipStr = this.tooltip;
			// if (!$_.isEmpty(this.info)) {
			// 	tooltipStr += ` ${this.info}`;
			// }
			tooltipStr += ` (${this.value}%)`;
			return $_.trim(tooltipStr);
		},
	},
	watch: {
		value() {
			this.redraw();
		},
	},
	created() {
		this.redraw();
	},
	methods: {
		redraw() {
			if (this.type === 'circle') {
				this.background = (
					`background: conic-gradient(` +
					`rgba(0, 0, 255, .1) 0%,` +
					`rgba(0, 0, 255, .1) ${this.value}%,` +
					`transparent ${this.value}%` +
					`)`
				);
			} else if (this.type === 'line') {
				this.background = `background: linear-gradient(90deg, #0077db 0%, #0fa1e5 ${this.value}%, #f5f5f5 ${this.value}%)`;
			}
		},
	},
};
</script>
