function timeBeforeAfterShort(date, usePrefixBefore = false, usePrefixAfter = false) {
	if (!date) {
		return null;
	}
	if (date.constructor === String || date.constructor === Number) {
		date = new Date(date);
	}
	if (date.constructor !== Date || isNaN(date.getTime())) {
		return null;
	}

	let prefix = '';
	if (usePrefixBefore && date <= new Date()) {
		prefix = `${this.$t('relativeTime.before')} `;
	} else if (usePrefixAfter && date > new Date()) {
		prefix = `${this.$t('relativeTime.after')} `;
	}

	const seconds = Math.abs(Math.floor((new Date() - date) / 1000));
	const divTable = [
		{
			value: 365 * 24 * 60 * 3600,
			label: 'years',
		},
		{
			value: 30 * 60 * 3600,
			label: 'months',
		},
		{
			value: 24 * 3600,
			label: 'days',
		},
		{
			value: 3600,
			label: 'hours',
		},
		{
			value: 60,
			label: 'minutes',
		},
	];
	let step = 0;
	let since = seconds;

	while (divTable[step]) {
		const interval = seconds / divTable[step].value;
		if (interval > 1) {
			since = Math.floor(interval);
			break;
		}
		step += 1;
	}

	const localePath = `relativeTime.short.${divTable[step] ? divTable[step].label : 'seconds'}`;
	return `${prefix}${since}${this.$t(localePath)}`;
}
function timeBeforeAfter(date, format = 'long', usePrefixBefore = true, usePrefixAfter = true) {
	if (format === 'short') {
		return this.$timeBeforeAfterShort(date, usePrefixBefore, usePrefixAfter);
	}
	if (!date) {
		return null;
	}
	if (date.constructor === String || date.constructor === Number) {
		date = new Date(date);
	}
	if (date.constructor !== Date || isNaN(date.getTime())) {
		return null;
	}
	const usePrefix = (
		(usePrefixBefore && date <= new Date()) ||
		(usePrefixAfter && date > new Date())
	);
	return moment(date).locale(this.$i18n.locale).fromNow(!usePrefix);
}

export default {
	install(Vue) {
		Vue.prototype.$timeBeforeAfterShort = timeBeforeAfterShort;
		Vue.prototype.$timeBeforeAfter = timeBeforeAfter;
	},
};
