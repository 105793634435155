<template>
	<div v-if="isActive">
		<div
			v-if="!isSearching && results.length"
			class="active"
			:key="`results-overview-data`"
		>
			<template
				v-for="(record, rIndex) in results"
			>
				<component
					v-if="$fulltextComponents && $fulltextComponents[record.doc_type]"
					:key="`result-component-${rIndex}`"
					:is="$fulltextComponents[record.doc_type].component"
					:data="record"
					:searchText="searchText"
				></component>
				<div v-else class="search-result-icon" :key="`result-title-${rIndex}`">
					{{ record.title }}
				</div>
			</template>
		</div>
		<div
			v-else-if="!isSearching && !results.length"
			:key="`results-overview-empty`"
		>
			<div v-if="!_.isEmpty(lastSearchText)" class="msg">
				<span>{{ $t('noRecordsFound') }}</span>
			</div>
			<div v-if="!_.isEmpty(searchText) && spellchecks && spellchecks.length" class="mt-4">
				{{ $t('fulltext.didYouMean') }}<br>
				<div
					v-for="(spell, sindex) in spellchecks"
					:key="`spellcheck-${sindex}`"
					class="mt-2"
				>
					<a @click="$emit('input', spell.word)"><strong>{{ spell.word }}</strong></a>
					<em>{{ $t('fulltext.spellcheckItem', { count: spell.freq }) }}</em>
				</div>
			</div>
		</div>
		<div
			v-else
			:key="`results-overview-loading`"
		>
			<div class="msg-loading mt-5"><span>{{ $t('grid.wait') }}</span></div>
		</div>
		<!-- PAGINATION -->
		<nav class="grid-paginaton" ref="pagination">
			<ul v-if="this.foundCount > 0">
				<li
					class="p-item prev"
					:class="{ 'disabled': currentPage === 1 }" @click="setPrewPage"
				>
					<i class="icon-collapse-all"></i>
				</li>
				<li
					v-for="(page, index) in pages"
					:key="`page-${index}`"
					:class="{
						'dots': page === '...',
						'p-item': page !== '...',
						'active': currentPage === page,
						'disabled': isSearching,
					}"
					@click="setCurrentPage(page)"
				>{{ page }}</li>
				<li
					class="p-item next"
					:class="{ 'disabled': currentPage === pageCount }"
					@click="setNextPage"
				>
					<i class="icon-collapse-all"></i>
				</li>
			</ul>
			<div class="p-info">
				<span v-if="this.foundCount > 0">
					{{ $t('grid.pagination_records', { from: pageRecordsFrom, to: pageRecordsTo, total: this.foundCount }) }}
				</span>
				<span v-else>
					{{ $t('grid.pagination_no_records') }}
				</span>
			</div>
			<div class="p-switch">
				<span style="margin-right: 10px;">{{ $t('grid.show_on_page') }}</span>
				<span
					class="count"
					:class="{ 'active': currentPerPage === item }"
					v-for="item in perPageOptions"
					:key="`gridPerPage${item}`"
					@click="setCurrentPerPage(item)"
				>
					{{ item }}
				</span>
				<span>{{ $t('grid.records') }}</span>
			</div>
		</nav>
	</div>
</template>

<script>
import FulltextModel from '../models/Fulltext.js';

export default {
	model: {
		prop: 'searchText',
	},
	props: {
		docTypes: {
			type: Array,
			required: true,
		},
		isActive: Boolean,
		searchText: String,
		targetSearch: Object,
		defaultCurrentPerPage: Number,
	},
	data() {
		return {
			fulltextModel: null,
			searchDebouncer: null,
			searchDelay: 500,
			isSearching: false,
			lastSearchText: '',
			lastTargetSearch: null,
			foundCount: 0,
			results: [],
			isCurrentPageWatcherDisabled: false,
			currentPage: 1,
			currentPerPage: this.defaultCurrentPerPage,
			perPageOptions: [5, 20, 50, 100, 500],
			pages: [],
			pageCount: 1,
			pageRecordsFrom: 1,
			pageRecordsTo: 1,
			spellchecks: [],
		};
	},
	computed: {
		isTargetSearchEmpty() {
			return $_.every(this.targetSearch, (value) => !value);
		},
	},
	watch: {
		isSearching() {
			this.$emit('searching', this.isSearching);
		},
		searchText() {
			if (this.lastSearchText === this.searchText) {
				return;
			}
			if ($_.isEmpty(this.searchText)) {
				this.resetPages();
				return;
			}
			this.isSearching = true;
			this.searchDebouncer.emit();
		},
		targetSearch: {
			deep: true,
			handler() {
				if ($_.isEqual(this.lastTargetSearch, this.targetSearch)) {
					return;
				}
				if (this.isTargetSearchEmpty) {
					this.resetPages();
					return;
				}
				this.isSearching = true;
				this.searchDebouncer.emit();
			},
		},
		async currentPage() {
			if (this.isCurrentPageWatcherDisabled) {
				this.isCurrentPageWatcherDisabled = false;
				return;
			}
			this.refreshPages();
			await this.$nextTick();
			this.search(true);
		},
	},
	async created() {
		this.fulltextModel = new FulltextModel(this.$http);
		this.searchDebouncer = new this.$Debouncer(
			this,
			this.search,
			null,
			this.searchDelay
		);
		await this.search();
		this.$root.$listen('lbadmin.fulltext-force-search', () => this.search(true), this);
	},
	methods: {
		resetPages() {
			this.foundCount = 0;
			this.results = [];
			this.currentPage = 1;
			this.pageRecordsFrom = 1;
			this.pageRecordsTo = this.currentPerPage;
		},
		async search(forced = false) {
			// not search if searched text is same, unles forced search
			if (this.lastSearchText === this.searchText && $_.isEqual(this.lastTargetSearch, this.targetSearch) && !forced) {
				this.isSearching = false;
				return;
			}

			this.lastSearchText = this.searchText;
			this.lastTargetSearch = $_.cloneDeep(this.targetSearch);

			if ($_.isEmpty(this.searchText) || this.isTargetSearchEmpty) {
				this.resetPages();
				this.isSearching = false;
				return;
			}

			this.isSearching = true;

			let response = null;
			try {
				response = await this.fulltextModel.query({
					search: this.searchText,
					...this.targetSearch,
					doc_type: this.docTypes,
					_offset: this.pageRecordsFrom - 1,
					_limit: this.currentPerPage,
				});
			} catch (error) {
				console.error(error);
			}
			this.isSearching = false;

			if (this.lastSearchText !== this.searchText && this.currentPage !== 1) {
				this.isCurrentPageWatcherDisabled = true;
				this.currentPage = 1;
			}

			this.foundCount = parseInt(response?.headers['x-lbadmin-count'], 10);
			this.refreshPages();
			this.results = response?.data?.results || [];
			this.spellchecks = response?.data?.spellchecks || [];
		},
		async setCurrentPerPage(perPage) {
			if (this.currentPerPage === perPage) {
				return;
			}
			// console.debug('[Fulltext] (setCurrentPerPage) - ', perPage);
			this.currentPerPage = perPage;
			this.currentPage = 1;
			await this.$nextTick();
			this.search(true);

			this.fulltextModel.saveSettings({ per_page: this.currentPerPage })
				.then(async () => {
					this.$root.$emit('content.saved', { reload: false });
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed');
				});
		},
		setPrewPage() {
			if (this.currentPage === 1 || this.isSearching) {
				return;
			}
			// console.debug('[Fulltext] (setPrewPage)');
			this.currentPage--;
		},
		setNextPage() {
			if (this.currentPage === this.pageCount || this.isSearching) {
				return;
			}
			// console.debug('[Fulltext] (setNextPage)');
			this.currentPage++;
		},
		setCurrentPage(page) {
			if (this.isSearching || this.currentPage === page || isNaN(page)) {
				return;
			}
			// console.debug('[Fulltext] (setCurrentPage) - ', page);
			this.currentPage = page;
		},
		async refreshPages() {
			await this.$nextTick();
			const max = this.foundCount;

			const from = ((this.currentPerPage * this.currentPage) - this.currentPerPage + 1);
			// console.debug(`[Fulltext] (pageRecordsFrom) - from: ${from}, max: ${max}`);
			this.pageRecordsFrom = from > max ? max : from;

			const to = this.currentPerPage * this.currentPage;
			// console.debug(`[Fulltext] (pageRecordsTo) - to: ${to}, max: ${max}`);
			this.pageRecordsTo = to > max ? max : to;

			this.pageCount = Math.ceil(this.foundCount / this.currentPerPage);
			// console.debug(`[Fulltext] (pageCount) - `, this.pageCount);

			if (this.pageCount < 7) {
				this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
			} else {
				this.pages = [1];
				if (this.currentPage - 2 > 1) this.pages.push('...');
				for (let i = (this.currentPage - 2); i <= (this.currentPage + 2); i++) {
					if (i > 1 && i < this.pageCount) {
						this.pages.push(i);
					}
				}

				if (this.currentPage + 2 < this.pageCount - 1) this.pages.push('...');
				if (this.pageCount > 1) this.pages.push(this.pageCount);
			}
		},
	},
};
</script>
