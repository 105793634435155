export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/fulltext', { params });
	}

	getSuggestions(searchValue) {
		return this.$http.get('lbadmin/fulltext-suggestions', { params: { searchValue } });
	}

	getSettings() {
		return this.$http.get('lbadmin/fulltext-settings');
	}

	saveSettings(params) {
		return this.$http.post('lbadmin/fulltext-settings', { params });
	}

}
