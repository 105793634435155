<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<a :data-cy="`${currentComponentId}__href`" :href="formattedLink" target="_blank">
			<i class="icon-new-tab"></i>
		</a>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<fake-provider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<span
				class="has-icon input-link"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				style="border: none"
			>
				<input
					:data-cy="`${currentComponentId}__input`"
					type="text"
					v-model="data[key]"
					v-bind="bindOptions()"
					:disabled="readOnly"
					:class="{
						'lba-invalid': (invalid && dirty),
						'showWarning': (showRecommendation)
					}"
					@input="onEvent('input', prepareLinkFormat)"
					@change="onEvent('change', trimAndPrepareLinkFormat)"
				>
				<template v-if="data[key] && data[key] !== ''">
					<a :data-cy="`${currentComponentId}__href`" :href="formattedLink" target="_blank">
						<i class="icon-new-tab"></i>
					</a>
				</template>
				<template v-else>
					<i class="icon-new-tab disabled"></i>
				</template>
			</span>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputLinkFormat',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			formattedLink: '#',
		};
	},
	created() {
		this.prepareComponent();
		this.prepareLinkFormat();
	},
	methods: {
		trimAndPrepareLinkFormat() {
			this.data[this.key] = $_.trim(this.data[this.key]);
			this.prepareLinkFormat();
		},
		prepareLinkFormat() {
			if (this.options && this.options.format) {
				this.formattedLink = this.options.format.replace(/{value}/, this.data[this.key]);
			} else {
				this.formattedLink = '#';
			}
		},
	},
};
</script>
