import libphonenumber from 'google-libphonenumber';
const PHONE_UTIL = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;
const SHORT_INFO = libphonenumber.ShortNumberInfo.getInstance();

export default class {
	constructor() {
		this.vm = null;
		this.countries = this._getCountries();
	}
	// methods with _ prefix should be called only from within the class

	_getCountries() {
		const callingCodes = PHONE_UTIL.getSupportedCallingCodes();
		if (callingCodes) {
			return callingCodes.map((callingCode) => {
				const regionCodes = PHONE_UTIL.getRegionCodesForCountryCode(callingCode);
				return {
					code: callingCode,
					regions: [...regionCodes],
				};
			});
		} else {
			return [];
		}
	}

	_backupFormat(number, slice = false) {
		try {
			let result = '';
			if (slice) {
				number = number.slice(-9);
			}

			let whiteSpaceCount = 0;
			for (let i = number.length - 1; i >= 0; i--) {
				result = number.charAt(i) + result;
				if ((number.length - i) % 3 === 0 && i !== 0) {
					if (whiteSpaceCount < 3) {
						result = ' ' + result;
					}
					whiteSpaceCount++;
				}
			}
			return result;
		} catch (error) {
			console.error(error);
			return number;
		}
	}

	_clearNumber(number) {
		if (number) return number.replace(/[\s_-]/g, '').trim();
		return null;
	}

	_getCountryCode(clearNumber) {
		let countryCode = '';
		if (clearNumber) {
			countryCode = this.countries.find((country) => (
				clearNumber.startsWith(`00${country.code}`) ||
				clearNumber.startsWith(`+${country.code}`)
			));
		}
		if (countryCode && countryCode.regions) {
			countryCode = countryCode.regions[0] || '';
		}
		return countryCode;
	}

	_userInputHasErrors(userInput, countryCode) {
		if (!userInput || !countryCode) {
			console.error('Missing parametrs in _userInputHasErrors');
			return userInput || true;
		}
		let rawUserInput;
		const rawNumberParse = PHONE_UTIL.parseAndKeepRawInput(userInput, countryCode);
		if (rawNumberParse.values_['5']) {
			rawUserInput = rawNumberParse.values_['5'].replace(/[\s_-]/g, '');
			if (/^(?:\+)?\d+$/.test(rawUserInput)) {
				return false;
			}
		}
		return rawUserInput;
	}

	format(number, rawFormar = false, callingCode = true) {
		if (!number || !number.length || (typeof number !== 'string')) {
			return number;
		}
		let clearNumber = null;
		try {
			let result = '';
			clearNumber = this._clearNumber(number);
			const countryCode = this._getCountryCode(clearNumber);

			if (rawFormar) {
				if (countryCode) {
					const numberData = PHONE_UTIL.parseAndKeepRawInput(clearNumber, countryCode);
					if (callingCode) {
						if (clearNumber.startsWith('+490') && numberData.values_['1']?.toString() === '49') {
							result = `+490${numberData.values_['2']}`;
						} else {
							result = `+${numberData.values_['1']}${numberData.values_['2']}`;
						}
					} else {
						result = numberData.values_['2'];
					}

				} else {
					result = (/^\d+$/.test(clearNumber)) ? clearNumber : number;
					(!result) && console.warn('Invalid input for phone number');
				}

			} else if (!rawFormar) {
				if (countryCode) {
					const userInputErrors = this._userInputHasErrors(clearNumber, countryCode);
					if (!userInputErrors) {
						result = PHONE_UTIL
							.format(
								PHONE_UTIL.parse(clearNumber, countryCode),
								((callingCode) ? PNF.INTERNATIONAL : PNF.NATIONAL)
							)
							.replace(/ /g, ' ');
					} else {
						return userInputErrors;
					}

				} else {
					result = this._backupFormat(clearNumber, false);
				}
			}
			if (result && (typeof result === 'string')) {
				return result.toString().trim();
			} else {
				return result;
			}

		} catch (error) {
			console.error(error);
			return number;
		}
	}

	validate(number, min, max) {
		try {
			let result = '';
			let successParse = false;
			let possibleNumber = false;
			let validNumber = false;
			let shortNumber = false;

			let clearNumber = this._clearNumber(number);
			const backupNumber = clearNumber;
			let emergencyNumber = false;
			const countryCode = this._getCountryCode(clearNumber);

			const longNumber = (clearNumber && clearNumber.length > 15);
			if (countryCode) {

				if (countryCode !== '') {
					const userInputErrors = this._userInputHasErrors(clearNumber, countryCode);
					if (userInputErrors) {
						return false;
					}

					clearNumber = PHONE_UTIL.parse(clearNumber, countryCode);
					emergencyNumber = SHORT_INFO.connectsToEmergencyNumber(backupNumber, countryCode);
					successParse = true;
				}
			}

			if (countryCode && successParse) {
				possibleNumber = PHONE_UTIL.isPossibleNumber(clearNumber);
				validNumber = PHONE_UTIL.isValidNumberForRegion(clearNumber, countryCode);
				shortNumber = SHORT_INFO.isPossibleShortNumberForRegion(clearNumber, countryCode);
				result = ((possibleNumber && validNumber && !shortNumber && !longNumber) || emergencyNumber);

			} else {
				let backupValidation = true;
				if (!(/^\d+$/.test(backupNumber))) backupValidation = false;
				if (min && backupNumber.length < min) backupValidation = false;
				if (max && backupNumber.length > max) backupValidation = false;
				result = backupValidation;
			}

			return result;

		} catch (error) {
			console.error(error);
			return false;
		}
	}

	numberType(number) {
		if (!number || !number.length || (typeof number !== 'string')) {
			return number;
		}
		let clearNumber = null;
		clearNumber = this._clearNumber(number);
		const countryCode = this._getCountryCode(clearNumber);

		if (!countryCode) return false;

		const numberData = PHONE_UTIL.parseAndKeepRawInput(clearNumber, countryCode);
		const numberType = PHONE_UTIL.getNumberType(numberData);
		return numberType;
	}

	isMobile(number) {
		const type = this.numberType(number);
		return (type === libphonenumber.PhoneNumberType.MOBILE);
	}

	isFixedLine(number) {
		const type = this.numberType(number);
		return (type === libphonenumber.PhoneNumberType.FIXED_LINE);
	}
}
