<template>
	<div class="content-tabs">
		<div class="nav-tabs">
			<template v-for="(tab, visibleIndex) in visibleTabs">
				<div
					class="content-tab"
					:key="tab.id"
					:class="{ active: visibleIndex === currentVisibleTabIndex, disabled: isTabDisabled(visibleIndex) }"
					:data-cy="`${currentComponentId}__contentTab${visibleIndex}`"
					@click="onTabClick(visibleIndex, tab.id)"
				>
					{{ tab.label }}
					<i v-if="!$_.isEmpty(tab.tooltip)" class="icon-tooltip" v-tooltip="tab.tooltip"></i>
					<span v-if="tab.invalid">
						<i class="icon-circle" style="color: #f64; scale: 0.65;"></i>
					</span>
				</div>
			</template>
		</div>

		<div class="content" ref="content" :style="contentStyle">
			<slot
				:activated="activatedTabs"
				:currentVisibleTab="currentVisibleTab"
				:parentComponentId="`${currentComponentId}__slotDefault`"
			></slot>
		</div>

		<div class="arrow-navigation" v-if="arrows || nextPrevious">
			<slot name="arrows" :currentTab="currentVisibleTab" :parentComponentId="`${currentComponentId}__slotArrows`">
				<template v-if="arrows">
					<i
						v-if="currentVisibleTabIndex > 0"
						class="icon-arrow-left"
						:class="{ disabled: isPreviousDisabled(currentVisibleTabIndex) }"
						:data-cy="`${currentComponentId}__arrowLeft`"
						@click="onPreviousClick"
					></i>
					<i
						v-if="currentVisibleTabIndex < visibleTabs.length - 1"
						class="icon-arrow-right"
						:class="{ disabled: isNextDisabled(currentVisibleTabIndex) }"
						:data-cy="`${currentComponentId}__arrowRight`"
						@click="onNextClick"
					></i>
				</template>
				<template v-if="nextPrevious">
					<span
						v-if="currentVisibleTabIndex > 0"
						class="link-icon"
						v-tooltip="currentVisibleTab.tooltipPrevious"
						:class="{ disabled: isPreviousDisabled(currentVisibleTabIndex) }"
						:data-cy="`${currentComponentId}__previous`"
						@click="onPreviousClick"
					>
						<i class="icon-arrow-left"></i>
						<span>{{ currentVisibleTab && currentVisibleTab.previous ? currentVisibleTab.previous : $t('previous') }}</span>
					</span>
					<span
						v-if="currentVisibleTabIndex < visibleTabs.length - 1"
						class="link-icon"
						v-tooltip="currentVisibleTab.tooltipNext"
						:class="{ disabled: isNextDisabled(currentVisibleTabIndex) }"
						:data-cy="`${currentComponentId}__next`"
						@click="onNextClick"
					>
						<span>{{ currentVisibleTab && currentVisibleTab.next ? currentVisibleTab.next : $t('next') }}</span>
						<i class="icon-arrow-right"></i>
					</span>
				</template>
			</slot>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	model: {
		prop: 'tabs',
	},
	props: {
		id: {
			type: String,
			default: () => {
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				const length = 4;

				for (let i = 0; i < length; i += 1) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
				}
				return result;
			},
		},
		tabs: {
			type: Array,
			required: true,
		},
		defaultTab: {
			type: [String, Number],
			default: 0,
		},
		arrows: Boolean,
		nextPrevious: Boolean,
		containsGrid: Boolean,
		beforeTabSelected: Function,
		contentStyle: String,
	},
	data() {
		return {
			initialized: false,
			currentVisibleTabIndex: -1,
			activatedTabs: {},
		};
	},
	computed: {
		tabsElements() {
			return this.$refs.content.children;
		},
		currentTabElement() {
			return this.tabsElements[this.currentTabIndex];
		},
		visibleTabs() {
			return this.tabs.filter((tab) => tab.visible !== false);
		},
		currentVisibleTab() {
			return this.visibleTabs[this.currentVisibleTabIndex];
		},
		currentTabIndex() {
			if (this.currentVisibleTab) {
				return this.getTabIndex(this.currentVisibleTab.id);
			}
			return -1;
		},
	},
	created() {
		this.prepareVisibleTabIndex();
		this.$root.$listen('open-content-tab', this.openContentTab, this);
		this.$root.$listen('content-tabs.open-tab', this.onOpenContentTab, this);
	},
	mounted() {
		if (this.currentTabElement) {
			this.currentTabElement.classList.add('active');
		} else {
			const unWatchCurrentTabElement = this.$watch('currentTabElement', () => {
				if (this.currentTabElement) {
					this.currentTabElement.classList.add('active');
					unWatchCurrentTabElement();
				}
			});
		}
	},
	methods: {
		getVisibleTabIndex(id) {
			return this.visibleTabs.findIndex((tab) => tab.id === id);
		},
		getTabIndex(id) {
			return this.tabs.findIndex((tab) => tab.id === id);
		},
		onOpenContentTab(data) {
			if (data.contentTabsId === this.id) {
				this.selectTab(this.getVisibleTabIndex(data.id), data.id);
			}
		},
		openContentTab(id) {
			this.selectTab(this.getVisibleTabIndex(id), id);
		},
		prepareVisibleTabIndex() {
			this.currentVisibleTabIndex = this.visibleTabs.findIndex((tab) => tab.default);

			if (this.currentVisibleTabIndex === -1) {
				if (typeof (this.defaultTab) === 'string') {
					this.currentVisibleTabIndex = this.getVisibleTabIndex(this.defaultTab);
				} else {
					this.currentVisibleTabIndex = this.defaultTab;
				}
			}
			if (this.currentVisibleTab) {
				this.initialized = false;
				this.$set(this.activatedTabs, this.currentVisibleTab.id, true);
			}
		},
		removeActiveClass() {
			$_.forEach(this.tabsElements, (element) => {
				element.classList.remove('active');
			});
		},
		async selectTab(visibleIndex, id) {
			if (this.beforeTabSelected) {
				const result = await this.beforeTabSelected(this.currentVisibleTabIndex, visibleIndex, this.currentVisibleTab.id, id);
				if (result === false) return;
			}

			if (!this.isItemDisabled(visibleIndex)) {
				this.removeActiveClass();
				this.currentVisibleTabIndex = visibleIndex;
				this.currentTabElement.classList.add('active');
				this.$set(this.activatedTabs, this.currentVisibleTab.id, true);
				this.$emit('active-tab-changed', this.currentVisibleTab.id);

				if (this.containsGrid) {
					this.$root.$emit('grid-recalc-actions-offset');
				}
			} else {
				console.warn(
					'[LbaContentTabs](selectTab) tab is disabled/not visible/does not exist, visibleIndex:', visibleIndex, 'id:', id
				);
			}
		},

		onTabClick(visibleIndex, tabId) {
			if (!this.isTabDisabled(visibleIndex)) {
				this.selectTab(visibleIndex, tabId);
			}
		},
		onPreviousClick() {
			if (!this.isPreviousDisabled(this.currentVisibleTabIndex)) {
				this.selectTab(this.currentVisibleTabIndex - 1);
			}
		},
		onNextClick() {
			if (!this.isNextDisabled(this.currentVisibleTabIndex)) {
				this.selectTab(this.currentVisibleTabIndex + 1);
			}
		},

		isItemDisabled(visibleIndex) {
			const tab = this.visibleTabs[visibleIndex];
			if (tab) return tab.disabled === true;
			return true;
		},
		isTabDisabled(visibleIndex) {
			const tab = this.visibleTabs[visibleIndex];
			if (tab) return tab.disabledTab === true || this.isItemDisabled(visibleIndex);
			return true;
		},
		isPreviousDisabled(visibleIndex) {
			const tab = this.visibleTabs[visibleIndex];
			if (tab) return tab.disabledArrowPrevious === true || this.isItemDisabled(visibleIndex - 1);
			return true;
		},
		isNextDisabled(visibleIndex) {
			const tab = this.visibleTabs[visibleIndex];
			if (tab) return tab.disabledArrowNext === true || this.isItemDisabled(visibleIndex + 1);
			return true;
		},
	},
};
</script>
