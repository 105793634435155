function downloadFile(vm, url, fileName = null) {
	let cancelled = false;
	let firstCall = true;

	if (vm) {
		vm.$on('hook:beforeDestroy', () => {
			cancelled = true;
		});
	}

	const download = async () => {
		try {
			const response = await vm.$http.get(url, { responseType: 'blob' });

			if (response.status === 202) {
				if (firstCall && vm) {
					vm.$root.$emit('download-file.registered', url);
					firstCall = false;
				}
				if (!cancelled) {
					setTimeout(download, 500);
				}
				return;
			}

			if (vm) {
				vm.$root.$emit('download-file.ready', url);
			}

			if ($_.isEmpty(fileName)) {
				const contentDisposition = response.headers('Content-Disposition');
				fileName = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9);
			}

			const objectURL = URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.style.display = 'none';
			link.href = objectURL;
			link.download = fileName;
			// document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(objectURL);
		} catch (errorResponse) {
			vm.$root.$notify.warn(vm.$t('failedToDownloadFile', { fileName }));

			if (errorResponse.headers && errorResponse.headers('Content-Type').startsWith('application/json')) {
				const text = await errorResponse.data.text();
				const errorObject = JSON.parse(text);

				if (errorObject && errorObject.error) {
					console.error(errorObject.error);
				}
			}
		}
	};
	download();
}

export default {
	install(Vue) {
		Vue.prototype.$downloadFile = downloadFile;
	},
};
