<template>
	<div id="login" class="first-login">
		<div id="loginBox" >
			<div class="error-wrap" v-if="user.token_error">
				<h1>{{ $t('firstLoginErrors.tokenNotValid') }}</h1>
			</div>
			<div class="error-wrap" v-else-if="user.expired">
				<h1>{{ $t('firstLoginErrors.tokenNotActive') }}</h1>
			</div>
			<div class="error-wrap" v-else-if="user.used">
				<h1 v-html="$t('firstLoginErrors.tokenUsed')"></h1>
			</div>
			<ValidationObserver v-else ref="form">
				<div id="loginForm" v-on:submit.prevent="nextPage">
					<!-- Step 1 Login a password -->
					<template v-if="pageStatus === 0">
						<div class="first-login-form step-1">
							<h1>{{ appTitlePrefix }}&nbsp;{{ appTitleSuffix }}&nbsp;&dash;&nbsp;{{ $t('firstLogin.config') }}</h1>
							<p>{{ $t('firstLogin.config2', { app_name: `${appTitlePrefix}${appTitleSuffix}` }) }}&colon;</p>
							<div class="mb-5">
								<p
									class="note mx-auto"
									style="max-width: 650px;"
									v-html="$t('tfaMethods.wizard.infoOne', { app: `${appTitlePrefix}${appTitleSuffix}` })"
								></p>
							</div>
							<div class="my-auto">
								<div>
									<span class="formGroupOrder">1</span>
									<h2 class="step">{{ $t('firstLogin.firstStep') }}</h2>
								</div>
								<p><span class="silver">{{ $t('userLogin') }}</span>&nbsp;{{ user.name }}
									<span class="silver ml-4">{{ $t('emailCap') }}</span>&nbsp;{{ user.email }}
								</p>
								<ValidationProvider
									:name="$t('settings.user.password')"
									v-slot="{ invalid, errors }"
									vid="passOne"
									:rules="{
										required: true,
										regex: passwordRegex,
										notContains: (passwordRules && passwordRules.easy_to_guess) ? avoidedItems : [],
									}"
								>
									<s class="mb-3 alignLeft">
										<small>
											{{ $t('password') }}<i class="icon-tooltip" v-tooltip="passwordInfo"></i>
										</small>
										<lba-input-password
											v-model="user.password_one"
											name="passOne"
											:readable="false"
											writeable
											:parentComponentId="'first_login'"
											componentId="passwordOne"
											:class="{ 'lba-invalid': (invalid && passwordOneDirty) }"
											class="firstLoginInputPassword"
											@input="passOneErr = generatePasswordErrors2(user.password_one);"
											@change="passwordOneDirty = true;"
											:rules="passwordRules"
											autocomplete="new-password"
										></lba-input-password>
										<template v-if="invalid">
											<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
											<span
												v-for="(err, index) in passOneErr"
												:key="`pwe-${index}`"
												:data-cy="`${'first_login'}__password__error${errors.length + index}`"
												class="lba-messages"
											>{{ err }}</span>
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${'first_login'}__password__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s>
								</ValidationProvider>
								<br>
								<ValidationProvider
									:name="$t('passwordAgain')"
									v-slot="{ invalid, errors }"
									vid="passTwo"
									:rules="{
										required: true,
										regex: passwordRegex,
										notContains: (passwordRules && passwordRules.easy_to_guess) ? avoidedItems : [],
										confirmed: 'passOne'
									}"
								>
									<s class="alignLeft">
										<small>{{ $t('passwordAgain') }}</small>
										<lba-input-password
											v-model="user.password_two"
											name="passTwo"
											:readable="false"
											writeable
											:parentComponentId="'first_login'"
											componentId="passwordTwo"
											:class="{ 'lba-invalid': (invalid && passwordTwoDirty) }"
											class="firstLoginInputPassword"
											@input="passTwoErr = generatePasswordErrors2(user.password_two);"
											@change="passwordTwoDirty = true;"
											:showGenPasswd="false"
											:rules="passwordRules"
											autocomplete="new-password"
										></lba-input-password>
										<template v-if="invalid">
											<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
											<span
												v-for="(err, index) in passTwoErr"
												:key="`pwe-${index}`"
												:data-cy="`${'first_login'}__password__error${errors.length + index}`"
												class="lba-messages"
											>{{ err }}</span>
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${'first_login'}__password__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s>
								</ValidationProvider>
							</div>
						</div>
					</template>

					<!-- Step 2 SMS -->
					<template v-if="pageStatus > 0 && pageStatus < pages.length - 1 && pages[pageStatus].type === 'sms'">
						<div class="first-login-form step-2">
							<h1>{{ appTitlePrefix }}&nbsp;{{ appTitleSuffix }}&nbsp;&dash;&nbsp;{{ $t('firstLogin.config') }}</h1>
							<p v-html="$t(`tfaMethods.methodsInfo.sms`)"></p>
							<div class="my-auto">
								<div class="mt-5">
									<span class="formGroupOrder">{{ pageStatus + 1 }}</span>
									<h2 class="step">{{ pages[pageStatus].name }}</h2>
								</div>
								<!-- Ověření čísla -->
								<div class="mt-4">
									<ValidationProvider
										:name="$t('tfaMethods.wizard.phoneForAuth')"
										v-slot="{ invalid, errors }"
										:rules="{ required: true, regex: /^\+(420|421)\d{9}$/ }"
										:vid="`first_login__sms_number`"
									>
										<s class="alignLeft">
											<small>{{ $t(`tfaMethods.wizard.phoneForAuth`) }}</small>
											<div class="input-button">
												<input
													id="phoneNumber"
													type="text"
													autocomplete="new-password"
													:disabled="user.sms_tested"
													name="phone"
													v-model="user.phone_number"
													placeholder="+420666777888"
													:class="{ 'lba-invalid': phoneNumberDirty && invalid }"
													@input="phoneNumberDirty = true;"
												>
												<template v-if="invalid">
													<span
														v-for="(err, index) in errors"
														:key="index"
														class="lba-messages"
														style="left: 0; top: 50px;"
														:data-cy="`first_login__sms_number__error${index}`"
													>{{ err }}</span>
												</template>
												<button
													v-if="!sendFirstSMS" :disabled="user.sms_tested" @click="resend()"
													style="width: 85px"
												>
													{{ $t(`tfaMethods.wizard.send`) }}
												</button>
											</div>
										</s>
									</ValidationProvider>
								</div>
								<!-- Ověření SMS -->
								<div :key="smsKey">
									<s class="alignLeft mt-3">
										<small>{{ $t(`tfaMethods.sms.enterCode`) }}</small>
										<div class="input-button">
											<input
												type="text"
												v-model="user.sms_code"
												:disabled="user.sms_tested || user.tooMuchSms"
											>
											<button
												type="button"
												:disabled="user.sms_tested"
												@click="testSms()"
												style="width: 85px"
											>
												{{ $t(`tfaMethods.sms.authenticateSmsCode`) }}
											</button>
										</div>
									</s>
									<br>
									<div class="mt-3" v-if="!user.sms_tested">
										<a
											class="link-icon"
											:class="{ 'silver': waitingSMS || user.sms_tested }"
											@click="resend()"
										>
											<i class="icon-reload2 mr-2" :class="{ 'silver': waitingSMS || user.sms_tested }"></i>
											{{ $t(`tfaMethods.sms.sendAgain`) }} {{ waitingSMS ? ` (${waitingForSMS+1} s)` : '' }}
										</a>
									</div>
									<br>
									<s id="loginError" class="wide mt-4" v-if="user.sendError">
										<div>{{ $t(`tfaMethods.sms.sendError`) }}</div>
									</s>
									<s id="loginError" class="wide mt-4" v-else-if="user.wrong_sms">
										<div>{{ $t(`tfaMethods.sms.wrongCode`) }}</div>
									</s>
									<s id="loginError" class="wide mt-4" v-else-if="user.sms_expired">
										<div>{{ $t(`tfaMethods.sms.codeExpired`) }}</div>
									</s>
									<s id="loginError" class="wide mt-4" v-else-if="user.tooMuchSms">
										<div>{{ $t(`tfaMethods.sms.tooMuchSmsLong`) }}</div>
									</s>
									<s id="loginError" class="wide mt-4 success" v-if="user.sms_tested">
										<div>{{ $t(`tfaMethods.sms.phoneAuthenticated`) }}</div>
									</s>
								</div>
							</div>
						</div>
					</template>

					<!-- Step 3 Gauth -->
					<template v-if="pageStatus > 0 && pageStatus < pages.length - 1 && pages[pageStatus].type === 'gauth'">
						<div class="first-login-form step-3">
							<h1>{{ appTitlePrefix }}&nbsp;{{ appTitleSuffix }}&nbsp;&dash;&nbsp;{{ $t('firstLogin.config') }}</h1>
							<p v-html="$t('tfaMethods.methodsInfo.gauth')"></p>
							<div class="displayFlex center">
								<a
									href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
									target="_blank"
									style="margin-right: 15px"
								>
									<img src="/images/download-google.png" alt="Google Play (Android)">
								</a>
								<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605" target="_blank">
									<img src="/images/download-apple.png" alt="App Store (iOS)">
								</a>
							</div>
							<div class="my-auto">
								<div class="mt-5">
									<span class="formGroupOrder">{{ pageStatus + 1 }}</span>
									<h2 class="step">{{ pages[pageStatus].name }}</h2>
									<i @click="openGauthInfo" class="icon-tooltip top-5"></i>
								</div>
							</div>
							<div :key="gauthKey">
								<p v-html="$t(`tfaMethods.gauth.afterDownload`, { token: gauth.token })"></p>
								<svg class="qrcode" v-bind:viewBox="`0 0 ${gauth.qr.size} ${gauth.qr.size}`">
									<path v-bind:d="gauth.qr.path" />
								</svg>
								<p>{{ $t(`tfaMethods.gauth.insertToken`) }}</p>
								<s v-show="!user.gauth_tested" class="pr-0">
									<div class="input-button">
										<input
											type="text"
											name="code"
											v-model="user.gauth_code"
											@input="user.gauth_tested = null"
										/>
										<button
											type="button"
											name="certBtn"
											:disabled="user.gauth_tested"
											@click="testCode()"
										>
											{{ $t(`tfaMethods.gauth.authenticateCode`) }}
										</button>
									</div>
								</s>
								<s v-if="user.gauth_tested" id="loginError" class="wide mt-4 success">
									<div>{{ $t(`gAuthStatus.verified`) }}</div>
								</s>
								<s v-else-if="user.gauth_tested === false" id="loginError" class="wide mt-4">
									<div>{{ $t(`gAuthStatus.notVerified`) }}</div>
								</s>
							</div>
						</div>
						<lba-dialog-modal
							:title="$t('tfaMethods.methods.gauth')"
							:name="'gauthInfo'"
							modal
						>
							<div class="popup-body alignCenter" style="max-width: 500px;">
								<p v-html="$t('tfaMethods.wizard.infoTwo', { app: `${appTitlePrefix}${appTitleSuffix}` })"></p>
							</div>
							<div class="popup-footer">
								<button type="button" class="buttonInverse" v-lba-dialog-close="'gauthInfo'"
								>
									{{ $t('ok') }}
								</button>
							</div>
						</lba-dialog-modal>
					</template>

					<!-- Step 4 Finish -->
					<template v-if="pageStatus === (pages.length - 1)">
						<div class="first-login-form step-4">
							<h1>{{ appTitlePrefix }}&nbsp;{{ appTitleSuffix }}&nbsp;&dash;&nbsp;{{ $t('firstLogin.config') }}</h1>
							<div class="my-auto">
								<div class="mt-5 mb-4">
									<span class="formGroupOrder">4</span>
									<h2 class="step">{{ $t('firstLogin.lastStep') }}</h2>
								</div>
							</div>
							<svg class="checkmark-big" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
									<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
							</svg>
							<p class="mt-5">{{ $t('tfaMethods.wizard.success') }}</p>
							<div v-if="savedTfa.sms" class="strong">
								{{ this.$t('firstLogin.auth') + this.$t(`tfaMethods.methods.sms`) }}
							</div>
							<div v-if="savedTfa.gauth" class="strong">
								{{ this.$t('firstLogin.auth') + this.$t(`tfaMethods.methods.gauth`) }}
							</div>
							<button type="button" @click="goToLogin" class="buttonBig mt-4">
								{{ $t('tfaMethods.wizard.goToLogin') }}
							</button>
						</div>
					</template>
				</div>
			</ValidationObserver>
			<template v-if="!tokenError && pageStatus !== (pages.length - 1)">
				<br>
				<!-- dodělat logiku tlačítek -->
				<div class="first-login-buttons">
					<button
						:disabled="pageStatus === 0"
						type="button"
						class="buttonBig buttonInverse mr-3"
						@click="pageStatus > 0 ? pageStatus-- : null"
					>
						{{ $t('back') }}
					</button>
					<button
						v-if="pageStatus === pages.length - 2"
						type="button"
						class="buttonBig"
						@click="saveWizard"
					>
						{{ $t('save') }}
					</button>
					<button
						v-else
						type="button"
						class="buttonBig"
						@click="nextPage()"
					>
						{{ $t('continue') }}
					</button>
					<button
						:disabled="pageStatus === 0 || pageStatus === (pages.length - 2)"
						type="button"
						class="buttonBig buttonInverse ml-3"
						@click="nextPage(true)"
					>
						{{ $t('tfaMethods.wizard.skip') }}
					</button>
				</div>
			</template>
		</div>
		<!-- PROGRESS BAR -->
		<div class="login-progress-bar" v-if="!tokenError">
			<div
				v-for="(page, pageIndex) in pages"
				:key="`login_progress_bar_${pageIndex}`"
				class="login-step"
				:class="{
					'active': pageIndex === pageStatus,
					'completed': pageIndex > pageStatus,
				}"
			>
				<div class="step-counter">{{ pageIndex + 1 }}</div>
				<div class="step-name">{{ page.name}}</div>
			</div>
		</div>
		<div class="login-footer" v-html="footer"></div>
	</div>
</template>

<script>
import AppTitle from '../mixins/AppTitle';
import Password from '../mixins/Password';

export default {
	name: 'FirstLogin',
	mixins: [AppTitle, Password],
	data() {
		return {
			pageStatus: 0,
			user: {},
			serverSettings: $_.first(this.$root.servers),
			testingPassword: '',
			passwordErrorsId: $generateUID(),
			passOneErr: [],
			passTwoErr: [],
			avoidedItems: [],
			avoidedItemsWhole: {},
			passwordInfo: '',
			showLongInfo: false,
			sendFirstSMS: false,
			waitingSMS: false,
			gauth: {},
			waitingTimer: null,
			waitingForSMS: null,
			smsKey: this.$generateUID(),
			gauthKey: this.$generateUID(),
			passwordOneDirty: false,
			passwordTwoDirty: false,
			phoneNumberDirty: false,
			smsCodeDirty: false,
			pages: [],
			savedTfa: {},
		};
	},
	watch: {
	},
	computed: {
		footer() {
			const contacts = [];
			if (this.serverSettings && this.serverSettings.email_support) {
				if (this.serverSettings.email_support.includes('>')) {
					contacts.push(this.$t('tfaMethods.wizard.onEmail', { email: this.serverSettings.email_support.match(/[^<>]+(?=>)/g) }));
				} else {
					contacts.push(this.$t('tfaMethods.wizard.onEmail', { email: this.serverSettings.email_support }));
				}
			}
			if (this.serverSettings && this.serverSettings.phone_support) {
				contacts.push(this.$t('tfaMethods.wizard.onPhone', { phone: this.serverSettings.phone_support }));
			}
			return this.$t('tfaMethods.wizard.contact') + contacts.join(this.$t('tfaMethods.wizard.eventualy')) + '.';
		},
		tokenError() {
			return (this.user.token_error || this.user.expired || this.user.used);
		},
	},
	async created() {
		if (this.$root.blocked) {
			this.blocked = this.$root.blocked;
		}
		document.body.classList.add('loginPage');

		if (this.$user.user_name) {
			await this.$user.logout();
		}

		this.$root.$once('servers-loaded', this.fetch);
	},
	mounted() {
		document.body.classList.remove('ng-cloak');
	},
	methods: {
		async fetch() {
			await this.$nextTick();
			this.serverSettings = $_.first(this.$root.servers);

			this.pages.push({
				name: this.$t('firstLogin.firstStep'),
				type: 'start',
			});

			$_.forEach(this.serverSettings.tfa, (tfa) => {
				this.pages.push({
					name: this.$t('firstLogin.auth') + this.$t(`tfaMethods.methods.${tfa}`),
					type: tfa,
				});
			});

			this.pages.push({
				name: this.$t('firstLogin.lastStep'),
				type: 'end',
			});

			this.setAppTitle();

			if (this.$route?.query?.token) {
				const tokenCheck = await this.$http.post('lbadmin/check-first-login-token', { token: this.$route.query.token })
					.catch(() => { this.user.token_error = true; });

				this.user = tokenCheck.data || {};

				this.$i18n.locale = tokenCheck.data.lang || this.serverSettings.lang || 'cs';

				this.addAvoided('firstname', this.user.firstname, 'settings.user.firstname');
				this.addAvoided('lastname', this.user.lastname, 'settings.user.lastname');
				this.addAvoided('login', this.user.user_name, 'settings.user.login');
				this.addAvoided('email', this.user.email, 'settings.user.email');

				if (this.user.token_error || this.user.expired) return;

				const gauth = await this.$http.post('lbadmin/first-login-gauth', { role_uid: this.user.role_uid });
				this.gauth = gauth.data;
				this.user.gauth_secret = this.gauth.token;

			}

			if (!$_.isEmpty(this.serverSettings)) {
				const pass = [];
				const passTexts = [];
				let length = 0;
				let easy = false;
				$_.forEach(this.serverSettings.login_password_strength, (value, key) => {
					if (key === 'length' && value) {
						length = value;
					} else if (key === 'easy_to_guess' && value) {
						easy = true;
					} else if (value) {
						pass.push(this.$t(`passwordRules.${key}`));
					}
				});

				if (pass.length) {
					passTexts.push(`${this.$t('passwordRules.mustContains')} ${pass.join(', ')}`);
				}

				if (length) {
					passTexts.push(this.$t('passwordRules.length', { length }));
				}

				if (easy) {
					passTexts.push(this.$t('passwordRules.easy_to_guess'));
				}

				this.passwordInfo = `${this.$t('password')} ${passTexts.join(', ')}`;
			}
		},
		addAvoided(key, value, label) {
			if (!value) return;
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			$_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
		async nextPage(force = false) {
			if (this.pageStatus === 0) {
				const valid = await this.$refs.form.validate();
				if (!force && !valid) {
					this.passwordOneDirty = true;
					this.passwordTwoDirty = true;
					this.$notify.warn(this.$t('tfaMethods.wizard.passwordNotValidated'));
					return;
				}
				this.passwordOneDirty = false;
				this.passwordTwoDirty = false;
				this.pageStatus++;
			} else if (this.pageStatus > 0 && this.pageStatus < this.pages.length - 1 && this.pages[this.pageStatus].type === 'sms') {
				if (!force && (!this.user.phone_number || !this.user.sms_tested)) {
					this.phoneNumberDirty = true;
					this.$notify.warn(this.$t('tfaMethods.wizard.smsNotValidated'));
					return;
				}
				this.pageStatus++;
			} else if (this.pageStatus > 0 && this.pageStatus < this.pages.length - 1 && this.pages[this.pageStatus].type === 'gauth') {
				if (!force && (!this.user.gauth_code || !this.user.gauth_tested)) {
					this.$notify.warn(this.$t('tfaMethods.wizard.gauthNotValidated'));
					return;
				}
				this.pageStatus++;
			}
		},
		async testCode() {
			const gauth = await this.$http.post('lbadmin/first-login-gauth-test',
				{
					role_uid: this.user.role_uid,
					token: this.user.gauth_code,
					auth_secret: this.user.gauth_secret,
				});

			this.user.gauth_tested = (gauth && gauth.data && gauth.data.status === 'verified');
			this.gauthKey = this.$generateUID();
		},
		async resend() {
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}
			if (this.waitingSMS || this.user.sms_tested) return;
			this.sendFirstSMS = true;
			this.user.tooMuchSms = false;

			this.$http.post('lbadmin/first-login-sms-resend',
				{
					role_uid: this.user.role_uid,
					number: this.user.phone_number,
				})
				.then((data) => {
					this.user.wrong_sms = false;
					this.user.sendError = false;
					this.smsWaitingStart();
					this.$notify.success(this.$t('tfaMethods.wizard.smsSent'));
				}).catch((err) => {
					if (err && err.response && err.response.data && err.response.data.error &&
						err.response.data.error === 'tfaMethods.sms.tooMuchSms'
					) {
						this.user.tooMuchSms = true;
					} else if (err && err.response && err.response.data && err.response.data.error &&
						err.response.data.error === 'tfaMethods.sms.sendError'
					) {
						this.user.sendError = true;
					} else {
						this.sendFirstSMS = false;
					}
					this.smsKey = this.$generateUID();
				});
		},
		async testSms() {
			this.smsCodeDirty = true;
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}
			this.$http.post('lbadmin/first-login-sms-test',
				{
					role_uid: this.user.role_uid,
					code: this.user.sms_code,
				})
				.then((data) => {
					this.user.wrong_sms = false;
					this.user.sms_tested = true;
					this.smsCodeDirty = false;
					this.user.sms_expired = false;
					this.smsWaitingStart();
				}).catch((err) => {
					if (err && err.response && err.response.data && err.response.data.error &&
						err.response.data.error === 'tfaMethods.sms.codeExpired'
					) {
						this.user.sms_expired = true;
					} else {
						this.user.wrong_sms = true;
					}
					console.error(err);
					this.smsKey = this.$generateUID();
				});
		},
		smsWaitingStart() {
			this.waitingForSMS = 9;
			this.waitingSMS = true;
			this.waitingTimer = setInterval(() => {
				this.waitingForSMS--;
				if (this.waitingForSMS < 0) {
					this.waitingSMS = false;
					clearInterval(this.waitingTimer);
				}
			}, 1000);

		},
		saveWizard() {
			let valid = false;

			if ((this.user.phone_number && this.user.sms_tested) ||
				(this.user.gauth_code && this.user.gauth_tested)
			) {
				valid = (this.user.password_one === this.user.password_two);
			} else {
				this.$notify.warn(this.$t('firstLoginErrors.oneTfaRequired'));
			}

			if (!valid) {
				return;
			}

			this.savedTfa = {};

			const user = {
				role_uid: this.user.role_uid,
				password: this.user.password_one,
				token: this.$route.query.token,
				tfa: [],
			};

			if (this.user.phone_number && this.user.sms_tested) {
				user.tfa.push({
					type: 'sms',
					secret: this.user.phone_number,
				});
				this.savedTfa.sms = true;
			}

			if (this.user.gauth_code && this.user.gauth_tested) {
				user.tfa.push({
					type: 'gauth',
					secret: this.user.gauth_secret,
				});
				this.savedTfa.gauth = true;
			}

			this.$http.post('lbadmin/first-login-save', user)
				.then(() => { this.pageStatus = (this.pages.length - 1); });
		},
		goToLogin() {
			this.$routerWrap.push({ name: 'login' });
		},
		openGauthInfo() {
			// console.log('open dialog');
			this.$root.$emit('dialog-open', { name: 'gauthInfo' });
		},
	},
};
</script>
