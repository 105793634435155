<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<template v-if="isCallEnabled">
			<i
				v-if="isActionCallAllowed"
				class="icon-phone hover-pointer"
				@click="onAction('call')"
				v-tooltip="$t('crm.callNow')"
			></i>
			<i v-else class="icon-phone silver"></i>
		</template>
		<template v-if="!(options && options.hideNumber)">
			{{ phoneNumber }}
		</template>
		<span v-else v-tooltip="options && options.label" class="silver">*** *** ***</span>
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired || isRequiredGroup" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<fake-provider
			:ref="inputRef"
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			:data-required-recommended="requiredRecommended"
		>
			<div class="call-input">
				<div
					v-if="isCallEnabled"
					class="call-input-icon"
					:class="[
						{
							active: isActionCallAllowed && !invalid
						},
						options.iconPhoneClasses
					]"
					v-tooltip="isActionCallAllowed && !invalid ? $t('call') : $t('cantCall')"
					@click="onAction('call')"
				>
					<i
						class="icon-phone"
						:class="options.iconPhoneClasses"
					></i>
				</div>

				<div
					v-if="isSmsEnabled"
					class="call-input-icon"
					:class="{ active: isActionSmsAllowed && !invalid }"
					v-tooltip="isActionSmsAllowed && !invalid ? $t('sendSms') : $t('cantSendSms')"
					@click="onAction('sms')"
				>
					<i
						class="icon-sms"
					></i>
				</div>

				<div
					v-if="isWhatsAppEnabled"
					class="call-input-icon"
					:class="{ active: isActionWhatsAppAllowed && !invalid }"
					v-tooltip="isActionWhatsAppAllowed && !invalid ? $t('whatsapp') : $t('cantWhatsApp')"
					@click="onAction('whatsapp')"
				>
					<i
						class="icon-whatsapp"
					></i>
				</div>

				<input
					v-if="!(options && options.hideNumber) || options.isNew"
					:data-cy="`${currentComponentId}__inputPhone`"
					type="text"
					v-model="phoneNumber"
					v-bind="bindOptions()"
					:disabled="readOnly"
					class="showInvalidTooltip"
					:class="{
						'lba-invalid': (invalid && dirty),
						'showWarning': (showRecommendation),
					}"
					@input="onEvent('input', parseString, true)"
					@change="onEvent('change', parsePhoneNumber, true)"
				>
				<input
					v-else
					:data-cy="`${currentComponentId}__inputPhone`"
					type="text"
					:placeholder="($_.isEmpty(phoneNumber)) ? '' : '*** *** ***'"
					readonly
				>
			</div>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputPhoneNumber',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			phoneNumber: null,
			maxLineNumberLength: null,
			userKey: 0,
		};
	},
	computed: {
		plainPhoneNumber() {
			if (this.phoneNumber && typeof this.phoneNumber === 'string') {
				const number = this.phoneNumber.replace(/\s/g, '').trim();
				return (number.length) ? number : null;
			}
			return null;
		},
		isCallEnabled() {
			return this.options && this.options.call_enabled;
		},
		isSmsEnabled() {
			return this.options && this.options.sms_enabled;
		},
		isWhatsAppEnabled() {
			return this.options && this.options.whatsapp_enabled;
		},
		isActionAllowed() {
			return (
				this.userKey != null &&
				this.plainPhoneNumber &&
				(!$_.isEmpty(this.$user.extension) || !$_.isEmpty(this.$user.agent_uid))
			);
		},
		isActionCallAllowed() {
			return this.isActionAllowed && this.options.callAllowed;
		},
		isActionSmsAllowed() {
			return this.isActionAllowed && this.options.smsAllowed;
		},
		isActionWhatsAppAllowed() {
			return this.isActionAllowed && this.options.whatsAppAllowed;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		this.phoneNumber = this.data[this.key];
		this.parsePhoneNumber();
		this.options.title_hint = this.$t('phoneValidationHint');

		if (
			(!this.rules.phoneNumber || $_.isEmpty(this.rules.phoneNumber)) &&
			(!this.rules.lineNumber || $_.isEmpty(this.rules.lineNumber)) &&
			(!this.rules.phoneNumberOrLineNumber || $_.isEmpty(this.rules.phoneNumberOrLineNumber))
		) {
			this.rules.phoneNumberOrLineNumber = true;
		}
		this.maxLineNumberLength = this.rules?.lineNumber?.line?.max || this.rules?.phoneNumberOrlineNumber?.line?.max || 5;
		if (this.options.hideNumber && this.readOnly) {
			this.rules = {};
		}

		this.$root.$listen('user.updated', this.updateUserKey, this, true);
	},
	methods: {
		updateUserKey() {
			this.userKey += 1;
		},
		async onAction(type) {
			if (
				!this.isActionAllowed ||
				(type === 'call' && !this.isActionCallAllowed) ||
				(type === 'sms' && !this.isActionSmsAllowed) ||
				(type === 'whatsapp' && !this.isActionSmsAllowed)
			) {
				return;
			}

			let isValid = true;
			if (this.$refs?.[this.inputRef]?.$refs?.provider) {
				isValid = await this.$refs[this.inputRef].$refs.provider.validate();
			} else if (this.rules.phoneNumber) {
				isValid = this.$root.validatePhoneNumber(this.data[this.key], {});
			} else if (this.rules.lineNumber) {
				isValid = this.$root.validateLineNumber(this.data[this.key], {});
			} else if (this.rules.phoneNumberOrLineNumber) {
				isValid = this.$root.validatePhoneNumberOrLineNumber(this.data[this.key], {});
			}
			if (!isValid) {
				this.$notify.warn(this.$t('invalidPhoneNumberFormat'));
				return;
			}
			this.$emit('action', { type, number: this.plainPhoneNumber, data: this.options.actionData });
		},
		parseString() {
			if (!this.phoneNumber) {
				this.phoneNumber = null;
				this.data[this.key] = null;
			} else {
				this.data[this.key] = this.phoneNumber.trim().replaceAll(' ', '');
			}
		},
		parsePhoneNumber() {
			this.parseString();

			if (!this.data[this.key] || !this.phoneNumber) {
				return null;
			}
			const value = this.$phoneNumber.format(this.phoneNumber, true, true);
			if (value.length > this.maxLineNumberLength) {
				this.phoneNumber = this.$phoneNumber._backupFormat(value, false);
			} else {
				this.phoneNumber = value;
			}
			this.data[this.key] = value;
		},
	},
};
</script>
