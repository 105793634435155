export default {
	data() {
		return {
			dirty: false,
			inputDirty: false,
			inputDirtyForSave: false,
		};
	},
	created() {
		this.$root.$listen('entry.saved', this.onEntrySaved, this, true);
	},
	methods: {
		async setDirty(value) {
			// console.debug('[mixin.SaveEntry](setDirty)', value);
			this.dirty = value;
			this.setInputDirty(value);

			if (this.dirty && this.$refs?.form?.validate) {
				this.isValid = await this.$refs.form.validate();
			}
		},
		setInputDirty(value) {
			// console.debug('[mixin.SaveEntry](setInputDirty)', value);
			this.inputDirty = value;
			this.inputDirtyForSave = value;
		},
		listenContentOnSave() {
			this.$root.$listen('keydown-ctrl-s', (event) => {
				let canSave = false;
				if (this.validateCanSave) {
					canSave = this.validateCanSave;
				} else {
					canSave = !this.readOnly;
				}
				if (canSave && this.inputDirty && !event.solved) {
					const optionsElement = document.getElementById('options');
					// focus on side detail
					if (
						document.activeElement &&
						optionsElement &&
						optionsElement.contains(document.activeElement)
					) {
						return;
					}
					// fake event
					if (document.activeElement) {
						document.activeElement.blur();
					}
					if ($_.get(this.$refs, 'saveButton.onClick')) {
						this.$refs.saveButton.onClick({ stopPropagation: () => {} });
					} else if ($_.get(this.$refs, 'saveButton.click')) {
						this.$refs.saveButton.click();
					} else {
						console.error('[mixins.SaveEntry](listenContentOnSave) missing saveButton ref');
					}
					event.solved = true;
				}
			}, this);
		},
		listenOptionsOnSave() {
			this.$root.$listen('keydown-ctrl-s', (event) => {
				let canSave = false;
				if (this.validateCanSave) {
					canSave = this.validateCanSave;
				} else {
					canSave = !this.readOnly;
				}
				if (canSave && this.inputDirty && !event.solved) {
					const optionsElement = document.getElementById('options');
					// focus not on side detail
					if (
						!document.activeElement ||
						!optionsElement ||
						!optionsElement.contains(document.activeElement)
					) {
						return;
					}
					// fake event
					document.activeElement.blur();
					if ($_.get(this.$refs, 'saveButton.onClick')) {
						this.$refs.saveButton.onClick({ stopPropagation: () => {} });
					} else if ($_.get(this.$refs, 'saveButton.click')) {
						this.$refs.saveButton.click();
					} else if (this.save) {
						this.save();
					} else if (this.saveEntry) {
						this.saveEntry();
					} else {
						console.error('[mixins.SaveEntry](listenOptionsOnSave) missing saveButton ref');
					}
					event.solved = true;
				}
			}, this);
		},
		emitEntrySaved(uid = null, routeName = null) {
			if ($_.isEmpty(uid) && (!('getEntrySaveUid' in this) || typeof this.getEntrySaveUid !== 'function')) {
				console.error('[mixins.SaveEntry](emitEntrySaved) missing getEntrySaveUid function');
				return;
			}

			if ($_.isEmpty(routeName) && !('entryRouteName' in this)) {
				console.error('[mixins.SaveEntry](emitEntrySaved) missing entryRouteName property');
				return;
			}

			if (!('isNew' in this)) {
				console.error('[mixins.SaveEntry](emitEntrySaved) missing isNew property');
				return;
			}

			const entryUid = uid || this.getEntrySaveUid();

			if ($_.isEmpty(entryUid)) {
				console.debug('[mixins.SaveEntry](emitEntrySaved) missing entryUid');
				return;
			}

			this.$root.$emit('entry.saved', {
				uid: entryUid,
				routeName: routeName || this.entryRouteName,
				isNew: this.isNew,
			});
		},
		onEntrySaved(event) {
			if (!('getEntrySaveUid' in this) || typeof this.getEntrySaveUid !== 'function') {
				console.error('[mixins.SaveEntry](onEntrySaved) missing getEntrySaveUid function');
				return;
			}

			if (!('entryRouteName' in this)) {
				console.error('[mixins.SaveEntry](onEntrySaved) missing entryRouteName property');
				return;
			}

			// same browser & lbadmin tab
			if (event.source !== 'socket' && event.routeName === this.entryRouteName) {
				return;
			}

			// same entry uid
			if (event.uid === this.getEntrySaveUid()) {
				this.reloadingProposal = true;
			}
		},
	},
};
