<template>
	<div id="content" v-if="loaded">
		<div class="page-header">
			<h1>{{ $t('settings.settings') }}</h1>
			<div class="page-controls">
				<lba-button-save
					ref="saveButton"
					:id="saveButtonId"
					:inputDirty="inputDirtyForSave"
					name="save"
					data-cy="options__save__button"
					@click.stop="save"
				/>
				<span @click="tabReload" data-cy="options__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			id="pbx-options"
			@scroll="setScroll"
			tabindex="0"
			v-autofocus
		>
			<ValidationObserver ref="form">
				<form name="form" lba-size-class="{'contentWide': 700}">

					<lba-section
						:key="'general'"
						:title="$t('general.general')"
						slotType="form"
						:componentId="`general`"
						expanded
					>
						<s>
							<small>{{ $t('general.defaultLanguage') }}</small>
							<select
								name="defaultLanguage"
								v-model="options.default_language"
								:data-cy="`options__defaultLanguage__select`"
								:disabled="!configurable"
								@change="toChange('default_language');"
							>
								<option value="cs" :data-cy="`options__defaultLanguage__select__optionCs`">
									česky
								</option>
								<option value="en" :data-cy="`options__defaultLanguage__select__optionEn`">
									english
								</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_language_per_user"
									:data-cy="`options__allowLanguagePerUser__enabled__inputCheckbox`"
									name="allowLanguagePerUser"
									:disabled="!configurable"
									@change="toChange('allow_language_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('general.defaultTimezone') }}</small>
							<select name="defaultTimezone" v-model="options.default_timezone"
								:data-cy="`options__defaultTimezone__select`"
								:disabled="!configurable"
								@change="toChange('default_timezone')"
							>
								<option v-for="(tz, index) in timezoneList" :key="tz"
									:data-cy="`options__defaultTimezone__select__option${index}`"
								>{{ tz }}</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_timezone_per_user"
									:data-cy="`options__allowTimezonePerUser__enabled__inputCheckbox`"
									name="allowTimezonePerUser"
									:disabled="!configurable"
									@change="toChange('allow_timezone_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('general.defaultColorTheme') }}</small>
							<select name="defaultColorTheme" v-model="options.default_color_theme"
								:data-cy="`options__defaultColorTheme__select`"
								:disabled="!configurable"
								@change="toChange('default_color_theme')"
							>
								<option v-for="(theme, index) in themes" :key="`theme-${index}`"
									:data-cy="`options__defaultColorTheme__select__option${index}`"
									:value="theme.value"
								>{{ theme.label }}</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_color_theme_per_user"
									:data-cy="`options__allowColorThemePerUser__enabled__inputCheckbox`"
									name="allowColorThemePerUser"
									:disabled="!configurable"
									@change="toChange('allow_color_theme_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<br><br>
						<s>
							<small>{{ $t(`settings.anonymousAccess`) }}</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.anonymous_access"
									:data-cy="`options__anonymousAccess__inputCheckbox`"
									name="anonymousAccess"
									:disabled="!configurable"
									@change="toChange('anonymous_access')"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>
								{{ $t('settings.cookieMaxAge') }}
							</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.cookieMaxAge')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.cookie_max_age_base"
										:data-cy="`options__cookieMaxAgeBase__input`"
										name="cookieMaxAgeBase"
										:disabled="!configurable"
										@input="toChange('cookie_max_age')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__cookieMaxAgeBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="cookie_max_age_multiplier" v-model="options.cookie_max_age_multiplier"
									:data-cy="`options__cookieMaxAgeMultiplier__select`"
									:disabled="!configurable"
									@change="toChange('cookie_max_age')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__cookieMaxAgeMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
					</lba-section>
					<lba-section
						:title="$t('settings.login')"
						slotType="form"
						:componentId="`login`"
						expanded
					>
						<s>
							<small>
								{{ $t(`settings.loginBanEnabled`) }}
								<i class="icon-info" v-tooltip="$t(`settings.loginBanEnabledInfo`)"></i>
							</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.login_ban_enabled"
									:data-cy="`options__loginBanEnabled__inputCheckbox`"
									name="loginBanEnabled"
									:disabled="!configurable"
									@change="toChange('login_ban_enabled')"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>{{ $t('settings.loginBanAttempts') }}</small>
							<input
								type="number"
								v-model="options.login_ban_attempts"
								:data-cy="`options__loginBanAttempts__input`"
								name="loginBanAttempts"
								:disabled="!configurable || !options.login_ban_enabled"
								@input="toChange('login_ban_attempts')"
							>
						</s>
						<s>
							<small>{{ $t('settings.loginBanTimeout') }}</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.loginBanTimeout')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.login_ban_timeout_base"
										:data-cy="`options__loginBanTimeoutBase__input`"
										name="login_ban_timeout_base"
										:disabled="!configurable || !options.login_ban_enabled"
										@input="toChange('login_ban_timeout')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__loginBanTimeoutBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="login_ban_timeout_multiplier" v-model="options.login_ban_timeout_multiplier"
									:data-cy="`options__loginBanTimeoutMultiplier__select`"
									:disabled="!configurable || !options.login_ban_enabled"
									@change="toChange('login_ban_timeout')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__loginBanTimeoutMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
						<br><br>
						<s>
							<small>{{ $t(`settings.idleLogoutEnabled`) }}</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.idle_logout_enabled"
									:data-cy="`options__idleLogoutEnabled__inputCheckbox`"
									name="idleLogoutEnabled"
									:disabled="!configurable"
									@change="toChange('idle_logout_enabled')"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
						<s>
							<small>{{ $t('settings.idleLogoutTime') }}</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.idleLogoutTime')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.idle_logout_time_base"
										:data-cy="`options__idleLogoutTimeBase__input`"
										name="idle_logout_time_base"
										:disabled="!configurable || !options.idle_logout_enabled"
										@input="toChange('idle_logout_time')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__idleLogoutTimeBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="idle_logout_time_multiplier" v-model="options.idle_logout_time_multiplier"
									:data-cy="`options__idleLogoutTimeMultiplier__select`"
									:disabled="!configurable || !options.idle_logout_enabled"
									@change="toChange('idle_logout_time')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__idleLogoutTimeMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
						<br><br>
						<s>
							<small>{{ $t('settings.loginPasswordStrengrth') }}</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.lowercase"
									:data-cy="`options__loginPasswordStrengrth__lowercase__inputCheckbox`"
									name="lowercase"
									:disabled="!configurable"
									@change="password.dirty = true; setDirty(true);"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.lowercasePassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.uppercase"
									:data-cy="`options__loginPasswordStrengrth__uppercase__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; setDirty(true);"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.uppercasePassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.number"
									:data-cy="`options__loginPasswordStrengrth__number__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; setDirty(true);"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.numberPassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.special"
									:data-cy="`options__loginPasswordStrengrth__special__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; setDirty(true);"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.specialPassword`) }}
									<i class="icon-info" v-tooltip="$t(`settings.specialPasswordInfo`)"></i>
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('settings.passwordLength') }}</small>
							<ValidationProvider
									:name="$t('settings.passwordLength')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 5, numeric: true }"
									title=""
								>
								<input
									type="number"
									v-model="password.length"
									:data-cy="`options__loginPasswordStrengrth__passwordLength__input`"
									name="loginBanTimeout"
									:disabled="!configurable"
									@input="password.dirty = true; setInputDirty(true);"
									@change="setDirty(true);"
									:class="{ 'lba-invalid': invalid }"
									min="5"
								>
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`options__loginPasswordStrengrth__passwordLength__input__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</ValidationProvider>
						</s>
						<s class="fit">
							<small>
								{{ $t(`settings.easyToGuess`) }}
								<i class="icon-info" v-tooltip="$t(`settings.easyToGuessInfo`)"></i>
							</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.easy_to_guess"
									:data-cy="`options__loginPasswordStrengrth__easyToGuess__inputCheckbox`"
									name="easyToGuess"
									:disabled="!configurable"
									@change="password.dirty = true; setDirty(true);"
								>
								<i class="icon-ok"></i>
							</label>
						</s>
					</lba-section>
					<lba-section
						:title="$t('settings.customTitle')"
						slotType="form"
						:componentId="`customTitle`"
						expanded
					>
						<s>
							<small>{{ $t('settings.appTitlePrefix') }}</small>
							<input
								type="text"
								v-model="options.app_title_prefix"
								:data-cy="`options__appTitlePrefix__input`"
								name="appTitlePrefix"
								:disabled="!configurable"
								@input="toChange('app_title_prefix')"
								@change="setDirty(true);"
							>
						</s>
						<s>
							<small>{{ $t('settings.appTitleSuffix') }}</small>
							<input
								type="text"
								v-model="options.app_title_suffix"
								:data-cy="`options__appTitleSuffix__input`"
								name="appTitleSuffix"
								:disabled="!configurable"
								@input="toChange('app_title_suffix')"
								@change="setDirty(true);"
							>
						</s>
						<br>
						<s class="fit mt-3">
							<small>{{ $t('settings.preview') }}</small>
							<div class="title-preview">
								<span id="logoBig" v-if="isTitle">
									<span id="logoPrefix">{{ options.app_title_prefix }}</span>
									<span id="logoSuffix logoSuffix-0">{{ options.app_title_suffix }}</span>
								</span>
								<span id="logoBig" v-else>
									<span id="logoPrefix">LinuxBox</span>
									<span id="logoSuffix logoSuffix-0">Admin4</span>
								</span>
							</div>
						</s>
					</lba-section>
					<lba-section
						:title="$t('settings.appTitleLong')"
						slotType="form"
						:componentId="`customTitle`"
						expanded
					>
						<s>
							<small>{{ $t('settings.appTitleLongPrefix') }}</small>
							<input
								type="text"
								v-model="options.app_title_long_prefix"
								:data-cy="`options__appTitleLongPrefix__input`"
								name="appTitlePrefix"
								:disabled="!configurable"
								@input="toChange('app_title_long_prefix')"
								@change="setDirty(true);"
							>
						</s>
						<s>
							<small>{{ $t('settings.appTitleLongSuffix') }}</small>
							<input
								type="text"
								v-model="options.app_title_long_suffix"
								:data-cy="`options__appTitleLongSuffix__input`"
								name="appTitleLongSuffix"
								:disabled="!configurable"
								@input="toChange('app_title_long_suffix')"
								@change="setDirty(true);"
							>
						</s>
						<s>
							<small>{{ $t('settings.appTitleLongUnder') }}</small>
							<input
								type="text"
								v-model="options.app_title_long_under"
								:data-cy="`options__appTitleLongUnder__input`"
								name="appTitleLongUnder"
								:disabled="!configurable"
								@input="toChange('app_title_long_under')"
								@change="setDirty(true);"
							>
						</s>
						<br>
						<s class="fit mt-3">
							<small>{{ $t('settings.previewLong') }}</small>
							<div class="title-preview title-login">
								<span id="loginAppName" v-if="options.app_title_long_prefix || options.app_title_long_suffix">
									<span id="logoPrefix">{{ options.app_title_long_prefix }}</span>
									<span id="logoSuffix logoSuffix-0">{{ options.app_title_long_suffix }}</span>
									<template v-if="options.app_title_long_under">
										<h2>{{ options.app_title_long_under }}</h2>
									</template>
								</span>
								<span id="loginAppName" v-else-if="isTitle">
									<span id="logoPrefix">{{ options.app_title_prefix }}</span>
									<span id="logoSuffix logoSuffix-0">{{ options.app_title_suffix }}</span>
									<template v-if="options.app_title_long_under">
										<h2>{{ options.app_title_long_under }}</h2>
									</template>
								</span>
								<span id="loginAppName" v-else>
									<span id="logoPrefix">LinuxBox</span>
									<span id="logoSuffix logoSuffix-0">Admin4</span>
									<h2>{{ options.app_title_long_under }}</h2>
								</span>
							</div>
						</s>
					</lba-section>

					<lba-section
						:title="$t('settings.loginInfo')"
						slotType="form"
						:componentId="`login`"
						expanded
					>
						<s>
							<lba-switch-simple
								:data-cy="`options__showLoginInfo__switch`"
								:key="`showLoginInfo`"
								name="showLoginInfoSwitch"
								:label="$t('settings.loginInfoShow')"
								v-model="options.login_info_show"
								@change="toChange('login_info_show'); setDirty(true);"
							></lba-switch-simple>
						</s>
						<template v-if="options.login_info_show">
							<s class="size-0">
								<small>{{ $t('settings.loginInfoType') }}</small>
								<label class="checkbox radio">
									<input type="radio"
										v-model="options.login_info_type"
										:value="null"
										:disabled="!configurable"
										@change="toChange('login_info_type')"
									>
									<span class="checkmark"></span>
									<span class="label">{{ $t('loginInfoType.info') }}</span>
								</label>
								<label class="checkbox radio">
									<input type="radio"
										v-model="options.login_info_type"
										:value="'warning'"
										:disabled="!configurable"
										@change="toChange('login_info_type')"
									>
									<span class="checkmark"></span>
									<span class="label">{{ $t('loginInfoType.warning') }}</span>
								</label>
								<label class="checkbox radio">
									<input type="radio"
										v-model="options.login_info_type"
										:value="'danger'"
										:disabled="!configurable"
										@change="toChange('login_info_type')"
									>
									<span class="checkmark"></span>
									<span class="label">{{ $t('loginInfoType.danger') }}</span>
								</label>
							</s>
							<span>
								<custom-value
									:parentComponentId="`options__login_info_text`"
									:componentId="`login_info_text`"
									:disabled="!configurable"
									@input="toChange('login_info_text'); setDirty(true);"
									@change="setDirty(true);"
									:attribute="{
										options: {
											ui_type: 'ckeditor',
											class: 'size-6',
											default: null,
											label: $t(`settings.loginInfoText`),
										},
										key: 'login_info_text',
										data: options
									}"
								></custom-value>
							</span>
							<br>
							<s
								v-if="options.login_info_show && options.login_info_text && options.login_info_text.length"
								class="fit mt-3"
							>
								<small>{{ $t('settings.loginInfoPreview') }}</small>
								<div>
									<div
										class="login-info"
										:class="options.login_info_type || ''"
									>
										<i class="icon-circle-warning"></i>
										<span v-html="options.login_info_text || ''"></span>
									</div>
								</div>
							</s>
						</template>
					</lba-section>
					<!-- DEFAULT HOME PAGE -->
					<lba-section
						:title="$t('defaultHomePage.title')"
						slotType="form"
						:componentId="`defaultHomePage`"
						expanded
					>
						<s>
							<small>{{ $t('defaultHomePage.type') }}</small>
							<select
								name="defaultHomepage"
								v-model="options.default_homepage"
								:data-cy="`options__defaultHomepage__select`"
								:disabled="!configurable"
								@change="updateDefaultHomepage()"
							>
								<option v-for="(page, index) in defaultHomepageOptions" :key="`profile-${index}`"
									:data-cy="`options__defaultHomepage__select__option${index+1}`"
									:value="page.value"
								>{{ page.label }}</option>
							</select>
						</s>
						<s v-if="options.default_homepage === 'route'">
							<small>{{ $t('defaultHomePage.route') }}</small>
							<input
								type="text"
								v-model="options.default_homepage_route"
								:data-cy="`options__defaultHomepageRoute__input`"
								name="defaultHomepageRoute"
								@input="toChange('default_homepage_route')"
								@change="toChange('default_homepage_route')"
							>
						</s>
					</lba-section>
					<!-- AUTH METHODS ENABLING -->
					<lba-section
						:title="$t('authMethods.authMethods')"
						slotType="form"
						:componentId="`authMethods`"
						expanded
					>
						<table class="content-table mt-1">
							<thead>
								<tr>
									<th style="width: 40px;display: flex;justify-content: center;">
										{{ $t('settings.priority') }}
										<i class="icon-info" v-tooltip="$t('authMethods.importPriorityInfo')"></i>
									</th>
									<th style="width: 220px">{{ $t('authMethods.enableAuthMethod') }}</th>
									<th style="width: 220px">{{ $t('authMethods.enableImport') }}</th>
								</tr>
							</thead>
						</table>
						<div
							:key="'auth00'"
							style="display: flex; align-items: center;"
						>
							<i class="icon-draggable" style="color: #c5c5c5"></i>
							<b class="mr-4 ml-4">1</b>
							<label class="checkbox size-1">
								<input
									type="checkbox"
									name="lbadmin"
									checked
									disabled
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`authMethods.lbadmin`) }}
								</span>
							</label>
							<label
								class="checkbox"
							>
								<input
									type="checkbox"
									name="import-lbadmin"
									disabled
								>
								<i class="icon-ok"></i>
							</label>
						</div>
						<lba-dnd-list2
							:list.sync="auth_methods"
							@drag-start="dirty = true"
							@drag-end="authOrder()"
							@update:list="authOrder()"
							style="width: 100%;"
							:componentId="`authMethods`"
							:parentComponentId="`options`"
						>
							<template v-slot:list>
								<div
									v-for="(auth, index) in auth_methods"
									:key="'auth' + index"
									style="display: flex; align-items: center;"
								>
									<i class="icon-draggable" style="cursor: move;"></i>
									<b class="mr-4 ml-4">{{ auth.priority + 1 }}</b>
									<label
										class="checkbox size-1"
									>
										<input
											type="checkbox"
											v-model="auth.enabled"
											:data-cy="`options__auth-method__${auth.auth_method}__enabled__inputCheckbox`"
											:name="auth.auth_method"
											:disabled="!configurable"
											@change="enableAuth(auth.auth_method)"
										>
										<i class="icon-ok"></i>
										<span class="label">
											{{ $t(`authMethods.${auth.auth_method}`) }}
										</span>
									</label>
									<label
										class="checkbox"
									>
										<input
											type="checkbox"
											v-model="auth.import"
											:data-cy="`options__auth-method__${auth.auth_method}__import__inputCheckbox`"
											:name="`import-${auth.auth_method}`"
											:disabled="!configurable || !auth.enabled"
											@change="authChanged(auth.auth_method)"
										>
										<i class="icon-ok"></i>
									</label>
								</div>
							</template>
						</lba-dnd-list2>
					</lba-section>
					<!-- AUTH METHODS SETTINGS -->
					<!-- LDAP SETTINGS -->
					<lba-section
						v-if="ldap.enabled"
						:title="$t('authMethods.ldapConfig.ldapConfig')"
						slotType="form"
						:componentId="`ldapConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.ldapConfig.url') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__url__input`"
								type="text"
								name="ldap_url"
								@input="dirty = true"
								v-model="ldap.configuration.url"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.base') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__base__input`"
								type="text"
								name="ldap_base"
								@input="dirty = true"
								v-model="ldap.configuration.base"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.search') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__search__input`"
								type="text"
								name="ldap_search"
								@input="dirty = true"
								v-model="ldap.configuration.search"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.ldapConfig.email') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__attrs-email__input`"
								type="text"
								name="ldap_attrs_email"
								@input="dirty = true"
								v-model="ldap.configuration.attrs.email"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.firstName') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__attrs-firstName__input`"
								type="text"
								name="ldap_attrs_email"
								@input="dirty = true"
								v-model="ldap.configuration.attrs.firstname"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.lastName') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__attrs-lastName__input`"
								type="text"
								name="ldap_attrs_email"
								@input="dirty = true"
								v-model="ldap.configuration.attrs.lastname"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.phone') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__attrs-phone__input`"
								type="text"
								name="ldap_attrs_phone"
								@input="dirty = true"
								v-model="ldap.configuration.attrs.phone"
								:disabled="!configurable"
								@change="updateLdap()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.ldapConfig.defaultProfile') }}</small>
							<select
								name="defaultLdapProfile"
								v-model="ldap.profile"
								:data-cy="`options__defaultLdapProfile__select`"
								:disabled="!configurable"
								@change="updateLdap()"
							>
								<option
									:data-cy="`options__defaultLdapProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultLdapProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<!-- AD SETTINGS -->
					<lba-section
						v-if="ad.enabled"
						:title="$t('authMethods.adConfig.adConfig')"
						slotType="form"
						:componentId="`activeDirectoryConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.adConfig.url') }}</small>
							<input
								:data-cy="`options__adConfiguration__url__input`"
								type="text"
								name="ad_url"
								@input="dirty = true"
								v-model="ad.configuration.url"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.base') }}</small>
							<input
								:data-cy="`options__adConfiguration__base__input`"
								type="text"
								name="ad_base"
								@input="dirty = true"
								v-model="ad.configuration.base"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.search') }}</small>
							<input
								:data-cy="`options__adConfiguration__search__input`"
								type="text"
								name="ad_search"
								@input="dirty = true"
								v-model="ad.configuration.search"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.ca') }}</small>
							<input
								:data-cy="`options__adConfiguration__ca__input`"
								type="text"
								name="ad_ca"
								@input="dirty = true"
								v-model="ad.configuration.ca"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.login') }}</small>
							<input
								:data-cy="`options__adConfiguration__login__input`"
								type="text"
								name="ad_login"
								@input="dirty = true"
								v-model="ad.configuration.login"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.password') }}</small>
							<lba-input-password
								:parentComponentId="`options`"
								componentId="ad-password"
								v-model="ad.configuration.password"
								:hasPassword="ad.configuration.t_password"
								:isPasswordReadable="false"
								:writeable="configurable"
								:readable="configurable"
								@input="dirty = true"
								@change="updateAd()"
							></lba-input-password>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.email') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-email__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.email"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.firstName') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-firstName__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.firstname"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.lastName') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-lastName__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.lastname"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.phone') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-phone__input`"
								type="text"
								name="ad_attrs_phone"
								@input="dirty = true"
								v-model="ad.configuration.attrs.phone"
								:disabled="!configurable"
								@change="updateAd()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.defaultProfile') }}</small>
							<select
								name="defaultAdProfile"
								v-model="ad.profile"
								:data-cy="`options__defaultAdProfile__select`"
								:disabled="!configurable"
								@change="updateAd()"
							>
								<option
									:data-cy="`options__defaultAdProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultAdProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<lba-section
						v-if="pam.enabled"
						:title="$t('authMethods.pamConfig.pamConfig')"
						slotType="form"
						:componentId="`pamConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.pamConfig.defaultProfile') }}</small>
							<select
								name="defaultPamProfile"
								v-model="pam.profile"
								:data-cy="`options__defaultLdapProfile__select`"
								:disabled="!configurable"
								@change="updatePam()"
							>
								<option
									:data-cy="`options__defaultPamProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultPamProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<!-- 2FA METHODS ENABLING -->
					<lba-section
						v-if="tfa_methods.length > 0"
						:title="$t('tfaMethods.tfaMethods')"
						slotType="form"
						:componentId="`tfaMethods`"
						expanded
					>
						<s class="two">
							<small>
								{{ $t('tfaMethods.tfaRequired') }}
							</small>
							<label class="checkbox radio">
								<input type="radio"
									v-model="options.tfa_required_global"
									:value="false"
									:disabled="!configurable"
									@change="toChange('tfa_required_global')"
								>
								<span class="checkmark"></span>
								<span class="label">{{ $t('tfaMethods.tfaRequiredNo') }}</span>
							</label>
							<label class="checkbox radio">
								<input type="radio"
									v-model="options.tfa_required_global"
									:value="'forced'"
									:disabled="!configurable"
									@change="toChange('tfa_required_global')"
								>
								<span class="checkmark"></span>
								<span class="label">{{ $t('tfaMethods.tfaRequiredYesAfterLogin') }}</span>
							</label>
							<label class="checkbox radio">
								<input type="radio"
									v-model="options.tfa_required_global"
									:value="'noLogin'"
									:disabled="!configurable"
									@change="toChange('tfa_required_global')"
								>
								<span class="checkmark"></span>
								<span class="label">{{ $t('tfaMethods.tfaRequiredYes') }}</span>
							</label>
						</s>
						<br>
						<s class="wide">
							<small>
								{{ $t(`tfaMethods.noLoginWithoutTfaMessage`) }}
								<i class="icon-tooltip" v-tooltip="$t('tfaMethods.noLoginWithoutTfaMessageInfo')"></i>
							</small>
							<input
								:data-cy="`options__no_login_without_tfa_message__input`"
								type="text"
								name="wizard_target_URL"
								v-model="options.no_login_without_tfa_message"
								@input="toChange('no_login_without_tfa_message')"
								:disabled="!configurable || options.tfa_required_global !== 'noLogin'"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('tfaMethods.tfaMethods') }}</small>
							<label
								v-for="(auth, index) in tfa_methods"
								:key="`tfa-${index}`"
								class="checkbox mr-3"
							>
								<input
									type="checkbox"
									v-model="auth.enabled"
									:data-cy="`options__2fa-method__${auth.tfa_method}__enabled__inputCheckbox`"
									:name="auth.tfa_method"
									:disabled="!configurable"
									@change="enableTfa(auth.tfa_method)"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`tfaMethods.methods.${auth.tfa_method}`) }}
								</span>
							</label>
						</s>
					</lba-section>
					<!-- 2FA WIZARD SETTINGS -->
					<lba-section
						:title="$t(`tfaMethods.wizard.wizard`)"
						slotType="form"
						:componentId="`tfaWizard`"
						expanded
					>
						<s>
							<small>
								{{ $t(`tfaMethods.wizard.targetUrl`) }}
								<i class="icon-tooltip" v-tooltip="$t('tfaMethods.wizard.targetUrlInfo')"></i>
							</small>
							<input
								:data-cy="`options__2fa_wizard__target_url__input`"
								type="text"
								name="wizard_target_URL"
								@input="setDirty(true); wizard.dirty = true;"
								v-model="wizard.target_url"
								:disabled="!configurable"
							/>
						</s>
						<s>
							<small>{{ $t(`tfaMethods.wizard.mailFrom`) }}</small>
							<input
								:data-cy="`options__2fa_wizard__email_from__input`"
								type="text"
								name="wizard_email_from"
								@input="setDirty(true); wizard.dirty = true;"
								v-model="wizard.email_from"
								:disabled="!configurable"
							/>
						</s>
						<s class="">
							<small>
								{{ $t(`tfaMethods.wizard.mailSupport`) }}
								<i class="icon-info" v-tooltip="$t(`tfaMethods.wizard.mailSupportInfo`)"></i>
							</small>
							<input
								:data-cy="`options__2fa_wizard__email_support__input`"
								type="text"
								name="wizard_email_support"
								@input="setDirty(true); wizard.dirty = true;"
								v-model="wizard.email_support"
								:disabled="!configurable"
							/>
						</s>
						<s class="">
							<small>
								{{ $t(`tfaMethods.wizard.phoneSupport`) }}
								<i class="icon-info" v-tooltip="$t(`tfaMethods.wizard.phoneSupportInfo`)"></i>
							</small>
							<input
								:data-cy="`options__2fa_wizard__phone_support__input`"
								type="text"
								name="wizard_phone_support"
								@input="setDirty(true); wizard.dirty = true;"
								v-model="wizard.phone_support"
								:disabled="!configurable"
							/>
						</s>
						<s>
							<small>
								{{ $t('tfaMethods.wizard.mailValidity') }}
								<i class="icon-info" v-tooltip="$t(`tfaMethods.wizard.mailValidityInfo`)"></i>
							</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('tfaMethods.wizard.mailValidity')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="wizard.email_valid_base"
										:data-cy="`options__2fa_wizard__email_valid_base__input`"
										name="cookieMaxAgeBase"
										:disabled="!configurable"
										@input="setDirty(true); wizard.dirty = true;"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__2fa_wizard__email_valid_base__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="cookie_max_age_multiplier" v-model="wizard.email_valid_multiplier"
									:data-cy="`options__2fa_wizard__email_valid_multiplier__select`"
									:disabled="!configurable"
									@change="setDirty(true); wizard.dirty = true;"
								>
									<option v-for="(mp, index) in days_multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__2fa_wizard__email_valid_multiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
						<s class="wide">
							<small>{{ $t(`tfaMethods.wizard.mailSubject`) }}</small>
							<input
								:data-cy="`options__2fa_wizard__email_subject__input`"
								type="text"
								name="wizard_email_subject"
								@input="setDirty(true); wizard.dirty = true;"
								v-model="wizard.email_subject"
								:disabled="!configurable"
							/>
						</s>
						<custom-value
							:parentComponentId="`options__2fa_wizard`"
							:componentId="`mail_text`"
							@input="setDirty(true); wizard.dirty = true;"
							:attribute="{
								options: {
									ui_type: 'ckeditor',
									class: 'size-6',
									default: null,
									label: $t(`tfaMethods.wizard.mailText`),
									title_hint: $t(`tfaMethods.wizard.mailTextInfo`)
								},
								key: 'mail_text',
								data: wizard
							}"
						></custom-value>
					</lba-section>
					<!-- 2FA METHODS SETTINGS -->
					<!-- SMS SETTINGS -->
					<lba-section
						v-if="sms.enabled"
						:title="$t(`tfaMethods.methods.sms`)"
						slotType="form"
						:componentId="`smsConfig`"
						expanded
					>
						<!-- GLOBAL SMS settings -->
						<s class="wide">
							<small>
								{{ $t(`tfaMethods.sms.smsText`) }}
								<i class="icon-info" v-tooltip="$t(`tfaMethods.sms.smsTextInfo`)"></i>
							</small>
							<ValidationProvider
								:name="$t(`tfaMethods.sms.smsText`)"
								v-slot="{ invalid, errors }"
								:rules="{ required: true, regex: /\{code\}/ }"
								vid="options__sms-configuration__sms_text"
							>
								<input
									:data-cy="`options__sms-configuration__sms_text__input`"
									type="text"
									name="options__sms-configuration__sms_text"
									@input="setDirty(true);"
									v-model="sms.configuration.sms_text"
									:disabled="!configurable"
									@change="updateSms();"
									:class="{ 'lba-invalid': invalid }"
									placeholder="${code}"

								/>
								<template v-if="invalid && dirty">
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__sms-configuration__sms_text__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</template>
							</ValidationProvider>
						</s>
						<s>
							<small>
								{{ $t(`tfaMethods.sms.smsCodeLength`) }}
							</small>
							<ValidationProvider
								:name="$t(`tfaMethods.sms.smsCodeLength`)"
								v-slot="{ invalid, errors }"
								:rules="{ min_value: 4, max_value: 24 }"
								vid="options__sms-configuration__sms_code_length"
							>
								<input
									:data-cy="`options__sms-configuration__sms_code_length__input`"
									type="number"
									name="options__sms-configuration__sms_code_length"
									@input="dirty = true"
									v-model="sms.configuration.sms_code_length"
									:disabled="!configurable"
									@change="updateSms()"
									:class="{ 'lba-invalid': invalid }"
									min="4"
									max="24"
								/>
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`options__sms-configuration__sms_code_length__input__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</ValidationProvider>
						</s>
						<s>
							<small>
								{{ $t(`tfaMethods.sms.smsCodeValidity`) }}
							</small>
							<ValidationProvider
								:name="$t(`tfaMethods.sms.smsCodeValidity`)"
								v-slot="{ invalid, errors }"
								:rules="{ min_value: 5 }"
								vid="options__sms-configuration__sms_valid"
							>
								<input
									:data-cy="`options__sms-configuration__sms_valid__input`"
									type="number"
									name="options__sms-configuration__sms_valid"
									@input="dirty = true"
									v-model="sms.configuration.sms_valid"
									:disabled="!configurable"
									@change="updateSms()"
									:class="{ 'lba-invalid': invalid }"
									min="5"
								/>
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`options__sms-configuration__sms_valid__input__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</ValidationProvider>
						</s>
						<s class="wide mt-5">
							<small>
								{{ $t(`tfaMethods.sms.sendSequence`) }}
								<i class="icon-info" v-tooltip="$t(`tfaMethods.sms.sendSequenceInfo`)"></i>
								</small>
							<lba-dnd-list2
								:list.sync="sms.configuration.configurations"
								@drag-end="setDirty(true); updateSms();"
								@update:list="setDirty(true); updateSms();"
								notDraggable
								:parentComponentId="'options__sms-configuration'"
								componentId="configurations"
								:appendComponentName="false"
							>
								<template v-slot:list>
									<div
										class="formGroup"
										v-for="(conf, confIndex) in sms.configuration.configurations"
										:key="`configuration${confIndex}`"
										style="width: 100%"
										:id="`configuration-${confIndex}`"
										:data-cy="`options__sms-configuration__configurations__config${confIndex}`"
									>
										<div class="formGroupHeader">
											<span class="formGroupLabel" style="display: initial">
												<span class="formGroupOrder">{{ confIndex + 1 }}</span>
												<span>{{ $t("tfaMethods.sms.configuration") }}</span>
											</span>
											<span class="formGroupControls" v-show="configurable">
												<a
													:data-cy="`options__sms-configuration__configurations__config${confIndex}__move_up`"
													v-show="confIndex > 0"
													:title="$t('moveUp')"
													lba-dnd-list-up>
													<i class="icon-move-up"></i>
												</a>
												<span
													v-show="confIndex == 0">
													<i style="color: #1111;" class="icon-move-up"></i>
												</span>
												<a
													:data-cy="`options__sms-configuration__configurations__config${confIndex}__move_down`"
													v-show="confIndex < sms.configuration.configurations.length - 1"
													:title="$t('moveDown')"
													lba-dnd-list-down>
													<i class="icon-move-down"></i>
												</a>
												<span
													v-show="confIndex == sms.configuration.configurations.length - 1">
													<i style="color: #1111;" class="icon-move-down"></i>
												</span>
												<a
													v-if="sms.configuration.configurations.length > 1"
													:title="$t('pbx.ivr.remove')"
													v-on:click="confDel(confIndex)"
													:data-cy="`options__sms-configuration__configurations__config${confIndex}__remove`"
												>
													<i class="icon-cancel"></i>
												</a>
												<span v-else>
													<i style="color: #1111;" class="icon-cancel"></i>
												</span>
											</span>
										</div>

										<s
											class="two"
											:data-cy="`options__sms-configuration__configurations__config${confIndex}__description`"
										>
											<small>{{ $t("tfaMethods.sms.description") }}</small>
											<input
												:disabled="!configurable"
												type="text"
												v-model="conf.description"
												@input="updateSms();"
												:data-cy="`options__sms-configuration__configurations__config${
													confIndex}__description__input`"
											/>
										</s>
										<s>
											<small>
												{{ $t(`tfaMethods.sms.confEnabled`) }}
												<i class="icon-info" v-tooltip="$t(`tfaMethods.sms.confEnabledInfo`)"></i>
											</small>
											<label class="checkbox">
												<input
													type="checkbox"
													v-model="conf.enabled"
													:data-cy="`options__sms-configuration__configurations__config${
														confIndex
													}_enabled__inputCheckbox`"
													:name="`config${confIndex}_enabled`"
													:disabled="!configurable"
													@change="updateSms()"
												>
												<i class="icon-ok"></i>
											</label>
										</s>
										<br>
										<s class="mb-3" :data-cy="`options__sms-configuration__configurations__config${confIndex}__type`">
											<small>{{ $t("tfaMethods.sms.type") }}</small>
											<select
												@change="updateSms();"
												v-model="conf.type"
												:disabled="!configurable"
												:data-cy="`options__sms-configuration__configurations__config${confIndex}__type__select`"
											>
												<option
													:value="'sms-ucp'"
													:data-cy="`options__sms-configuration__configurations__config${confIndex
														}__type__select__option0`"
												>
													{{ $t('tfaMethods.methods.sms-ucp') }}
												</option>
												<option
													:value="'ipsms'"
													:data-cy="`options__sms-configuration__configurations__config${confIndex
														}__type__select__option1`"
												>
													{{ $t('tfaMethods.methods.ipsms') }}
												</option>
											</select>
										</s>
										<br>
										<!-- SMS-UCP settings -->
										<s v-if="conf.type === 'sms-ucp'" class="wide">
											<small>
												{{ $t(`tfaMethods.sms.configString`) }}
												<span class="required-sign"></span>
											</small>
											<ValidationProvider
												:name="$t(`tfaMethods.sms.configString`)"
												v-slot="{ invalid, errors }"
												:rules="{ required: true }"
												:vid="`options__sms-configuration__configurations__config${confIndex}__config_string`"
												title=""
											>
												<input
													:data-cy="`options__sms-configuration__config-string__input`"
													type="text"
													name="sms-config-string"
													@input="dirty = true"
													v-model="conf.config_string"
													:disabled="!configurable"
													@change="updateSms()"
													placeholder="<jmeno>:<heslo>@<URL>:<port>|<oadc>:<otoa>"
													:class="{ 'lba-invalid': dirty && invalid }"
												/>
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`options__sms-configuration__config-string__input__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</ValidationProvider>
										</s>
										<!-- IPSMS settings -->
										<template v-if="conf.type === 'ipsms'">
											<s class="wide">
												<small>
													{{ $t(`tfaMethods.sms.url`) }}
													<span class="required-sign"></span>
												</small>
												<ValidationProvider
													:name="$t(`tfaMethods.sms.url`)"
													v-slot="{ invalid, errors }"
													:rules="{ required: true }"
													:vid="`options__sms-configuration__configurations__config${confIndex}__url`"
													title=""
												>
													<input
														:data-cy="`options__sms-configuration__url__input`"
														type="text"
														name="sms-url"
														@input="dirty = true"
														v-model="conf.url"
														:disabled="!configurable"
														@change="updateSms()"
														placeholder="https://www..."
														:class="{ 'lba-invalid': dirty && invalid }"
													/>
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`options__sms-configuration__url__input__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</ValidationProvider>
											</s>
											<s class="two">
												<small>
													{{ $t(`tfaMethods.sms.baID`) }}
													<i class="icon-info" v-tooltip="$t(`tfaMethods.sms.baIDInfo`)"></i>
													<span class="required-sign"></span>
												</small>
												<ValidationProvider
													:name="$t(`tfaMethods.sms.baID`)"
													v-slot="{ invalid, errors }"
													:rules="{ required: true }"
													:vid="`options__sms-configuration__configurations__config${confIndex}__baID`"
													title=""
												>
													<input
														:data-cy="`options__sms-configuration__baID__input`"
														type="text"
														name="sms-baID"
														@input="dirty = true"
														v-model="conf.baid"
														:disabled="!configurable"
														@change="updateSms()"
														:placeholder="`2FA-${confIndex}`"
														:class="{ 'lba-invalid': dirty && invalid }"
													/>
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`options__sms-configuration__baID__input__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</ValidationProvider>
											</s>
											<s class="two">
												<small>
													{{ $t(`tfaMethods.sms.connTimeout`) }}
													<span class="required-sign"></span>
												</small>
												<ValidationProvider
													:name="$t(`tfaMethods.sms.connTimeout`)"
													v-slot="{ invalid, errors }"
													:rules="{ required: true }"
													:vid="`options__sms-configuration__configurations__config${confIndex}__request_timeout`"
													title=""
												>
													<input
														:data-cy="`options__sms-configuration__request_timeout__input`"
														type="text"
														name="sms-request_timeout"
														@input="dirty = true"
														v-model="conf.request_timeout"
														:disabled="!configurable"
														@change="updateSms()"
														:placeholder="`30000`"
														:class="{ 'lba-invalid': dirty && invalid }"
													/>
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`options__sms-configuration__request_timeout__input__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</ValidationProvider>
											</s>
											<ValidationProvider
												:disabled="conf.cert_pem_file !== null"
												:name="$t('tfaMethods.sms.pemFile')"
												:rules="{ required: true }"
												v-slot="{ validate, invalid, errors }"
											>
												<s
													:data-cy="`options__sms-configuration__config${confIndex}__cert_pem_file`"
													class="size-3"
												>
													<small>
														{{ $t('tfaMethods.sms.pemFile') }}
														<span class="required-sign"></span>
													</small>
													<div class="lba-input-file">
														<input
															:key="confIndex"
															class="inputfile"
															type="file"
															:name="`options__sms-configuration__config${confIndex}__cert_pem_file`"
															accept=".pem"
															:id="`options__sms-configuration__config${confIndex}__cert_pem_file`"
															@change="onFileChange(conf, $event, validate); updateSms();"
															:disabled="!configurable"
														/>
														<label
															:for="`options__sms-configuration__config${confIndex}__cert_pem_file`"
															:data-cy="`options__sms-configuration__config${confIndex
																}__cert_pem_file__input`"
															class="input"
															:class="{ 'lba-invalid': dirty && invalid }"
														>
															<i class="icon-upload"></i>
															<span>{{ conf.cert_pem_file || $t('chooseFile').toUpperCase() }}</span>
														</label>
														<template v-if="dirty">
															<span v-for="(err, index) in errors" :key="index" class="lba-messages"
																:data-cy="`options__sms-configuration__config${confIndex
																	}__cert_pem_file__input__error${index}`"
															>{{ err }}</span>
														</template>
													</div>
												</s>
											</ValidationProvider>
										</template>
									</div>
								</template>
							</lba-dnd-list2>
						</s>

						<div class="formGroup" v-if="configurable">
							<div class="formGroupHeader">
								<span class="formGroupLabel">
									<span class="formGroupOrder">{{ sms.configuration.configurations.length + 1 }}</span>
									<span>{{ $t("tfaMethods.sms.newConfiguration") }}</span>
								</span>
							</div>
							<s>
								<small>{{ $t('tfaMethods.sms.configuration') }}</small>
								<div
									class="formGroupNew" v-on:click="confAdd()"
									:data-cy="`options__sms-configuration__configurations__addConfiguration`"
								>+</div>
							</s>
						</div>
					</lba-section>
					<lba-section
						:title="$t('settings.grid')"
						slotType="form"
						:componentId="`grid`"
						expanded
					>
						<s class="size-2">
							<small>{{ $t('settings.columnTooltip') }}</small>
							<lba-switch-simple
								:data-cy="`options__columnTooltipEnabled__switch`"
								:key="`columnTooltipEnabled`"
								name="columnTooltipEnabledSwitch"
								:label="$t('settings.columnTooltipEnabled')"
								v-model="options.column_tooltip_enabled"
								@change="toChange('column_tooltip_enabled'); setDirty(true);"
							></lba-switch-simple>
						</s>
						<s v-show="options.column_tooltip_enabled">
							<small>{{ $t('settings.columnTooltipMaxLength') }}</small>
							<ValidationProvider
								:name="$t('settings.columnTooltipMaxLength')"
								v-slot="{ invalid, errors }"
								:rules="{ min_value: 1, numeric: true }"
								title=""
							>
								<input
									type="number"
									v-model="options.column_tooltip_max_length"
									:data-cy="`options__columnTooltipMaxLength__input`"
									name="column_tooltip_max_length"
									:disabled="!configurable"
									@input="toChange('column_tooltip_max_length')"
									min="1"
									:class="{ 'lba-invalid': invalid }"
								>
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`options__columnTooltipMaxLength__input__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</ValidationProvider>
						</s>
						<br>
					</lba-section>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import OptionsModel from '../models/Options';
import ProfilesModel from '../models/Profile';
import OnScroll from '../mixins/OnScroll';
import SaveEntry from '../mixins/SaveEntry';
import Page from '../mixins/Page';
import ColorThemes from '../mixins/ColorThemes';

export default {
	name: 'Options',
	data() {
		return {
			loaded: false,
			dirty: false,
			optionsModel: null,
			profilesModel: null,
			options: {
				default_language: 'cs',
				allow_language_per_user: true,
				default_timezone: 'Europe/Prague',
				allow_timezone_per_user: true,
				default_color_theme: 'theme-basic',
				allow_color_theme_per_user: true,
				cookie_max_age: 2678400000,
			},
			auth_methods: [],
			tfa_methods: [],
			changed_auth: {
				auth: [],
				tfa: [],
			},
			old_options: {},
			changed: [],
			configurable: false,
			timezoneList: [],
			themes: [],
			ldap: {},
			ad: {},
			pam: {},
			sms: {},
			profiles: null,
			password: {
				lowercase: false,
				uppercase: false,
				number: false,
				special: false,
				easy_to_guess: false,
				length: 8,
			},
			cookie_max_age: {},
			multipliers: [
				{ label: this.$t('settings.days'), value: 86400000 },
				{ label: this.$t('settings.hours'), value: 3600000 },
				{ label: this.$t('settings.minutes'), value: 60000 },
				{ label: this.$t('settings.seconds'), value: 1000 },
			],
			days_multipliers: [
				{ label: this.$t('settings.days'), value: 24 },
				{ label: this.$t('settings.hours'), value: 1 },
			],
			wizard: {},
			smsTextRegex: new RegExp(/\$\{code\}/g),
			defaultAttrs: {
				email: null,
				firstname: null,
				lastname: null,
				phone: null,
			},
			newFiles: {},
			saveButtonId: 'lbadmin.options',
			defaultHomepageOptions: [
				{ value: 'blank', label: this.$t('defaultHomePage.typeBlank') },
				{ value: 'dashboard', label: this.$t('defaultHomePage.typeDashboard') },
				{ value: 'first_menu_item', label: this.$t('defaultHomePage.typeFirstMenuItem') },
				{ value: 'route', label: this.$t('defaultHomePage.typeRoute') },
			],
		};
	},

	computed: {
		isTitle() {
			return !(['', null, undefined].includes(this.options.app_title_prefix) &&
				['', null, undefined].includes(this.options.app_title_suffix));
		},
	},

	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},

	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.permissionsReload();
		this.optionsModel = new OptionsModel(this.$http);
		this.profilesModel = new ProfilesModel(this.$http);

		this.initColorThemes();

		await this.fetch();

		this.$user.queryAction('timezoneList').then((data) => {
			this.timezoneList = data;
		});

		this.loaded = true;
	},

	mixins: [OnScroll, Page, ColorThemes, SaveEntry],

	methods: {
		permissionsReload() {
			this.configurable = this.$permissions.checkPermission('lbadmin.global-settings.write');
		},
		async fetch() {
			// let resp = await this.optionsModel.getAuth();
			let resp = await this.optionsModel.getOptions();
			this.auth_methods = resp.data.auth_methods.auth_methods;
			this.tfa_methods = resp.data.auth_methods.tfa_methods;
			this.wizard = resp.data.wizard || {};

			$_.forEach(this.auth_methods, (auth) => {
				if (auth.auth_method === 'ldap') {
					this.ldap = $_.cloneDeep(auth);
					if (!this.ldap.configuration) {
						this.ldap.configuration = {
							url: null,
							base: null,
							search: null,
							attrs: this.defaultAttrs,
						};
					} else {
						this.ldap.configuration.attrs = { ...this.defaultAttrs, ...this.ldap.configuration.attrs };
					}

				} else if (auth.auth_method === 'ad') {
					this.ad = $_.cloneDeep(auth);
					if (!this.ad.configuration) {
						this.ad.configuration = {
							url: null,
							base: null,
							login: null,
							password: null,
							search: null,
							ca: null,
							attrs: this.defaultAttrs,
						};

					} else {
						this.ad.configuration.attrs = { ...this.defaultAttrs, ...this.ad.configuration.attrs };
					}
				} else if (auth.auth_method === 'pam') {
					this.pam = $_.cloneDeep(auth);

				}
			});

			this.sms = {};

			$_.forEach(this.tfa_methods, (tfa_method) => {
				if (tfa_method.tfa_method === 'sms') {
					this.sms = $_.cloneDeep(tfa_method);
				}
			});

			this.options = resp.data.settings;
			await this.calcIntervals();
			this.old_options = $_.cloneDeep(resp.data);

			if (this.options.login_password_strength) {
				const oldPassword = $_.cloneDeep(this.password);
				try {
					this.password = { ...this.password, ...JSON.parse(this.options.login_password_strength) };
				} catch {
					this.password = oldPassword;
				}
			}

			if ($_.isEmpty(this.options.login_info_type)) {
				this.options.login_info_type = null;
			}

			resp = await this.profilesModel.getProfilesToSet();
			this.profiles = resp.data;

			if ($_.isEmpty(this.options.default_homepage)) {
				this.options.default_homepage = 'blank';
			}

			if ($_.isEmpty(this.options.column_tooltip_max_length)) {
				this.options.column_tooltip_max_length = 150;
			}
		},
		toChange(key) {
			this.setInputDirty(true);
			this.changed.push(key);
			this.changed = $_.uniq(this.changed);
		},
		async save() {
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed', { id: this.saveButtonId });
				return;
			}

			const opts = {};
			const intervals = ['idle_logout_time', 'cookie_max_age', 'login_ban_timeout'];
			// save only changed options
			$_.forEach(this.changed, (el) => {
				// console.log(el, this.options[`${el}_base`], this.options[`${el}_multiplier`]);
				if (intervals.includes(el)) {
					this.options[el] = ((this.options[`${el}_base`] || 1) * (this.options[`${el}_multiplier`] || 86400000));
				}

				if (this.options[el] !== this.old_options[el]) {
					opts[el] = this.options[el];
				}
			});

			if (this.password.dirty) {
				delete this.password.dirty;
				opts.login_password_strength = JSON.stringify(this.password);
			}

			const auth = [];
			const tfa = [];

			$_.forEach(this.changed_auth.tfa, (type) => {
				tfa.push($_.find(this.tfa_methods, { tfa_method: type }));
			});
			$_.forEach(this.changed_auth.auth, (type) => {
				auth.push($_.find(this.auth_methods, { auth_method: type }));
			});

			// console.log('saving: ', { options: opts, auth, tfa });
			if (this.wizard.dirty) {
				if (!this.wizard.email_valid_base) {
					this.wizard.email_valid_base = 24;
				}
				if (!this.wizard.email_valid_multiplier) {
					this.wizard.email_valid_multiplier = 1;
				}
				this.wizard.email_valid = this.wizard.email_valid_base * this.wizard.email_valid_multiplier;
			}

			const data = new FormData();

			if (!$_.isEmpty(opts)) {
				data.append('options', JSON.stringify(opts));
			}
			if (!$_.isEmpty(auth)) {
				data.append('auth', JSON.stringify(auth));
			}
			if (!$_.isEmpty(tfa)) {
				data.append('tfa', JSON.stringify(tfa));
			}
			if (this.wizard.dirty) {
				data.append('wizard', JSON.stringify(this.wizard));
			}

			if (!$_.isEmpty(this.newFiles)) {
				$_.forEach(Object.keys(this.newFiles), (fileName) => {
					data.append(fileName, this.newFiles[fileName].file);
				});
			}

			this.optionsModel.setOptions(data)
				.then((response) => {
					this.$root.$emit('content.saved', { reload: false, id: this.saveButtonId });
					this.setDirty(false);
					// remove files from inputs
					$_.forEach(Object.keys(this.newFiles), (fileName) => {
						this.newFiles[fileName].target.value = null;
					});
					this.newFiles = {};
					this.fetch();
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed', { id: this.saveButtonId });
				});
		},
		enableAuth(auth) {
			this.authChanged(auth);

			this.setDirty(true);
			if (auth === 'ldap') {
				this.ldap.enabled = !this.ldap.enabled;
				if (!this.ldap.enabled) {
					this.ldap.import = false;
				}

			} else if (auth === 'ad') {
				this.ad.enabled = !this.ad.enabled;
				if (!this.ad.enabled) {
					this.ad.import = false;
				}

			} else if (auth === 'pam') {
				this.pam.enabled = !this.pam.enabled;
				if (!this.ad.enabled) {
					this.ad.import = false;
				}

			}
		},
		enableTfa(auth, noEnable = false) {
			if (!this.changed_auth.tfa.includes(auth)) {
				this.changed_auth.tfa.push(auth);
			}
			if (auth === 'sms' && !noEnable) {
				this.sms.enabled = !this.sms.enabled;
			}
			this.setDirty(true);
		},
		updateLdap() {
			this.authChanged('ldap');
			this.setDirty(true);
			$_.forEach(this.auth_methods, (el) => {
				if (el.auth_method === 'ldap') {
					el.configuration = this.ldap.configuration;
					el.profile = this.ldap.profile;
				}
			});
		},
		updatePam() {
			this.authChanged('pam');
			this.setDirty(true);
			$_.forEach(this.auth_methods, (el) => {
				if (el.auth_method === 'pam') {
					el.profile = this.pam.profile;
				}
			});
		},
		updateAd() {
			this.authChanged('ad');
			this.setDirty(true);
			$_.forEach(this.auth_methods, (el) => {
				if (el.auth_method === 'ad') {
					el.configuration = this.ad.configuration;
					el.profile = this.ad.profile;
				}
			});
		},
		updateSms() {
			this.enableTfa('sms', true);
			this.setDirty(true);
			$_.forEach(this.tfa_methods, (el) => {
				if (el.tfa_method === 'sms') {
					el.configuration = this.sms.configuration;
				}
			});
		},
		authChanged(auth) {
			this.setDirty(true);
			if (!this.changed_auth.auth.includes(auth)) {
				this.changed_auth.auth.push(auth);

			}
		},
		authOrder() {
			this.setDirty(true);
			$_.forEach(this.auth_methods, (auth, index) => {
				auth.priority = index + 1;
				this.authChanged(auth.auth_method);
			});
		},
		calcIntervals() {
			return new Promise((resolve, reject) => {
				for (let i = 0; i < this.multipliers.length; i++) {
					const mp = this.multipliers[i];
					// cookie max age
					if (!this.options.cookie_max_age_multiplier && (this.options.cookie_max_age / mp.value) >= 1) {
						this.options.cookie_max_age_multiplier = mp.value;
						this.options.cookie_max_age_base = Math.round(this.options.cookie_max_age / mp.value);
					}
					// login ban timeout
					if (!this.options.login_ban_timeout_multiplier && (this.options.login_ban_timeout / mp.value) >= 1) {
						this.options.login_ban_timeout_multiplier = mp.value;
						this.options.login_ban_timeout_base = Math.round(this.options.login_ban_timeout / mp.value);
					}
					// idle logout time
					if (!this.options.idle_logout_time_multiplier && (this.options.idle_logout_time / mp.value) >= 1) {
						this.options.idle_logout_time_multiplier = mp.value;
						this.options.idle_logout_time_base = Math.round(this.options.idle_logout_time / mp.value);
					}
				}
				if (!this.wizard.email_valid) {
					this.wizard.email_valid = 24;
				}
				if (this?.sms?.configuration) {
					if (!this.sms.configuration.sms_code_length) {
						this.sms.configuration.sms_code_length = 6;
					}
					if (!this.sms.configuration.sms_valid) {
						this.sms.configuration.sms_valid = 5;
					}
				}
				for (const dmp of this.days_multipliers) {
					// email validation
					if (!this.wizard.email_valid_multiplier && (this.wizard.email_valid / dmp.value) >= 1) {
						this.wizard.email_valid_multiplier = dmp.value;
						this.wizard.email_valid_base = Math.round(this.wizard.email_valid / dmp.value);
					}
				}
				resolve();
			});
		},
		confDel(index) {
			this.updateSms();
			this.setDirty(true);
			this.sms.configuration.configurations.splice(index, 1);
		},
		confAdd() {
			this.setDirty(true);
			this.sms.configuration.configurations.push({
				type: 'sms-ucp',
				description: this.$t('tfaMethods.sms.newConfiguration'),
				time_group: null,
			});
		},
		async onFileChange(conf, event, validateCallback) {
			const { files } = event.target;
			if ($_.isEmpty(files)) {
				return;
			}

			const found = $_.find(this.sms.configuration.configurations, (cfg) => {
				return (cfg.type === 'ipsms' && cfg.cert_pem_file === files[0].name);
			});
			if (conf.cert_pem_file !== files[0].name && (this.newFiles[files[0].name] || found)) {
				this.$notify.warn(this.$t('tfaMethods.sms.certificateExists'));
				conf.cert_pem_file = null;
				conf.new_file = true;
				event.target.value = null;
				validateCallback(event);
				this.setDirty(true);
				return;
			}

			delete this.newFiles[conf.cert_pem_file];

			this.newFiles[files[0].name] = {
				file: files[0],
				name: files[0].name,
				target: event.target,
			};

			conf.cert_pem_file = files[0].name;
			conf.new_file = true;

			validateCallback(event);
			this.setDirty(true);
		},
		updateDefaultHomepage() {
			this.dirty = true;
			if (this.options.default_homepage !== 'route') {
				this.options.default_homepage_route = '';
			}
			this.toChange('default_homepage');
		},
	},
};
</script>
