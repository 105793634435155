/* eslint no-bitwise: 0 */
import './assets/styles/style.scss';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCompositionAPI from '@vue/composition-api';
import Chat from 'vue-beautiful-chat';
import VueMeta from 'vue-meta';
import VueI18n from 'vue-i18n';
import VuePapaParse from 'vue-papa-parse';
import VCalendar from 'v-calendar';
import IdleJs from 'idle-js';
import VTooltip from 'v-tooltip';
import VueMasonry from 'vue-masonry-css';
import PortalVue from 'portal-vue';
import * as VeeValidate from 'vee-validate';
import { messages as validationMessagesCs } from 'vee-validate/dist/locale/cs.json';
import { messages as validationMessagesEn } from 'vee-validate/dist/locale/en.json';
import DOMPurify from 'dompurify';
import uslug from 'uslug';
import clip from 'text-clipper';
import App from './App.vue';
import LbaFulltextSearch from './components/LbaFulltextSearch.vue';
import NotFound from './components/NotFound.vue';
import LBTray from './mixins/LBTray';
import Collection from './mixins/Collection';
import Permissions from './mixins/Permissions';
import UserMixin from './mixins/User';
import Socket from './mixins/Socket';
import Versions from './mixins/Versions';
import Timepicker from './mixins/Timepicker';
import Notification from './mixins/Notification';
import DesktopNotification from './mixins/DesktopNotification';
import Formatter from './mixins/Formatter';
import Content from './mixins/Content';
import MenuUpdate from './mixins/MenuUpdate';
import RouteKey from './mixins/RouteKey';
import SaveEntry from './mixins/SaveEntry';
import Component from './mixins/Component';
import Page from './mixins/Page';
import Permission from './mixins/Permission';
import ClientError from './mixins/ClientError';
import ComponentIdentifier from './mixins/ComponentIdentifier';
import OnRecordDeleted from './mixins/OnRecordDeleted';
import OnScroll from './mixins/OnScroll';
import RootListener from './mixins/RootListener';
import RouterWrap from './mixins/RouterWrap';
import GetWrappedItems from './mixins/GetWrappedItems';
import GenerateUID from './mixins/GeneratorUID';
import Debouncer from './mixins/Debouncer';
import ClassWatcher from './mixins/ClassWatcher';
import PhoneNumber from './mixins/PhoneNumber';
import TabErrors from './mixins/TabErrors';
import Clipboard from './mixins/Clipboard';
import RootShortcuts from './mixins/root/Shortcuts';
import RootValidationMethods from './mixins/root/ValidationMethods';
import AppTitle from './mixins/AppTitle';
import Password from './mixins/Password';
import exceljs from 'exceljs/dist/es5/exceljs.browser';
import jsonMenu from '../config/menu.json';
import EventBus from './mixins/EventBus';

import registerComponents from './setup/components';
import registerDirectives from './setup/directives';
import registerPlugins from './setup/plugins';

import routes from './setup/routes';
import i18nOptions from './setup/i18nOptions';

Vue.mixin(EventBus);

registerComponents(Vue, VeeValidate);
registerDirectives(Vue, VeeValidate);
registerPlugins(Vue, VeeValidate);

// globalsmime;
window.mixins = {
	AppTitle,
	ComponentIdentifier,
	OnRecordDeleted,
	OnScroll,
	SaveEntry,
	TabErrors,
	Component,
	Page,
	Permission,
	Password,
	Clipboard,
};

window.Vue = Vue;

Vue.use(VueCompositionAPI);
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(Chat);
Vue.use(VuePapaParse);
Vue.use(VueI18n);
Vue.use(VCalendar, { componentPrefix: 'vue', masks: { weekdays: 'WW' } });
Vue.use(VueMasonry);
Vue.use(VTooltip, { defaultClass: 'tooltip', defaultDelay: { show: 700, hide: 0 } });
Vue.use(PortalVue);
Vue.use(VeeValidate, { locale: 'cs' });
VeeValidate.setInteractionMode('lazy');

Vue.config.productionTip = false;
Vue.prototype.$hasValue = (value, allowSpace = false) => {
	if (value == null) return false;
	if (value.constructor === Boolean) return true;
	if (value.constructor === Date) return true;
	if (value.constructor === String) return allowSpace ? !$_.isEmpty(value) : !$_.isEmpty($_.trim(value));
	if (value.constructor === Number) return !isNaN(value);
	return !$_.isEmpty(value);
};
Vue.prototype.$LBTray = LBTray;
Vue.prototype.$Collection = Collection;
Vue.prototype.$DOMPurify = DOMPurify;
Vue.prototype.$socket = new Socket();
Vue.prototype.$versions = new Versions();
Vue.prototype.$Timepicker = Timepicker;
Vue.prototype.$permissions = new Permissions();
Vue.prototype.$user = new UserMixin();
Vue.prototype.$notify = new Notification();
Vue.prototype.$desktopNotify = new DesktopNotification();
Vue.prototype.$content = new Content();
Vue.prototype.$RouteKey = RouteKey;
Vue.prototype.$SaveEntry = SaveEntry;
Vue.prototype.$routerWrap = new RouterWrap();
Vue.prototype.$getWrappedItems = GetWrappedItems;
Vue.prototype.$fulltextComponents = {};
Vue.prototype.$headerPlugins = [
	{
		component: LbaFulltextSearch,
		order: 9999,
		id: GenerateUID(),
	},
];
Vue.prototype.$widgets = [];
Vue.prototype.$availableRouteActions = [];
Vue.prototype.$availableNewEntries = [];
Vue.prototype.$phoneNumber = new PhoneNumber();
Vue.prototype.$generateUID = GenerateUID;
window.$generateUID = GenerateUID;
Vue.prototype.$Debouncer = Debouncer;
Vue.prototype.$ClassWatcher = ClassWatcher;
Vue.prototype.$TabErrors = TabErrors;
Vue.prototype.$exceljs = exceljs;
Vue.prototype.$uslug = uslug;
Vue.prototype.$formatter = new Formatter();
Vue.prototype.$textClipper = clip;
Vue.prototype.$normalizeString = (value) => (value || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
Vue.prototype.$nullIfEmptyString = (value) => value === '' ? null : value;
Vue.prototype.$prepareId = () => {
	return (new Date().getTime()) % 1000000000;
};
Vue.prototype.$prepareNewId = () => {
	return `+${Vue.prototype.$prepareId()}`;
};

Vue.prototype.$it = (value) => {
	if ($_.isEmpty(value)) return '0s';
	const timeUnits = {
		years: value.years || 0,
		months: value.months || 0,
		days: value.days || 0,
		hours: value.hours || 0,
		minutes: value.minutes || 0,
		seconds: value.seconds || 0,
	};

	const formattedTime = Object.keys(timeUnits)
		.filter((unit) => timeUnits[unit] > 0)
		.map((unit) => `${timeUnits[unit]}${unit[0]}`)
		.join(' ');

	return formattedTime;
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

i18nOptions.messages = {
	cs: {
		veeValidate: {
			...validationMessagesCs,
			is_not: '{_field_} nesmí být {other}',
		},
	},
	en: {
		veeValidate: {
			...validationMessagesEn,
			is_not: '{_field_} must not equal {other}',
		},
	},
};
const i18n = new VueI18n(i18nOptions);
window.$t = i18n.t.bind(i18n);
window.getI18nLocale = () => {
	return i18n.locale;
};
window.setI18nLocale = (locale) => {
	i18n.locale = locale;
};

new Vue({
	mixins: [
		MenuUpdate,
		RootListener,
		ClientError,
		Clipboard,
		RootShortcuts,
		RootValidationMethods,
	],
	data() {
		return {
			homeRoute: { name: 'home' },
			firstMenuItemRoute: { name: null, path: null },
			defaultHomePage: 'blank',
			defaultHomePageRoute: null,
			scripts: [],
			theme: null,
			lang: null,
			anonymousAccess: false,
			loadingLogin: true,
			servers: [],
			leftMenuPlugins: [],
			menu: [],
			firstURL: null,
			isFullscreen: false,
			uid: this.$generateUID().slice(24), // slice last part
			browserName: null,
			gridsChannel: null,
			preparingGridsChannel: true,
			entryChannel: null,
			preparingEntryChannel: true,
			blocked: null,
			isFirstLogin: true,
		};
	},
	metaInfo() {
		return {
			script: this.scripts,
		};
	},
	computed: {
		hasAnonymousAccess() {
			return (
				this.$user.user_name != null && (
					!this.$user.is_anonymous_user ||
					(this.$user.is_anonymous_user && this.anonymousAccess)
				)
			);
		},
	},
	async created() {
		window.getBrowserLanguage = this.getBrowserLanguage;
		this.$listen('entry.saved', this.onEntrySaved, this, true);
		this.$listen('grid.update-row', this.gridUpdateRow, this, true);
		this.$listen('grid.insert-row', this.gridInsertRow, this, true);
		this.$once('socket-opened', this.connectChannels);

		if (navigator.userAgent.match(/opr\//i)) {
			this.browserName = 'opera';
		} else if (navigator.userAgent.match(/firefox|fxios/i)) {
			this.browserName = 'firefox';
		} else if (navigator.userAgent.match(/edg/i)) {
			this.browserName = 'edge';
		} else if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
			this.browserName = 'chrome';
		// not very reliable, chrome contains safari too
		} else if (navigator.userAgent.match(/safari/i)) {
			this.browserName = 'safari';
		}

		if (this.browserName != null) {
			document.body.classList.add(this.browserName);
		}

		window.$getLocale = this.$getLocale.bind(this);

		DOMPurify.addHook('afterSanitizeAttributes', (node, data, config) => {
			if (node.nodeName === 'IMG' && node.src && node.src.indexOf('cid:') !== 0) {
				if (config.banExternalImage) {
					node.alt = this.$t('externalImage', { attribute: node.src });
					node.src = '';
				} else {
					const newSrc = `/api/lbadmin/lbadmin/external-image?url=${encodeURIComponent(node.src)}`;
					node.src = newSrc;
				}
				return node;
			}
		});

		this.$content.init(this);
		this.$socket.vm = this;
		this.$versions.vm = this;
		this.$user.vm = this;
		this.$permissions.vm = this;
		this.$routerWrap.vm = this;
		this.$desktopNotify.vm = this;
		this.$pageSettings.init(this);

		if (!this.$route.fullPath.startsWith('/login')) {
			this.firstURL = this.$route.fullPath;
		}

		if ('Android' in window) {
			document.body.classList.add('androidApp');
		}

		// load locales
		const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
		locales.keys().forEach((key) => {
			const matched = key.match(/([A-Za-z0-9-_]+)\./i);
			if (matched && matched.length > 1) {
				const locale = matched[1];
				// i18n.setLocaleMessage(locale, locales(key));
				const preparedLocales = locales(key);
				this.preparePluralLocales(preparedLocales);
				this.$i18n.mergeLocaleMessage(locale, preparedLocales);
				moment.updateLocale(locale, preparedLocales);
			}
		});

		this.menu = [jsonMenu];

		if (!this.$route.fullPath.startsWith('/login')) {
			await this.$user.load();
			// add last route with 404 page
			router.addRoute({
				name: 'error-404',
				path: '*',
				component: NotFound,
				meta: { name: () => '404' },
			});
		}

		try {
			const info = await this.$http.get('lbadmin/servers');
			this.servers = info.data;
			this.theme = this.servers[0].theme;
			this.lang = this.servers[0].lang;
			this.logClientErrors = this.servers[0].log_client_errors;
			this.maxClientErrorsPerBatch = this.servers[0].max_client_errors_per_batch || 10;
			this.maxClientErrorsPerHour = this.servers[0].max_client_errors_per_hour || 50;
			if (this.logClientErrors) {
				this.initClientErrorListener();
			}

			for (let i = 0; i < this.servers.length; i += 1) {
				this.servers[i].timeDiff = Math.abs(new Date() - new Date(info.headers.date));
			}

			this.$root.fulltextEnabled = this.servers[0].fulltext_enabled || false;

			this.$root.$emit('servers-loaded');

			// test server if Iam blocked by fail2ban
			await this.$http.get('lbadmin/modules');

			this.anonymousAccess = this.servers[0].anonymous_access;

			this.idleLogoutEnabled = this.servers[0].idle_logout_enabled;
			this.idleLogoutTime = this.servers[0].idle_logout_time;
			if (this.idleLogoutEnabled) {
				const idle = new IdleJs({
					idle: this.idleLogoutTime,
					events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
					keepTracking: true,
					startAtIdle: true,
					onIdle: () => {
						this.$root.$emit('user-idle');
					},
					onActive: () => {
						this.$root.$emit('user-active');
					},
				});
				idle.start();
			}

		} catch (error) {
			console.error('Servers load error:', error);
			if (error.response.data.blocked) {
				this.blocked = error.response.data.blocked;
			}
		}

		if (
			!$_.isEmpty(this.$user.role_uid) &&
			this.$user.can_login &&
			(
				!this.$user.is_anonymous_user ||
				this.anonymousAccess
			)
		) {
			this.isFirstLogin = false;
		}

		if (process.env.NODE_ENV === 'production') {
			this.scripts.push({
				src: `/themes/${this.theme}.umd.min.js`,
				callback: () => {
					this.$user.loadTheme(this.theme, window[`theme-${this.theme}`]);
					const { resolved } = this.$router.resolve(this.$root.homeRoute);

					// user is anonymous but server does not have anonymous access -> login page
					// or user has anonymous access but does not have some special link
					if (
						(!this.hasAnonymousAccess && !this.$route.fullPath.startsWith('/login')) ||
						(
							this.$user.is_anonymous_user && (
								this.$route.path === resolved.path || this.$route.name === 'error-404'
							)
						)
					) {
						this.$routerWrap.push({ name: 'login' });
					} else {
						this.loadingLogin = false;
						document.body.classList.remove('ng-cloak');
					}

					this.setupRouterHooks();
				},
			});
		} else {
			/* DEVELOPMENT ONLY - BEGIN
			import(`./themes/${this.theme}/index.js`)
				.then((module) => {
					window[`theme-${this.theme}`] = module;
					this.$user.loadTheme(this.theme, module);
					const { resolved } = this.$router.resolve(this.$root.homeRoute);

					// user is anonymous but server does not have anonymous access -> login page
					// or user has anonymous access but does not have some special link
					if (
						(!this.hasAnonymousAccess && !this.$route.fullPath.startsWith('/login')) ||
						(
							this.$user.is_anonymous_user && (
								this.$route.path === resolved.path || this.$route.name === 'error-404'
							)
						)
					) {
						this.$routerWrap.push({ name: 'login' });
					} else {
						this.loadingLogin = false;
						document.body.classList.remove('ng-cloak');
					}

					this.setupRouterHooks();
				});
			/* DEVELOPMENT ONLY - END */
		}

		this.$http.interceptors.response.use(
			(response) => response,
			(error) => {
				let message;
				let quiet = false;

				if (error.response && error.response.data && error.response.data.error) {
					if (typeof error.response.data.error === 'string') {
						if (error.response.data.error.substr(0, 6) === 'quiet:') {
							quiet = true;
							message = error.response.data.error.substr(6);
						} else {
							message = error.response.data.error;
						}
					}
				}

				if (
					error.response &&
					(
						(!error.response.data && error.response.status < 0) ||
						(error.response.status === 502 && error.response.statusText === 'Proxy Error')
					)
				) {
					message = 'requestTimeout';
				}

				if (message && !quiet) {
					this.$notify.warn(this.$t(message));
				}

				throw error;
			}
		);
	},
	beforeDestroy() {
		if (this.gridsChannel) {
			this.gridsChannel.unsubscribe();
			this.gridsChannel = null;
		}
	},
	methods: {
		preparePluralLocales(locale) {
			const keys = Object.keys(locale);
			for (let i = 0; i < keys.length; i += 1) {
				const key = keys[i];

				if (!$_.isEmpty(locale[key])) {
					const pluralTag = '_plural';
					if (key.endsWith(pluralTag) && locale[key].constructor === Array) {
						const newKey = key.slice(0, key.length - pluralTag.length);
						locale[newKey] = locale[key].join(' | ');
						delete locale[key];
					} else if (locale[key].constructor === Object) {
						this.preparePluralLocales(locale[key]);
					}
				}
			}
		},
		connectChannels() {
			this.connectGridsChannel();
			this.connectEntryChannel();
		},
		connectEntryChannel() {
			if ($_.isEmpty(this.entryChannel)) {
				this.entryChannel = this.$socket.on(
					'/lbadmin/entry',
					{
						vueUid: this.uid,
						browser: this.browserName,
					},
					this.entryOnSocketMessage
				);
			}
		},
		entryOnSocketMessage(message) {
			if (message.type === 'registered') {
				if (this.preparingEntryChannel) {
					this.preparingEntryChannel = false;
					this.$emit('entry.channel-ready');
				}

			} else if (message.type === 'entry.saved') {
				this.$emit('entry.saved', { uid: message.uid, routeName: message.routeName, isNew: message.isNew, source: 'socket' });

			} else {
				console.error('[main](entryOnSocketMessage) unknown message type:', message.type);

			}
		},
		onEntrySaved(event) {
			if ($_.isEmpty(event)) {
				console.error(`[main](onEntrySaved) received empty event`);
				return;
			}
			if (
				event.source === 'socket' ||
				this.preparingEntryChannel ||
				event.internalOnly
			) {
				return;
			}
			if ($_.isEmpty(event.uid)) {
				console.error(`[main](onEntrySaved) missing event.uid`);
				return;
			}
			this.entryChannel.send({ type: 'entry.saved', ...event });
		},
		connectGridsChannel() {
			if ($_.isEmpty(this.gridsChannel)) {
				// console.log('register grid channel');
				this.gridsChannel = this.$socket.on(
					'/lbadmin/grids',
					{
						vueUid: this.uid,
						browser: this.browserName,
						roleUid: this.$user.role_uid,
					},
					this.gridOnSocketMessage
				);
			}
		},
		gridOnSocketMessage(message) {
			if (message.type === 'registered') {
				if (this.preparingGridsChannel) {
					// console.log('socket ready');
					this.preparingGridsChannel = false;
					this.$emit('grid.channel-ready');
				}

			} else if (message.type === 'reload-grid') {
				if ($_.isEmpty(message.payload.gridName)) {
					console.error('[main](gridOnSocketMessage) socket message missing payload.gridName type:', message.type);
					return;
				}
				// console.log('insert row', message.payload.gridName);
				this.$emit('grid.insert-row', {
					gridName: message.payload.gridName,
					parentUid: message.payload.parentUid,
					internalOnly: true,
				});

			} else if (message.type === 'reload-grid-row') {
				if ($_.isEmpty(message.payload.gridName)) {
					console.error('[main](gridOnSocketMessage) socket message missing payload.gridName type:', message.type);
					return;
				}
				if ($_.isEmpty(message.payload.rowId)) {
					console.error('[main](gridOnSocketMessage) socket message missing payload.rowId type:', message.type);
					return;
				}
				// console.log('update row', message.payload.gridName);
				this.$emit('grid.update-row', {
					gridName: message.payload.gridName,
					rowId: message.payload.rowId,
					parentUid: message.payload.parentUid,
					reload: true,
					internalOnly: true,
				});

			} else {
				console.error('[main](gridOnSocketMessage) unknown message type:', message.type);

			}
		},
		gridInsertRow(info) {
			if (
				info.internalOnly ||
				$_.isEmpty(this.gridsChannel) ||
				this.preparingGridsChannel ||
				($_.isEmpty(info.gridName) && $_.isEmpty(info.gridNames))
			) {
				// console.log('insert row but only internal', info);
				return;
			}

			const gridNames = info.gridNames || [info.gridName];
			gridNames.forEach((gridName) => {
				// console.log('insert row for grid:', gridName);
				this.gridsChannel.send({
					type: 'reload-grid',
					payload: { gridName, parentUid: info.parentUid },
				});
			});
		},
		gridUpdateRow(info) {
			if (
				info.internalOnly ||
				$_.isEmpty(this.gridsChannel) ||
				this.preparingGridsChannel ||
				($_.isEmpty(info.gridName) && $_.isEmpty(info.gridNames)) ||
				$_.isEmpty(info.rowId)
			) {
				// console.log('update row but only internal', info);
				return;
			}

			const gridNames = info.gridNames || [info.gridName];
			gridNames.forEach((gridName) => {
				// console.log('update row for grid:', gridName);
				this.gridsChannel.send({
					type: 'reload-grid-row',
					payload: { gridName, rowId: info.rowId, parentUid: info.parentUid },
				});
			});
		},
		/* DEVELOPMENT ONLY - BEGIN
		testDataCy() {
			const elements = document.querySelectorAll('[data-cy]');
			const ids = $_.map(elements, (element) => element.getAttribute('data-cy'));
			const getDuplicates = (array) => array.filter((item, index) => array.indexOf(item) !== index);
			const duplicates = getDuplicates(ids);
			if (duplicates.length > 0) {
				console.error('data-cy duplicates found:', duplicates);
			} else {
				console.log('data-cy duplicates not found -> ok');
			}
			const uniqueIds = Array.from(new Set(ids));
			const nullOrUndefined = uniqueIds.filter((id) => id.includes('null') || id.includes('undefined'));
			if (nullOrUndefined.length > 0) {
				console.error('data-cy null or undefined found:', nullOrUndefined);
			} else {
				console.log('data-cy null or undefined not found -> ok');
			}
		},
		/* DEVELOPMENT ONLY - END */
		localeChanged() {
			VeeValidate.localeChanged();
			moment.locale(this.$i18n.locale);
		},
		setIsFullscreen() {
			this.isFullscreen = (
				document.webkitIsFullScreen ||
				document.mozFullScreen ||
				document.msFullscreenElement != null ||
				document.fullscreenElement ||
				document.mozFullScreenElement ||
				document.webkitFullscreenElement
			);
		},
		setupRouterHooks() {
			// check if logged in if not, redirect to login page
			router.beforeEach(async (to, from, next) => {
				document.body.classList.add('ng-cloak');

				if (to.name === 'login' || to.path === '/login' || to.name === 'firstLogin' || to.path === '/login-first') {
					next();
				} else if (router.app.$user.user_name == null) {
					const userLoaded = await router.app.$user.load();

					// anonymous with access or not anonymous
					if (
						userLoaded &&
						(
							(router.app.$user.is_anonymous_user && router.app.anonymousAccess) ||
							!router.app.$user.is_anonymous_user
						)
					) {
						next();
					} else {
						next({ name: 'login' });
					}
				} else if (
					(router.app.$user.is_anonymous_user && router.app.anonymousAccess) ||
					!router.app.$user.is_anonymous_user
				) {
					next();
				} else {
					next({ name: 'login' });
				}
			});

			router.afterEach(() => {
				document.body.classList.remove('ng-cloak');
			});

			router.onError(() => {
				document.body.classList.remove('ng-cloak');
			});
		},
		getBrowserLanguage() {
			const browserLang = navigator.language;
			if (browserLang.includes('cs')) { return 'cs'; }
			return 'en';
		},
	},
	router,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
