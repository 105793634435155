<template>
	<component
		v-if="noInput && $_.isEmpty(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $d(new Date(data[key]), 'long') }}
	</component>
	<component
		v-else-if="noInput && ['time_since', 'from_now'].includes(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
		v-tooltip="(data[key] && $d(new Date(data[key]), 'long')) || ''"
	>
		{{ $timeBeforeAfter(data[key]) }}
	</component>
	<component
		v-else-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
		v-tooltip="(data[key] && $d(new Date(data[key]), options.format)) || ''"
	>
		{{ $d(new Date(data[key]), options.format) }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<fake-provider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-datepicker
				:parentComponentId="currentComponentId"
				v-model="data[key]"
				v-bind="bindOptions()"
				:min="options.min"
				:max="options.max"
				:disabled="readOnly"
				:yearRange="options.yearRange || 10"
				:dirty="dirty"
				@input="onEvent('input')"
				@change="onEvent('change')"
				:invalid="invalidMessages(invalid, errors, customMessages)"
			></lba-datepicker>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputDatetime',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		invalidMessages(invalid, errors, customMessages) {
			if (!invalid) {
				return null;
			}
			const message = [];
			$_.forEach(errors, (error) => {
				message.push(error);
			});
			$_.forEach(customMessages, (msg) => {
				message.push(msg);
			});

			return message.length ? message.join(', ') : null;
		},
	},
};
</script>
