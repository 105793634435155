<template>
	<div style="position: relative;">
		<s v-if="label">
			<small>{{ label }}</small>
		</s>
		<s v-if="sliderImages && sliderImages.length" class="carousel-items-count">
			<small>{{ `${$t('items')}: ${sliderImages.length}` }}</small>
		</s>
		<div v-if="isCreated && sliderImages && sliderImages.length" class="carousel">
			<div class="carousel-shader"></div>
			<div class="carousel-wrap">
				<template v-if="isCreated && sliderImages && sliderImages.length">
					<div
						v-for="(image, index) in previewImages"
						:key="index"
						class="carousel-item"
						:style="`width: ${image.width + 60}px; height: ${image.height + 30}px`"
						@click.stop="openDialog(index)"
					>
						<figure
							:style="`width: ${image.width}px; height: ${image.height}px`"
						>
							<img
								:src="image.src"
								:height="image.height"
								:width="image.width"
							/>
						</figure>
					</div>
				</template>
				<template v-else>
					{{ 'No Images Found' }}
				</template>
			</div>
			<div
				class="carousel-shader"
				style="top: 0; right: 0; transform: scaleX(-1);"
			></div>
			<div class="carousel-progress">
				<div class="carousel-progress-bar"></div>
			</div>
		</div>
		<lba-dialog-modal
			:name="imageSliderDialogName"
			:onClose="closeDialog"
			ref="dialog"
		>
			<div class="popup-header">
				<h2 v-if="label">{{ label }}</h2>
			</div>
			<div
				class="popup-body"
				style="height: 80vh; padding: 1rem 0 1rem 17px !important;"
			>
				<div v-if="selectedImage" class="form-fieldset carousel-active-image">
					<img :src="selectedImage" />
				</div>
			</div>
			<div class="popup-footer">
				<span style="cursor: pointer;" @click.stop="handleImageSlide(-1)">
					<i class="icon-arrow-left"></i>
				</span>
				<span style="margin: 0 30px 0 30px">
					{{ `${selectedIndex + 1} / ${sliderImages.length}` }}
				</span>
				<span style="cursor: pointer;" @click.stop="handleImageSlide(1)">
					<i class="icon-arrow-right"></i>
				</span>
			</div>
		</lba-dialog-modal>
	</div>
</template>

<script>
import { DragScroll } from './sliderDragScroll';

export default {
	props: {
		// use attachments for various file types
		attachments: Array,
		// use images for  image files only
		images: Array,
		maxWidth: String,
		maxHeight: String,
		label: String,
		showPhotoIndex: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			scroll: null,
			scrollInitialized: false,
			sliderImages: [],
			imageSliderDialogName: this.$generateUID(),
			previewImages: [],
			selectedImage: null,
			selectedIndex: 0,
			isCreated: false,
			isMounted: false,
			isScrolling: false,
			isActivated: false,
			isDialogOpened: false,
		};
	},
	async created() {
		if (this.images) {
			this.sliderImages = this.images;
		} else if (this.attachments) {
			this.sliderImages = this.attachments.filter(
				(att) => (att.mime_type.startsWith('image'))
			);
		}
		if (this.sliderImages) {
			await this.preparePreviewImages();
		}
		this.isCreated = true;
		this.initScroller();
	},
	mounted() {
		document.addEventListener('sliderDragScroll-scroll', this.handleEvent, this);
		document.addEventListener('sliderDragScroll-activate', this.handleActivate, this);
		this.isMounted = true;
		this.initScroller();
	},
	beforeDestroy() {
		if (this.scroll) {
			this.scroll.removeScrollListeners();
		}
		document.removeEventListener('sliderDragScroll-scroll', this.handleEvent, this);
		document.removeEventListener('sliderDragScroll-activate', this.handleActivate, this);
	},
	methods: {
		getSrc(image) {
			let src = null;
			if (typeof img === 'string') {
				src = image;
			} else if (typeof image === 'object' && image.src) {
				src = image.src;
			}
			return src;
		},
		async getImageDimensions(img) {
			const src = this.getSrc(img);
			if (!src) return;
			const image = new Image();
			image.src = src;

			await new Promise((resolve) => {
				image.onload = resolve;
			});

			const width = image.naturalWidth;
			const height = image.naturalHeight;
			return { width, height };

		},
		async preparePreviewImages() {
			if (!this.sliderImages || !this.sliderImages.length) {
				return;
			};
			const imagesDimensions = await Promise.all(this.sliderImages.map(async (image) => this.getImageDimensions(image)));
			this.previewImages = [];
			this.sliderImages.forEach((image, index) => {
				const dimensions = imagesDimensions[index];
				if (dimensions && dimensions.height && dimensions.width) {
					const height = 198;
					const width = (dimensions.width / dimensions.height) * height;
					this.previewImages.push({
						src: this.getSrc(image),
						width,
						height,
					});
				}
			});
		},
		lerp(f0, f1, t) {
			return (1 - t) * f0 + t * f1;
		},
		raf() {
			if (!this.scrollInitialized) {
				return;
			}
			if (!this.isActivated) {
				this.scroll.progress = this.scroll.x || this.scroll.oldX;
				this.scroll.speed = 0;
				return;
			};
			this.scroll.x = this.lerp(this.scroll.x, this.scroll.progress, 0.1);
			this.scroll.playrate = this.scroll.x / this.scroll.maxScroll;
			this.scroll.$wrap.style.transform = `translateX(${-this.scroll.x}px)`;
			this.scroll.$bar.style.transform = `scaleX(${0.18 + this.scroll.playrate * 0.82})`;
			this.scroll.speed = Math.min(100, this.scroll.oldX - this.scroll.x);
			this.scroll.oldX = this.scroll.x;
			this.scroll.scale = this.lerp(this.scroll.scale, this.scroll.speed, 0.1);

			requestAnimationFrame(this.raf);
		},
		initScroller() {
			if (!this.isCreated || !this.isMounted || this.scrollInitialized) {
				return;
			}

			this.$nextTick(() => {
				this.scroll = new DragScroll({
					el: '.carousel',
					wrap: '.carousel-wrap',
					item: '.carousel-item',
					bar: '.carousel-progress-bar',
				});

				this.raf();
			});
			this.scrollInitialized = true;
		},
		handleEvent(event) {
			if (!event.detail.scroll) {
				setTimeout(() => {
					this.isScrolling = event.detail.scroll;
				}, 100);
			} else {
				this.isScrolling = true;
			}
		},
		handleActivate(event) {
			this.isActivated = event.detail.activate;
			if (this.isActivated) {
				this.raf();
			}
		},
		handleImageSlide(step) {
			this.selectedIndex += step;
			if (this.selectedIndex >= this.sliderImages.length) {
				this.selectedIndex = 0;
			} else if (this.selectedIndex < 0) {
				this.selectedIndex = (this.sliderImages.length - 1);
			}
			this.selectedImage = this.getSrc(this.sliderImages[this.selectedIndex]);
		},
		handleKeyUp(event) {
			if (event.code === 'ArrowLeft') {
				this.handleImageSlide(-1);
			} else if (event.code === 'ArrowRight') {
				this.handleImageSlide(1);
			}
		},
		openDialog(index) {
			if (this.isScrolling) return;
			this.selectedImage = this.getSrc(this.sliderImages[index]);
			this.selectedIndex = index;
			this.$root.$emit('dialog-open', { name: this.imageSliderDialogName });
			window.addEventListener('keyup', this.handleKeyUp, this);
			this.isDialogOpened = true;
		},
		closeDialog() {
			window.removeEventListener('keyup', this.handleKeyUp, this);
			this.isDialogOpened = false;
		},
	},
};
</script>

<style scoped>
* { box-sizing: border-box; }
.carousel {
	position: relative;
	width: calc(100% - 20px);
	height: 230px;
	cursor: -webkit-grab;
	cursor: grab;
	overflow: hidden;
}
.carousel.dragging {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}
.carousel-wrap {
	height: 228px;
	white-space: nowrap;
	padding: 0 20px 0 20px;
}
.carousel-item {
	display: inline-block;
	width: 350px;
	max-height: 228px;
	padding: 15px 30px 15px 30px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.carousel-item figure {
	position: relative;
	z-index: 2;
	display: block;
	height: 198px;
	overflow: hidden;
	margin: 0;
}
.carousel-item figure img {
	position: absolute;
	z-index: 2;
	height: 198px;
	-o-object-fit: cover;
	object-fit: cover;
	vertical-align: middle;
	transform-origin: center;
}
.carousel-progress {
	z-index: 1;
	pointer-events: none;
	width: 100%;
	height: 2px;
}
.carousel-progress-bar {
	z-index: 1;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
	transform-origin: 0% 0%;
}
.carousel-shader {
	display: flex;
	position: absolute;
	z-index: 3;
	height: 228px;
	width: 20px;
}
.carousel-active-image {
	margin: 0;
	overflow: hidden;
	height: -webkit-fill-available;
	height: -moz-available;
	height: stretch;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75vw;
}
.carousel-active-image img {
	max-width: 100%;
	max-height: -webkit-fill-available;
	max-height: -moz-available;
}
.carousel-items-count {
	width: auto;
	margin-left: auto;
	position: absolute;
	right: 0;
}
</style>
