import Resumable from './resumable';

class UploadFile {
	constructor() {
		this.resumable = {};
		this.url = '';

		this.onNoSpace = null;
		this.onFileAdded = null;
		this.onUploadStart = null;
		this.onPause = null;
		this.noCancel = null;
		this.onFileProgress = null;
		this.onComplete = null;
	}

	setUrl = (url) => {
		this.url = url;
	};

	noSpace = (callback) => {
		this.onNoSpace = callback;
	};

	fileAdded = (callback) => {
		this.onFileAdded = callback;
	};

	uploadStart = (callback) => {
		this.onUploadStart = callback;
	};

	pause = (callback) => {
		this.onPause = callback;
	};

	cancel = (callback) => {
		this.onCancel = callback;
	};

	fileProgress = (callback) => {
		this.onFileProgress = callback;
	};

	complete = (callback) => {
		this.onComplete = callback;
	};

	upload = (file, url, event) => {
		const self = this;

		this.resumable = new Resumable({
			target: url,
			chunkSize: 25 * 1024 * 1024,
			simultaneousUploads: 1,
			maxFiles: 1,
		});

		this.resumable.on('noSpace', function (...args) {
			if (self.onNoSpace) {
				self.onNoSpace(...args);
			}
		});

		this.resumable.on('fileAdded', function (...args) {
			if (self.onFileAdded) {
				self.onFileAdded(...args);
			}
		});

		this.resumable.on('uploadStart', function (...args) {
			if (self.onUploadStart) {
				self.onUploadStart(...args);
			}
		});

		this.resumable.on('pause', function (...args) {
			if (self.onPause) {
				self.onPause(...args);
			}
		});

		this.resumable.on('cancel', function (...args) {
			if (self.onCancel) {
				self.onCancel(...args);
			}
		});

		this.resumable.on('fileProgress', function (...args) {
			if (self.onFileProgress) {
				self.onFileProgress(...args);
			}
		});

		this.resumable.on('complete', function (...args) {
			if (self.onComplete) {
				self.onComplete(...args);
			}
		});

		this.resumable.appendFilesFromFileList([file], event);
	};

	uploadNow = () => {
		this.resumable.upload();
	}
};

export default {
	install(Vue) {
		Vue.prototype.$UploadFile = UploadFile;
	},
};
