import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const instance = Axios.create({ baseURL: '/api/lbadmin/' });
const axios = setupCache(instance, {
	ttl: 0,
	interpretHeader: false,
	staleIfError: false,
});

async function axiosFetch(vm, request) {
	if (vm == null) {
		throw new Error('Missing vm param in $axiosFetch');
	}
	if (request == null) {
		throw new Error('Missing request param in $axiosFetch');
	}

	try {
		const response = await request;
		return response;

	} catch (error) {
		const customError = {
			url: error.response.config.url,
			status: error.response.status,
			error: null,
			key: vm.$vnode.key,
		};

		if (error.response.status === 404) {
			vm.$emit('view-not-found', customError);

		} else if (error.response.status === 401 || error.response.status === 403) {
			vm.$emit('view-permission-denied', customError);

		} else if (error.response.status === 500) {
			customError.error = error.response.data.error;
			vm.$emit('view-server-error', customError);

		}
		throw new Error(error);

	}
}

const axiosLongCache = { ttl: 24 * 60 * 60 * 1000 }; // 24h
const axiosMediumCache = { ttl: 30 * 60 * 1000 }; // 30m
const axiosShortCache = { ttl: 60 * 1000 }; // 1m

export default {
	install(Vue) {
		Vue.prototype.$http = axios;
		window.$axiosLongCache = axiosLongCache;
		window.$axiosMediumCache = axiosMediumCache;
		window.$axiosShortCache = axiosShortCache;
		Vue.prototype.axiosLongCache = axiosLongCache;
		Vue.prototype.axiosMediumCache = axiosMediumCache;
		Vue.prototype.axiosShortCache = axiosShortCache;
		Vue.prototype.$axiosFetch = axiosFetch;
	},
};
