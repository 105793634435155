<template>
	<div class="switchInlineWrap">
		<span class="leftOption" :class="{ active: !active }" @click="toggle(false)" v-tooltip="help1"
			:data-cy="`${currentComponentId}__leftOption`"
		>
			<span v-if="label1Html" v-html="label1Html"></span>
			<template v-else>{{ label1 }}</template>
			<i v-if="help1" class="icon-tooltip" v-tooltip="help1"></i>
		</span>
		<div class="switchInline">
			<input
				:data-cy="`${currentComponentId}__inputCheckbox`"
				type="checkbox"
				:id="name"
				:name="name"
				:disabled="disabled"
				:required="required"
				v-model="active"
				ref="checkbox"
			>
			<label :for="name" :data-cy="`${currentComponentId}__checkboxLabel`"></label>
		</div>
		<span class="rightOption" :class="{ active: active }" @click="toggle(true)" v-tooltip="help2"
			:data-cy="`${currentComponentId}__rightOption`"
		>
			<span v-if="label2Html" v-html="label2Html"></span>
			<template v-else>{{ label2 }}</template>
			<i v-if="help2" class="icon-tooltip" v-tooltip="help2"></i>
		</span>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false,
		},
		name: {
			type: String,
			required: true,
		},
		label1: {
			type: String,
			required: false,
			default: '',
		},
		label2: {
			type: String,
			required: false,
			default: '',
		},
		label1Html: {
			type: String,
			required: false,
			default: null,
		},
		label2Html: {
			type: String,
			required: false,
			default: null,
		},
		help1: {
			type: String,
		},
		help2: {
			type: String,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			setCheckbox: true,
		};
	},
	computed: {
		active: {
			get() {
				return this.value;
			},
			set(value) {
				if (value !== this.value) {
					this.$emit('input', value);
					this.$emit('change', value);
				}
			},
		},
	},
	methods: {
		toggle(state) {
			if (this.disabled) {
				return;
			};
			if (state != null) {
				this.active = state;
			}
		},
	},
};
</script>
