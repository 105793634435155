import { render, staticRenderFns } from "./LbaTableExport.vue?vue&type=template&id=64e4ea86&scoped=true"
import script from "./LbaTableExport.vue?vue&type=script&lang=js"
export * from "./LbaTableExport.vue?vue&type=script&lang=js"
import style0 from "./LbaTableExport.vue?vue&type=style&index=0&id=64e4ea86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e4ea86",
  null
  
)

export default component.exports