var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noInput)?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId,"readOnly":_vm.readOnly}},[_vm._v(" "+_vm._s(_vm.valueLabel)+" ")]):_c('s',{class:_vm.classes,style:(_vm.style),attrs:{"data-cy":_vm.currentComponentId}},[_c('small',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired)?_c('span',{staticClass:"required-sign"}):_vm._e(),(_vm.titleTooltip)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.titleTooltip),expression:"titleTooltip"}],staticClass:"icon-tooltip"}):_vm._e()]),_c('span',[_c('fake-provider',{ref:_vm.inputRef,attrs:{"name":_vm.$getLocale(_vm.label),"vid":((_vm.$getLocale(_vm.label)) + "-" + _vm.inputRef),"rules":_vm.rules,"customMessages":_vm.customMessages,"immediate":"","data-required-recommended":_vm.requiredRecommended},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [(_vm.attribute.options && _vm.attribute.options.grouped)?_c('select',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data[_vm.key]),expression:"data[key]"}],class:{
					'lba-invalid': (invalid && _vm.dirty),
					fieldIsInvalid: (_vm.showRecommendation),
					'not-set':_vm.options.allowNull && _vm.data[_vm.key] == null
				},attrs:{"data-cy":(_vm.currentComponentId + "__select"),"name":_vm.attribute.name,"title":_vm.title || '',"disabled":_vm.readOnly},on:{"input":function($event){return _vm.onEvent('input')},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, _vm.key, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.onEvent('change')}],"blur":function($event){return _vm.$emit('blur')}}},'select',_vm.bindOptions(),false),_vm._l((_vm.options.options),function(group,index){return _c('optgroup',{key:index,attrs:{"label":group.label}},_vm._l((group.options),function(option,index2){return _c('option',{key:(index + "-" + index2),attrs:{"data-cy":(_vm.currentComponentId + "__select__option" + index + "_" + index2)},domProps:{"value":option.value,"selected":_vm.data[_vm.key] === option.value}},[_vm._v(" "+_vm._s(_vm.$getLocale(option.label))+" ")])}),0)}),0):_c('select',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data[_vm.key]),expression:"data[key]"}],class:{
					'lba-invalid': (invalid && _vm.dirty),
					'showWarning': (_vm.showRecommendation),
					'not-set': _vm.options.allowNull && _vm.data[_vm.key] == null,
				},attrs:{"data-cy":(_vm.currentComponentId + "__select"),"name":_vm.attribute.name,"title":_vm.title || '',"disabled":_vm.readOnly},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, _vm.key, $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.onEvent('change')}],"blur":function($event){return _vm.$emit('blur')}}},'select',_vm.bindOptions(),false),[(_vm.options.allowNull)?_c('option',{staticClass:"not-set",attrs:{"data-cy":(_vm.currentComponentId + "__select__optionNull")},domProps:{"value":null}},[_vm._v(" --"+_vm._s(_vm.$t('notSet'))+"-- ")]):_vm._e(),_vm._l((_vm.options.options),function(option,index){return _c('option',{key:index,attrs:{"data-cy":(_vm.currentComponentId + "__select__option" + index)},domProps:{"value":option.value,"selected":_vm.data[_vm.key] === option.value}},[_vm._v(" "+_vm._s(_vm.$getLocale(option.label))+" ")])})],2),(_vm.dirty)?[_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(err)+" ")])}),_vm._l((_vm.customMessages),function(msg,index){return _c('span',{key:index,staticClass:"lba-messages warning",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(msg)+" ")])})]:_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }