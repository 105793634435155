export default class {
	constructor($http) {
		this.$http = $http;
	}

	getTableOfContent(params) {
		return this.$http.get('lbadmin/documentation', { params });
	}

	get(module, file, params) {
		return this.$http.get(`lbadmin/documentation/${encodeURIComponent(module)}/${encodeURIComponent(file)}`, { params });
	}

	checkDocumentation() {
		return this.$http.get(`lbadmin/documentation-is-available`);
	}
}
